/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import { Row, Button } from 'antd';
import { useSelector } from 'react-redux';
import {
  CloseOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';

import PropTypes from 'prop-types';
import PDF from '../pdf/PDF';
import BasicPDF from '../pdf/BasicPDF';
import Excel from '../excel/Excel';
import PhotoPreview from './PhotoPreview';
import useToggle from '../hooks/useToggle';
import PhotoMetadataModal from './PhotoMetadataModal';
import OnTraccrButton from '../buttons/OnTraccrButton';

const getFullPhotoTitle = (title, file) => {
  if (!title && !file) return '';
  if (title) return title;
  const { name: fileName = '' } = file || {};
  return fileName;
};

export default function FullPhoto({
  path,
  url,
  file,
  rotation: initialRotation = 0,
  type,
  onClose,
  allowClose = true,
  onDownload,
  onSave,
  onLeft,
  showLeft,
  onRight,
  showRight,
  title,
  className = '',
  pdfStyle = {},
  annotatedFileName,
  useApryse,
  enableEscapeToClose = false,
  showMetadata = false,
}) {
  const company = useSelector((state) => state.settings.company);
  const [rotation, setRotation] = useState(initialRotation);
  const { toggle, isToggled } = useToggle(false);
  useEffect(() => {
    if (enableEscapeToClose) {
      const handleKeyDown = (event) => {
        // ESCAPE key
        if (event.keyCode === 27) {
          onClose();
        }
      };
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [enableEscapeToClose, onClose]);

  useEffect(() => {
    setRotation(initialRotation);
  }, [file, initialRotation]);

  const paidPDF = company?.paidPDF;
  const PDFComponent = paidPDF && useApryse ? PDF : BasicPDF;

  const isFullBackground = !className;
  const fullPhotoTitle = getFullPhotoTitle(title, file);
  const getView = () => {
    switch (type) {
      case 'pdf': return (
        <PDFComponent
          url={url}
          file={file}
          onSave={onSave}
          style={pdfStyle}
          darkMode={className !== 'completed-form-contained'}
          className={className}
          annotatedFileName={annotatedFileName}
          onClose={onClose}
        />
      );
      case 'spreadsheet': return <Excel url={url} file={file} />;
      case 'image': return (
        <PhotoPreview
          url={url}
          file={file}
          rotation={rotation}
          setRotation={setRotation}
          className={className}
        />
      );
      default: return (
        <Row className="unknown-file-prompt" justify="center" align="middle">
          <span className="unknown-file-prompt-title">Ontraccr can&apos;t display this file type.</span>
          {onDownload && <span className="unknown-file-prompt-subtext">Please download to view this file.</span>}
          {onDownload && (
          <OnTraccrButton
            title="Download"
            type="cancel"
            icon={<DownloadOutlined />}
            onClick={onDownload}
          />
          )}
        </Row>
      );
    }
  };

  const showButtons = !useApryse || !paidPDF || type !== 'pdf';

  return url || file ? (
    <div
      className={className ? `${className}-full-photo-background` : 'full-photo-background'}
      style={{
        zIndex: 1001,
      }}
    >
      <Row justify="center" align="middle" style={{ width: '100%', height: '100%' }}>
        {getView()}
      </Row>
      {isFullBackground && showButtons && <span className="full-photo-title">{fullPhotoTitle}</span>}
      { type === 'image' && showMetadata && (
        <Button
          className={className ? `${className}-full-photo-info-button` : 'full-photo-info-button'}
          icon={<InfoCircleOutlined />}
          onClick={toggle}
        />
      )}
      {onDownload && showButtons
        && (
        <Button
          className={className ? `${className}-full-photo-download-button` : 'full-photo-download-button'}
          icon={<DownloadOutlined />}
          onClick={onDownload}
        />
        )}
      {allowClose && onClose && showButtons && (
      <Button
        className={className ? `${className}-full-photo-close-button` : 'full-photo-close-button'}
        icon={
          <CloseOutlined />
        }
        onClick={() => onClose(rotation)}
      />
      )}
      {onLeft && showLeft && showButtons && (
      <Button
        className="full-photo-left-button"
        icon={<LeftOutlined />}
        onClick={onLeft}
      />
      )}
      {onRight && showRight && showButtons && (
      <Button
        className="full-photo-right-button"
        icon={<RightOutlined />}
        onClick={onRight}
      />
      )}
      <PhotoMetadataModal
        visible={isToggled}
        onClose={toggle}
        path={path}
      />
    </div>
  ) : null;
}

FullPhoto.propTypes = {
  path: PropTypes.string,
  url: PropTypes.string,
  file: PropTypes.instanceOf(File),
  rotation: PropTypes.number,
  type: PropTypes.string,
  onClose: PropTypes.func,
  allowClose: PropTypes.bool,
  onDownload: PropTypes.func,
  onSave: PropTypes.func,
  onLeft: PropTypes.func,
  showLeft: PropTypes.bool,
  onRight: PropTypes.func,
  showRight: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
  pdfStyle: PropTypes.shape({}),
  annotatedFileName: PropTypes.string,
  useApryse: PropTypes.bool,
  enableEscapeToClose: PropTypes.bool,
  showMetadata: PropTypes.bool,
};

FullPhoto.defaultProps = {
  path: '',
  url: null,
  file: null,
  rotation: 0,
  type: null,
  onClose: null,
  allowClose: true,
  onDownload: null,
  onSave: null,
  onLeft: null,
  showLeft: false,
  onRight: null,
  showRight: false,
  title: '',
  className: '',
  pdfStyle: {},
  annotatedFileName: null,
  useApryse: false,
  enableEscapeToClose: false,
  showMetadata: false,
};
