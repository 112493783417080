import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Drawer, Row, } from 'antd';
import { useTranslation } from 'react-i18next';

import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import DivisionDrawerMoveBody from './DivisionDrawerMoveBody';
import DivisionDrawerAddBody from './DivisionDrawerAddBody';

const getTypeTranslationMap = (t) => ({
  Projects: t('Project', { count: 2 }),
  Teams: 'Teams',
  Users: 'Users',
});

export default ({
  visible,
  onClose,
  onSubmit,
  id,
  name,
  type,
  isMoveMode,
  onSelectChange,
}) => {
  const { t } = useTranslation();
  const divisions = useSelector(state => state.settings.divisions);
  const users = useSelector(state => state.users.users);
  const teams = useSelector(state => state.teams.teams);
  const projects = useSelector(state => state.projects.projects);

  const [destination,setDestination] = useState();
  const [keepOld,setKeepOld] = useState(true);

  const typeTranslationMap = getTypeTranslationMap(t);

  useEffect(() => {
    if(!visible) {
      setDestination();
    }
  },[visible]);

  const submit = useCallback(() => {
    onSubmit({
      divisionId: destination,
      keepOldDivision: keepOld,
    });
  },[onSubmit,destination, keepOld]);

  const onCheckChange = useCallback((e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setKeepOld(checked);
  },[]);

  const body = useMemo(() => {
    if(isMoveMode) {
      return (
        <DivisionDrawerMoveBody
          setDestination={setDestination}
          onCheckChange={onCheckChange}
          keepOld={keepOld}
          name={name}
          divisions={divisions}
          type={type}
        />
      )
    }
    let data = [];
    switch(type) {
      case 'Users': {
        const {
          [id]:{
            users: divUserSet = new Set(),
          } = {},
        } = divisions;
        data = users.filter((user) => !divUserSet.has(user.id));
        break;
      }
      case 'Teams':
        data = teams.filter(({divisionIds = []}) => divisionIds?.every((dId) => dId !== id));
        break;
      case 'Projects':
        data = projects.filter((project) => project.divisionId !== id);
        break;
      default:
        break;
    }

    return (
      <DivisionDrawerAddBody
        data={data}
        type={type}
        translatedType={typeTranslationMap[type]}
        onSelectChange={onSelectChange}
      />
    );
  },[
    id,
    isMoveMode,
    divisions,
    onCheckChange,
    keepOld,
    name,
    type,
    users,
    teams,
    projects,
    onSelectChange,
  ]);

  return (
    <Drawer
      title={`${isMoveMode ? 'Transfer' : 'Add'} ${typeTranslationMap[type]} ${isMoveMode ? 'from' : 'to'} ${name} division`}
      visible={visible}
      onClose={onClose}
      width={500}
      bodyStyle={{ padding: 0 }}
    >
      {body}
      <div className='drawer-footer'>
        <Row justify='end' gutter={10}>
          <OnTraccrButton
            title='Cancel'
            type='cancel'
            style={{ marginRight: 8 }}
            onClick={onClose}
          />
          <OnTraccrButton
            title='Submit'
            disabled={!destination && isMoveMode}
            onClick={submit}
          />
        </Row>
      </div>
    </Drawer>
  );
}