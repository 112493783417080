import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Row, Select } from 'antd';
import { FormOutlined, SettingOutlined } from '@ant-design/icons';

import BorderlessButton from '../../common/buttons/BorderlessButton';

import WorkflowActionNode from './WorkflowActionNode';
import WorkflowHandle from './WorkflowHandle';

import {
  updateData,
} from './workflowHelpers';
import { PRIORITY_OPTS, STATUS_OPTS } from '../../helpers/subtasks.constants';
import useToggle from '../../common/hooks/useToggle';
import WorkflowSimpleMappingConfigureDrawer from './WorkflowSimpleMappingConfigureDrawer';

const requiredFields = new Set(['title']);
const HOVER_TEXT = 'Use this step to create a new task';
const DISABLED_TEXT = 'Add at least one Text Field to enable this step';

const subtaskFields = [
  {
    key: 'title',
    text: 'Task Title',
    type: 'text',
    dataType: undefined,
  },
  {
    key: 'description',
    text: 'Task Description',
    type: 'text',
    dataType: undefined,
  },
  {
    key: 'assigneeId',
    text: 'Assigned User',
    type: 'dropdown',
    dataType: 'Users',
  },
  {
    key: 'dueDate',
    text: 'Due Date',
    type: 'dateTime',
    dataType: undefined,
  },
  {
    key: 'attachments',
    text: 'Attachments',
    type: 'attachment',
    dataType: undefined,
  },
];

const validDataTypes = new Set(['Equipment', 'Cards']);

export default function WorkflowCreateTaskNode({
  setDataMap,
  setElements,
  isDisplay,
  sections = [],
} = {}) {
  return function innerFunc({
    id,
    draggable,
    data = {},
    disabled,
  }) {
    const {
      fieldMappings: initialFieldMappings = {},
      priority: initialPriority,
      status: initialStatus,
      linkFieldId: initialLinkFieldId,
    } = data;

    const { toggle, isToggled } = useToggle();

    const [selectedPriority, setSelectedPriority] = useState(initialPriority);
    const [selectedStatus, setSelectedStatus] = useState(initialStatus);
    const [fieldMappings, setFieldMappings] = useState(initialFieldMappings);
    const [linkFieldId, setLinkFieldId] = useState(initialLinkFieldId);

    const onFieldMappingsChange = useCallback((newMappings) => {
      if (!setDataMap || !id) return;
      setFieldMappings(newMappings);
      setDataMap(updateData(id, { fieldMappings: newMappings }));
    }, [setDataMap, id]);

    const onPriorityChange = useCallback((newPriority) => {
      if (!setDataMap || !id) return;
      setSelectedPriority(newPriority);
      setDataMap(updateData(id, { priority: newPriority }));
    }, [setDataMap, id]);

    const onStatusChange = useCallback((newStatus) => {
      if (!setDataMap || !id) return;
      setSelectedStatus(newStatus);
      setDataMap(updateData(id, { status: newStatus }));
    }, [setDataMap, id]);

    const dropdownFields = useMemo(() => (
      sections
        .flatMap(
          ({ fields }) => (
            fields.filter(
              ({ selectedType, configProps: { dataType } }) => (
                selectedType === 'dropdown' && validDataTypes.has(dataType)
              ),
            )
          ),
        )
        .map(
          ({ id: fieldId, configProps }) => ({
            value: fieldId,
            label: configProps?.title,
            type: configProps?.dataType,
          }),
        )
    ), [sections]);

    const onLinkFieldChange = useCallback((newLinkField) => {
      if (!setDataMap || !id) return;

      const relevantField = dropdownFields.find(({ value }) => value === newLinkField);

      setLinkFieldId(newLinkField);
      setDataMap(updateData(id, { linkFieldId: newLinkField, linkFieldType: relevantField?.type }));
    }, [setDataMap, id, dropdownFields]);

    return (
      <WorkflowActionNode
        title="Create Task"
        Icon={FormOutlined}
        type="createSubtask"
        id={id}
        draggable={draggable}
        onNodeUpdate={setElements}
        isDisplay={isDisplay || disabled}
        hover={disabled ? DISABLED_TEXT : HOVER_TEXT}
        style={disabled ? { opacity: 0.7 } : {}}
      >
        {!draggable && (
          <div>
            <Row style={{ marginTop: 10 }}>
              Select Priority
            </Row>
            <Row style={{ margin: '10px 0px' }}>
              <Select
                onChange={onPriorityChange}
                value={selectedPriority}
                showSearch
                options={PRIORITY_OPTS}
              />
            </Row>
            <Row style={{ marginTop: 10 }}>
              Select Status
            </Row>
            <Row style={{ margin: '10px 0px' }}>
              <Select
                onChange={onStatusChange}
                value={selectedStatus}
                showSearch
                options={STATUS_OPTS}
              />
            </Row>
            <Row style={{ marginTop: 10 }}>
              Select Link Field
            </Row>
            <Row style={{ margin: '10px 0px' }}>
              <Select
                onChange={onLinkFieldChange}
                value={linkFieldId}
                showSearch
                options={dropdownFields}
              />
            </Row>
            <BorderlessButton
              title="Configure"
              style={{ margin: 5 }}
              iconNode={<SettingOutlined />}
              onClick={toggle}
            />
            <WorkflowSimpleMappingConfigureDrawer
              id={id}
              visible={isToggled}
              onClose={toggle}
              onSubmit={toggle}
              sourceSections={sections}
              fieldMappings={fieldMappings}
              onFieldMappingsChange={onFieldMappingsChange}
              data={data}
              setDataMap={setDataMap}
              fields={subtaskFields}
              requiredFields={requiredFields}
            />
            <WorkflowHandle type="target" position="top" disabled={isDisplay} />
          </div>
        )}
      </WorkflowActionNode>
    );
  };
}
