import React, {
  useCallback, useState, useMemo, useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { InputNumber, Row, Select } from 'antd';

import CheckRows from '../CheckRows';
import CompanySettingsCard from '../CompanySettingsCard';
import UserAssignmentSelector from '../../forms/FormWorkflows/selectors/UserAssignmentSelector';
import CompanyEditRow from '../CompanyEditRow';
import Debouncer from '../../helpers/Debouncer';

// eslint-disable-next-line no-useless-escape

const DEFAULT_MAX_HOURS = 10;
const DEFAULT_FREQUENCY = 1;

const emailRegex = new RegExp(/^[^@\s]+@[^@\s\.]+\.[^@\.\s]+$/);

const tokenSeparators = [',', '\n'];

const notificationOptions = [
  {
    key: 'shiftAssignmentUpdateNotification',
    title: 'Shift Assignment/Update Notifications',
    helpText: 'With this setting enabled, users will be sent a notification regarding shift assignments/updates',
    divider: true,
  },
  {
    key: 'scheduledShiftNotifications',
    title: 'Scheduled Shift Notifications',
    helpText: 'With this setting enabled, users will be sent reminders prior to their shifts',
    divider: true,
  },
  {
    key: 'materialQuantityNotifications',
    title: 'Material Quantity Notifications (email only)',
    helpText: 'With this setting enabled, users will be sent an email when any total material quantity is below a preset threshold or when quantity available at any location goes below 0',
    divider: true,
  },
];

const clockoutRows = [
  {
    key: 'clockoutReminderNotification',
    title: 'Clockout Reminder Notifications',
    helpText: 'With this setting enabled, users will be sent a reminder to clock out at the end of their task',
    divider: false,
  },
];

const frequencyRow = [{
  key: 'clockoutReminderRepeatNotification',
  title: 'Repeat Clockout Reminder Notifications',
  helpText: 'With this setting enabled, users will be sent a reminder to clock out at the end of their task every X hours',
  divider: false,
}];

const preferenceRows = [
  {
    key: 'emailNotification',
    title: 'Email Notification',
    helpText: 'With this setting enabled, users will be sent any notifications via email',
    divider: false,
  },
  {
    key: 'pushNotification',
    title: 'Push Notification',
    helpText: 'With this setting enabled, users will be sent any notifications via mobile alert',
    divider: false,
  },
];

const frequencyOptions = [
  { value: 1, label: '1 Hour' },
  { value: 2, label: '2 Hours' },
  { value: 3, label: '3 Hours' },
];

function NotificationPreferences({
  saveCompanySettings,
  settings = {},
}) {
  const maxDebouncer = new Debouncer();
  const { materialRecipients, materialQuantityNotifications } = settings;

  const [selected, setSelected] = useState(materialRecipients || []);
  const [maxHours, setMaxHours] = useState(settings.clockoutReminderMaxHour || DEFAULT_MAX_HOURS);

  const users = useSelector((state) => state.users.users);
  const positionNames = useSelector((state) => state.settings.positionNames);

  const userIds = useMemo(() => {
    const idSet = new Set();
    users.forEach((user) => {
      if (user.active) {
        idSet.add(user.id);
      }
    });
    return idSet;
  }, [users]);

  const positionIds = useMemo(() => {
    const idSet = new Set();
    positionNames.forEach((position) => {
      idSet.add(position.id);
    });
    return idSet;
  }, [positionNames]);

  const validate = (selectedValues) => selectedValues.filter((emailOrId) => (
    userIds.has(emailOrId)
    || positionIds.has(emailOrId)
    || emailRegex.test(emailOrId)
  ));

  const onSettingToggle = useCallback(({ key }) => {
    saveCompanySettings({ [key]: !settings[key] });
  }, [saveCompanySettings, settings]);

  const onSelectChange = useCallback((key) => (newSelected) => {
    saveCompanySettings({ [key]: newSelected });
  }, [saveCompanySettings]);

  const onChange = useCallback((newSelected) => {
    let filteredSelected = newSelected;
    if (validate) {
      filteredSelected = validate(newSelected);
    }

    saveCompanySettings({ materialRecipients: filteredSelected });
    setSelected(filteredSelected);
  }, [validate, saveCompanySettings]);

  useEffect(() => {
    maxDebouncer.debounce(() => {
      saveCompanySettings({ clockoutReminderMaxHour: maxHours });
    }, 500);

    return () => {
      maxDebouncer.clear();
    };
  }, [maxHours]);

  return (
    <>
      <Row style={{ width: '100%', marginLeft: 180, marginTop: 30 }}>
        <CompanySettingsCard title="Notifications">
          <CheckRows
            data={notificationOptions}
            isChecked={({ key }) => settings[key]}
            onChange={onSettingToggle}
          />
          {materialQuantityNotifications && (
            <UserAssignmentSelector
              onChange={onChange}
              selected={selected}
              mode="tags"
              showPositions
              type="email"
              text="Select Recipient(s):"
              tokenSeparators={tokenSeparators}
            />
          )}
        </CompanySettingsCard>
      </Row>
      <Row style={{ width: '100%', marginLeft: 180 }}>
        <CompanySettingsCard title="Task Clockout Reminders">
          <CheckRows
            data={clockoutRows}
            isChecked={({ key }) => settings[key]}
            onChange={onSettingToggle}
          />

          <CompanyEditRow
            title="Threshold"
            helpText="This field specifies the maximum total amount of hours that a user can be clocked in for the day before they are reminded to clock out."
            childColumnStyle={{ marginRight: 50 }}
            divider={false}
          >
            <InputNumber
              value={maxHours}
              onChange={(val) => setMaxHours(val ?? DEFAULT_MAX_HOURS)}
              style={{ width: 75 }}
              min={0}
              max={24}
              disabled={!settings.clockoutReminderNotification}
            />
          </CompanyEditRow>
          <CheckRows
            data={frequencyRow}
            isChecked={({ key }) => settings[key]}
            onChange={onSettingToggle}
            checkBoxDisabled={() => !settings.clockoutReminderNotification}
          />

          <CompanyEditRow
            title="Frequency"
            helpText="This field specifies the frequency in hours at which users will be reminded to clock out."
            childColumnStyle={{ marginRight: 50 }}
            divider={false}
          >
            <Select
              options={frequencyOptions}
              value={settings.clockoutReminderFrequency}
              onChange={onSelectChange('clockoutReminderFrequency')}
              defaultValue={DEFAULT_FREQUENCY}
              disabled={!settings.clockoutReminderRepeatNotification
                || !settings.clockoutReminderNotification}
            />
          </CompanyEditRow>
        </CompanySettingsCard>
      </Row>
      <Row style={{ width: '100%', marginLeft: 180 }}>
        <CompanySettingsCard title="Preferences" style={{ marginBottom: 50 }}>
          <CheckRows
            data={preferenceRows}
            isChecked={({ key }) => settings[key]}
            onChange={onSettingToggle}
          />
        </CompanySettingsCard>
      </Row>
    </>
  );
}

NotificationPreferences.propTypes = {
  saveCompanySettings: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    emailNotification: PropTypes.bool,
    pushNotification: PropTypes.bool,
  }),
};

NotificationPreferences.defaultProps = {
  settings: {},
};

export default NotificationPreferences;
