import React, { useMemo, useCallback, useState } from 'react';
import {
  Checkbox, Col, Divider, Row, Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { DollarCircleOutlined, SettingOutlined } from '@ant-design/icons';

import { getType, updateData } from './workflowHelpers';
import WorkflowActionNode from './WorkflowActionNode';
import WorkflowHandle from './WorkflowHandle';
import HoverHelp from '../../common/HoverHelp';
import WorkflowSimpleMappingConfigureDrawer from './WorkflowSimpleMappingConfigureDrawer';
import useToggle from '../../common/hooks/useToggle';
import BorderlessButton from '../../common/buttons/BorderlessButton';

const HOVER_TEXT = 'This step can update a projects cost based on the fields submitted';
const DISABLED_TEXT = 'Add a Project Dropdown and one of Material Table, Equipment Table, Costcode Table, Time Entry Table, or Sub-Contract Dropdown to enable this step';

const changeOrderFields = [
  { key: 'description', text: 'Description', types: ['text'] },
  { key: 'contractAmount', text: 'Contract Amount', types: ['text'] },
];

const requiredFields = new Set(['description', 'contractAmount']);

export default function WorkflowUpdateCostNode({
  isDisplay,
  setElements,
  setDataMap,
  sections = [],
  name,
} = {}) {
  return function _({
    id,
    draggable,
    data = {},
    disabled
  }) {
    const { t } = useTranslation();

    const {
      projectFieldId: initialProjectFieldId,
      materialTables: initialMaterialTables,
      equipmentTables: initialEquipmentTables,
      costcodeTables: initialCostcodeTables,
      timeEntryTables: initialTimeEntryTables,
      updateBudget: initialUpdateBudget = false,
      subContractFieldId: initialSubContractFieldId,
      fieldMappings: initialFieldMappings = {},
    } = data;

    const title = `Update Cost${disabled ? ' - DISABLED' : ''}`;

    const [selectedProjectFieldId, setSelectedProjectFieldId] = useState(initialProjectFieldId);
    const [selectedMaterialTables, setSelectedMaterialTables] = useState(initialMaterialTables);
    const [selectedEquipmentTables, setSelectedEquipmentTables] = useState(initialEquipmentTables);
    const [selectedCostcodeTables, setSelectedCostcodeTables] = useState(initialCostcodeTables);
    const [selectedTimeEntryTables, setSelectedTimeEntryTables] = useState(initialTimeEntryTables);
    const [selectedSubContractFieldId, setSelectedSubContractFieldId] = useState(initialSubContractFieldId); // eslint-disable-line max-len
    const [fieldMappings, setFieldMappings] = useState(initialFieldMappings);

    const {
      isToggled: isMappingDrawerVisible,
      toggle: toggleMappingDrawer,
    } = useToggle();

    const setMap = {
      projectFieldId: setSelectedProjectFieldId,
      materialTables: setSelectedMaterialTables,
      equipmentTables: setSelectedEquipmentTables,
      costcodeTables: setSelectedCostcodeTables,
      timeEntryTables: setSelectedTimeEntryTables,
      subContractFieldId: setSelectedSubContractFieldId,
      fieldMappings: setFieldMappings,
    };

    const onDataChange = useCallback((key) => (newValue) => {
      if (!setDataMap || !id) return;
      setMap[key]?.(newValue);
      setDataMap(updateData(id, { [key]: newValue }));
    }, [id, setDataMap]);

    const onCheckChange = useCallback((key) => (e) => {
      const {
        target: {
          checked,
        } = {},
      } = e;
      onDataChange(key)(checked);
    }, [onDataChange]);

    const {
      materialTableFields,
      equipmentTableFields,
      costcodeTableFields,
      timeEntryTableFields,
    } = useMemo(() => (
      sections.reduce((acc, section) => {
        const { fields = [] } = section;
        fields.forEach((field) => {
          const { id: fieldId, configProps: { title: fieldTitle } = {} } = field;
          const type = getType(field);
          const fieldObj = { label: fieldTitle, value: fieldId };
          if (type === 'table - Materials') {
            acc.materialTableFields.push(fieldObj);
          } else if (type === 'table - Equipment') {
            acc.equipmentTableFields.push(fieldObj);
          } else if (type === 'table - CostCodes') {
            acc.costcodeTableFields.push(fieldObj);
          } else if (type === 'table - TimeEntry') {
            acc.timeEntryTableFields.push(fieldObj);
          }
        });
        return acc;
      }, {
        materialTableFields: [],
        equipmentTableFields: [],
        costcodeTableFields: [],
        timeEntryTableFields: [],
      })
    ), [sections]);

    const { projectDropdownFields, subContractDropdownFields } = useMemo(() => (
      sections.reduce((acc, section) => {
        const { fields = [] } = section;
        fields.forEach((field) => {
          const { id: fieldId, configProps: { title: fieldTitle } = {} } = field;
          const type = getType(field);
          if (type === 'dropdown - Projects') {
            acc.projectDropdownFields.push({ label: fieldTitle, value: fieldId });
          } else if (type === 'dropdown - PayableSubContracts') {
            acc.subContractDropdownFields.push({ label: fieldTitle, value: fieldId });
          }
        });
        return acc;
      }, { projectDropdownFields: [], subContractDropdownFields: [] })
    ), [sections]);

    return (
      <WorkflowActionNode
        id={id}
        title={title}
        Icon={DollarCircleOutlined}
        type="updateCost"
        draggable={draggable}
        onNodeUpdate={setElements}
        isDisplay={isDisplay || disabled}
        hover={disabled ? DISABLED_TEXT : HOVER_TEXT}
        style={disabled ? { opacity: 0.7 } : { width: 210 }}
      >
        {!draggable && (
          <div>
            <Row style={{ marginTop: 10 }}>
              Select
              {' '}
              {t('Project')}
              {' '}
              Dropdown:
            </Row>
            <Row style={{ margin: '10px 0px' }}>
              <Select
                onChange={onDataChange('projectFieldId')}
                value={selectedProjectFieldId}
                showSearch
                optionFilterProp="label"
                options={projectDropdownFields}
              />
            </Row>
            <Divider style={{ marginTop: 0, marginBottom: 0 }} />
            <Row style={{ marginTop: 10, width: '100%' }}>
              <Col>
                <Checkbox
                  onChange={onCheckChange('updateBudget')}
                  defaultChecked={initialUpdateBudget}
                />
              </Col>
              <Col style={{ padding: '0px 5px' }}>
                Update Budget
              </Col>
              <Col>
                <HoverHelp
                  content={(
                    <div style={{ width: 300 }}>
                      If turned on, table cost updates will update the project budget page.
                      <br />
                      <br />
                      If turned off, table cost updates will update the project progress page.
                    </div>
                  )}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              Select Material Table Field(s):
            </Row>
            <Row style={{ margin: '10px 0px' }}>
              <Select
                mode="multiple"
                onChange={onDataChange('materialTables')}
                value={selectedMaterialTables}
                showSearch
                optionFilterProp="label"
                options={materialTableFields}
              />
            </Row>
            <Row style={{ marginTop: 10 }}>
              Select Equipment Table Field(s):
            </Row>
            <Row style={{ margin: '10px 0px' }}>
              <Select
                mode="multiple"
                onChange={onDataChange('equipmentTables')}
                value={selectedEquipmentTables}
                showSearch
                optionFilterProp="label"
                options={equipmentTableFields}
              />
            </Row>
            <Row style={{ marginTop: 10 }}>
              Select Costcode Table Field(s):
            </Row>
            <Row style={{ margin: '10px 0px' }}>
              <Select
                mode="multiple"
                onChange={onDataChange('costcodeTables')}
                value={selectedCostcodeTables}
                showSearch
                optionFilterProp="label"
                options={costcodeTableFields}
              />
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col style={{ padding: '0px 5px' }} span={20}>
                Select Time Entry Table Field(s):
              </Col>
              <Col>
                <HoverHelp
                  content={(
                    <div style={{ width: 300 }}>
                      Any material & equipment table custom fields in a time entry table will be used to update the projects budget/progress.
                      <br /><br />
                      Cost updated will be logged under the cost code of the time entry.
                    </div>
                  )}
                />
              </Col>
            </Row>
            <Row style={{ margin: '10px 0px' }}>
              <Select
                mode="multiple"
                onChange={onDataChange('timeEntryTables')}
                value={selectedTimeEntryTables}
                showSearch
                optionFilterProp="label"
                options={timeEntryTableFields}
              />
            </Row>
            <Divider style={{ marginTop: 0, marginBottom: 0 }} />
            <Row style={{ marginTop: 10 }}>
              Select Sub-Contract Dropdown Field:
            </Row>
            <Row style={{ margin: '10px 0px' }}>
              <Select
                onChange={onDataChange('subContractFieldId')}
                value={selectedSubContractFieldId}
                showSearch
                optionFilterProp="label"
                options={subContractDropdownFields}
              />
            </Row>
            {selectedSubContractFieldId && (
              <Row style={{ marginTop: 10 }}>
                <BorderlessButton
                  title="configure"
                  style={{ margin: 5 }}
                  iconNode={<SettingOutlined />}
                  onClick={toggleMappingDrawer}
                />
              </Row>
            )}
            <WorkflowSimpleMappingConfigureDrawer
              id={id}
              visible={isMappingDrawerVisible}
              onClose={toggleMappingDrawer}
              onSubmit={toggleMappingDrawer}
              sourceName={name}
              sourceSections={sections}
              fieldMappings={fieldMappings}
              onFieldMappingsChange={onDataChange('fieldMappings')}
              data={data}
              setDataMap={setDataMap}
              fields={changeOrderFields}
              requiredFields={requiredFields}
            />
            <WorkflowHandle type="target" position="top" disabled={isDisplay} />
          </div>
        )}
      </WorkflowActionNode>
    );
  };
}
