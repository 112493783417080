import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Typography, Row, Col,
} from 'antd';
import { FormHelpers, Buckets } from 'ontraccr-common';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { getContactAddressBooks } from 'ontraccr-common/lib/Contacts';
import { addFileToFileMap, rotateFile } from '../files/state/files.actions';
import fieldTypes from './FormBuilder/FormFields/formFields.types';

import LiveFeedFileUpload from '../dashboard/LiveFeed/LiveFeedFileUpload';
import FullPhoto from '../common/photos/FullPhoto';
import CollapseButton from '../common/buttons/CollapseButton';
import BorderlessButton from '../common/buttons/BorderlessButton';
import CustomConfirmModal from '../common/modals/CustomConfirmModal';

import Colors from '../constants/Colors';
import { getFileType, downloadFile } from '../files/fileHelpers';

import {
  preparePreloadedFormTriggerData,
  PO_FORM_RESPONDER_MODE,
} from './ResponderHelpers';
import { getIdMap, uuid } from '../helpers/helpers';
import Analytics from '../helpers/Analytics';

import {
  getProjectEquipment,
  getProjectScheduleOfValues,
  getScheduleOfValueSections,
} from '../projects/state/projects.actions';
import { isObjectEmpty } from '../common/helpers';
import { getCustomers } from '../contacts/customers/state/customers.actions';
import { getMaterials } from '../materials/state/materials.actions';
import { getEquipment, getEquipmentTypes } from '../equipment/state/equipment.actions';
import { getUserTeams } from '../users/state/users.actions';
import { getLabels } from '../labels/state/labels.actions';
import useGetFormDropdownField from '../common/hooks/useGetFormDropdownField';
import { getLightWeightForms } from './state/forms.actions';
import { getBoardDetails } from '../boards/state/boards.actions';
import { getBuckets } from '../buckets/state/buckets.actions';
import { getUnions } from '../unions/state/unions.actions';
import { getVendorLabels } from '../contacts/vendors/state/vendors.actions';

const {
  Text,
} = Typography;

const filterByDivision = (arr, divisionSet) => (
  arr.filter((item) => divisionSet.has(item.divisionId))
);

const interactiveFields = new Set([
  'attachment',
  'gpsLocation',
]);

export default function FormResponseFields({
  mode,
  errors = {},
  sections,
  fileMap = {},
  initialData,
  initialCustomerIds,
  initialProjectIds,
  initialCostcodeIds,
  initialVendorIds,
  initialBucketId,
  templateProjectId,
  templateCustomerId,
  visible = false,
  selectedFormId,
  responses,
  onResponsesChanged,
  onCustomerIdChanged,
  onProjectIdChanged,
  onVendorIdChanged,
  onBucketIdChanged,
  style = {},
  header,
  divisions,
  isExternalForm,
  templateId,
  setFieldTriggerMap,
  fieldTriggerMap,
  onResponseSectionsChanged,
  isDisplay = false,
  sectionPermissionMap,
  parentForm,
  createTimeTableIds,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects.projects);
  const projectTypes = useSelector((state) => state.projects.projectTypes);
  const costcodes = useSelector((state) => state.costcodes.costcodes);
  const phases = useSelector((state) => state.costcodes.phases);
  const scheduleOfValues = useSelector((state) => state.projects.scheduleOfValues);
  const scheduleOfValueSections = useSelector((state) => state.projects.scheduleOfValueSections);
  const userToLabel = useSelector((state) => state.users.userToLabel);
  const customerToLabel = useSelector((state) => state.customers.customerToLabel);
  const vendorToLabel = useSelector((state) => state.vendors.vendorToLabel);
  const formTemplates = useSelector((state) => state.forms.templates);
  const boardDetailsMap = useSelector((state) => state.boards.boardDetailsMap);

  const form = useRef(null);

  const projectIdMap = useMemo(() => getIdMap(projects), [projects]);
  const phaseIdMap = useMemo(() => getIdMap(phases), [phases]);
  const costcodeIdMap = useMemo(() => getIdMap(costcodes), [costcodes]);
  const [key, setKey] = useState(DateTime.local().toMillis());
  const [selectedFile, setSelectedFile] = useState();
  // Keeps track of which file field is currently active/viewed
  // to ensure we cycle through only this field's files
  const [currAttachmentFieldId, setCurrAttachmentFieldId] = useState();
  const [currIndex, setCurrIndex] = useState();
  const [previewProps, setPreviewProps] = useState({});
  const [fileAddId, setFileAddId] = useState();
  const [hasInitialLoaded, setHasInitialLoaded] = useState(false);
  const [scheduleOfValuesSummary, setScheduleOfValuesSummary] = useState();
  const [changeOrderMap, setChangeOrderMap] = useState();
  const [subContractMap, setSubContractMap] = useState();
  const [subContractCOMap, setSubContractCOMap] = useState();
  const [initialResponses, setInitialResponses] = useState({});
  const [collapsed, setCollapsed] = useState(new Array(sections?.length).fill(false));
  const [currFieldId, setCurrFieldId] = useState();
  const [bucketId, setBucketId] = useState();

  // attributes Id state
  const [customerIds, setCustomerIds] = useState();
  const [projectIds, setProjectIds] = useState();
  const [vendorIds, setVendorIds] = useState();
  const [contactIds, setContactIds] = useState();
  const [costcodeIds, setCostcodeIds] = useState();
  const [userIds, setUserIds] = useState();
  const [subContractIds, setSubContractIds] = useState();

  const vendorId = useMemo(() => vendorIds?.[0], [vendorIds]);
  const customerId = useMemo(() => customerIds?.[0], [customerIds]);
  const projectId = useMemo(() => projectIds?.[0], [projectIds]);

  const users = useSelector((state) => state.users.users);
  const materials = useSelector((state) => state.materials.materials);
  const customers = useSelector((state) => state.customers.customers);
  const equipment = useSelector((state) => state.equipment.equipment);
  const projectEquipment = useSelector((state) => state.equipment.projectEquipment);
  const equipmentTypes = useSelector((state) => state.equipment.equipmentTypes);
  const vendors = useSelector((state) => state.vendors.vendors);
  const customTables = useSelector((state) => state.forms.customTables);
  const selectedFormTemplate = useSelector((state) => state.forms.selectedFormTemplate);
  const globalAddressBooks = useSelector((state) => state.contacts.globalAddressBooks);
  const divisionMap = useSelector((state) => state.settings.divisions);
  const labels = useSelector((state) => state.labels);
  const forms = useSelector((state) => state.forms.lightWeightForms);
  const subContractForms = useSelector((state) => state.forms.lightWeightSubContracts);
  const bucketTemplateMap = useSelector((state) => state.buckets.bucketTemplateMap);
  const { settings = {} } = useSelector((state) => state.settings.company);
  const globalFileMap = useSelector((state) => state.files.fileMap);

  const equipmentTypeMap = useMemo(() => getIdMap(equipmentTypes), [equipmentTypes]);

  const contactAddressBooks = useMemo(() => (
    getContactAddressBooks(globalAddressBooks)
  ), [globalAddressBooks]);

  const selectedFileType = useMemo(() => (
    selectedFile ? getFileType(selectedFile) : null
  ), [selectedFile]);

  const onAddFile = useCallback((file) => {
    onResponsesChanged((oldResponses) => {
      const {
        [fileAddId]: oldResponse = {},
      } = oldResponses;
      const {
        files: oldFiles = [],
      } = oldResponse;
      const fileObj = file;
      fileObj.autoSaveId = uuid();
      fileObj.rotation = 0;
      if (!file.existing) {
        Object.defineProperty(fileObj, 'name', {
          writable: true,
          value: `${DateTime.local().toMillis()}-${fileObj.name}`,
        });
      }
      const newFiles = [...oldFiles].concat([fileObj]);
      dispatch(addFileToFileMap(fileObj.autoSaveId, fileObj));
      Analytics.track('FormResponder/FileAdd');
      return {
        ...oldResponses,
        [fileAddId]: {
          ...oldResponse,
          files: newFiles,
        },
      };
    });
  }, [fileAddId]);

  const onNextFileClick = useCallback((isRight) => () => {
    const {
      [currAttachmentFieldId]: existingResponse = {},
    } = responses;
    const {
      files: existingFiles = [],
    } = existingResponse;
    const newIndex = isRight ? currIndex + 1 : currIndex - 1;
    const isInvalid = isRight ? newIndex >= existingFiles.length : newIndex < 0;
    if (isInvalid) return;
    const ourFile = existingFiles[newIndex] || {};
    setSelectedFile(ourFile);
    setCurrIndex(newIndex);
  }, [currIndex, currAttachmentFieldId, responses]);

  const onUploadEnd = useCallback(() => setFileAddId(), []);
  const onCloseFile = useCallback(async (rotation = -1) => {
    if (selectedFile.id) {
      if (await dispatch(rotateFile(selectedFile.id, { rotation }, fileMap?.[selectedFile?.id]?.rotation))) {
        onResponsesChanged((prev) => {
          const savedFile = prev[currFieldId]?.files?.find(({ id }) => id === selectedFile.id);
          if (savedFile) savedFile.rotation = rotation;
          return prev;
        });
      }
    } else if (await dispatch(rotateFile(selectedFile.autoSaveId, { rotation, autoSaved: true }, -1))) {
      onResponsesChanged((prev) => {
        const savedFile = prev[currFieldId]?.files?.find(({ autoSaveId }) => autoSaveId === selectedFile.autoSaveId);
        if (savedFile) savedFile.rotation = rotation;
        return prev;
      });
    }
    setSelectedFile();
    setCurrIndex();
    setCurrAttachmentFieldId();
  }, [selectedFile]);

  const onDownloadFile = useCallback(async () => {
    if (!selectedFile) return;
    const globalFile = globalFileMap[selectedFile.id];
    const downloadOpts = {};
    if (globalFile instanceof File) {
      downloadOpts.fileObject = globalFile;
    } else {
      downloadOpts.fileDetails = selectedFile;
    }
    await downloadFile(downloadOpts);
  }, [selectedFile, globalAddressBooks, globalFileMap]);

  const {
    relevantCompletedField,
    relevantSubContractField,
  } = useGetFormDropdownField({ sections });

  useEffect(() => {
    if ((relevantCompletedField || relevantSubContractField) && visible) {
      const {
        configProps: {
          subDataType: completedSubDataType,
          subDataTypeFilter: completedSubDataTypeFilter,
        } = {},
      } = relevantCompletedField ?? {};
      const {
        configProps: {
          subDataType: subContractSubDataType,
          subDataTypeFilter: subContractSubDataTypeFilter,
        } = {},
      } = relevantSubContractField ?? {};
      dispatch(getLightWeightForms({
        templateId: completedSubDataType,
        statusIds: completedSubDataTypeFilter,
        subContractTemplateId: subContractSubDataType,
        subContractStatusIds: subContractSubDataTypeFilter,
      }));
    }
  }, [relevantCompletedField, relevantSubContractField, visible]);

  useEffect(() => {
    const boardIds = new Set();
    const bucketTemplateIds = new Set();
    sections?.forEach(({ fields = [] }) => {
      fields?.forEach((field) => {
        const { selectedType, configProps: { dataType, subDataType } = {} } = field;
        if (selectedType === 'dropdown') {
          if (dataType === 'Cards') boardIds.add(subDataType);
          if (dataType === 'Buckets') bucketTemplateIds.add(subDataType);
        }
      });
    });

    if (boardIds.size) {
      boardIds.forEach((boardId) => dispatch(getBoardDetails(boardId, false)));
    }

    if (bucketTemplateIds.size) {
      dispatch(getBuckets({ bucketTemplateIds: Array.from(bucketTemplateIds) }));
    }
  }, [sections]);

  useEffect(() => {
    if (!visible) setSelectedFile();
  }, [visible]);

  useEffect(() => {
    // Preload responses
    const {
      id: parentFormId,
      templateId: parentTemplateId,
    } = parentForm ?? {};

    if (!visible || hasInitialLoaded) return;
    if ((!initialData || !fileMap) && !templateProjectId && !templateCustomerId && !parentFormId) {
      if (!selectedFormId) {
        setHasInitialLoaded(true);
      }
      return;
    }

    const newResponses = preparePreloadedFormTriggerData({
      initialData: initialData || {},
      fileMap,
      sections: sections ?? [],
      templateProjectId,
      templateCustomerId,
      parentFormId,
      parentTemplateId,
      projectIdMap,
      customerIdMap: customers,
      formTemplateMap: formTemplates,
    });

    Analytics.track('FormResponder/Preload');
    setInitialResponses(newResponses);
    onResponsesChanged(newResponses);
    setHasInitialLoaded(true);
  }, [
    visible,
    hasInitialLoaded,
    selectedFormId,
    sections,
    initialData,
    fileMap,
    templateProjectId,
    templateCustomerId,
    projectIdMap,
    customers,
    parentForm,
    formTemplates,
  ]);

  useEffect(() => {
    // Preload responses for PO_FORM_RESPONDER_MODE:
    // Required to cover edge case due to form being selected after this component
    // Guarded to only run in PO_FORM_RESPONDER_MODE
    if (!visible || mode !== PO_FORM_RESPONDER_MODE || isObjectEmpty(selectedFormTemplate)) return;
    const { projectId: selectedProjectId } = selectedFormTemplate || {};
    const isProjectFormTemplate = !!selectedProjectId;
    if (isProjectFormTemplate) {
      const newResponses = preparePreloadedFormTriggerData({
        initialData: initialData || {},
        fileMap,
        sections: sections ?? [],
        templateProjectId,
        templateCustomerId,
        projectIdMap,
        customerIdMap: customers,
      });
      setInitialResponses(newResponses);
      onResponsesChanged(newResponses);
    }
  }, [visible, mode, selectedFormTemplate]);

  const onPreviewPropsChanged = useCallback((fieldId) => (newProps) => {
    setPreviewProps({
      ...previewProps,
      [fieldId]: newProps,
    });
  }, [previewProps]);

  const onCollapsedChanged = useCallback((sectionIndex) => () => {
    const newCollapsed = [...collapsed];
    newCollapsed[sectionIndex] = !collapsed[sectionIndex];
    setCollapsed(newCollapsed);
  }, [collapsed]);

  useEffect(() => {
    // Calculate field values for 'calculation' fields
    if (!hasInitialLoaded) return;
    onResponsesChanged((oldResponses) => {
      const {
        newResponses,
        shouldUpdate,
      } = FormHelpers.calculateValues({ responses: oldResponses, sections: sections ?? [] });
      if (!shouldUpdate) return oldResponses;
      Analytics.track('FormResponder/Calculation');
      return newResponses;
    });
  }, [hasInitialLoaded, responses, sections]);

  useEffect(() => {
    // Need to reset project dropdown when customer changes
    if (!customerId) return;
    const projectFields = [];
    sections?.forEach(({ fields = [] }) => {
      fields.forEach((field) => {
        const {
          selectedType,
          configProps: { dataType, shouldDisableSmartFiltering = false } = {},
          id: fieldId,
        } = field;
        if (selectedType === 'dropdown' && dataType === 'Projects' && !shouldDisableSmartFiltering) {
          projectFields.push(fieldId);
        }
      });
    });
    const newResponses = { ...responses };
    let shouldUpdate = false;
    projectFields.forEach((fieldId) => {
      const {
        [fieldId]: {
          values = [],
        } = {},
      } = newResponses;
      const filteredValues = values.filter((val) => {
        const { id: valueProjectId } = val;
        const {
          [valueProjectId]: {
            customerId: projectCustomerId,
          } = {},
        } = projectIdMap;
        return projectCustomerId === customerId;
      });
      if (filteredValues.length !== values.length && values.length > 0) {
        shouldUpdate = true;
        newResponses[fieldId] = { values: filteredValues };
      }
    });
    if (shouldUpdate) {
      Analytics.track('FormResponder/CustomerProjectChange');
      onResponsesChanged(newResponses);
    }
  }, [sections, responses, customerId, projectIdMap]);

  useEffect(() => {
    if (onCustomerIdChanged) onCustomerIdChanged([customerId]);
  }, [customerId]);

  useEffect(() => {
    if (onVendorIdChanged) onVendorIdChanged(vendorId);
  }, [vendorId]);

  const projectIdChanged = (newProjectIds) => {
    setProjectIds(newProjectIds);
    if (onProjectIdChanged) onProjectIdChanged(newProjectIds);
  };

  const bucketIdChanged = (newBucketId) => {
    setBucketId(newBucketId);
    if (onBucketIdChanged) onBucketIdChanged([newBucketId]);
  };

  useEffect(() => {
    if (visible && initialCustomerIds?.length) setCustomerIds(initialCustomerIds);
  }, [visible, initialCustomerIds]);

  useEffect(() => {
    if (visible && initialProjectIds?.length) setProjectIds(initialProjectIds);
  }, [visible, initialProjectIds]);

  useEffect(() => {
    if (visible && initialCostcodeIds?.length) setCostcodeIds(initialCostcodeIds);
  }, [visible, initialCostcodeIds]);

  useEffect(() => {
    if (visible && initialVendorIds?.length) setVendorIds(initialVendorIds);
  }, [visible, initialVendorIds]);

  useEffect(() => {
    if (visible) setBucketId(initialBucketId);
  }, [visible, initialBucketId]);

  useEffect(() => {
    if (!visible) {
      Analytics.track('FormResponder/Close');
      setKey(DateTime.local().toMillis());
      onResponsesChanged({});
      setInitialResponses({});
      setBucketId();
      setHasInitialLoaded(false);
      setScheduleOfValuesSummary();
      setCustomerIds();
      setProjectIds();
      setVendorIds();
      setContactIds();
      setCostcodeIds();
      setUserIds();
      setSubContractIds();
    }
  }, [visible]);

  useEffect(() => {
    if (!isExternalForm) {
      dispatch(getProjectScheduleOfValues({}));
      dispatch(getScheduleOfValueSections({}));
      dispatch(getCustomers());
      dispatch(getMaterials());
      dispatch(getEquipment());
      dispatch(getUserTeams());
      dispatch(getLabels());
      dispatch(getUnions());
      dispatch(getProjectEquipment());
      dispatch(getEquipmentTypes());
      dispatch(getVendorLabels());
    }
  }, [isExternalForm]);

  useEffect(() => {
    // Do not collapse sections if they have been manually expanded
    if (hasInitialLoaded) return;
    if (sections?.length) {
      setCollapsed(sections.map((section) => (
        section?.settings?.defaultCollapsed
      )));
    } else {
      setCollapsed([]);
    }
  }, [sections]);

  const scheduleOfValueSectionMap = useMemo(() => (
    getIdMap(scheduleOfValueSections)
  ), [scheduleOfValueSections]);

  useEffect(() => {
    let mostRecentValue;
    let values;
    const projectChangeOrderMap = {};
    const projectSubContractMap = {};
    const subContractChangeOrderMap = {};
    const summary = {};

    Object.keys(scheduleOfValues).forEach((sovKey) => {
      values = scheduleOfValues[sovKey];

      mostRecentValue = values[values.length - 1];

      if (mostRecentValue.isChangeOrder) {
        if (!projectChangeOrderMap[mostRecentValue.projectId]) {
          projectChangeOrderMap[mostRecentValue.projectId] = [];
        }
        if (mostRecentValue.isSubContract) {
          if (!(mostRecentValue.parentRowId in subContractChangeOrderMap)) {
            subContractChangeOrderMap[mostRecentValue.parentRowId] = {
              changes: [],
              totalChanges: 0,
            };
          }
          subContractChangeOrderMap[mostRecentValue.parentRowId].changes.push(mostRecentValue);
          subContractChangeOrderMap[mostRecentValue.parentRowId]
            .totalChanges += mostRecentValue.contractAmount;
        }

        projectChangeOrderMap[mostRecentValue.projectId].push(mostRecentValue);
      } else if (mostRecentValue.isSubContract) {
        if (!projectSubContractMap[mostRecentValue.projectId]) {
          projectSubContractMap[mostRecentValue.projectId] = [];
        }

        projectSubContractMap[mostRecentValue.projectId].push(mostRecentValue);
      }

      const project = projectIdMap[mostRecentValue.projectId];
      if (!project) return;
      const {
        totalContractAmount: totalBaseContract = 0,
        holdbackPercentage = 0.1,
      } = project || {};

      if (!(mostRecentValue.projectId in summary)) {
        summary[mostRecentValue.projectId] = {
          projectId: mostRecentValue.projectId,
          totalBaseContract,
          totalChanges: 0,
          totalCompletedToDate: 0,
          totalBilledToDate: 0,
          totalHoldbackToDate: 0,
          holdbackPercentage,
          contractTotal: 0,
          invoiceAmount: 0,
          invoices: [],
        };
      }

      const projectSummary = summary[mostRecentValue.projectId];

      if (mostRecentValue.rowId === 'log') {
        projectSummary.invoices.push(mostRecentValue);
        return;
      }

      if (mostRecentValue.isChangeOrder) {
        projectSummary.totalChanges += mostRecentValue.contractAmount;
      }

      const relevantSection = scheduleOfValueSectionMap[mostRecentValue.sectionId];
      if (!relevantSection || relevantSection.shouldBeInCalculations) {
        projectSummary.totalBilledToDate += mostRecentValue.previousBillings;
        projectSummary.invoiceAmount += mostRecentValue.invoiceAmount;
      }
      projectSummary.totalHoldbackToDate += mostRecentValue.holdbackAmount;
    });

    Object.values(summary).forEach((projectSummary) => {
      // eslint-disable-next-line no-param-reassign
      projectSummary.contractTotal = projectSummary.totalBaseContract + projectSummary.totalChanges;
      // eslint-disable-next-line no-param-reassign
      projectSummary.totalCompletedToDate = (
        projectSummary.totalBilledToDate + projectSummary.invoiceAmount
      ) / projectSummary.contractTotal;
    });

    setScheduleOfValuesSummary(summary);
    setChangeOrderMap(projectChangeOrderMap);
    setSubContractMap(projectSubContractMap);
    setSubContractCOMap(subContractChangeOrderMap);
  }, [scheduleOfValues, projectIdMap, projectId, scheduleOfValueSectionMap]);

  const customerList = useMemo(() => Object.values(customers), [customers]);
  const vendorList = useMemo(() => Object.values(vendors), [vendors]);
  const divisionSet = useMemo(() => new Set(divisions), [divisions]);
  const divisionProjects = useMemo(() => (
    filterByDivision(projects, divisionSet)
  ), [projects, divisionSet]);

  const divisionEquipment = useMemo(() => (
    equipment.filter((eq) => eq.divisionIds.some((divId) => divisionSet.has(divId)))
  ), [equipment, divisionSet]);

  const divisionCostcodes = useMemo(() => (
    filterByDivision(costcodes, divisionSet)
  ), [costcodes, divisionSet]);

  const divisionMaterials = useMemo(() => {
    const divMaterials = {};
    Object.values(materials).forEach((mat) => {
      if (divisionSet.has(mat.divisionId)) {
        divMaterials[mat.id] = mat;
      }
    });
    return divMaterials;
  }, [materials, divisionSet]);

  const divUsers = useMemo(() => {
    const allUsers = divisions.map((divisionId) => {
      const {
        [divisionId]: {
          users: divisionUsers = new Set(),
        } = {},
      } = divisionMap;
      return Array.from(divisionUsers);
    });
    const userSet = new Set(allUsers.flat());
    return users.filter((user) => user.active && userSet.has(user.id));
  }, [users, divisions, divisionMap]);

  const userIdMap = useMemo(() => getIdMap(divUsers), [divUsers]);

  const divisionFormTemplates = useMemo(
    () => Object
      .values(formTemplates)
      .filter(({ divisionId }) => divisionSet.has(divisionId)),
    [formTemplates, divisionSet],
  );

  const copySection = useCallback((sectionId) => () => {
    const relevantSectionIndex = sections?.findIndex(({ id }) => id === sectionId) ?? -1;
    if (relevantSectionIndex < 0) return;
    const newSections = [...sections];
    newSections.splice(
      relevantSectionIndex + 1,
      0,
      FormHelpers.duplicateSection(sections[relevantSectionIndex]),
    );

    onResponseSectionsChanged(newSections);
  }, [sections, onResponseSectionsChanged]);

  const onDeleteClicked = useCallback((sectionId) => () => {
    const relevantSection = sections?.find(({ id }) => id === sectionId);
    if (!relevantSection) return;
    CustomConfirmModal({
      title: `Remove Section ${relevantSection.name}?`,
      onOk: () => {
        const newSections = sections.filter(({ id }) => id !== sectionId);
        onResponseSectionsChanged(newSections);
      },
      okText: 'Remove',
    });
  }, [sections, onResponseSectionsChanged]);

  const selectedBucket = useMemo(() => (
    Object.values(bucketTemplateMap).flat().find((bucket) => bucket.id === bucketId)
  ), [bucketId, bucketTemplateMap]);

  const {
    selectedBucketTypeToIdMap,
    selectedBucketTypes,
  } = useMemo(() => (
    Buckets.getRelevantSelectedBucketDetails({ responses, sections })
  ), [responses, sections]);

  return (
    <>
      <div className="form-response-container" style={style}>
        { header ?? null }
        {sections?.map(({
          name,
          id: sectionId,
          fields = [],
          hidden,
          settings: {
            defaultCollapsed,
            isDuplicationEnabled = false,
          } = {},
          duplicatedParentId,
        }, sectionIndex) => (
          !hidden && (!sectionPermissionMap || sectionPermissionMap[sectionId]?.canView) ? (
            <div key={sectionId}>
              <Row
                align="middle"
                style={{
                  marginBottom: 5,
                  padding: '2px 5px',
                  border: '1px solid lightgray',
                  backgroundColor: '#4a4a4a',
                }}
              >
                <Col>
                  {
                    defaultCollapsed
                    && (
                      <CollapseButton
                        open={!collapsed[sectionIndex]}
                        onClick={onCollapsedChanged(sectionIndex)}
                        color="white"
                      />
                    )
                  }
                </Col>
                <Col style={{
                  height: 25,
                  marginLeft: defaultCollapsed ? 10 : 0,
                }}
                >
                  <Text
                    style={{
                      fontFamily: 'roboto-medium',
                      fontSize: 18,
                      verticalAlign: 'baseline',
                      color: 'white',
                    }}
                  >
                    {name}
                  </Text>
                </Col>
                <Col style={{ paddingLeft: 20 }}>
                  <Row justify="end" align="middle" style={{ height: 29 }} gutter={10}>
                    <Col>
                      { isDuplicationEnabled && (
                        <BorderlessButton
                          style={{ padding: 0, background: 'inherit', color: 'white' }}
                          iconNode={<CopyOutlined />}
                          onClick={copySection(sectionId)}
                        />
                      )}
                    </Col>
                    <Col>
                      { duplicatedParentId && (
                        <BorderlessButton
                          style={{ padding: 0, background: 'inherit' }}
                          iconNode={<DeleteOutlined style={{ color: Colors.FADED_ONTRACCR_RED }} />}
                          onClick={onDeleteClicked(sectionId)}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              {hasInitialLoaded
                && !collapsed[sectionIndex]
                && fields.map(({ id: fieldId, configProps, selectedType }) => {
                  const { preview } = fieldTypes[selectedType];
                  const {
                    hasConditionalRendering,
                    conditionalRenderingFormula,
                  } = configProps;

                  // If a field has conditional rendering, and the formula is complete,
                  // and the formula returns false, we don't render the field
                  if (
                    hasConditionalRendering
                    && FormHelpers.isConditionalRenderingFormulaComplete(conditionalRenderingFormula)
                    && !FormHelpers.executeConditionalRenderingFormula({
                      formula: conditionalRenderingFormula,
                      responses,
                    })
                  ) {
                    return null;
                  }

                  const isDisplayOrUnpermitted = isDisplay
                    || (sectionPermissionMap && !sectionPermissionMap[sectionId]?.canEdit);
                  return (
                    <div
                      key={fieldId}
                      style={{
                        paddingBottom: 20,
                        paddingLeft: 5,
                        paddingRight: 5,
                        height: collapsed[sectionIndex] ? 0 : 'auto',
                        pointerEvents: isDisplayOrUnpermitted && !interactiveFields.has(selectedType) ? 'none' : 'auto',
                      }}
                    >
                      {preview({
                        key,
                        id: fieldId,
                        configProps,
                        setResponses: onResponsesChanged,
                        responses,
                        initialResponses,
                        users: divUsers,
                        projects: divisionProjects,
                        equipment: divisionEquipment,
                        customers: customerList,
                        costcodes: divisionCostcodes,
                        formTemplates: divisionFormTemplates,
                        phases,
                        scheduleOfValues: scheduleOfValuesSummary,
                        vendors: vendorList,
                        contactAddressBooks,
                        globalAddressBooks,
                        materials: divisionMaterials,
                        labels,
                        projectEquipment,
                        responding: true,
                        onOpenFileModal: setFileAddId,
                        onOpenFile: setSelectedFile,
                        setCurrAttachmentFieldId,
                        setOpenFileIndex: setCurrIndex,
                        setPreviewProps: onPreviewPropsChanged(fieldId),
                        previewProps: previewProps[fieldId],
                        formRef: { current: form },
                        selectedBucket,
                        setBucketId: bucketIdChanged,
                        customerIds,
                        customerId,
                        setCustomerIds,
                        projectIds,
                        projectId,
                        setProjectIds: projectIdChanged,
                        vendorIds,
                        vendorId,
                        setVendorIds,
                        contactIds,
                        setContactIds,
                        costcodeIds,
                        setCostcodeIds,
                        userIds,
                        setUserIds,
                        subContractIds,
                        setSubContractIds,
                        customTables,
                        userToLabel,
                        customerToLabel,
                        vendorToLabel,
                        locked: templateProjectId,
                        changeOrderMap,
                        subContractMap,
                        subContractCOMap,
                        projectIdMap,
                        costcodeIdMap,
                        phaseIdMap,
                        userIdMap,
                        vendorIdMap: vendors,
                        divisions,
                        templateId,
                        fieldTriggerMap,
                        setFieldTriggerMap,
                        isExternalForm,
                        t,
                        projectTypes,
                        equipmentTypes,
                        equipmentTypeMap,
                        isDisplay: isDisplayOrUnpermitted,
                        fileMap,
                        sections,
                        forms,
                        subContractForms,
                        parentForm,
                        editing: true,
                        setCurrFieldId,
                        boardDetailsMap,
                        bucketTemplateMap,
                        createTimeTableIds,
                        selectedBucketTypeToIdMap,
                        selectedBucketTypes,
                        settings,
                        globalFileMap,
                      })}
                      {errors[fieldId] && (
                        <Text
                          style={{
                            color: Colors.ONTRACCR_RED,
                          }}
                        >
                          {errors[fieldId]}
                        </Text>
                      )}
                    </div>
                  );
                })}
            </div>
          ) : null))}
      </div>
      <FullPhoto
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...selectedFile}
        rotation={selectedFile?.rotation !== undefined
          ? selectedFile.rotation
          : (fileMap?.[selectedFile?.id]?.rotation ?? 0)}
        file={globalFileMap[selectedFile?.id || selectedFile?.autoSaveId]}
        type={selectedFileType}
        onClose={onCloseFile}
        onLeft={onNextFileClick(false)}
        onRight={onNextFileClick(true)}
        showLeft={selectedFile && currIndex > 0}
        showRight={selectedFile
          && responses
          && currIndex < ((responses[currAttachmentFieldId]?.files?.length ?? 0) - 1)}
        onDownload={onDownloadFile}
        useApryse
      />
      <LiveFeedFileUpload
        visible={!!fileAddId}
        onUploadEnd={onUploadEnd}
        addFile={onAddFile}
        customProps={{
          width: 600,
          zIndex: 1001,
          style: {},
        }}
        placement="right"
        isExternalForm={isExternalForm}
      />
    </>
  );
}

FormResponseFields.propTypes = {
  mode: PropTypes.string.isRequired,
  errors: PropTypes.objectOf(PropTypes.string),
  sections: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      configProps: PropTypes.shape({}),
      selectedType: PropTypes.string,
    })),
    duplicatedParentId: PropTypes.string,
  })),
  fileMap: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
  })),
  initialData: PropTypes.shape({}),
  initialCustomerIds: PropTypes.arrayOf(PropTypes.string),
  initialProjectIds: PropTypes.arrayOf(PropTypes.string),
  initialCostcodeIds: PropTypes.arrayOf(PropTypes.string),
  initialVendorIds: PropTypes.arrayOf(PropTypes.string),
  initialBucketId: PropTypes.string,
  templateProjectId: PropTypes.string,
  templateCustomerId: PropTypes.string,
  visible: PropTypes.bool,
  selectedFormId: PropTypes.string,
  responses: PropTypes.shape({}),
  onResponsesChanged: PropTypes.func,
  onCustomerIdChanged: PropTypes.func,
  onProjectIdChanged: PropTypes.func,
  onVendorIdChanged: PropTypes.func,
  onBucketIdChanged: PropTypes.func,
  style: PropTypes.shape({}),
  header: PropTypes.node,
  divisions: PropTypes.arrayOf(PropTypes.string),
  isExternalForm: PropTypes.bool.isRequired,
  templateId: PropTypes.string,
  setFieldTriggerMap: PropTypes.func.isRequired,
  fieldTriggerMap: PropTypes.shape({}),
  onResponseSectionsChanged: PropTypes.func.isRequired,
  isDisplay: PropTypes.bool,
  sectionPermissionMap: PropTypes.shape({}),
  parentForm: PropTypes.shape({}),
  createTimeTableIds: PropTypes.arrayOf(PropTypes.string),
};

FormResponseFields.defaultProps = {
  errors: {},
  sections: undefined,
  fileMap: null,
  initialData: null,
  initialCustomerIds: [],
  initialProjectIds: [],
  initialCostcodeIds: [],
  initialVendorIds: [],
  initialBucketId: null,
  templateProjectId: null,
  templateCustomerId: null,
  visible: false,
  selectedFormId: null,
  responses: undefined,
  onResponsesChanged: null,
  onCustomerIdChanged: null,
  onProjectIdChanged: null,
  onVendorIdChanged: null,
  onBucketIdChanged: null,
  style: {},
  header: null,
  divisions: [],
  templateId: null,
  fieldTriggerMap: {},
  isDisplay: false,
  sectionPermissionMap: null,
  parentForm: undefined,
  createTimeTableIds: [],
};
