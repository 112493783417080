import axios from 'axios';
import { fileIconMap, getFileType, visuallyDownloadFile } from '../files/fileHelpers';
import { includesTerm, isNullOrUndefined } from './helpers';

const fileGetter = axios.create({
  responseType: 'blob',
});
const fileUploader = axios.create();

export const sortFiles = (files = []) => {
  const sorted = [...files];
  sorted.sort((a, b) => {
    if (a.type === 'folder' && b.type !== 'folder') {
      return -1;
    }
    if (a.type !== 'folder' && b.type === 'folder') {
      return 1;
    }
    const aVal = (a?.name || '~')?.toLowerCase()?.trim();
    const bVal = (b?.name || '~')?.toLowerCase()?.trim();
    return aVal.localeCompare(bVal, undefined, { numeric: true });
  });
  return sorted;
};

export const downloadFiles = async (files = []) => {
  let parsedFiles = [];
  const existingFiles = [];
  const ids = [];
  files.forEach((file) => {
    if (file instanceof File) {
      existingFiles.push(file);
    } else {
      ids.push(file);
    }
  });
  try {
    const { data: urls } = await axios.post('/files', { ids });
    parsedFiles = await Promise.all(
      urls.map(async ({ url, id }, idx) => {
        const { data: blob } = await fileGetter(url);
        const { name } = files[idx];
        const file = new File([blob], name, { type: getFileType({ name }) });
        file.id = id;
        return file;
      }),
    );
  } catch (err) {
    console.log(err);
  }
  return parsedFiles.concat(existingFiles);
};

export const uploadFiles = async (files = []) => {
  let existingFiles = [];
  const newFiles = [];
  // New files need to be uploaded to S3
  files.forEach((file) => {
    if (file.id) {
      existingFiles.push({ id: file.id });
    } else {
      newFiles.push(file);
    }
  });

  const decoratedFiles = [];
  if (newFiles.length) {
    let parsedFiles = [];
    const {
      data: urls = [],
    } = await axios.post('/field_data/file/upload/url', {
      files: newFiles.map(({ type, name, timestamp }) => ({
        type,
        name,
        timestamp,
      })),
    });
    parsedFiles = await Promise.all(urls.map(async ({
      url,
      type,
      id,
      name,
      timestamp,
    }, index) => {
      await fileUploader.put(url, files[index], {
        headers: {
          'Content-Type': type,
        },
      });
      return {
        id,
        isNew: true,
        name,
        timestamp,
      };
    }));
    parsedFiles.forEach(({ id }, idx) => {
      const ourFile = newFiles[idx];
      ourFile.id = id;
      decoratedFiles.push(ourFile);
    });
    existingFiles = existingFiles.concat(parsedFiles);
  }
  return { payloadFiles: existingFiles, decoratedFiles };
};

export const constructTreeData = ({
  id,
  fileStructure = {},
  rootFiles = [],
  node,
  name,
  path,
  level = 0,
  selectedPaths = new Set(),
  parentDisabled = false,
  icon,
  selectable,
  searchTerm,
  onlyFolders = false,
}) => {
  const fullPath = path && level > 1 ? `${path}${name}` : name;
  const isDisabled = selectedPaths.has(fullPath);
  const treeNode = {
    id,
    title: name.substring(0, name.length - 1),
    key: fullPath,
    children: [],
    icon,
    selectable,
    disabled: isDisabled || parentDisabled,
  };
  let children = [];
  if (node) {
    const {
      children: nodeChild = [],
    } = node;
    children = nodeChild;
  } else {
    children = rootFiles.map(({ fullPath }) => fullPath);
  }
  children.forEach((child) => {
    if (child in fileStructure) {
      const file = fileStructure[child];
      const fileType = getFileType(file);
      const Icon = fileIconMap[fileType];
      const isFolder = fileType === 'folder';
      const childNode = constructTreeData({
        fileStructure,
        rootFiles,
        node: file,
        id: file.id,
        name: `${file.name}/`,
        path: fullPath,
        level: level + 1,
        parentDisabled: isDisabled || parentDisabled,
        selectedPaths,
        icon: <Icon />,
        selectable: (!onlyFolders && !isFolder) || (onlyFolders && isFolder),
        searchTerm,
        onlyFolders,
      });
      if (onlyFolders && !isFolder) return;

      if (includesTerm(fullPath, searchTerm) || includesTerm(child, searchTerm)
        || (childNode && childNode.children && childNode.children.length > 0)) {
        treeNode.children.push(childNode);
      }
    }
  });
  return treeNode;
};

/**
 * Cycles through files in the direction specified
 * @param {boolean} isRight - direction to cycle
 * @param {object} selectedFileDetails - contains info regarding current index and files
 * @param {object} fileMap - file details
 * @param {function} setSelectedFile
 * @param {function} setSelectedFileDetails
 */
export const onNextFileClick = ({
  isRight,
  selectedFileDetails,
  fileMap,
  setSelectedFile,
  setSelectedFileDetails,
  globalFileMap = {},
  dispatch,
}) => {
  const {
    index: currIndex,
    response: {
      fileIds = [],
      files = [],
    } = {},
  } = selectedFileDetails || {};
  if (isNullOrUndefined(currIndex) || (!Array.isArray(fileIds) && !Array.isArray(files))) return;
  let ourFiles = files;
  if (fileIds.length && !files.length) {
    ourFiles = fileIds
      .filter((fileId) => fileMap[fileId] && fileMap[fileId].jsFileObject)
      .map((fileId) => fileMap[fileId].jsFileObject);
  }
  const newIndex = isRight ? currIndex + 1 : currIndex - 1;
  const isInvalid = isRight ? newIndex >= ourFiles.length : newIndex < 0;
  if (isInvalid) return;
  const ourFile = ourFiles[newIndex] || {};
  const trueId = ourFile.id || ourFile.autoSaveId;
  const globalFile = globalFileMap[trueId];
  if (!globalFile && dispatch && trueId) {
    visuallyDownloadFile({
      file: ourFile,
      dispatch,
    });
  }
  setSelectedFile(ourFile);
  setSelectedFileDetails((prvFileDetails) => ({ ...prvFileDetails, index: newIndex }));
};

/**
 * Returns true if we should show the right file cycle button, false otherwise
 * @param {object} selectedFile
 * @param {object} selectedFileDetails - contains info regarding current index and files
 * @returns {boolean}
 */
export const showRightFileCycleButton = ({
  selectedFile,
  selectedFileDetails,
  fileMap,
}) => {
  const { index: currIndex, response: { fileIds = [] } = {} } = selectedFileDetails || {};
  const files = fileIds.map((fileId) => ({ ...(fileMap[fileId] || {}) }));
  return selectedFile && currIndex < (files.length - 1);
};

/**
 * Returns true if we should show the left file cycle button, false otherwise
 * @param {object} selectedFile
 * @param {object} selectedFileDetails - contains info regarding current index and files
 * @returns {boolean}
 */
export const showLeftFileCyleButton = ({ selectedFile, selectedFileDetails }) => (
  selectedFile && selectedFileDetails && selectedFileDetails?.index > 0
);
