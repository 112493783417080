import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, notification } from 'antd';
import PropTypes from 'prop-types';

import ScheduleOfValuesInput from './ScheduleOfValuesInput';
import {
  getLatestRowValue,
  isValueWithinBounds,
  isInputDirty,
} from './helpers';
import { isNullOrUndefined, percentageFormatter } from '../../helpers/helpers';
import {
  updateProjectScheduleOfValues,
  updateIndividualProject,
  updateScheduleOfValuesRow,
  updateProgressSubContract,
  updateProgressSubContractRow,
} from '../state/projects.actions';

function ScheduleOfValuesInputField({
  projectId,
  templateId,
  rowId,
  field,
  type,
  formatter,
  totalContractAmount,
  currentContractValue,
  holdbackPercentage,
  title,
  liveSummaryValues,
  min,
  max,
  subType,
  isProgress,
  formId,
}) {
  const dispatch = useDispatch();
  const stateRow = useSelector((state) => getLatestRowValue({
    state,
    projectId,
    templateId,
    rowId,
    formId,
    isProgress,
  }));

  const row = rowId ? stateRow : {
    currentContractValue,
    totalContractAmount,
    holdbackPercentage,
  };

  const [showPopover, setShowPopover] = useState(false);
  const [value, setValue] = useState(
    subType === 'percentage'
      ? row[field] * 100
      : row[field],
  );

  const resetValue = (val) => {
    if (subType === 'percentage') {
      setValue(percentageFormatter(val));
    } else {
      setValue(val);
    }
  };

  const timeoutRef = useRef(null);

  useEffect(() => {
    if (row && rowId) setValue(row[field]);
  }, [row, rowId]);

  const onConfirm = useCallback(async () => {
    notification.close('inputFieldOutOfRange');

    if (rowId) {
      const newRow = {
        itemNumber: row.itemNumber,
        description: row.description,
        contractAmount: row.contractAmount,
        percentageComplete: row.percentageComplete,
        invoiceAmount: row.invoiceAmount,
        progressToDate: row.progressToDate,
        previousBillings: row.previousBillings,
        rowId,
        [field]: value,
        updatedField: field,
        isChangeOrder: !!row.isChangeOrder,
        isSubContract: !!row.isSubContract,
        holdbackAmount: row.holdbackAmount,
        excludeHoldback: !!row.excludeHoldback,
        parentRowId: row.parentRowId,
        sectionId: row.sectionId,
      };

      if (isProgress) newRow.formId = row.formId;

      const ourUpdateFunc = isProgress ? updateProgressSubContract : updateProjectScheduleOfValues;

      const result = await dispatch(ourUpdateFunc({
        projectId,
        templateId,
        payload: newRow,
      }));
      if (result) {
        setShowPopover(false);
      }
    } else {
      const result = await dispatch(updateIndividualProject({
        projectId,
        payload: {
          details: {
            [field]: subType === 'percentage'
              ? (value / 100).toFixed(2)
              : value.toFixed(2),
          },
        },
      }));
      if (result) {
        setShowPopover(false);
      }
    }
  }, [
    value,
    projectId,
    templateId,
    rowId,
    row,
    isProgress,
  ]);

  const onCancel = useCallback(() => {
    let originalValues = {};

    // Clear debounced onChange since we're cancelling all changes
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (type !== 'text' && rowId) {
      originalValues = {
        progressToDate: !isNullOrUndefined(row.oldProgressToDate)
          ? row.oldProgressToDate
          : row.progressToDate,
        percentageComplete: !isNullOrUndefined(row.oldPercentageComplete)
          ? row.oldPercentageComplete
          : row.percentageComplete,
        contractAmount: !isNullOrUndefined(row.oldContractAmount)
          ? row.oldContractAmount
          : row.contractAmount,
      };

      if (!isProgress) {
        originalValues.invoiceAmount = !isNullOrUndefined(row.oldInvoiceAmount)
          ? row.oldInvoiceAmount
          : row.invoiceAmount;
      }

      const ourUpdateFunc = isProgress ? updateProgressSubContractRow : updateScheduleOfValuesRow;

      dispatch(ourUpdateFunc({
        projectId,
        templateId,
        row,
        payload: originalValues,
        formId,
      }));
      notification.close('inputFieldOutOfRange');
    } else {
      resetValue(row[field]);
      notification.close('inputFieldOutOfRange');
    }
    setShowPopover(false);
  }, [
    row,
    projectId,
    templateId,
    rowId,
    type,
    isProgress,
    formId,
  ]);

  const handleValidation = useCallback((newValue) => {
    const validationResult = isValueWithinBounds(
      row,
      field,
      newValue,
      liveSummaryValues,
      isProgress,
    );

    if (!validationResult.valid) {
      notification.warn({
        key: 'inputFieldOutOfRange',
        message: 'Warning',
        description: validationResult.invalidMessage,
      });
    } else {
      notification.close('inputFieldOutOfRange');
    }

    const ourUpdateFunc = isProgress ? updateProgressSubContractRow : updateScheduleOfValuesRow;

    if (rowId) {
      dispatch(ourUpdateFunc({
        formId,
        projectId,
        templateId,
        row,
        payload: {
          [field]: newValue,
        },
      }));
    }

    if (!isInputDirty(row, field, newValue)) {
      setShowPopover(false);
    }

    timeoutRef.current = null;
  }, [
    row,
    projectId,
    templateId,
    liveSummaryValues,
    formId,
    isProgress,
  ]);

  const onChange = useCallback((newValue) => {
    setShowPopover(true);

    // Refresh debounce period
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    let newVal = newValue;

    if (type === 'text') {
      newVal = newValue.target.value;

      if (!isInputDirty(row, field, newVal)) {
        setShowPopover(false);
      }
    } else {
      // Debounce with 250ms period
      const timeout = setTimeout(() => {
        handleValidation(newVal);
      }, 250);
      timeoutRef.current = timeout;
    }

    setValue(newVal);
  }, [handleValidation, liveSummaryValues]);

  return (
    <Row style={{ height: '100%' }} align="middle" gutter={10}>
      <Col style={{ height: 32 }}>
        <ScheduleOfValuesInput
          title={`Update ${title}`}
          showPopover={showPopover}
          onConfirm={onConfirm}
          onCancel={onCancel}
          onChange={onChange}
          value={value}
          type={type}
          formatter={formatter}
          placeholder="Description"
          min={min}
          max={max}
          step={0.01}
        />
      </Col>
    </Row>
  );
}

ScheduleOfValuesInputField.propTypes = {
  formId: PropTypes.string,
  isProgress: PropTypes.bool,
};

ScheduleOfValuesInputField.defaultProps = {
  formId: null,
  isProgress: false,
};

export default ScheduleOfValuesInputField;
