import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Route, withRouter, Redirect } from 'react-router-dom';
import { Row, Col } from 'antd';
import { withTranslation } from 'react-i18next';

import Analytics from '../helpers/Analytics';
import BreadCrumbContainer from '../common/breadcrumbContainer/breadcrumbContainer';

import { createUser } from '../users/state/users.actions';
import { toggleFullscreen } from '../main/state/main.actions';
import { getProjects } from '../projects/state/projects.actions';
import { getAllPhases } from '../costcodes/state/costcodes.actions';
import { setBreadcrumb } from '../common/breadcrumbContainer/state/breadcrumb.actions';
import {
  saveReport, getAllReports, deleteReport, updateReport,
} from './state/reports.actions';
import { getUnions } from '../unions/state/unions.actions';
import { getSageShifts, getSagePayIDs } from '../sage/state/sage.actions';

import ReportsList from './ReportsList';
import MainReportsContainer from './MainReportsContainer/MainReportsContainer';
import EmployeePayroll from './ReportsViews/EmployeePayroll/EmployeePayroll';
import StaffTimesheets from './ReportsViews/StaffTimesheets/StaffTimesheets';
import ServiceJobs from './ReportsViews/ServiceJobs';
import ProjectTimeline from './ReportsViews/ProjectTimeline';
import ProjectStatus from './ReportsViews/ProjectStatus';
import CertificationsReport from './ReportsViews/CertificationsReport';
import CostCodeBilling from './ReportsViews/CostCodeBilling';

import ComingSoon from '../common/ComingSoon';

import { getReports } from './reportConstants';

import ReportExcelExport from './reportExcelExport';

import Permissions from '../auth/Permissions';

import { getId, mergeSets } from '../helpers/helpers';

import config from '../config';

const SIDELIST_WIDTH = 240;

export default withTranslation()(connect((state, ownProps) => ({
  ...ownProps,
  users: state.users.users,
  crumbs: state.breadcrumb,
  selectedUser: state.users.selectedUser,
  teams: state.teams.teams,
  projects: state.projects.projects,
  costcodes: state.costcodes.costcodes,
  phases: state.costcodes.phases,
  fullscreen: state.main.fullscreen,
  reports: state.reports.reports,
  divisions: state.settings.divisions,
  selectedDivisions: state.settings.selectedDivisions,
  userDivisions: state.users.userDivisions,
  timeEntryUserMap: state.timeTracking.timeEntryUserMap,
  settings: state.settings.company && state.settings.company.settings ? state.settings.company.settings : { payPeriod: 'Weekly', payPeriodDates: '2020-02-02' },
  classes: state.unions.classes,
  positionNames: state.settings.positionNames,
}), {
  createUser,
  setBreadcrumb,
  getProjects,
  toggleFullscreen,
  getAllPhases,
  saveReport,
  getAllReports,
  deleteReport,
  updateReport,
  getUnions,
  getSageShifts,
  getSagePayIDs,
})(withRouter(class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.defaultCrumbs = [{
      text: 'Reports',
      icon: 'bar-chart',
    }];

    this.divideReportsByType();
    const payPeriod = this.props.settings.payPeriod || 'Weekly';
    const payPeriodDates = this.props.settings.payPeriodDates || '2020-02-02';
    let firstDay = moment(payPeriodDates, 'YYYY-MM-DD');
    const now = moment();
    const calendarMoveCount = (['Monthly', 'Weekly']).includes(payPeriod) ? 1 : 2;
    const calendarMoveRange = payPeriod === 'Monthly' ? 'month' : 'w';
    const past = now < firstDay;
    // find beginning of current pay period, it should be < now
    while (!now.isBetween(firstDay, firstDay.clone().add(calendarMoveCount, calendarMoveRange), null, '[]')) {
      if (past) {
        firstDay = firstDay.subtract(calendarMoveCount, calendarMoveRange);
      } else {
        firstDay = firstDay.add(calendarMoveCount, calendarMoveRange);
      }
    }
    const firstDayOfRunningPayPeriod = firstDay.add(1, 'd');

    const dateRange = [
      firstDayOfRunningPayPeriod,
      firstDayOfRunningPayPeriod.clone().add(calendarMoveCount, calendarMoveRange)
    ];
    this.state = {
      reports: this.companyReports,
      selectedReportType: 'default',
      selectedReport: this.companyReports[0],
      selectedReportSet: new Set([this.companyReports[0]]),
      fullscreen: this.props.fullscreen,
      width: SIDELIST_WIDTH,
      opacity: 1,
      dateRange,
      summaryEnabled: false,
      zeroHoursEnabled: false,
    };

    this.props.setBreadcrumb(this.defaultCrumbs);

    this.savedReports = [];

    this.onReportTypeChanged = this.changeReportType.bind(this);
    this.onReportSearch = this.search.bind(this);
    this.onReportSelected = this.selectReport.bind(this);

    this.reportViews = {
      'Employee Payroll': EmployeePayroll,
      'Staff Timesheets': StaffTimesheets,
      'Service Jobs': ServiceJobs,
      'Project Status': ProjectStatus,
      'Project Timeline': ProjectTimeline,
      'User Certifications': CertificationsReport('users'),
      'Vendor Certifications': CertificationsReport('vendors'),
      'Cost Code Billing': CostCodeBilling,
    };

    this.exporter = new ReportExcelExport('Employee Payroll', this.props.settings, true, dateRange);
    this.onDataChanged = this.exporter.updateData.bind(this.exporter);
    this.onColumnsChanged = this.exporter.updateColumns.bind(this.exporter);
    this.onFiltersChanged = this.exporter.updateFilters.bind(this.exporter);
    this.onSummaryChanged = this.summaryChanged.bind(this);
    this.onZeroHoursChanged = this.zeroHoursChanged.bind(this);
    this.onExport = this.exporter.export.bind(this.exporter);
    this.onDateRangeChanged = this.dataRangeChanged.bind(this);
    this.onRoundedChanged = this.exporter.onRoundedChanged.bind(this.exporter);
    this.onSave = this.save.bind(this);
    this.onDelete = this.deleteReport.bind(this);
  }

  componentDidMount() {
    this.props.getAllPhases();
    this.props.getAllReports();
    this.props.getUnions();
    this.props.getSageShifts({ divisionIds: Array.from(this.props.selectedDivisions) });
    this.props.getSagePayIDs({ divisionIds: Array.from(this.props.selectedDivisions) });
  }

  componentDidUpdate(prevProps) {
    const {
      reports: prevReports,
      settings: prevSettings,
    } = prevProps;
    const {
      reports,
      settings,
    } = this.props;
    const {
      selectedReportType = 'default',
      selectedReport,
    } = this.state;
    if (prevReports !== reports) {
      this.divideReportsByType();
      const newReports = this.applySearch(selectedReportType);
      this.setState({
        reports: newReports,
      });
      if (selectedReport) {
        const newSelectedReport = newReports.filter((report) => report.id === selectedReport.id);
        if (newSelectedReport.length > 0) {
          this.selectReport(newSelectedReport[0]);
        }
      } else {
        this.selectReport(this.companyReports[0]);
      }
    }

    if (prevSettings !== settings) {
      this.exporter.updateSettings(settings);
    }
  }

  search(value) {
    this.searchTerm = value;
    this.setState({
      reports: this.applySearch(this.state.selectedReportType),
    });
  }

  applySearch(selectedReportType) {
    const data = selectedReportType === 'default' ? this.companyReports : this.myReports;
    if (!this.searchTerm) return data;
    return data.filter(({
      name,
      title = name, // Default reports have a "title", while custom reports have a "name"
    }) => title.toLowerCase().includes(this.searchTerm.toLowerCase()));
  }

  selectReport(selectedReport) {
    if (!selectedReport) return;
    const {
      setBreadcrumb,
    } = this.props;
    const isDefault = !selectedReport.baseReport;
    const title = isDefault ? selectedReport.title : selectedReport.name;
    setBreadcrumb(this.defaultCrumbs.concat({ text: title }));
    this.exporter.setTitle(title);
    this.setState({
      selectedReport,
      selectedReportSet: new Set([selectedReport]),
    });
    Analytics.track('Reports/View', {
      ReportTitle: title,
      Type: isDefault ? 'Default' : 'Custom',
      BaseReport: selectedReport.baseReport || title,
    });
    if (!isDefault) {
      const {
        dateOffsets,
      } = selectedReport;
      this.setState({
        dateRange: [moment().subtract(dateOffsets[0], 'd'), moment().subtract(dateOffsets[1], 'd')],
      });
    }
  }

  divideReportsByType() {
    const {
      reports = [],
      t,
      positionNames,
    } = this.props;

    const defaultReports = getReports(t);

    const myDefaults = defaultReports.filter(
      (report) => (
        Permissions.has(`REPORTS_VIEW_${report.key.toUpperCase()}`)
        || report.hasPermissions?.(positionNames)
      ),
    );
    const propReports = reports.map((report) => ({ ...report, key: report.id }));

    this.companyReports = myDefaults.concat(
      propReports.filter((report) => report.userId !== Permissions.id),
    );
    this.myReports = propReports.filter((report) => report.userId === Permissions.id);
  }

  getReportView() {
    const {
      selectedReport: {
        title = '',
        baseReport,
      } = {},
    } = this.state;
    const reportBaseType = baseReport || title;

    const ReportView = this.reportViews[reportBaseType] || ComingSoon;
    const comingSoon = !(reportBaseType in this.reportViews);
    return { ReportView, comingSoon };
  }

  changeReportType(selectedReportType) {
    const newReports = this.applySearch(selectedReportType);
    this.setState({
      reports: newReports,
      selectedReportType,
    });
    if (newReports.length > 0) {
      this.selectReport(newReports[0]);
    }
  }

  dataRangeChanged(dateRange) {
    this.setState({
      dateRange,
    });
    this.exporter.updateDateRange(dateRange);
  }

  summaryChanged(summaryEnabled) {
    this.setState({
      summaryEnabled,
    });
  }

  zeroHoursChanged(zeroHoursEnabled) {
    this.setState({
      zeroHoursEnabled,
    });
  }

  save(name, isPublic, saveCopy) {
    const {
      projects = [],
      phases = [],
      costcodes = [],
    } = this.props;
    const {
      selectedReport: {
        title = '',
        baseReport,
        id,
      } = {},
      dateRange,
      summaryEnabled,
      zeroHoursEnabled,
    } = this.state;
    const {
      columns,
      filters,
    } = this.exporter.getCurrentState();

    const isEdit = (baseReport !== undefined && baseReport !== null);
    const ourBase = baseReport || title;

    let payloadColumns = columns;
    if (summaryEnabled) payloadColumns = ['summaryEnabled'].concat(columns);

    const now = moment();
    const payload = {
      name,
      isPublic,
      columns: payloadColumns,
      baseReport: ourBase,
      dateOffsets: [now.diff(dateRange[0], 'd'), now.diff(dateRange[1], 'd')],
      zeroHoursEnabled,
    };

    if (filters.users) {
      payload.users = filters.users.map(getId);
    }

    if (filters.projects) {
      payload.projects = projects.filter((project) => filters.projects.has(project.name))
        .map(getId);
      if (filters.projects.has('None')) payload.projects.push(null);
    }

    if (filters.phases) {
      payload.phases = phases.filter((phase) => filters.phases.has(phase.name))
        .map(getId);
      if (filters.phases.has('None')) payload.phases.push(null);
    }

    if (filters.costcodes) {
      payload.costcodes = costcodes.filter((costcode) => filters.costcodes.has(costcode.code))
        .map(getId);
      if (filters.costcodes.has('None')) payload.costcodes.push(null);
    }
    const isUpdate = isEdit && !saveCopy;
    Analytics.track(`Report/${isUpdate ? 'Update' : 'Save'}`, {
      Public: isPublic,
      BaseReport: payload.baseReport,
      IsCopy: saveCopy,
    });

    return isUpdate ? this.props.updateReport(id, payload) : this.props.saveReport(payload);
  }

  async deleteReport(id) {
    const {
      deleteReport,
    } = this.props;
    if (await deleteReport(id)) {
      this.myReports = this.myReports.filter((report) => report.id !== id);
      if (this.myReports.length > 0) {
        this.selectReport(this.myReports[0]);
        this.setState({
          reports: this.applySearch('custom'),
        });
      } else {
        this.changeReportType('default');
      }
    }
  }

  filterBasedOnDivision() {
    const {
      users = [],
      projects = [],
      costcodes = [],
      divisions = {},
      selectedDivisions = new Set(),
    } = this.props;
    const divArray = Object.values(divisions);
    if (!config.showDivisions) {
      return {
        users,
        projects,
        costcodes,
        divisions: divArray,
      };
    }
    const filteredDivs = divArray
      .filter((division) => selectedDivisions.has(division.id));
    const userSet = mergeSets(
      filteredDivs.map(({ users: divUsers }) => divUsers),
    );
    return {
      users: users.filter((user) => userSet.has(user.id)),
      projects: projects.filter((project) => selectedDivisions.has(project.divisionId)),
      costcodes,
      divisions: filteredDivs,
    };
  }

  render() {
    const {
      phases = [],
      fullscreen,
      reports: propReports = [],
      settings = {},
      certifications = [],
      userDivisions,
      timeEntryUserMap,
      classes = [],
    } = this.props;
    const {
      reports = [],
      selectedReportType,
      selectedReport = {},
      selectedReportSet = new Set(),
      dateRange,
    } = this.state;

    if (!Permissions.match('REPORTS')) return <Redirect to="/dashboard" />;

    const { ReportView, comingSoon } = this.getReportView();
    const {
      projects,
      users,
      costcodes,
      divisions,
    } = this.filterBasedOnDivision();
    return (
      <BreadCrumbContainer crumbs={this.props.crumbs} fullscreen={fullscreen}>
        <Route
          exact
          path="/reports"
          render={(props) => (
            <Row style={{ height: '100%', width: '100%' }}>
              <Col
                style={{
                  height: '100%',
                  paddingRight: 10,
                  transition: 'width 0.5s, opacity 0.5s',
                  width: fullscreen ? 0 : SIDELIST_WIDTH,
                  opacity: fullscreen ? 0 : 1,
                }}
              >
                <ReportsList
                  reports={reports}
                  selected={selectedReportType}
                  onTypeChanged={this.onReportTypeChanged}
                  onSearch={this.onReportSearch}
                  onSelect={this.onReportSelected}
                  selectedReports={selectedReportSet}
                />
              </Col>
              <Col flex="auto" style={{ height: '100%', paddingLeft: 10, width: 250 }}>
                {comingSoon ? <ReportView /> : (
                  <MainReportsContainer
                    reports={propReports}
                    report={selectedReport}
                    SelectedReportView={ReportView}
                    users={users}
                    projects={projects}
                    costcodes={costcodes}
                    phases={phases}
                    divisions={divisions}
                    userDivisions={userDivisions}
                    settings={settings}
                    certifications={certifications}
                    dateRange={dateRange}
                    timeEntryUserMap={timeEntryUserMap}
                    onFullScreenToggle={this.props.toggleFullscreen}
                    isFullScreen={fullscreen}
                    onExport={this.onExport}
                    onDataChanged={this.onDataChanged}
                    onColumnsChanged={this.onColumnsChanged}
                    onFiltersChanged={this.onFiltersChanged}
                    onDateRangeChanged={this.onDateRangeChanged}
                    onSummaryChanged={this.onSummaryChanged}
                    onZeroHoursChanged={this.onZeroHoursChanged}
                    onRoundedChanged={this.onRoundedChanged}
                    onSave={this.onSave}
                    deleteReport={this.onDelete}
                    exporter={this.exporter}
                    classes={classes}
                  />
                )}
              </Col>
            </Row>
          )}
        />
      </BreadCrumbContainer>
    );
  }
})));
