import moment from 'moment';
import { Form, Tabs } from 'antd';
import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import '@ant-design/compatible/assets/index.css';
import { connect } from 'react-redux';
import Analytics from '../helpers/Analytics';
import Permissions from '../auth/Permissions';

import CustomConfirmModal from '../common/modals/CustomConfirmModal';
import WarningHeader from '../common/text/WarningHeader';

import StepsContainer from '../common/containers/StepsContainer';
import ProjectAddGeneral from './ProjectAddGeneral';
import ProjectAddFiles from './ProjectAddFiles';
import ProjectFormTemplates from './ProjectFormTemplates';
import PayablesList from '../common/forms/PayablesList';
import ProjectAddBilling from './ProjectAddBilling';
import ProjectSimpleFormList from './ProjectSimpleFormList';
import ProjectAddCostCode from './ProjectAddCostCode';
import ProjectAddEquipment from './ProjectEquipment/ProjectAddEquipment';
import ProjectBudgetTracking from './ProjectBudgetTracking/ProjectBudgetTracking';
import ProjectProgress from './ProjectProgress/ProjectProgress';
import ProjectScheduleOfValues from './ProjectScheduleOfValues/ProjectScheduleOfValues';
import ProjectAnalytics from './ProjectAnalytics/ProjectAnalytics';
import ProjectNotes from './ProjectNotes';
import { calculateExpirationReminder } from './projectHelpers';

import BoardLinkView from '../boards/BoardLinkView';

import { getIdMap, getHighestNumber, sortByNumber } from '../helpers/helpers';

import exportProject from './projectExport';

import { PROJECT_COSTCODE_TYPE } from '../nux/nux.constants';

import config from '../config';
import GanttSchedule from '../schedule/GanttSchedule/GanttSchedule';
import AttachmentTab from '../attachment/AttachmentTab';
import {
  getProjectAttachments,
} from './state/projects.actions';
import { getBuckets } from '../buckets/state/buckets.actions';
import getGoogleMapKey from '../common/keys';

const { TabPane } = Tabs;

const EXCLUDE_SET = new Set(['Work Order', 'Invoice']);
const tabStyle = {
  minHeight: '100%',
  width: '100%',
};

const parseEquipmentSchedules = (eqs = []) => {
  const eqMap = {};
  eqs.forEach((eq) => {
    if (eq.startTime && eq.endTime) {
      eqMap[eq.id] = {
        ...eq,
        range: [
          eq.startTime ? moment(eq.startTime) : null,
          eq.endTime ? moment(eq.endTime) : null,
        ],
      };
    }
  });
  return eqMap;
};

const keyToStep = {
  general: 0,
  Billing: 1,
  'Cost Codes': 2,
  Equipment: 3,
  'Budget Tracking': config.showEquipment ? 4 : 3,
};

const PROGRESS_TAB = 'Progress';
const PAYABLES_TAB = 'Payables';
const BILLING_TAB = 'Billing';
const WORK_ORDERS_TAB = 'Work Orders';
const CHANGES_TAB = 'Changes';
const OTHER_FORMS_TAB = 'Other Forms';
const ANALYTICS_TAB = 'Analytics';
const ATTACHMENTS = 'attachments';

class ProjectAddView extends React.Component {
  constructor(props) {
    super(props);
    this.sortedcc = this.props.costcodes.filter((cc) => cc.active).sort(sortByNumber('code'));
    const {
      id,
      geofence = null,
      projectCostcodes = [],
      phases = [],
      editing,
      address,
      lat,
      lng,
      currentStep,
      activeTab = 'general',
      firstApprover,
      secondApprover,
      positionNames = [],
      equipment: propEquipment = [],
      equipmentSchedules: propEquipSchedules = [],
      cardLinks = [],
      projectTypeId,
      type,
    } = this.props;

    if (editing) this.initializeValues();
    const projectCostcodeMap = getIdMap(projectCostcodes);

    this.state = {
      geofenceEnabled: geofence !== null,
      geofenceSize: geofence ? geofence / 1000 : 0.3,
      available: this.sortedcc.filter((cc) => !(cc.id in projectCostcodeMap)),
      chosen: projectCostcodes,
      phaseCodes: phases,
      activeTab,
      showList: projectCostcodes.length > 0,
      address,
      lat,
      lng,
      currentStep,
      firstApprover: this.mapApprover(firstApprover,positionNames),
      secondApprover: this.mapApprover(secondApprover,positionNames),
      showEquipment: propEquipment.length > 0,
      equipment: propEquipment,
      equipmentSchedule: parseEquipmentSchedules(propEquipSchedules),
      ourLinks: cardLinks.filter((cardLink) => (
        cardLink.projectId === id
      )),
      projectTypeId,
    };

    if (geofence !== null) {
      this.state.geofenceSize = geofence / 1000;
    }

    this.onPlaceChanged = this.placeChanged.bind(this);
    this.onGeofenceEnableChanged = this.geofenceEnableChanged.bind(this);
    this.onGeofenceSizeChanged = this.geofenceSizeChanged.bind(this);
    this.onApproverChanged1 = this.approverChanged1.bind(this);
    this.onApproverChanged2 = this.approverChanged2.bind(this);

    this.onCheckChanged = this.changeShowList.bind(this);
    this.onSearchSelect = this.searchSelected.bind(this);
    this.onPhaseChanged = this.phaseChanged.bind(this);
    this.onCostCodeListClear = this.clearCostCodeList.bind(this);
    this.onCostCodeRemove = this.tryRemoveCostCode.bind(this);
    this.onCostcodeAdd = this.costcodeAdded.bind(this);
    this.onCostcodeEdit = this.costcodeEdited.bind(this);

    this.onAddPhaseCode = this.addPhaseCode.bind(this);
    this.onRemovePhaseCode = this.tryRemovePhaseCode.bind(this);
    this.onPhaseCodeListClear = this.clearPhaseCodes.bind(this);
    this.onEditPhaseCostCodes = this.editPhaseCostCodes.bind(this);
    this.onEditCostcodeEstimateChanged = this.editCostcodeEstimate.bind(this);
    this.onEditPhaseDetails = this.editPhaseDetails.bind(this);
    this.onClearDates = this.clearDates.bind(this);
    this.onClearBudget = this.clearBudget.bind(this);
    this.onEquipmentToggle = this.toggleEquipment.bind(this);
    this.onEquipmentAdd = this.addEquipment.bind(this);
    this.onExistingEquipmentAdd = this.addExistingEquipment.bind(this);
    this.onEquipmentRemove = this.removeEquipment.bind(this);
    this.onEquipmentDateChanged = this.equipmentDateChanged.bind(this);
    this.onEquipmentDateRemoved = this.removeEquipmentDate.bind(this);
    this.onEditEquipment = this.editEquipment.bind(this);
    this.onApplyTemplate = this.applyTemplate.bind(this);
    this.onImportBudget = this.importBudget.bind(this);
    this.onProjectTypeChanged = this.projectTypeChanged.bind(this);

    this.onTabChange = this.tabChanged.bind(this);

    this.onExport = this.export.bind(this);

    this.getCostCodeMap();

    this.phaseCodeIdCounter = 0;
    this.costcodeCounter = 0;
    this.equipmentCounter = 0;

    // If we are creating from a project type page, we need to lock project type
    if (type) {
      this.onProjectTypeChanged(type);
    }
  }

  componentDidMount() {
    const { id, getProjectAttachments, getBuckets, hideBucketDropdown } = this.props;
    if (id) getProjectAttachments(id);
    if (!hideBucketDropdown) getBuckets();
  }

  componentDidUpdate(prevProps) {
    const {
      costcodes: prevCC = [],
      currentStep: prevStep,
      phases: prevPhases = [],
      editing: prevEditing,
      cardLinks: prevCardLinks,
      id: prevId,
    } = prevProps;
    const {
      id,
      costcodes = [],
      geofence = null,
      currentStep,
      phases = [],
      editing,
      cardLinks = [],
    } = this.props;

    this.sortedcc = costcodes.filter((cc) => cc.active).sort(sortByNumber('code'));
    if (prevCC !== costcodes) {
      this.setState({
        available: this.sortedcc,
      });
      if (geofence !== null) {
        this.setState({
          geofenceEnabled: true,
          geofenceSize: geofence / 1000,
        });
      }
      this.getCostCodeMap();
    }
    if (prevPhases !== phases && !(phases.length === 0 && prevPhases.length === 0)) {
      this.setState({
        phaseCodes: phases,
      })
    }
    if (currentStep !== prevStep || currentStep !== this.state.currentStep) {
      this.setState({
        currentStep,
      });
    }
    if (editing && !prevEditing) {
      this.initializeValues();
    }

    if (id && (cardLinks !== prevCardLinks || id !== prevId)) {
      this.setState({
        ourLinks: cardLinks.filter((cardLink) => (
          cardLink.projectId === id
        )),
      });
    }
  }

  componentWillUnmount() {
    const {
      onShowFilesChanged,
    } = this.props;
    if (onShowFilesChanged) onShowFilesChanged(false);
  }

  initializeValues() {
    const {
      geofence = null,
      projectCostcodes = [],
      phases,
      equipment = [],
      equipmentSchedules = [],
    } = this.props;
    this.props.onValuesChanged('costcodes',projectCostcodes);
    this.props.onValuesChanged('geofence', geofence ? geofence : undefined);
    this.props.onValuesChanged('phases',phases);
    this.props.onValuesChanged('equipment',equipment);
    this.props.onValuesChanged('equipmentSchedule',parseEquipmentSchedules((equipmentSchedules)));
  }

  getCostCodeMap() {
    const {
      costcodes = [],
    } = this.props;
    const map = {};
    costcodes.forEach((cc) => {
      map[cc.id] = cc;
    });
    this.ccMap = map;
  }

  mapApprover(approver, positionNames) {
    for (var p of positionNames) {
      if (approver === p.id.toString()) return `Any ${p.name}`
    }
    const users = this.props.users.filter((u)=>u.id===approver)
    return users[0] ? users[0].name : undefined
  }

  placeChanged(place) {
    const existingVals = this.props.formRef.current.getFieldsValue();
    this.props.formRef.current.setFieldsValue({ ...existingVals,...place });
  }


  geofenceEnableChanged() {
    const {
      geofenceEnabled,
    } = this.state;
    Analytics.track(`Project/Geofence/${geofenceEnabled ? 'Disabled' : 'Enabled'}`);
    this.props.onValuesChanged('geofence', geofenceEnabled
      ? undefined : this.state.geofenceSize * 1000);
    this.setState({
      geofenceEnabled: !geofenceEnabled,
    });

  }

  geofenceSizeChanged(geofenceSize) {
    if (isNaN(geofenceSize)) return;
    this.setState({
      geofenceSize,
    });
    this.props.onValuesChanged('geofence',geofenceSize * 1000);
  }

  approverChanged1(approver) {
    this.props.onValuesChanged('firstApprover', approver)
  }

  approverChanged2(approver) {
    this.props.onValuesChanged('secondApprover', approver)
  }

  projectTypeChanged(projectTypeId) {
    this.props.onValuesChanged('projectTypeId', projectTypeId);
    this.setState({
      projectTypeId,
    });
  }

  changeShowList() {
    const {
      nux = new Set(),
      createNuxEntry,
    } = this.props;
    this.setState({
      showList: !this.state.showList,
    },() => {
      if (!this.state.showList) {
        this.clearCostCodeList();
        this.clearPhaseCodes();
      } else {
        if (!nux.has(PROJECT_COSTCODE_TYPE)) {
          createNuxEntry(PROJECT_COSTCODE_TYPE);
        }
      }
    });
  }

  searchSelected(value) {
    if (!(value in this.ccMap)) return;
    const chosen = this.state.chosen.concat([this.ccMap[value]]).sort(sortByNumber('code'));
    this.setState({
      chosen,
      available: this.state.available.filter((cc) => cc.id !== value).sort(sortByNumber('code')),
    });
    this.props.onValuesChanged('costcodes',chosen);
  }

  phaseChanged(costcode,checked) {
    const newChosen = this.state.chosen.map((chosen) => {
      const res = { ...chosen };
      if (chosen.id === costcode.id) {
        res.phased = checked;
      }
      return res;
    }).sort(sortByNumber('code'));
    this.setState({
      chosen: newChosen,
    });
    this.props.onValuesChanged('costcodes',newChosen);
  }

  clearCostCodeList() {
    this.setState({
      available: this.sortedcc,
      chosen: [],
    });
    this.props.onValuesChanged('costcodes',[]);
  }

  removeCostCode(record) {
    const { onValuesChanged } = this.props;
    const {
      chosen: stateChosen = [],
      phaseCodes = [],
      available = [],
    } = this.state;
    const chosen = stateChosen.filter((cc) => cc.id !== record.id).sort(sortByNumber('code'));

    const newPhases = phaseCodes.map((phase) => ({
      ...phase,
      costcodes: phase.costcodes.filter((cc) => cc.id !== record.id),
    }));
    this.setState({
      available: available.concat([record]).sort(sortByNumber('code')),
      chosen,
      phaseCodes: newPhases,
    });

    onValuesChanged('costcodes', chosen);
    onValuesChanged('phases', newPhases);
  }

  tryRemoveCostCode(record) {
    const { id } = this.props;
    if (id) {
      CustomConfirmModal({
        title: `Delete Cost Code '${record?.name}'?`,
        content: (
          <div>
            <WarningHeader justify="center" />
            <br />
            Deleting a cost code will unlink all tracked entries for that cost code
          </div>
        ),
        onOk: () => {
          this.removeCostCode(record);
        },
        okText: 'Delete',
      });
    } else {
      this.removeCostCode(record);
    }
  }

  costcodeAdded(values) {
    const {
      chosen: stateChosen = [],
    } = this.state;
    const chosen = stateChosen.concat(
      values.map((cc, index) => ({
        ...cc,
        id: cc.id ??this.costcodeCounter + index,
        isNew: !cc.id,
      })),
    ).sort(sortByNumber('code'))

    this.costcodeCounter += values.length;
    this.setState({
      chosen,
    });
    this.props.onValuesChanged('costcodes',chosen);
  }

  costcodeEdited(id, newData = {}) {
    const {
      onValuesChanged,
      actionButtonEnabled
    } = this.props;
    const {
      chosen: stateChosen = [],
    } = this.state;

    const chosen = stateChosen.map((cc) => {
      if (cc.id !== id) return cc;
      return {
        ...cc,
        ...newData,
        isNew: true,
        // Need to set this as new or else backend
        // overwrites the changes with whats in the DB for the global code
      };
    });

    let missingKey = false;
    let codeConflict = false;
    let nameConflict = false;

    // Name is unique per project + category
    // Code is unique per project
    const codeSet = new Set();
    const nameMap = {};

    chosen.forEach((data) => {
      if (!data.name || !data.code || !data.description) {
        missingKey = true;
      } else {
        if (!(data.categoryId in nameMap)) {
          nameMap[data.categoryId] = new Set();
        }
        if (nameMap[data.categoryId].has(data.name)) nameConflict = true;
        nameMap[data.categoryId].add(data.name);

        if (codeSet.has(data.code)) codeConflict = true;
        codeSet.add(data.code);
      }
    });

    this.setState({
      chosen,
    });

    onValuesChanged('costcodes', chosen);
    actionButtonEnabled(
      !missingKey
      && !nameConflict
      && !codeConflict,
    );
  }

  addPhaseCode(record) {
    const phaseCodes = [{
      ...record,
      id: this.phaseCodeIdCounter,
    }].concat(this.state.phaseCodes);

    this.setState({
      phaseCodes,
    });
    this.phaseCodeIdCounter += 1;
    this.props.onValuesChanged('phases',phaseCodes);
  }

  removePhaseCode(record) {
    const {
      onValuesChanged,
    } = this.props;
    const {
      phaseCodes = [],
    } = this.state;
    const filteredCodes = phaseCodes.filter((pc) => pc.id !== record.id);
    this.setState({
      phaseCodes: filteredCodes,
    });
    onValuesChanged('phases', filteredCodes);
  }

  tryRemovePhaseCode(record) {
    const { id } = this.props;
    if (id) {
      CustomConfirmModal({
        title: `Delete Phase '${record?.name}'?`,
        content: (
          <div>
            <WarningHeader justify="center" />
            <br />
            Deleting a phase will unlink all tracked entries for that phase
          </div>
        ),
        onOk: () => {
          this.removePhaseCode(record);
        },
        okText: 'Delete',
      });
    } else {
      this.removePhaseCode(record);
    }
  }

  clearPhaseCodes() {
    this.setState({
      phaseCodes: [],
    });
    this.phaseCodeIdCounter = 0;
    this.props.onValuesChanged('phases',[]);
  }

  editPhaseCostCodes(phaseCodeId,costcodes) {
    const phaseCodes = this.state.phaseCodes.map((pc) => {
      if (pc.id !== phaseCodeId) return pc;
      return { ...pc,costcodes };
    });

    this.setState({
      phaseCodes,
    });
    this.props.onValuesChanged('phases',phaseCodes);
  }

  editCostcodeEstimate(costcode,data = {}) {
    const chosen = this.state.chosen.map((cc) => {
      if (cc.id !== costcode.id) return cc;
      return {
        ...cc,
        ...data,
      };
    });

    this.setState({
      chosen,
    });

    this.props.onValuesChanged('costcodes',chosen);
  }

  editPhaseDetails(phaseCodeId,newDetails) {
    const phaseCodes = this.state.phaseCodes.map((pc) => {
      if (pc.id !== phaseCodeId) return pc;
      return { ...pc,...newDetails };
    });
    this.setState({
      phaseCodes,
    });
    this.props.onValuesChanged('phases',phaseCodes);
  }

  clearDates() {
    const {
      phaseCodes = [],
    } = this.state;
    const newPhases = phaseCodes.map((phase) => {
      const { costcodes = [] } = phase;
      const newCC = costcodes.map((cc) => ({ ...cc, startDate: null, endDate: null }));
      return { ...phase, costcodes: newCC };
    });
    this.setState({
      phaseCodes: newPhases,
    });
    this.props.onValuesChanged('phases',newPhases);
  }

  clearBudget() {
    const chosen = this.state.chosen.map((cc) => {
      return {
        ...cc,
        hours: null,
      };
    });

    this.setState({
      chosen,
    });

    this.props.onValuesChanged('costcodes',chosen);
    this.clearPhaseCodes();
    this.props.onValuesChanged('budget',{
      estimatedLabourHours: null,
      estimatedLabourCost: null,
      dateRange: [null,null],
    });
  }

  toggleEquipment() {
    const {
      showEquipment,
    } = this.state;
    this.setState({
      showEquipment: !showEquipment,
    });
  }

  addEquipment(record) {
    const {
      equipment: stateEquipment = [],
    } = this.state;
    this.equipmentCounter += 1;
    const ourId = this.equipmentCounter.toString();
    const chosen = stateEquipment.concat({
      ...record,
      id: ourId,
      isNew: true,
    });

    this.setState({
      equipment: chosen,
    });
    this.props.onValuesChanged('equipment',chosen);
  }

  addExistingEquipment(records) {
    const {
      equipment: stateEquipment = [],
    } = this.state;
    const chosen = stateEquipment.concat(records);

    this.setState({
      equipment: chosen,
    });
    this.props.onValuesChanged('equipment',chosen);
  }

  removeEquipment(record) {
    const {
      equipment: stateEquipment = [],
      equipmentSchedule = {},
    } = this.state;
    const newEquipment = stateEquipment.filter((eq) => eq.id !== record.id);

    const newSchedule = { ...equipmentSchedule };
    Object.keys(newSchedule).forEach((key) => {
      const [realId] = key.split('.');
      if (realId === record.id) {
        delete newSchedule[key];
      }
    });

    this.setState({
      equipment: newEquipment,
      equipmentSchedule: newSchedule,
    });
    this.props.onValuesChanged('equipment',newEquipment);
    this.props.onValuesChanged('equipmentSchedule',newSchedule);
  }

  editEquipment(equipmentId, newEq) {
    const {
      equipment: stateEquipment = [],
    } = this.state;
    const newEquipment = stateEquipment.map((eq) => {
      if (eq.id !== equipmentId) return eq;
      return {
        ...eq,
        ...newEq,
      };
    });
    this.setState({
      equipment: newEquipment,
    });
    this.props.onValuesChanged('equipment', newEquipment);
  }

  equipmentDateChanged({ id, range, name }) {
    const {
      equipmentSchedule = {},
    } = this.state;

    const newSchedule = { ...equipmentSchedule };
    const {
      [id]: existing = {},
    } = newSchedule;
    newSchedule[id] = { ...existing, range, name, id };

    this.setState({
      equipmentSchedule: newSchedule,
    });
    this.props.onValuesChanged('equipmentSchedule',newSchedule);
  }

  removeEquipmentDate({ id }) {
    const {
      equipmentSchedule = {},
    } = this.state;

    const newSchedule = { ...equipmentSchedule };
    delete newSchedule[id];
    this.setState({
      equipmentSchedule: newSchedule,
    });
    this.props.onValuesChanged('equipmentSchedule',newSchedule);
  }

  applyTemplate({ costcodes, phases }) {
    const {
      props: {
        onValuesChanged,
      },
    } = this;
    this.setState({
      showList: true,
      chosen: costcodes,
      phaseCodes: phases,
    });
    onValuesChanged('costcodes', costcodes);
    onValuesChanged('phases', phases);
  }

  importBudget({
    phaseCodes: newPhaseCodes,
    unphasedCostcodes: newUnphased,
    estimates: newEstimates,
  }) {
    const {
      props: {
        onValuesChanged,
        formRef: {
          current: form,
        } = {},
      },
      state: {
        chosen,
        phaseCodes,
      },
    } = this;
    const {
      startDate,
      endDate,
      ...restEstimates
    } = newEstimates;

    const costcodeMap = getIdMap(chosen, 'code');
    newUnphased.forEach((cc) => {
      costcodeMap[cc.code] = cc;
    });
    newPhaseCodes.forEach(({ costcodes = [] }) => {
      costcodes.forEach((cc) => {
        costcodeMap[cc.code] = cc;
      });
    });
    const mergedCostcodes = Object.values(costcodeMap);
    // Phases are allowed to have the same name, so not safe to replace existing
    const mergedPhaseCodes = [...phaseCodes, ...newPhaseCodes];
    const dateRange = [
      startDate ? moment(startDate) : null,
      endDate ? moment(endDate) : null,
    ];

    // Update form values
    if (form) {
      const existingVals = form.getFieldsValue();
      form.setFieldsValue({
        ...existingVals,
        dateRange,
        ...restEstimates,
      });
    }
    // Update projectAddViewState
    this.setState({
      showList: true,
      chosen: mergedCostcodes,
      phaseCodes: mergedPhaseCodes,
      dateRange,
      ...restEstimates,
    });
    // Update projectList values
    onValuesChanged('budget', {
      ...restEstimates,
      dateRange,
    });
    onValuesChanged('costcodes', mergedCostcodes);
    onValuesChanged('phases', mergedPhaseCodes);
  }

  export(isPDF) {
    const {
      projectCostcodes,
      name,
      number,
      startDate,
      endDate,
      labourCost,
      labourHours,
      companyImageURL,
      t,
    } = this.props;
    const {
      phaseCodes = [],
      chosen: chosenCostcodes = [],
    } = this.state;

    const unphasedCostcodes = (projectCostcodes || chosenCostcodes).filter((cc) => !cc.phased);
    Analytics.track('Project/Export',{ ExportFileType: isPDF ? 'pdf' : 'xlsx' })
    exportProject({
      isPDF,
      startDate,
      endDate,
      companyImageURL,
      name,
      number,
      labourCost,
      labourHours,
      unphasedCostcodes,
      phaseCodes,
      t,
    });
  }

  getGeneralPage() {
    const newProps = {};
    const {
      errors,
      customFields,
      setCustomFields,
    } = this.props;

    Object.keys(this.props).forEach((key) => {
      if (key !== 'address') {
        newProps[key] = this.props[key];
      }
    });

    return (
      <ProjectAddGeneral
        key={`${this.props.id}-info`}
        {...this.props}
        {...this.state}
        onPlaceChanged={this.onPlaceChanged}
        onGeofenceEnableChanged={this.onGeofenceEnableChanged}
        geofenceEnabled={this.state.geofenceEnabled}
        onGeofenceSizeChanged={this.onGeofenceSizeChanged}
        geofenceSize={this.state.geofenceSize}
        isNotDisplay={this.isNotDisplay}
        onApproverChanged1={this.onApproverChanged1}
        onApproverChanged2={this.onApproverChanged2}
        onProjectTypeChanged={this.onProjectTypeChanged}
        errors={errors}
        customField={customFields}
        setCustomFields={setCustomFields}
        googleMapURL={getGoogleMapKey()}
        loadingElement={<div style={{ height: '100%' }} />}
      />
    );
  }

  getPayablesPage() {
    const { activeTab } = this.state;
    return (
      <PayablesList
        {...this.props}
        type="Projects"
        isNotDisplay={this.isNotDisplay}
        visible={activeTab === PAYABLES_TAB}
      />
    );
  }

  getAnalyticsPage() {
    const {
      id,
      projectTypeId,
    } = this.props;

    return (
      <ProjectAnalytics
        projectId={id}
        projectTypeId={projectTypeId}
      />
    )
  }

  getBillingPage() {
    const { activeTab } = this.state;
    return (
      <ProjectAddBilling
        {...this.props}
        isNotDisplay={this.isNotDisplay}
        visible={activeTab === BILLING_TAB}
      />
    )
  }

  getSimpleFormList(type, visible) {
    return (
      <ProjectSimpleFormList
        {...this.props}
        isNotDisplay={this.isNotDisplay}
        type={type}
        visible={visible}
      />
    )
  }

  getOtherFormsList() {
    const { activeTab } = this.state;
    const formProps = { ...this.props };
    /*
      https://projectharbour.atlassian.net/browse/HARBOUR-5465
      When viewing this project from the project specific
      type view, this.props has the project type (e.g. Residential)
      and the spread passes that down to the form filtering to look for
      forms by type.

      Need to remove type so we can get all other forms
    */
    delete formProps.type;
    return (
      <ProjectSimpleFormList
        {...formProps}
        isNotDisplay={this.isNotDisplay}
        exclude={EXCLUDE_SET}
        visible={activeTab === OTHER_FORMS_TAB}
      />
    )
  }

  getCostcodePage() {
    const {
      formRef,
    } = this.props;
    const divisionId = (
      formRef && formRef.current ? formRef.current.getFieldValue('divisionId') : null
    );

    return (
      <ProjectAddCostCode
        key={`${this.props.id}-cc`}
        projectCostcodes={this.props.projectCostcodes}
        costcodes={this.props.costcodes}
        available={this.state.available}
        chosen={this.state.chosen}
        onCostcodeAdd={this.onCostcodeAdd}
        onCostcodeEdit={this.onCostcodeEdit}
        onSearchSelect={this.onSearchSelect}
        onCheckChanged={this.onCheckChanged}
        onPhaseChanged={this.onPhaseChanged}
        showList={this.state.showList}
        onListClear={this.onCostCodeListClear}
        onRemove={this.onCostCodeRemove}
        isNotDisplay={this.isNotDisplay}
        settings={this.props.settings}
        divisionId={divisionId}
        templateView={this.props.templateView}
        onApplyTemplate={this.onApplyTemplate}
      />
    );
  }

  getEquipmentPage() {
    const {
      formRef,
      id,
    } = this.props;
    const {
      showEquipment,
      equipment,
      equipmentSchedule = {},
    } = this.state;
    const divisionId = (
      formRef && formRef.current ? formRef.current.getFieldValue('divisionId') : null
    );

    return (
      <ProjectAddEquipment
        isNotDisplay={this.isNotDisplay}
        divisionId={divisionId}
        showEquipment={showEquipment}
        onCheckChanged={this.onEquipmentToggle}
        equipment={equipment}
        onAdd={this.onEquipmentAdd}
        onAddExisting={this.onExistingEquipmentAdd}
        onRemove={this.onEquipmentRemove}
        projectId={id}
        equipmentSchedule={equipmentSchedule}
        onEquipmentDateChanged={this.onEquipmentDateChanged}
        onEquipmentDateRemoved={this.onEquipmentDateRemoved}
        onEditEquipment={this.onEditEquipment}
      />
    );
  }

  getBudgetPage() {
    const { id, divisionId, formRef } = this.props;
    const {
      equipment = [],
      equipmentSchedule = {},
    } = this.state;

    const formDivisionId = formRef?.current?.getFieldValue('divisionId') || null;

    const unphasedCostcodes = this.state.chosen.filter((cc) => !cc.phased);
    return (
      <ProjectBudgetTracking
        id={id}
        key={`${this.props.id}-budget`}
        onAdd={this.onAddPhaseCode}
        onRemove={this.onRemovePhaseCode}
        unphasedCostcodes={unphasedCostcodes}
        dataSource={this.state.phaseCodes}
        onListClear={this.onPhaseCodeListClear}
        costcodes={this.state.chosen.filter((cc) => cc.phased)}
        onEditPhaseCostCodes={this.onEditPhaseCostCodes}
        onEditCostcodeEstimateChanged={this.onEditCostcodeEstimateChanged}
        isNotDisplay={this.isNotDisplay}
        labourHours={this.props.labourHours}
        labourCost={this.props.labourCost}
        estimatedMaterialCost={this.props.estimatedMaterialCost}
        estimatedOverheadCost={this.props.estimatedOverheadCost}
        estimatedEquipmentCost={this.props.estimatedEquipmentCost}
        estimatedBurdenRate={this.props.estimatedBurdenRate}
        contractValue={this.props.contractValue}
        startDate={this.props.startDate}
        endDate={this.props.endDate}
        onEditPhaseDetails={this.onEditPhaseDetails}
        onExport={this.onExport}
        onClearDates={this.onClearDates}
        onClearBudget={this.onClearBudget}
        createNuxEntry={this.props.createNuxEntry}
        nux={this.props.nux}
        equipment={equipment}
        equipmentSchedule={equipmentSchedule}
        onEquipmentDateChanged={this.onEquipmentDateChanged}
        templateView={this.props.templateView}
        divisionId={formDivisionId || divisionId}
        onImportBudget={this.onImportBudget}
        enableBuildEstimate={this.props.enableBuildEstimate}
        formRef={formRef}
      />
    );
  }

  getProgressPage () {
    const {
      id,
      phases,
      name,
      labourHours,
      labourCost,
      estimatedMaterialCost,
      estimatedOverheadCost,
      estimatedBurdenRate,
      estimatedEquipmentCost,
      enableBillingDisplay,
      divisionId,
    } = this.props;
    const { activeTab } = this.state;
    let averageWage;

    if (labourHours && labourCost) {
      averageWage = labourCost / labourHours;
    }

    return (
      <ProjectProgress
        projectId={id}
        dataSource={phases}
        projectName={name}
        visible={activeTab === PROGRESS_TAB}
        averageWage={averageWage}
        estimatedLaborCost={labourCost}
        estimatedMaterialCost={estimatedMaterialCost}
        estimatedOverheadCost={estimatedOverheadCost}
        estimatedBurdenRate={estimatedBurdenRate}
        estimatedEquipmentCost={estimatedEquipmentCost}
        enableBillingDisplay={enableBillingDisplay}
        divisionId={divisionId}
      />
    );
  }

  getScheduleOfViewsPage () {
    const {
      id,
      templateId,
    } = this.props;

    return (
      <ProjectScheduleOfValues
        projectId={id}
        templateId={templateId}
      />
    );
  }

  getGanttPage () {
    const {
      id,
      history,
    } = this.props;

    return (
      <GanttSchedule
        history={history}
        projectId={id}
      />
    )
  }

  getFilesPage(isEdit, newName) {
    const {
      name,
      onValuesChanged,
      showFiles,
      onShowFilesChanged,
      divisionId,
      divisions = {},
      showAddFileButton = true,
      showAddFileCheckbox = true,
      fileStructure,
      formRef,
      isFromProjectAddFileTemplates = false,
    } = this.props;

    const formDivisionId = (
      formRef && formRef.current ? formRef.current.getFieldValue('divisionId') : null
    );

    const divisionName = divisions[formDivisionId || divisionId]?.name;
    const pathname = (
      divisionName
        ? `/files/${divisionName}/Projects/${name}`
        : `/files/Projects/${name}`
    );

    return (
      <ProjectAddFiles
        name={newName || name}
        onValuesChanged={onValuesChanged}
        showFiles={isEdit || showFiles}
        onShowFilesChanged={onShowFilesChanged}
        pathname={isEdit ? pathname : ''}
        divisionId={formDivisionId || divisionId}
        divisionName={divisionName}
        isEdit={isEdit}
        showAddFileButton={showAddFileButton}
        showAddFileCheckbox={showAddFileCheckbox}
        defaultFileStructure={fileStructure}
        isFromProjectAddFileTemplates={isFromProjectAddFileTemplates}
      />
    );
  }

  tabChanged(activeTab) {
    const {
      tabs,
    } = this.props;

    const {
      projectTypeId,
    } = this.state;

    const displayedTabs = tabs
      .filter((tab) => (
        tab.category === 'Projects'
        && tab.enabled
        && tab.projectTypeId === projectTypeId
      ))
      .map((tab) => tab.tabName);

    const predecessorSteps = [];
    let step = keyToStep[activeTab];

    // Look at predecessors
    if (step > 1) {
      Object.keys(keyToStep).forEach((key) => {
        if (keyToStep[key] > 0 && keyToStep[key] < step) {
          predecessorSteps.push(key);
        }
      });
    }

    predecessorSteps.forEach((predecessor) => {
      const predecessorTab = displayedTabs.find((tab) => tab === predecessor);

      // If previous step doesn't exist then the step must be back one index
      if (!predecessorTab) {
        step -= 1;
      }
    });

    this.setState({
      activeTab,
      currentStep: step,
    });
    this.props.currentStepChanged(step);
  }

  getHighestNumber() {
    const {
      isAdd,
      number,
      projects = [],
      settings: {
        suggestNumbers,
      } = {},
    } = this.props;

    if (!isAdd) return number;
    if (!suggestNumbers) return null;
    if (!projects.length) return '1';
    const highest = getHighestNumber(projects);
    return (highest + 1).toString();
  }

  render() {
    const {
      id: projectId,
      isAdd,
      editing,
      name,
      description,
      customerId,
      labourHours,
      labourCost,
      estimatedMaterialCost,
      estimatedOverheadCost,
      estimatedEquipmentCost,
      estimatedBurdenRate,
      contractValue,
      startDate,
      endDate,
      divisionId,
      formRef,
      billingRateId,
      customBillingRate,
      materialDiscount,
      contractExpirationTimestamp,
      contractReminderTimestamp,
      billingContact,
      poNumber,
      info,
      tabs,
      templateView,
      customSteps = [],
      showContractStep = false,
      t,
      type = null,
      qboClassId,
      firstApprover,
      secondApprover,
      enableBuildEstimate,
      attachments = {},
    } = this.props;

    const {
      projectTypeId,
      activeTab,
      currentStep,
      ourLinks = [],
    } = this.state;

    const relevantType = projectTypeId ?? type;
    const displayedTabs = tabs
      .filter((tab) => (
        tab.category === 'Projects'
        && tab.enabled
        && tab.projectTypeId === relevantType
      ))
      .map((tab) => tab.tabName);

    this.isNotDisplay = (isAdd || editing);

    const initialValues = {
      name,
      description,
      number: this.getHighestNumber(),
      customerId,
      estimatedLabourCost: labourCost,
      estimatedLabourHours: labourHours,
      divisionId,
      estimatedMaterialCost,
      estimatedOverheadCost,
      estimatedEquipmentCost,
      estimatedBurdenRate,
      contractValue,
      billingRateId,
      customBillingRate,
      materialDiscount: materialDiscount ? materialDiscount * 100 : null,
      billingContact,
      poNumber,
      info,
      projectTypeId: relevantType,
      qboClassId,
      firstApprover,
      secondApprover,
      enableBuildEstimate,
    };

    if (contractExpirationTimestamp) {
      initialValues.contractExpiration = moment(contractExpirationTimestamp);
      if (contractReminderTimestamp) {
        initialValues.contractReminder = calculateExpirationReminder({
          isNotDisplay: true,
          contractExpirationTimestamp,
          contractReminderTimestamp,
        });
      }
    }

    if (startDate && endDate) {
      initialValues.dateRange = [moment(startDate), moment(endDate)];
    }

    const currentName = (
      formRef && formRef.current ? formRef.current.getFieldValue('name') : null
    );

    const steps = [];
    if (!templateView) {
      steps.push({
        content: this.getGeneralPage(),
        title: `${t('Project')} Info`,
        key: 'info',
      });
    }

    if (customSteps) {
      steps.push(...customSteps);
    }

    if (displayedTabs.includes('Billing')) {
      steps.push({
        content: this.getBillingPage(),
        title: 'Billing',
        key: 'billing',
      });
    }

    if (Permissions.has('FILES_WRITE') && isAdd && displayedTabs.includes('Files')) {
      steps.push({
        content: this.getFilesPage(false, currentName),
        title: `${t('Project')} Files`,
        key: 'files',
      });
    }

    if (displayedTabs.includes('Cost Codes')) {
      steps.push({
        content: this.getCostcodePage(),
        title: 'Cost Codes',
        key: 'cc',
      });
    }

    if (config.showEquipment && displayedTabs.includes('Equipment')) {
      steps.push({
        content: this.getEquipmentPage(),
        title: 'Equipment',
        key: 'equipment',
      });
    }

    if (displayedTabs.includes('Budget Tracking')) {
      steps.push({
        content: this.getBudgetPage(),
        title: 'Budget Tracking',
        key: 'budget',
      });
    }

    if (showContractStep) {
      steps.push({
        content: this.getScheduleOfViewsPage(),
        title: 'Contract',
        key: 'contract',
      });
    }

    // The tab count can differ from the step count depending on if
    // the custom project type has different steps set
    if (this.isNotDisplay && this.props.numberOfSteps !== steps.length && this.props.numberOfStepsChanged) {
      this.props.numberOfStepsChanged(steps.length);
    }

    const hasFileRead = Permissions.has('FILES_READ');
    const hasFormsRead = Permissions.has('FORMS_READ');
    const hasScheduleOfValuesRead = Permissions.has('PROJECT_SCHEDULEOFVALUES_READ');
    const hasGanttRead = Permissions.has('PROJECT_GANTT_SCHEDULE_READ');
    const hasProgress = Permissions.has('PROJECT_PROGRESS_READ');
    return (
      <Form
        ref={formRef}
        layout="vertical"
        style={{
          height: '80%', width: '100%',
        }}
        initialValues={initialValues}
      >
        {this.isNotDisplay ? (
          <StepsContainer
            currentStep={currentStep}
            steps={steps}
          />
        ) : (
          <Tabs
            key={name}
            defaultActiveKey={activeTab}
            destroyInactiveTabPane={!this.isNotDisplay}
            style={activeTab === 'general' ? {
              position: 'absolute',
              bottom: 53,
              top: 55,
              left: 24,
              right: 24,
            } : {
              marginTop: -24,
            }}
            onChange={this.onTabChange}
            tabBarStyle={{ marginBottom: 10 }}
          >
            <TabPane tab="General Info" key="general" style={tabStyle}>
              {this.getGeneralPage()}
            </TabPane>
            {displayedTabs.includes('Analytics') && config.showProjectAnalytics && (
              <TabPane tab="Analytics" key={ANALYTICS_TAB} style={tabStyle}>
                {this.getAnalyticsPage()}
              </TabPane>
            )}
            {displayedTabs.includes('Files') && hasFileRead && (
              <TabPane tab="Files" key="Files" style={tabStyle}>
                {this.getFilesPage(true)}
              </TabPane>
            )}

            {displayedTabs.includes('Notes') && projectId && (
              <TabPane tab="Notes" key="notes" style={tabStyle}>
                <ProjectNotes projectId={projectId} divisionId={divisionId} />
              </TabPane>
            )}
            {projectId && <TabPane
              tab="Attachments"
              key="Attachments"
              style={tabStyle}
            >
              <AttachmentTab
                attachments={attachments}
              />
            </TabPane>}
            {displayedTabs.includes('Forms') && hasFormsRead && (
              <TabPane tab="Forms" key="Forms" style={tabStyle}>
                <ProjectFormTemplates projectId={projectId} />
              </TabPane>
            )}
            {displayedTabs.includes('Payables') && (
              <TabPane tab="Payables" key={PAYABLES_TAB} style={tabStyle}>
                {this.getPayablesPage()}
              </TabPane>
            )}
            {displayedTabs.includes('Billing') && (
              <TabPane tab="Billing" key={BILLING_TAB} style={tabStyle}>
                {this.getBillingPage()}
              </TabPane>
            )}
            {displayedTabs.includes('Work Orders') && (
              <TabPane tab="Work Orders" key={WORK_ORDERS_TAB} style={tabStyle}>
                {this.getSimpleFormList('Work Order', activeTab === WORK_ORDERS_TAB)}
              </TabPane>
            )}
            {displayedTabs.includes('Changes') && (
              <TabPane tab="Changes" key={CHANGES_TAB} style={tabStyle}>
                {this.getSimpleFormList('Change Order', activeTab === CHANGES_TAB)}
              </TabPane>
            )}
            {displayedTabs.includes('Other Forms') && (
              <TabPane tab="Other Forms" key={OTHER_FORMS_TAB} style={tabStyle}>
                {this.getOtherFormsList()}
              </TabPane>
            )}
            {displayedTabs.includes('Cost Codes') && (
              <TabPane tab="Cost Codes" key="Cost Codes" style={tabStyle}>
                {this.getCostcodePage()}
              </TabPane>
            )}
            {displayedTabs.includes('Equipment') && config.showEquipment && (
              <TabPane
                tab="Equipment"
                key="Equipment"
                style={tabStyle}
              >
                {this.getEquipmentPage()}
              </TabPane>
            )}
            { displayedTabs.includes('Budget Tracking') && (
              <TabPane
                tab="Budget Tracking"
                key="Budget Tracking"
                style={tabStyle}
              >
                {this.getBudgetPage()}
              </TabPane>
            )}
            {displayedTabs.includes('Contract') && config.showScheduleOfValues && hasScheduleOfValuesRead && (
              <TabPane
                tab="Contract"
                key="Contract"
                style={tabStyle}
              >
                {this.getScheduleOfViewsPage()}
              </TabPane>
            )}
            {displayedTabs.includes('Progress') && config.showProgress && hasProgress && (
              <TabPane
                tab="Progress"
                key="Progress"
                style={tabStyle}
              >
                {this.getProgressPage()}
              </TabPane>
            )}
            {displayedTabs.includes('Cards') && ourLinks.length > 0 && (
              <TabPane
                tab="Cards"
                key="Cards"
                style={tabStyle}
              >
                <BoardLinkView links={ourLinks} linkType="projectId" linkId={projectId} />
              </TabPane>
            )}
            {displayedTabs.includes('Gantt') && config.showGantt && hasGanttRead && (
              <TabPane
                tab="Gantt"
                key="Gantt"
                style={tabStyle}
              >
                {this.getGanttPage()}
              </TabPane>
            )}
          </Tabs>
        )}
      </Form>
    );
  }
}

ProjectAddView.propTypes = {
  id: PropTypes.string,
  onValuesChanged: PropTypes.func.isRequired,
};

ProjectAddView.defaultProps = {
  id: undefined,
};

const mapStateToProps = (state) => ({
  attachments: state.projects.projectAttachments,
});

export default withTranslation()(
  connect(mapStateToProps, {
    getProjectAttachments,
    getBuckets,
  })(ProjectAddView),
);
