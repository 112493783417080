/* eslint-disable react/jsx-filename-extension */
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Button,
} from 'antd';
import { FileAddOutlined } from '@ant-design/icons';
import axios from 'axios';

import { useDispatch, useSelector } from 'react-redux';
import PhotoReel from '../photos/PhotoReel';
import FullPhoto from '../photos/FullPhoto';

import LiveFeedFileUpload from '../../dashboard/LiveFeed/LiveFeedFileUpload';

import colors from '../../constants/Colors';

import { downloadFile, getFileType, visuallyDownloadFile } from '../../files/fileHelpers';
import { uuid } from '../../helpers/helpers';
import { addFileToFileMap } from '../../files/state/files.actions';

export default function FileReelWithUpload({
  files = [],
  onChange,
  isDisplay,
  style = {},
  downloadEnabled,
}) {
  const dispatch = useDispatch();

  const globalFileMap = useSelector((state) => state.files.fileMap);

  const [showUpload, setShowUpload] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [currIndex, setCurrIndex] = useState();

  const addFile = useCallback((file) => {
    if (!file.id) {
      const fileRef = file;
      fileRef.autoSaveId = uuid();
    }
    const trueId = file.id || file.autoSaveId;
    dispatch(addFileToFileMap(trueId, file));
    onChange([...files, file]);
  }, [files]);

  const removeFile = useCallback((index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    onChange(newFiles);
  }, [files]);

  const onFileClick = async (idx) => {
    const ourFile = files[idx];
    setSelectedFile(ourFile);
    setCurrIndex(idx);
    const { id: fileId } = ourFile;
    if (fileId) {
      // Let the backend know the user opened a file
      // fileId is undefined for files the user just added, that are not uploaded yet
      await axios.post('/events', { id: fileId });
    }
  };

  const closePhotoPreview = useCallback(() => setSelectedFile(), []);
  const onNextFileClick = useCallback((isRight) => () => {
    const newIndex = isRight ? currIndex + 1 : currIndex - 1;
    const isInvalid = isRight ? newIndex >= files.length : newIndex < 0;
    if (isInvalid) return;
    const ourFile = files[newIndex] || {};
    const globalFile = globalFileMap[ourFile.id || ourFile.autoSaveId];
    if (!globalFile) {
      visuallyDownloadFile({
        file: ourFile,
        dispatch,
      })
        .then((res) => {
          if (res) {
            setSelectedFile(ourFile);
            setCurrIndex(newIndex);
          }
        });
      return;
    }
    setSelectedFile(ourFile);
    setCurrIndex(newIndex);
  }, [currIndex, files, globalFileMap]);

  const onDownload = useCallback(() => {
    const fileObject = selectedFile.fileObject instanceof File
      ? selectedFile.fileObject
      : globalFileMap[selectedFile.id];
    downloadFile({ fileObject });
  }, [selectedFile]);

  return (
    <>
      <Row style={{ marginTop: 5, ...style }}>
        {!isDisplay && (
        <Col style={{ marginTop: 10, width: 60 }}>
          <Row style={{ height: 80 }} align="bottom">
            <Row style={{ height: 70 }} align="top">
              <Button
                className="form-attachment-add-button"
                icon={(
                  <FileAddOutlined
                    style={{
                      color: colors.ONTRACCR_RED,
                    }}
                  />
                )}
                onClick={() => setShowUpload(true)}
              />
            </Row>
          </Row>
        </Col>
        )}
        <Col style={{ width: isDisplay ? '100%' : 'calc(100% - 60px)' }}>
          <PhotoReel
            small
            files={files}
            onDelete={isDisplay ? null : removeFile}
            onClick={onFileClick}
          />
        </Col>
      </Row>
      <LiveFeedFileUpload
        visible={showUpload}
        onUploadEnd={() => setShowUpload(false)}
        placement="right"
        customProps={{
          style: {},
          // Need to override default props or drawer opens in a different DOM node
          getContainer: undefined,
          width: 500,
        }}
        addFile={addFile}
      />
      <FullPhoto
        url={selectedFile ? selectedFile.url : null}
        file={globalFileMap[selectedFile?.id || selectedFile?.autoSaveId]}
        type={selectedFile ? getFileType(selectedFile) : null}
        onClose={closePhotoPreview}
        onLeft={onNextFileClick(false)}
        onRight={onNextFileClick(true)}
        showLeft={selectedFile && currIndex > 0}
        showRight={selectedFile && currIndex < (files.length - 1)}
        onDownload={downloadEnabled ? onDownload : undefined}
        useApryse
      />
    </>
  );
}

FileReelWithUpload.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func.isRequired,
  isDisplay: PropTypes.bool,
  style: PropTypes.shape({}),
  downloadEnabled: PropTypes.bool,
};

FileReelWithUpload.defaultProps = {
  downloadEnabled: false,
  isDisplay: false,
  style: {},
  files: [],
};
