import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Form, Row, Col, Select,
} from 'antd';
import PropTypes from 'prop-types';

import { Common } from 'ontraccr-common';
import Permissions from '../auth/Permissions';

import FormTextInput from '../common/inputs/FormTextInput';
import FormNumberInput from '../common/inputs/FormNumberInput';
import FormCurrencyInput from '../common/inputs/FormCurrencyInput';
import DivisionSelector from '../common/inputs/DivisionSelector';
import MaterialUnitsSelector from '../common/inputs/MaterialUnitsSelector';
import DisplayText from '../common/text/DisplayText';
import IntegrationTag from '../common/IntegrationTag';

import { percentFormatter } from '../helpers/inputParsers';
import { getIdMap, sortByCode } from '../helpers/helpers';

import {
  getMarkupString,
} from './materialsHelpers';
import MaterialLocation from './MaterialLocation';

const formLabelStyle = {
  style: {
    paddingBottom: 5,
    marginTop: 10,
  },
};

const brokenStyle = {
  color: 'red',
  fontWeight: 900,
};

export default function MaterialInfo({
  item,
  isNotDisplay,
  onDivisionChange,
  locations = [],
  onLocationsChanged,
  price,
  labourPrice,
  totalQuantity,
  totalQuantityAllocated,
  relevantCC,
  hasTabs,
}) {
  const costcodes = useSelector((state) => state.costcodes.costcodes);

  const costcodeIdMap = useMemo(() => getIdMap(costcodes), [costcodes]);
  const costcodeName = useMemo(() => {
    const { costcodeId } = item || {};
    const {
      [costcodeId]: {
        name,
      } = {},
    } = costcodeIdMap;
    return name;
  }, [item, costcodeIdMap]);

  const maxHeight = useMemo(() => {
    let offset = 55;
    if (Permissions.hasWrite('MATERIALS')) offset += 53;
    if (hasTabs) offset += 46;
    return `calc(100vh - ${offset}px)`;
  }, [hasTabs, Permissions]);

  const sortedRelevantCC = useMemo(() => [...relevantCC].sort(sortByCode('code')), [relevantCC]);

  const {
    id,
    code,
    name,
    description,
    partNumber,
    supplier,
    cost,
    markup,
    labourMarkup,
    units,
    eclipseId,
    quantityThreshold,
    intuitId,
    labourCost,
  } = item || {};

  return (
    <div className="material-tab-pane-container" style={{ maxHeight }}>
      <FormTextInput
        name="name"
        label="Name"
        isNotDisplay={isNotDisplay}
        value={name}
        rules={[
          { required: isNotDisplay, message: 'Please enter a name' },
        ]}
      />
      <FormTextInput
        textarea
        autoSize
        name="description"
        label="Description"
        value={description}
        isNotDisplay={isNotDisplay}
        rules={[
          { required: isNotDisplay, message: 'Please enter a description' },
        ]}
      />
      <Row gutter={20} justify="space-between" align="middle">
        <Col span={isNotDisplay ? 24 : 12}>
          <FormTextInput
            name="partNumber"
            label="Part Number"
            value={partNumber}
            isNotDisplay={isNotDisplay}
            rules={[
              { required: isNotDisplay, message: 'Please enter a part number' },
            ]}
          />
        </Col>
        {!isNotDisplay && (
          <Col span={12}>
            <FormTextInput
              name="code"
              label="Code"
              isNotDisplay={false}
              value={code}
            />
          </Col>
        )}
      </Row>
      <Form.Item
        name="divisionId"
        label="Division"
        style={{ marginBottom: 0, paddingBottom: -8 }}
        labelCol={formLabelStyle}
        rules={[{ required: isNotDisplay, message: 'Please enter a division' }]}
        valuePropName="divisionId"
      >
        <DivisionSelector onChange={onDivisionChange} displayMode={!isNotDisplay} />
      </Form.Item>
      <FormTextInput
        name="supplier"
        label="Supplier"
        isNotDisplay={isNotDisplay}
        value={supplier}
      />
      <Row gutter={20} justify="space-between" align="middle">
        <Col span={8}>
          <FormCurrencyInput
            name="cost"
            label="Cost"
            isNotDisplay={isNotDisplay}
            value={cost ? `$${cost}` : cost}
          />
        </Col>
        <Col span={8}>
          <FormNumberInput
            name="markup"
            label="Markup"
            isNotDisplay={isNotDisplay}
            numberInputProps={{
              formatter: percentFormatter,
              defaultValue: 0,
            }}
            value={getMarkupString(markup)}
          />
        </Col>
        <Col span={8}>
          <Form.Item
            label="Price"
            style={{ marginBottom: 0, paddingBottom: -8, height: 75 }}
            labelCol={formLabelStyle}
          >
            {
              price && <DisplayText title={price} id={isNotDisplay ? 'material-form-price' : null} />
            }
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20} justify="space-between" align="middle">
        <Col span={8}>
          <FormCurrencyInput
            name="labourCost"
            label="Labour Cost"
            isNotDisplay={isNotDisplay}
            value={labourCost ? `$${labourCost}` : labourCost}
          />
        </Col>
        <Col span={8}>
          <FormNumberInput
            name="labourMarkup"
            label="Markup"
            isNotDisplay={isNotDisplay}
            numberInputProps={{
              formatter: percentFormatter,
              defaultValue: 0,
            }}
            value={getMarkupString(labourMarkup)}
          />
        </Col>
        <Col span={8}>
          <Form.Item
            label="Price"
            style={{ marginBottom: 0, paddingBottom: -8, height: 75 }}
            labelCol={formLabelStyle}
          >
            {
              !Common.isNullOrUndefined(labourPrice)
                ? <DisplayText title={labourPrice} id={isNotDisplay ? 'material-form-price' : null} />
                : null
            }
          </Form.Item>
        </Col>

      </Row>

      <Form.Item
        name="units"
        label="Units"
        style={{ marginBottom: 0, paddingBottom: -8 }}
        labelCol={formLabelStyle}
        trigger="onSelect"
      >
        {isNotDisplay
          ? (
            <MaterialUnitsSelector
              dropdownMatchSelectWidth
              dropdownWidth="100%"
            />
          )
          : <DisplayText title={units} />}
      </Form.Item>
      <Form.Item
        name="costcodeId"
        label="Cost Code"
        style={{ marginBottom: 0, paddingBottom: -8 }}
        labelCol={formLabelStyle}
      >
        {
            isNotDisplay
              ? (
                <Select
                  placeholder="Select a Cost Code"
                  allowClear
                  showSearch
                  optionFilterProp="label"
                >
                  {
                  sortedRelevantCC.map((cc) => (
                    <Select.Option value={cc.id} key={cc.id} label={`${cc.code} - ${cc.name}`}>
                      {`${cc.code} - ${cc.name}`}
                    </Select.Option>
                  ))
                }
                </Select>
              )
              : <DisplayText title={costcodeName} />
          }
      </Form.Item>
      {!isNotDisplay
       && (
       <Form.Item
         name="integrations"
         key="integrations"
         label="Integrations"
         style={{ marginBottom: 0 }}
       >
         <IntegrationTag id={eclipseId} title="Eclipse" />
         <IntegrationTag id={intuitId} title="QuickBooks" />
       </Form.Item>
       )}
      <Row gutter={20} justify="space-between" align="middle">
        <Col span={12}>
          <FormNumberInput
            name="totalQuantity"
            label="Quantity"
            isNotDisplay={false}
            numberInputProps={{ min: 0 }}
            value={totalQuantity}
          />
        </Col>
        <Col span={12}>
          <FormNumberInput
            name="quantityThreshold"
            label="Quantity Threshold"
            isNotDisplay={isNotDisplay}
            numberInputProps={{
              defaultValue: 0,
              min: 0,
            }}
            value={quantityThreshold}
            displayStyle={totalQuantity <= quantityThreshold && brokenStyle}
          />
        </Col>
      </Row>
      <Row gutter={20} justify="space-between" align="middle">
        <Col span={12}>
          <FormNumberInput
            name="totalQuantityAllocated"
            label="Quantity Allocated"
            isNotDisplay={false}
            numberInputProps={{ min: 0 }}
            value={totalQuantityAllocated}
          />
        </Col>
        <Col span={12}>
          <FormNumberInput
            name="quantityAvailable"
            label="Quantity Available"
            isNotDisplay={false}
            numberInputProps={{ min: 0 }}
            value={totalQuantity - totalQuantityAllocated}
          />
        </Col>
      </Row>
      <MaterialLocation
        id={id}
        isNotDisplay={isNotDisplay}
        locations={locations}
        onLocationsChanged={onLocationsChanged}
      />
    </div>
  );
}

MaterialInfo.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
  }),
  isNotDisplay: PropTypes.bool,
  onDivisionChange: PropTypes.func,
  locations: PropTypes.arrayOf(PropTypes.shape({})),
  onLocationsChanged: PropTypes.func,
  price: PropTypes.number,
  labourPrice: PropTypes.number,
  totalQuantity: PropTypes.number,
  totalQuantityAllocated: PropTypes.number,
  relevantCC: PropTypes.arrayOf(PropTypes.shape({})),
  hasTabs: PropTypes.bool,
};

MaterialInfo.defaultProps = {
  item: null,
  isNotDisplay: false,
  onDivisionChange: () => {},
  locations: [],
  onLocationsChanged: () => {},
  price: 0,
  labourPrice: 0,
  totalQuantity: 0,
  totalQuantityAllocated: 0,
  relevantCC: [],
  hasTabs: false,
};
