import React from 'react';

import Permissions from '../auth/Permissions';

export default {};

function HoverHelp({ text }) {
  return (
    <div style={{ width: 300, textOverflow: 'wrap' }}>
      {text}
      <br />
      <br />
      For more details on any reports, check out the resources in our ‘Support’ section.
    </div>
  );
}

export const getReports = (t) => [{
  key: 'employee_payroll',
  title: 'Employee Payroll',
  helpText: (
    <HoverHelp
      text={'A report mainly used by admin staff who are responsible for payroll. It summarizes all staff\'s time sheets and is used to export into excel form for entry into the payroll software (e.g. QuickBooks).'}
    />
  ),
}, {
  key: 'staff_timesheets',
  title: 'Staff Timesheets',
  helpText: (
    <HoverHelp
      text="This report is typically used by managers and supervisors to review and approve workers hours and ensure they are allocating them to the correct phase and cost codes."
    />
  ),
}, {
  key: 'service_jobs',
  title: 'Service Jobs',
  helpText: (
    <HoverHelp
      text="This report is shows hours worked on service jobs."
    />
  ),
}, {
  key: 'project_status',
  title: `${t('Project')} Status`,
  helpText: (
    <HoverHelp
      text={`A detailed report which allows managers to gain insight into all aspects of ${t('Project').toLowerCase()} costing. Key metrics found in this report allow managers to manage the labour onsite, materials consumed, and track overhead throughout the ${t('Project').toLowerCase()} work.`}
    />
  ),
}, {
  key: 'timeline',
  title: `${t('Project')} Timeline`,
  hideDate: true,
  helpText: (
    <HoverHelp
      text={`A powerful report used to foresee gaps in scheduled labour and is typically used to aid in estimating strategies. Users can view an overall timeline of the start/end dates of all company ${t('Project', { count: 0 }).toLowerCase()} or a detailed ${t('Project').toLowerCase()} view which shows scheduled labour by phases and cost codes.`}
    />
  ),
}, {
  key: 'user_certifications',
  title: 'User Certifications',
  hasPermissions: (positions) => (
    positions?.some(({ name: positionName } = {}) => {
      const formattedName = Permissions.formatPosition(positionName);
      return Permissions.has(`USERS_${formattedName}`)
        && Permissions.has(`USERS_VIEW_${formattedName}`);
    })
  ),
  hideDate: true,
  hideSearch: false,
  hideSave: true,
  helpText: (
    <HoverHelp
      text="A report which allows managers to view all users and their certifications. This report is used to ensure that all users are up to date with their certifications and that they are not expired."
    />
  ),
}, {
  key: 'vendor_certifications',
  title: 'Vendor Certifications',
  hasPermissions: () => Permissions.has('VENDORS_READ'),
  hideDate: true,
  hideSearch: false,
  hideSave: true,
  helpText: (
    <HoverHelp
      text="A report which allows managers to view all vendors and their certifications. This report is used to ensure that all vendors are up to date with their certifications and that they are not expired."
    />
  ),
}, {
  key: 'cost_code_billing',
  title: 'Cost Code Billing',
  hideDate: false,
  hideSearch: false,
  hideSave: true,
  helpText: (
    <HoverHelp
      text="A report which allows managers to view all cost codes and their current costs/billing rates."
    />
  ),
}];
