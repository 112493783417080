/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Typography, Tooltip,
} from 'antd';
import { fileIconMap, getFileType } from '../../files/fileHelpers';

export default function FilePreview({
  file, onClick, small, location = '', style,
}) {
  const fileType = file?.name ? getFileType(file) : 'other';
  const Icon = fileIconMap[fileType];
  const iconSize = small ? 14 : 28;

  return (
    <Row
      style={{
        padding: 2,
        height: '100%',
        backgroundColor: 'white',
        cursor: onClick ? 'pointer' : 'auto',
        ...style,
      }}
      align="middle"
      onClick={onClick}
    >
      <Row
        justify="center"
        style={{
          width: '100%', display: 'flex', justifyContent: 'center', gap: 8,
        }}
      >
        <Icon style={{ fontSize: iconSize }} />
      </Row>
      <Row style={{ width: '100%' }}>
        <Tooltip placement="bottom" title={`${file.name}, ${location}`}>
          <Typography.Paragraph
            ellipsis={{ rows: small ? 1 : 2 }}
            style={{ fontSize: 10, textAlign: 'center', width: '100%' }}
          >
            {file.name}
          </Typography.Paragraph>
        </Tooltip>
      </Row>
    </Row>
  );
}

FilePreview.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.string,
    autoSaveId: PropTypes.string,
    name: PropTypes.string,
  }),
  onClick: PropTypes.func,
  small: PropTypes.bool,
  location: PropTypes.string,
  style: PropTypes.shape({}),
};

FilePreview.defaultProps = {
  file: {
    name: '',
  },
  onClick: null,
  small: false,
  location: '',
  style: {},
};
