import React from 'react';
import { Button, message } from 'antd';
import {
  FolderOutlined,
  FileImageOutlined,
  FileOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import { DateTime } from 'luxon';
import axios from 'axios';
import * as XLSX from 'xlsx';

import { uploadFilesToS3 } from './state/files.service';
import Analytics from '../helpers/Analytics';
import { addFileToFileMap, uploadFiles } from './state/files.actions';
import { decorateFile } from '../forms/formHelpers';

const fileGetter = axios.create();

export const validFileTypes = new Set([
  'image/png',
  'image/jpeg',
  'image/svg+xml',
  'image/heic',
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // XLSX
  'application/vnd.ms-excel.addin.macroenabled.12', // XLAM
  'application/vnd.ms-excel.template.macroenabled.12', // XLTX
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template', // XLTM
  'application/vnd.ms-excel.sheet.binary.macroenabled.12', // XLSB
  'application/vnd.ms-excel.sheet.macroenabled.12', // XLSM
  'text/csv',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/rtf',
  'text/plain',
]);

export default {};

export const getPathPrefix = (pathname) => {
  let prefix = decodeURIComponent(pathname).replace('/files','')
  if(prefix) prefix = `${prefix.substring(1)}/`;
  return prefix;
};

export const getBaseCrumbs = () =>  [{
  text: 'Files',
  icon: 'file',
}];

export const fileIconMap = {
  image: FileImageOutlined,
  pdf: FilePdfOutlined,
  spreadsheet: FileExcelOutlined,
  document: FileTextOutlined,
  folder: FolderOutlined,
  other: FileOutlined,
};

/**
 * Removes trailing slash from path/str if any
 * @param {string} path
 * @returns {string}
 */
export const removeTrailingSlash = (path) => {
  const { length } = path || {};
  if (typeof length !== 'number') return '';
  return path.charAt(length - 1) === '/' ? path.substring(0, length - 1) : path;
};

export const getFileType = (file) => {
  if (!file) return;
  const { type, name } = file;
  if (type === 'folder') return 'folder';
  const suffix = name.split('.').pop();
  switch (suffix) {
    case 'png':
    case 'jpeg':
    case 'jpg':
    case 'svg':
      return 'image';
    case 'pdf':
      return 'pdf';
    case 'xlsx':
    case 'xlsm':
    case 'xlsb':
    case 'xltx':
    case 'xltm':
    case 'xlam':
    case 'xls':
    case 'csv':
    case 'numbers':
      return 'spreadsheet';
    case 'doc':
    case 'docx':
    case 'rtf':
    case 'txt':
      return 'document';
    default:
      return 'other';
  }
};

export const getLevelFromPath = ({
  fileStructure,
  pathname,
}) => {
  const levels = pathname.split('/');
  // Levels starts with ['','files'];
  let crumbs = getBaseCrumbs();
  let basePath = '/files';
  let subPath = '';
  if (levels.length > 2) {
    crumbs = getBaseCrumbs();
    crumbs[0].link = basePath;
  }
  // Use some to early exit if we find an invalid path

  levels.slice(2).some((name, index) => {
    const newPath = `${basePath}/${name}`;
    subPath += name;
    if (!(subPath in fileStructure)) return true;

    crumbs.push({
      text: name,
      link: index < levels.length - 3 ? newPath : null,
    });
    basePath = newPath;
    subPath += '/';
    return false;
  });
  return { crumbs, path: subPath ? subPath.substring(0, subPath.length - 1) : subPath };
};

export const getLevelsFromPath = (path, pop) => {
  const levels = path.split('/');
  if (pop) levels.pop(); // Folder path always ends with / so levels has an empty last object
  return levels;
};

export const addItemsToStructure = ({
  fileStructure,
  items = [],
  rootFiles = [],
}) => {
  const originalStruct = { ...fileStructure };
  const newRootFiles = [...rootFiles];
  items.forEach((item) => {
    const {
      id,
      path,
      name, // Only exists for files
      size = 0,
      lastUpdated,
      jsFileObject, // Only exists for files
      defaultPublic,
      type,
    } = item;
    const fullPath = name ? `${path}/${name}` : path.substring(0, path.length - 1);
    const lastSlash = fullPath.lastIndexOf('/');
    const parent = name ? path : fullPath.substring(0, lastSlash);
    const file = {
      id,
      name: name || fullPath.substring(lastSlash + 1),
      fullPath,
      parent,
      size,
      lastUpdated,
      type: type ?? (name ? 'file' : 'folder'),
      jsFileObject,
      defaultPublic,
    };

    if (file.type === 'folder') {
      file.children = [];
    }

    if (parent in originalStruct) {
      const parentObject = { ...originalStruct[parent] };
      const {
        children = [],
      } = originalStruct[parent];
      // Should optimize this if we think users will be adding files frequently.
      // Or if directories will have a large number of files
      if (!children.includes(fullPath)) {
        parentObject.children = children.concat([fullPath]).sort();
      }
      originalStruct[parent] = parentObject;
    } else if (!parent) {
      if (!newRootFiles.find((existingFile) => existingFile.name === file.name)) {
        newRootFiles.push(file);
      }
    }

    originalStruct[fullPath] = file;
  });

  return { fileStructure: originalStruct, rootFiles: newRootFiles };
};

export const deleteItems = ({
  deletedItems = [],
  fileStructure = {},
  rootFiles = [],
}) => {
  let originalStruct = { ...fileStructure };
  const rootDeleteSet = new Set();
  deletedItems.forEach((item) => {
    const { name, type } = item;
    const isFolder = type === 'folder';
    const fullPath = isFolder ? name.substring(0, name.length - 1) : name;
    if (!(fullPath in originalStruct)) return;
    const file = originalStruct[fullPath];

    const { children = [], parent, name: fileName } = file;
    if (type === 'folder') {
      // Recursively delete all subfolders/items
      const subDelete = deleteItems({
        deletedItems: children,
        fileStructure: originalStruct,
        rootFiles,
      });
      originalStruct = subDelete.fileStructure;
    }
    delete originalStruct[fullPath];
    if (!parent) rootDeleteSet.add(fileName);
  });

  if (rootDeleteSet.size === 0) return { fileStructure: originalStruct, rootFiles };

  const newRootFiles = rootFiles.filter((rootFile) => !rootDeleteSet.has(rootFile.name));
  return { fileStructure: originalStruct, rootFiles: newRootFiles };
};

export const constructFullPath = (prefix, name, addTrailingSlash = true) => (
  `${prefix}${name}${addTrailingSlash ? '/' : ''}`
);

export const parseFile = (file) => {
  const {
    id,
    type,
    name,
    size,
    lastUpdated,
    fullPath,
    defaultPublic,
    isPublic = false,
  } = file;
  return {
    id,
    name,
    type: getFileType({ name, type }),
    size,
    lastUpdated,
    fullPath,
    defaultPublic,
    isPublic,
  };
};

const B = 1;
const KB = 1000;
export const MB = 1000 * KB;
export const GB = 1000 * MB;
const magnitudes = [GB, MB, KB, B];
const suffixes = ['GB', 'MB', 'KB', 'B'];
export const parseSize = (size) => {
  if (!size) return size;
  let i = 0;
  while (i < magnitudes.length) {
    const val = size / magnitudes[i];
    if (val > 1) return `${val.toFixed(2)} ${suffixes[i]}`;
    i += 1;
  }
  return size;
};

export const parseUpdated = (lastUpdated) => (
  lastUpdated
    ? DateTime.fromMillis(lastUpdated).toLocaleString(DateTime.DATETIME_MED)
    : lastUpdated
);

export const downloadFile = async ({
  // fileObject corresponds to built in javascript File object
  // fileDetails corresponds to a file served up by Ontraccr backend
  fileDetails: { fullPath, name, VersionId } = {},
  fileObject,
  fileURL,
  saveName,
}) => {
  try {
    let url = fileURL;
    if (fileObject) {
      url = URL.createObjectURL(fileObject);
    } else if(!fileURL) {
      const { data } = await axios.get(`download/files/${fullPath}`, {
        params: {
          Disposition: `attachment; filename="${encodeURIComponent(name)}"`,
          VersionId,
        },
      });
      url = data;
    }

    // https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      // IE workaround for "HTML7007: One or more blob URLs were
      // revoked by closing the blob for which they were created.
      // These URLs will no longer resolve as the data backing
      // the URL has been freed."
      if (fileObject) {
        window.navigator.msSaveBlob(fileObject, fileObject.name);
      } else {
        const { data } = await fileGetter.get(url);
        window.navigator.msSaveBlob(data, name);
      }
    } else {
      let downloadName = fileObject ? fileObject.name : name;
      if (saveName) downloadName = saveName;
      const tempLink = document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = url;
      tempLink.setAttribute('download', downloadName);

      // Safari thinks _blank anchor are pop ups. We only want to set _blank
      // target if the browser does not support the HTML5 download attribute.
      // This allows you to download files in desktop safari if pop up blocking
      // is enabled.
      if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
      }
      document.body.appendChild(tempLink);
      tempLink.click();

      // Fixes "webkit blob resource error 1"
      setTimeout(() => {
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(url);
      }, 20);
    }
  } catch (err) {
    //
  }
};

export const getFileDetails = async ({ name, path, type, fullPath: fullPathFromFile } = {}) => {
  try {
    let fullPath = path ? `${path}/${name}` : name;
    if (fullPathFromFile) {
      fullPath = fullPathFromFile;
    }
    const url = `download/files/${fullPath}`;
    const { data: signedURL } = await axios.get(url, { params: { isView: true } });
    return {
      url: signedURL,
      type,
      name,
      fullPath,
    };
  } catch (err) {
    return null;
  }
};

// Currently not used.
export const findFileIdsDownTree = ({ fileStructure = {}, selectedFile = {} }) => {
  const { id:fileId, type, children = [], } = selectedFile;
  const files = new Set([fileId]);

  if (type === 'folder' && children.length > 0) {
    // Go down the tree to grant access as well
    children.forEach((childId) => {
      if(!(childId in fileStructure)) return;
      const child = fileStructure[childId];
      const subFiles = findFileIdsDownTree({ fileStructure, selectedFile: child });
      subFiles.forEach((subFile) => files.add(subFile))
    });
  }
  return Array.from(files);
}

// Currently not used.
export const grantAccessInFileStructure = ({ fileStructure = {}, selectedFile = {} }) => {
  const { fullPath, id:fileId, } = selectedFile;
  // Go backwards to grant access 'up' the tree
  const files = new Set([fileId]);
  let i = fullPath.lastIndexOf('/');
  while (i > 0) {
    const sub = fullPath.substring(0,i);
    if(sub in fileStructure) files.add(fileStructure[sub].id);
    i = sub.lastIndexOf('/');
  }

  const subFiles = findFileIdsDownTree({ fileStructure, selectedFile });
  subFiles.forEach((subFile) => files.add(subFile))
  return Array.from(files);
};

export const isDefaultPublic = (record) => record.defaultPublic;

export const isLinkedFolder = ({
  record,
  pathname,
  projectMap,
  divisionNames,
  divisionFolders,
  divisionProjectsFolders,
}) => {
  if (pathname === '/files' && isDefaultPublic(record)) return true;
  if (pathname === '/files' && record.name === 'Attachments') return true;
  if (pathname === '/files/Projects' && (record.name in projectMap || record.name === 'Archived')) return true;
  if (pathname === '/files/Projects/Archived') return true;

  if (pathname === '/files' && divisionNames.has(record.name)) return true;
  if (divisionFolders.has(pathname) && (record.name === 'Projects' || record.name === 'Attachments')) return true;
  if (divisionProjectsFolders.has(pathname) && (record.name in projectMap || record.name === 'Archived')) return true;

  return false;
};


const getBody = (isOwner, history, action) => {
  if(isOwner) return (
    <div>
      If you need to {action} navigate to
      <Button
        type='link'
        style={{ padding: '0px 3px' }}
        onClick={() => {
          history.push('/settings',{ activeTab: 'billing' });
        }}
      >
      your settings page
      </Button>to upgrade your account.
    </div>
  )

  return (
    <div>
      Contact your account owner to upgrade your account
    </div>
  )
};

export const storageUpgradeWarning = ({
  isOwner,
  history,
  title = 'You need to upgrade your account to access this feature',
  action = 'upload files',
  bodyText,
}) => (
  <div style={{ maxWidth: 320, overflow:'hidden', overflowWrap:'break-word'}}>
  {title}
  <br/>
  <br/>
  { bodyText ?? getBody(isOwner,history,action)}
</div>
)

export const checkFileIsSpreadsheet = (file) => {
  const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
    file.type === 'application/vnd.ms-excel';
  if (!isExcel) {
    message.error('You can only upload xlsx or xls file types');
  }
  const isLt2M = file.size / 1048576 < 10;
  if (!isLt2M) {
    message.error('File must be smaller than 10MB!');
  }
  return isExcel && isLt2M;
};

export const parseWorkbook = (file, callback) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    const data = new Uint8Array(e.target.result);
    const workbook = XLSX.read(data, { type: 'array', cellDates: true });
    callback(workbook);
  };
  reader.readAsArrayBuffer(file);
};

const SHOULD_VALIDATE_FILE_SIGNATURE = new Set(['image/png', 'image/jpeg', 'image/jpeg', 'application/pdf']);
const JPEG_HEADERS = new Set(['FFD8FFDB', 'FFD8FFE0', 'FFD8FFEE', 'FFD8FFE1']);
export const validateFileHeader = (file) => (
  new Promise((resolve) => {
    const { type } = file;
    if (!SHOULD_VALIDATE_FILE_SIGNATURE.has(type)) resolve(true); // Can't validate these files
    const fileReader = new FileReader();
    fileReader.onloadend = function(e) {
      const arr = (new Uint8Array(e.target.result)).subarray(0, 8);
      let header = '';
      for(let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16).padStart(2, '0');
      }
      header = header.toUpperCase();
      // Check the file signature against known types
      if (header === '89504E470D0A1A0A') return resolve(type === 'image/png');
      if (JPEG_HEADERS.has(header.slice(0,8))) return resolve(type === 'image/jpeg' || type === 'image/jpg');
      if (header.slice(0,10) === '255044462D') return resolve(type === 'application/pdf');
      resolve(false);
    };
    fileReader.readAsArrayBuffer(file)
  })
)

export const parseAndUploadFiles = async (files = [], path = 'Attachments') => {
  const parsedFiles = new Array(files.length).fill().map(() => ({}));
  const toUpload = [];
  files.forEach((file, idx) => {
    if (file instanceof File) {
      toUpload.push({ file, idx });
    } else {
      parsedFiles[idx] = { id: file.id, existing: true };
    }
  });

  if (toUpload.length > 0) {
    const uploadedFiles = await uploadFilesToS3(
      toUpload.map(({ file }) => ({ jsFileObject: file, path })),
    );
    uploadedFiles.forEach((file, uploadIdx) => {
      parsedFiles[toUpload[uploadIdx].idx] = { id: file.id, path: file.path, name: file.name, timestamp: file.timestamp };
    });
  }
  return parsedFiles;
};

export const loadFromSigned = async ({
  signedURL,
  name,
  id,
}) => {
  const { data, headers: { 'content-type': type } } = await fileGetter.get(signedURL, {
    responseType: 'arraybuffer',
  });
  const suffix = type === 'image/png' ? 'png' : 'jpeg';
  const image = new File([data], `${name}.${suffix}`, { type });
  /*
    antd Upload needs a unique uid for files or it will crash
    See: https://github.com/ant-design/ant-design/issues/4120
  */
  image.uid = id;
  return image;
};

export const uploadFile = async ({
  updatedFile,
  dispatch,
  pathname,
}) => {
  if (!updatedFile) return true;

  const prefix = getPathPrefix(pathname);
  const path = prefix.substring(0, prefix.length - 1);
  const payload = [];
  const fileMetadata = [];
  payload.push({
    jsFileObject: updatedFile,
    path,
  });
  fileMetadata.push({
    type: updatedFile.type,
    size: updatedFile.size,
  });

  Analytics.track('Files/UploadFiles', { files: fileMetadata });
  const result = await dispatch(uploadFiles(payload));
  return !!result;
};

/**
 * Downloads the file from the signed s3 url and adds it to the file map
 * Side Effect: Will also update the download progress and show a loading message
 * @param {*} dispatch - Redux dispatch function
 * @param {object} file - Shallow File object to download
 * @param {function} setProgress - (optional) Function to update download progress
 * @returns {boolean} if successfully downloaded file
 */
export const visuallyDownloadFile = async ({
  dispatch, file, setProgress,
}) => {
  // The file may not have an id if it is a new file. There would be nothing to download
  if (!file?.id) return false;

  // Show a loading message
  message.loading({ content: `Loading ${file?.name}`, key: file.id, duration: 0 });

  // Grab the signedUrl using the fileId
  const { data: signedUrl } = await axios.get(`download/file/${file.id}`);

  if (!signedUrl) {
    message.destroy(file.id);
    message.error('Could not find file. Please try again later.');
    return false;
  }

  // Load the file into the browser
  const tempFile = { ...file, url: signedUrl };
  const jsFileObject = await decorateFile(tempFile, file.id, false, setProgress);

  // If the file is not loaded, show an error message
  if (!jsFileObject) {
    message.destroy(file.id);
    message.error('Error loading file. Please try again later.');
    return false;
  }

  // Add the file to the file map
  dispatch(addFileToFileMap(file.id, jsFileObject));
  message.destroy(file.id);
  return true;
};
