import React from 'react';
import { Row } from 'antd';
import evaluate from 'evaluator.js';
import { getIdMap } from 'ontraccr-common/lib/Common';
import { DateTime } from 'luxon';
import { Buckets } from 'ontraccr-common';
import { formatCardTitle } from '../../../boards/boardHelpers';
import { formatFormDropdownList, getLinkId, getPhaseCostcodeTreeData } from '../../formHelpers';
import { isObjectEmpty } from '../../../common/helpers';
import { formatProjectLabelFromCompanySettings } from '../../../projects/projectHelpers';

// Check if there is a loop in calculations (e.g A -> B -> C -> A);
// If there is a loop we won't be able to do the calculations properly

// If we revist the current node while visiting it's parents, there must be a loop
const getLoopId = (sectionId, fieldId) => `${sectionId}.${fieldId}`;
const calculationFieldsHaveLoop = ({
  configProps = {},
  sections = [],
  field = {},
  sectionId,
  calculationColumn,
}) => {
  const { id: fieldId } = field;
  if (!fieldId) return false;
  const originalId = getLoopId(sectionId, fieldId);
  const sectionMap = {};
  sections.forEach((section) => {
    if (!(section.id in sectionMap)) sectionMap[section.id] = {};
    const { fields = [] } = section;
    fields.forEach((sectionField) => {
      sectionMap[section.id][sectionField.id] = sectionField;
    });
  });
  const { tags = [] } = configProps;
  let nodes = [...tags];
  // Visit all parent nodes to see if we repeat the original node
  while (nodes.length) {
    const node = nodes.pop();
    const {
      colKey: tagColKey,
      fieldId: tagFieldId,
      sectionId: tagSectionId,
    } = node;
    const tagLoopId = getLoopId(tagSectionId, tagFieldId);
    const isCalculationTable = calculationColumn && tagLoopId === originalId;
    if (
      (!isCalculationTable && tagLoopId === originalId)
      || (isCalculationTable && tagColKey === calculationColumn)
    ) return true;
    const {
      [tagSectionId]: {
        [tagFieldId]: {
          configProps: {
            tags: nextTags = [],
            tagsMap: nextTagsMap = {},
          } = {},
        } = {},
      } = {},
    } = sectionMap;
    if (tagColKey) {
      const colNextTags = nextTagsMap[tagColKey] || [];
      nodes = nodes.concat(colNextTags);
    } else {
      nodes = nodes.concat(nextTags);
    }
  }
  return false;
};

export const getFormulaError = ({
  configProps = {},
  sections = [],
  field = {},
  sectionId,
  calculationColumn,
}) => {
  if (calculationFieldsHaveLoop({
    configProps,
    sections,
    field,
    sectionId,
    calculationColumn,
  })) {
    return 'Calculation fields have a reference loop.';
  }
  const { formula = '', tags = [] } = configProps;
  let replacedFormula = formula;
  let offset = 0;
  tags.forEach((tag) => {
    const { startIndex, endIndex } = tag;
    replacedFormula = `${replacedFormula.substring(0, startIndex - offset)}1${replacedFormula.substring(endIndex - offset + 1)}`;
    offset += endIndex - startIndex;
  });
  try {
    evaluate(replacedFormula);
    return null;
  } catch (err) {
    return err.message;
  }
};

export const getTableFormulaError = ({
  configProps = {},
  sections = [],
  field = {},
  sectionId,
}) => {
  const { columns = [], formulaMap = {}, tagsMap = {} } = configProps;
  const isValid = columns.every((col = {}) => {
    const { key, isCalculation } = col;
    if (!isCalculation) return true;
    const formula = formulaMap[key];
    const tags = tagsMap[key] || [];
    const err = getFormulaError({
      configProps: { formula, tags },
      sections,
      field,
      sectionId,
      calculationColumn: key,
    });
    if (!formula?.length || err) return false;
    return true;
  });
  return !isValid;
};

const dataTypesThatRequireSubDataType = new Set(['CompletedForms', 'Cards', 'Buckets', 'PayableSubContracts']);

const isValidLink = (url = '') => url.match(/^(?:.{0,5}:)?\/\//);

export const fieldTypeCanSubmit = {
  'yes-no': ({ configProps }) => configProps.title,
  dropdown: ({ configProps }) => {
    const {
      numAnswers = 1,
    } = configProps;
    return (
      configProps.title
      && configProps.dataType
      && numAnswers
      && (
        !dataTypesThatRequireSubDataType.has(configProps.dataType)
        || !!configProps.subDataType
      )
      && (
        configProps.dataType !== 'Custom'
        || (
          configProps.customOptions
          && configProps.customOptions.length > 0
        )
      )
    );
  },
  text: ({ configProps }) => configProps.title,
  attachment: ({ configProps }) => configProps.title,
  table: ({
    configProps, sections, field, sectionId,
  }) => (
    configProps.title
    && configProps.columns
    && configProps.columns.length > 0
    && !getTableFormulaError({
      configProps, sections, field, sectionId,
    })
    && (configProps.preloadEntryDateType !== 'dateTime' || !!configProps.preloadEntryDateField)
  ),
  calculation: ({
    configProps, sections, field, sectionId,
  }) => (
    configProps.formula
    && configProps.formula.length > 0
    && !getFormulaError({
      configProps, sections, field, sectionId,
    })
  ),
  dateRange: ({ configProps }) => configProps.title,
  dateTime: ({ configProps }) => configProps.title,
  attribute: ({ configProps }) => configProps.title,
  payment: ({ configProps = {} }) => configProps.title && configProps.linkedField,
  multiSig: ({ configProps = {} }) => configProps.title && configProps.linkedField,
  staticText: ({ configProps }) => (configProps.isHyperlink
    ? isValidLink(configProps.title)
    : configProps.title),
  staticAttachments: ({ configProps }) => configProps.title,
  gpsLocation: ({ configProps }) => configProps.title,
  weather: ({ configProps }) => configProps.title,
};

const premiumText = 'Available with subscription upgrade, contact your Ontraccr account manager for more information';
export const fieldOption = (field, disabled) => (
  <Row style={{ width: 330 }}>
    <Row className="form-field-type-title">
      {field.title}
    </Row>
    {disabled && (
      <Row className="form-field-type-premium">
        {premiumText}
      </Row>
    )}
    <Row className="form-field-type-description">
      {field.description}
    </Row>
  </Row>
);

const filterByDivision = (arr, divisionSet) => (
  arr.filter((item) => divisionSet.has(item.divisionId))

);

const combineSharedTreeTitles = (treeOptions) => {
  const parsedTreeOptions = {};

  treeOptions.forEach((option) => {
    const { title, children } = option;
    if (!parsedTreeOptions[title]) {
      parsedTreeOptions[title] = option;
    } else {
      parsedTreeOptions[title].children.push(...children);
    }
  });

  return Object.values(parsedTreeOptions);
};

export const getCostcodeDropdownOptions = ({
  costcodes,
  divisionSet,
  projectId,
  projectIds = [],
  phases,
  costcodeIdMap,
  projectIdMap,
  openLimit,
  numAnswers = 1,
  actualSelectedSet = new Set(),
  responding = false,
}) => {
  const divisionCostcodes = filterByDivision(costcodes, divisionSet);
  const data = getPhaseCostcodeTreeData({
    responding,
    projectId,
    phases,
    costcodes: divisionCostcodes,
    costcodeMap: costcodeIdMap,
    projectMap: projectIdMap,
    disableOptions: !openLimit && numAnswers > 1 && actualSelectedSet.length >= numAnswers,
  });
  if (projectIds.length) {
    projectIds.forEach((id) => {
      const projectData = getPhaseCostcodeTreeData({
        responding,
        projectId: id,
        phases,
        costcodes: divisionCostcodes,
        costcodeMap: costcodeIdMap,
        projectMap: projectIdMap,
        disableOptions: !openLimit && numAnswers > 1 && actualSelectedSet.length >= numAnswers,
      });
      data.push(...projectData);
    });
  }
  return combineSharedTreeTitles(data);
};

export const getContactDropdownOptions = ({
  contactAddressBooks = {},
  globalAddressBooks = {},
  linkField,
  linkedCustomerId,
  linkedVendorId,
  responding,
  actualSelected,
}) => {
  const customerAddressBook = contactAddressBooks[linkedCustomerId] ?? [];
  const vendorAddressBook = contactAddressBooks[linkedVendorId] ?? [];
  if (!responding) {
    return actualSelected ?? [];
  } if (!linkField) {
    return Object.values(globalAddressBooks);
  } if (linkedCustomerId === linkedVendorId) { // the same if linked
    return customerAddressBook;
  }
  return customerAddressBook.concat(vendorAddressBook);
};

// Make sure to mirror changes in DropdownField.jsx
// TODO: Refactor to combine logic
export const getDropDownOptions = ({
  // Base Inputs
  t = (val) => val,
  configProps = {},
  actualSelectedSet = new Set(),
  divisionSet = new Set(),
  filterLabels = [],
  responding = false,
  responses = {},
  settings = {},

  // Data Lists
  customers = [],
  projects = [],
  users = [],
  equipment = [],
  vendors = [],
  labels = [],
  forms = [],
  subContractForms = [],
  costcodes = [],
  phases = [],
  formTemplates = [],

  // subtypes
  projectTypes = [],
  equipmentTypes = [],

  // Maps
  projectIdMap = {},
  bucketTemplateMap = {},
  boardDetailsMap = {},
  subContractMap = {},
  contactAddressBooks = {},
  globalAddressBooks = {},
  projectEquipment = {},
  costcodeIdMap = {},
  divisionMap = {},
  vendorIdMap = {},

  // Ids
  customerId = null,
  projectId = null,
  templateId = null,
  vendorId = null,

  // x to label
  customerToLabel = {},
  userToLabel = {},
  vendorToLabel = {},

  selectedBucket = null,
  selectedBucketTypeToIdMap = {},
  selectedBucketTypes = [],
}) => {
  let data = [];
  let allData = [];
  let placeholder = 'Select an option';

  const {
    dataType,
    subDataType,
    openLimit,
    numAnswers = 1,
    linkField,
    enableGlobalSelection,
    customOptions,
  } = configProps;

  const linkedCustomerId = getLinkId({ responses, defaultValue: customerId, linkField });
  const linkedVendorId = getLinkId({ responses, defaultValue: vendorId, linkField });

  switch (dataType) {
    case 'Customers': {
      const {
        [projectId]: { customerId: projectCustomerId } = {},
      } = projectIdMap;
      data = customers.filter((customer) => {
        const {
          [customer.id]: customerLabels = [],
        } = customerToLabel;
        const customerLabelSet = new Set(customerLabels);

        return (
          customer.active
          && (!projectId || customer.id === projectCustomerId)
          && filterLabels.every((label) => customerLabelSet.has(label))
          && (!selectedBucket || isObjectEmpty(selectedBucket) || Buckets.getLinkedBuckets([selectedBucket], [customer.id], ['customers']).allBuckets.length > 0)
        );
      });
      placeholder = `Select a ${t('Customer')}`;
      break;
    }
    case 'Projects': {
      const projectTypeSet = new Set(projectTypes.map((type) => type.id));
      const subDataTypeSet = new Set(
        subDataType?.filter?.((typeId) => projectTypeSet.has(typeId) || typeId === null) ?? [],
      );
      data = projects.filter((project) => project.active
        && (divisionSet.has(project.divisionId))
        && (!customerId || project.customerId === customerId)
        && (!subDataTypeSet?.size || subDataTypeSet.has(project.projectTypeId))
        && (!selectedBucket || isObjectEmpty(selectedBucket) || Buckets.getLinkedBuckets([selectedBucket], [project.id], ['projects']).allBuckets.length > 0))
        .map(({ name, number, ...rest }) => ({
          label: formatProjectLabelFromCompanySettings({ name, number, settings }),
          name,
          number,
          ...rest,
        }));
      placeholder = `Select a ${t('Project')}`;
      break;
    }
    case 'Users': {
      const allUsers = Array.from(divisionSet).map((divisionId) => {
        const {
          [divisionId]: {
            users: divisionUsers = new Set(),
          } = {},
        } = divisionMap;
        return Array.from(divisionUsers);
      });
      const userSet = new Set(allUsers.flat());
      const divUsers = users.filter((user) => user.active && userSet.has(user.id));

      data = divUsers.filter((user) => {
        const {
          [user.id]: userLabels = [],
        } = userToLabel;
        const userLabelSet = new Set(userLabels);
        return (
          user.active
          && (
            filterLabels.length === 0
            || actualSelectedSet.has(user.id)
            || filterLabels.every((label) => userLabelSet.has(label))
          )
        );
      });
      placeholder = 'Select a User';
      break;
    }
    case 'Costcodes': {
      data = getCostcodeDropdownOptions({
        costcodes,
        divisionSet,
        projectId,
        phases,
        costcodeIdMap,
        projectIdMap,
        openLimit,
        numAnswers,
        actualSelectedSet,
        responding,
      });
      placeholder = 'Select a Costcode';
      break;
    }
    case 'Equipment': {
      const equipmentTypeSet = new Set(equipmentTypes.map((type) => type.id));
      const subDataTypeSet = new Set(
        subDataType?.filter?.((typeId) => equipmentTypeSet.has(typeId) || typeId === null) ?? [],
      );
      data = equipment.filter((eq) => (
        eq.active
          && eq.divisionIds.some((divisionId) => divisionSet.has(divisionId))
          && (enableGlobalSelection || !projectId || !!projectEquipment[projectId]?.[eq.id])
          && (!subDataTypeSet?.size || subDataTypeSet.has(eq.equipmentTypeId))
      ));
      placeholder = 'Select Equipment';
      break;
    }
    case 'Vendors': {
      data = vendors.filter((vendor) => {
        const {
          [vendor.id]: vendorLabels = [],
        } = vendorToLabel;
        const vendorLabelSet = new Set(vendorLabels);

        return (
          vendor.active
          && filterLabels.every((label) => vendorLabelSet.has(label))
        );
      });
      placeholder = 'Select Vendor';
      break;
    }
    case 'Sub-Contracts': {
      const {
        [projectId]: subcontracts = [],
      } = subContractMap;
      data = subcontracts.map((sub) => ({ id: sub.rowId, name: sub.description }));
      break;
    }
    case 'Contacts': {
      data = getContactDropdownOptions({
        contactAddressBooks,
        linkedCustomerId,
        linkedVendorId,
        responding,
        linkField,
        globalAddressBooks,
      });
      placeholder = 'Select Contact';
      break;
    }
    case 'Custom': {
      data = customOptions;
      break;
    }
    case 'Forms': {
    // Prevent infinite form workflow loop
      data = formTemplates.filter((ft) => ft.active
      && divisionSet.has(ft?.divisionId)
      && ft.id !== templateId);
      placeholder = 'Select Form Template';
      break;
    }
    case 'Labels': {
      const subDataTypeSet = new Set(subDataType);
      data = labels
        .filter((label) => !subDataType?.length || subDataTypeSet.has(label.type))
        .map((label) => ({ id: label.id, name: label.title }));
      placeholder = 'Select Label';
      break;
    }
    case 'CompletedForms': {
      data = formatFormDropdownList({
        formList: forms,
        projectIdMap,
        projectId,
        shouldFilterByProjects: true,
      });
      placeholder = 'Select Form';
      break;
    }
    case 'Cards': {
      const statusMap = getIdMap(boardDetailsMap?.[subDataType]?.statuses);
      data = boardDetailsMap?.[subDataType]?.cards?.map((card) => ({
        id: card.id,
        name: formatCardTitle(card),
        subNames: [
          statusMap[card.statusId]?.title ?? '',
          card.lastUpdated ? DateTime.fromMillis(card.lastUpdated).toLocaleString(DateTime.DATETIME_MED) : '',
        ],
      })) ?? [];
      placeholder = 'Select Card';
      break;
    }
    case 'Buckets': {
      const buckets = bucketTemplateMap?.[subDataType] ?? [];
      const filterIds = Buckets.getFilterIdsForBucketDropdown({
        buckets,
        customerId,
        projectId,
        selectedBucketTypeToIdMap,
        subDataType,
      });

      data = buckets;
      allData = buckets;

      if (filterIds.length) {
        data = Buckets.getLinkedBuckets(buckets, filterIds, ['customers', 'projects', ...selectedBucketTypes]).allBuckets;
      }
      placeholder = 'Select Bucket';
      break;
    }
    case 'PayableSubContracts': {
      data = formatFormDropdownList({
        formList: subContractForms,
        projectIdMap,
        vendorIdMap,
        projectId,
        shouldFilterByProjects: true,
      });
      placeholder = 'Select Sub-Contract';
      break;
    }
    default: {
      data = [];
      break;
    }
  }

  return {
    data,
    placeholder,
    allData,
  };
};

export const updateStackedDates = (oldDate) => {
  const safeDatesArray = typeof oldDate === 'string' ? oldDate.split(', ') : [];
  if (safeDatesArray.length) {
    return oldDate;
  }
  return DateTime.local().toISO();
};
