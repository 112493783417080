import React, {
  useMemo, useEffect, useCallback, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table, Row, Spin, Col,
} from 'antd';
import axios from 'axios';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { getIdMap } from '../../helpers/helpers';
import {
  prepareExportData,
  MATERIAL_EXPORT_TYPE,
  OVERHEAD_EXPORT_TYPE,
  LABOR_EXPORT_TYPE,
  PHASED_EXPORT_TYPE,
  exportFile,
  SUMMARY_EXPORT_TYPE,
} from './export/exportHelpers';

import FormDetailView from '../../forms/CompletedForms/FormDetailView';
import InvoiceAddDrawer from '../../payables/invoices/InvoiceAddDrawer';

import {
  getLaborColumns,
  getMaterialColumns,
  getOverheadColumns,
  getPhasedColumns,
  getSubcontractColumns,
  getProgressSummaryColumns,
  getUncategorizedInvoiceColumns,
  getMaterialCostColumns,
  getEquipmentColumns,
  getEquipmentCostColumns,
  getBasicCostColumns,
} from './ProgressColumns';
import ProgressHistoryDrawer from './ProgressHistoryDrawer';
import ProgressHeader from './ProgressHeader';

import { getProgress } from '../../reports/state/reports.actions';
import {
  getAllProjectCostcodeDetails,
  updateIndividualProject,
  updateProjectProgressFilters,
  getProgressSubContractUpdates,
} from '../state/projects.actions';
import { getCompanyImageURL } from '../../settings/state/settings.actions';
import { getFormById, getFormApprovals } from '../../forms/state/forms.actions';

import Analytics from '../../helpers/Analytics';
import { getActualCostForCostCode, getActualSpend, getProgressFromState } from './progressHelpers';
import Permissions from '../../auth/Permissions';
import { INVOICE_DRAWER_ADD_MODE, INVOICE_DRAWER_VIEW_MODE } from '../../payables/invoices/invoiceConstants';
import { getInvoices } from '../../payables/invoices/state/invoices.actions';
import useToggle from '../../common/hooks/useToggle';
import ProgressSubContractInfoSlider from './ProgressSubContractInfoSlider';

const formatCC = ({ costcode, phaseId }) => ({
  ...costcode,
  key: `${phaseId} - ${costcode.id}`,
  type: 'costcode',
});

const getExportTitle = (t) => `${t('Project')} Progress`;

const getSubcontractProgress = async (id, filters) => {
  try {
    const {
      data = [],
    } = await axios.get(`/projects/${id}/subcontracts/progress`, { params: filters });
    return data;
  } catch (e) {
    return [];
  }
};

const areFiltersEqual = (filters1, filters2) => {
  if (filters1 === filters2) return true;
  if (!filters1 || !filters2) return false;

  const {
    start: startDate1,
    end: endDate1,
  } = filters1 || {};

  const {
    start: startDate2,
    end: endDate2,
  } = filters2 || {};

  if (startDate1 !== startDate2) return false;
  if (endDate1 !== endDate2) return false;

  return true;
};

const dataTimers = {
  progress: {
    time: DateTime.fromObject({ year: 1900 }),
    filters: {},
  },
  subcontractorsAndCostcodeDetails: {
    time: DateTime.fromObject({ year: 1900 }),
    filters: {},
  },
};

export default function ProjectProgress({
  visible,
  projectId,
  projectName,
  averageWage = 0,
  estimatedLaborCost = 0,
  estimatedMaterialCost = 0,
  estimatedOverheadCost = 0,
  estimatedBurdenRate = 0,
  estimatedEquipmentCost = 0,
  enableBillingDisplay,
  divisionId,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const hasPOPerms = Permissions.match('PURCHASE_ORDERS');
  const hasWrite = Permissions.has('PROJECT_PROGRESS_WRITE');

  const { phases = [], costcodes = [] } = useSelector((state) => state.costcodes);
  const users = useSelector((state) => state.users.users);
  const progresses = useSelector((state) => state.reports.progress);
  const invoices = useSelector((state) => state.invoices.invoices);
  const statuses = useSelector((state) => state.invoices.statuses);
  const vendors = useSelector((state) => state.vendors.vendors);
  const projectCostUpdates = useSelector((state) => state.projects.projectCostUpdates);
  const subContractUpdates = useSelector((state) => state.projects.subContractUpdates);

  const {
    projectCostcodeDetails: projectCostcodeDetailsMap,
    projectProgressFilters,
  } = useSelector((state) => state.projects);
  const companyImageURL = useSelector((state) => {
    const {
      settings: {
        company: {
          companyImageURL: stateCompanyURL = '',
        } = {},
      } = {},
    } = state;
    return stateCompanyURL;
  });

  const filteredProjectCostUpdates = useMemo(() => {
    if (!projectProgressFilters?.dateRange) return projectCostUpdates;

    const { start, end } = projectProgressFilters?.dateRange ?? {};

    return projectCostUpdates.filter((cost) => cost.timestamp >= start && cost.timestamp <= end);
  }, [projectCostUpdates, projectProgressFilters]);

  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(); // for history drawer
  const [subcontracts, setSubcontracts] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const [showInvoiceDrawer, setShowInvoiceDrawer] = useState(false);
  const [selectedCostcode, setSelectedCostcode] = useState();
  const [selectedInvoice, setSelectedInvoice] = useState({});
  const [drawerMode, setDrawerMode] = useState(INVOICE_DRAWER_ADD_MODE);
  const [showBilling, setShowBilling] = useState(enableBillingDisplay);
  const [selectedSubContract, setSelectedSubContract] = useState();
  const [subContractCOs, setSubContractCOs] = useState({});
  const [newItemNumbers, setNewItemNumbers] = useState({ subContractCOs: 1 });
  const [liveSummaryValues, setLiveSummaryValues] = useState([]);

  const {
    isToggled: showSubContractDrawer,
    toggle: toggleSubContractDrawer,
  } = useToggle();

  const onCloseDrawer = useCallback(() => setSelectedRecord(), []);
  const onHistoryClick = useCallback((record) => setSelectedRecord(record), []);

  const toggleBillingDisplay = useCallback(async () => {
    if (await dispatch(updateIndividualProject({
      projectId,
      payload: {
        details: {
          enableBillingDisplay: !showBilling,
        },
      },
    }))) {
      setShowBilling(!showBilling);
    }
  }, [showBilling]);

  const onCloseInvoiceDrawer = useCallback(() => {
    setShowInvoiceDrawer(false);
    setSelectedCostcode();
    setSelectedInvoice({});
  }, []);

  const onUploadInvoice = useCallback((record) => {
    const { costcodeId, phaseId } = record ?? {};
    setSelectedCostcode({ costcodeId, phaseId });
    setSelectedInvoice({});
    setDrawerMode(INVOICE_DRAWER_ADD_MODE);
    setShowInvoiceDrawer(true);
  }, []);

  const onUncategorizedClick = useCallback((invoice) => ({
    onClick: () => {
      setSelectedCostcode();
      setSelectedInvoice(invoice);
      setDrawerMode(INVOICE_DRAWER_VIEW_MODE);
      setShowInvoiceDrawer(true);
    },
  }), []);

  const onInfoClick = useCallback((record) => {
    toggleSubContractDrawer();
    setSelectedSubContract(record);
  }, [toggleSubContractDrawer]);

  const updateDrawerMode = useCallback((mode) => setDrawerMode(mode), []);

  const onInvoiceSubmit = useCallback(() => {
    dispatch(getProgress(projectProgressFilters?.dateRange));
  }, [projectProgressFilters]);

  const loadSubcontracts = useCallback(async () => {
    if (!hasPOPerms) return;
    const newSubcontracts = await getSubcontractProgress(projectId, projectProgressFilters?.dateRange);
    dispatch(getProgressSubContractUpdates(projectId, projectProgressFilters?.dateRange));
    setSubcontracts(newSubcontracts);
  }, [projectId, hasPOPerms, projectProgressFilters]);

  const statusMap = useMemo(() => getIdMap(statuses), [statuses]);

  const projectInvoices = useMemo(() => (
    invoices
      .filter((i) => i.projectId === projectId || i.costcodeDistributions?.some(
        ({ projectId: distProjectId }) => distProjectId === projectId,
      ))
  ), [invoices, projectId]);

  const uncategorizedInvoices = useMemo(() => {
    const formattedInvoices = projectInvoices.map((invoice) => {
      const { vendorId, statusId, costcodeDistributions = [] } = invoice ?? {};

      const { title: statusName } = statusMap[statusId] ?? {};
      const { name: vendorName } = vendors[vendorId] ?? {};

      const amountDistributed = costcodeDistributions.reduce((total, distribution) => (
        total + distribution.amount
      ), 0);

      return {
        ...invoice,
        amountDistributed,
        vendor: vendorName,
        status: statusName,
      };
    }).filter(({ amount, amountDistributed }) => amountDistributed < amount);

    return formattedInvoices;
  }, [projectInvoices, statusMap, vendors]);

  const latestInvoiceSyncTimestamp = useMemo(() => {
    const invoicesFromQBO = projectInvoices
      .filter((i) => !!i.fromQBOTimestamp)
      .sort((a, b) => b.fromQBOTimestamp - a.fromQBOTimestamp);

    const latest = invoicesFromQBO?.[0]?.fromQBOTimestamp ?? null;

    return latest
      ? DateTime.fromMillis(latest).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
      : null;
  }, [projectInvoices]);

  const categorizedCostUpdateMap = useMemo(() => {
    const categorizedCosts = filteredProjectCostUpdates.filter((update) => (
      update.costcodeId && !update.isBudgetUpdate
    ));

    return categorizedCosts.reduce((acc, update) => {
      const key = `${update?.phaseId ?? 'unphased'}.${update.costcodeId}`;
      if (!(key in acc)) {
        acc[key] = [];
      }
      acc[key].push(update);
      return acc;
    }, {});
  }, [filteredProjectCostUpdates]);

  const { uncategorizedMaterialUpdates, uncategorizedEquipmentUpdates } = useMemo(() => {
    const equipmentUpdates = [];
    const materialUpdates = [];

    filteredProjectCostUpdates.filter((update) => !update.costcodeId && !update.isBudgetUpdate)
      .forEach((update) => {
        if (update.type === 'material') {
          materialUpdates.push(update);
        } else {
          equipmentUpdates.push(update);
        }
      });

    return {
      uncategorizedMaterialUpdates: materialUpdates,
      uncategorizedEquipmentUpdates: equipmentUpdates,
    };
  }, [filteredProjectCostUpdates]);

  const hasUncategorizedInvoices = !!uncategorizedInvoices.length;
  const hasUncategorizedMaterials = !!uncategorizedMaterialUpdates.length;
  const hasUncategorizedEquipment = !!uncategorizedEquipmentUpdates.length;

  useEffect(() => {
    const newSubContractCOs = {};
    const maxItemNumbers = { subContractCOs: 1 };

    const newLiveSummaryValue = {
      projectId,
      totalChanges: 0,
      changeOrderProgressToDate: 0,
    };

    let values;
    let mostRecentValue;

    Object.keys(subContractUpdates).forEach((key) => {
      values = subContractUpdates[key];

      if (!values.length) return;

      mostRecentValue = values[values.length - 1];

      if (mostRecentValue.projectId !== projectId) return;

      const itemNumberInt = parseInt(mostRecentValue.itemNumber, 10);

      if (!(mostRecentValue.formId in newSubContractCOs)) {
        newSubContractCOs[mostRecentValue.formId] = {
          changes: [],
          totalChanges: 0,
        };
      }
      newSubContractCOs[mostRecentValue.formId].changes.push(mostRecentValue);
      newSubContractCOs[mostRecentValue.formId]
        .totalChanges += mostRecentValue.contractAmount;

      newLiveSummaryValue.totalChanges += mostRecentValue.contractAmount;
      newLiveSummaryValue.changeOrderProgressToDate += mostRecentValue.progressToDate;

      if (itemNumberInt >= maxItemNumbers.subContractCOs) {
        maxItemNumbers.subContractCOs = itemNumberInt + 1;
      }
    });

    setNewItemNumbers(maxItemNumbers);
    setSubContractCOs(newSubContractCOs);
    setLiveSummaryValues([newLiveSummaryValue]);
  }, [subContractUpdates, projectId]);

  const filteredSubContracts = useMemo(() => (
    subcontracts.map((subcontract) => {
      const {
        id,
        formId,
        projectId: subcontractProjectId,
        phaseId,
        costcodeId,
        children,
        contractValue,
        costToDate,
      } = subcontract;

      const { progressHistory } = getProgressFromState({
        progressState: progresses,
        taskState: projectCostcodeDetailsMap,
        id,
        projectId: subcontractProjectId,
        phaseId,
        costcodeId,
        formId,
        children,
      });

      const {
        actualSpend = 0,
      } = progressHistory[0] || {};

      const {
        [formId]: {
          totalChanges = 0,
        } = {},
      } = subContractCOs;

      const contractTotal = contractValue + totalChanges;

      let percentageComplete = 0;
      if (contractTotal) percentageComplete = costToDate / contractTotal;

      // Sub-contract from API contains most up-to-date contract value as it is not tracked by timestamp
      // We need to use the contract value from the progress history to get the correct cost to date
      // Based on filter data
      return {
        ...subcontract,
        costToDate: actualSpend,
        itemNumber: subcontract.number,
        description: subcontract.name,
        contractAmount: subcontract.contractValue,
        totalChanges,
        contractTotal,
        percentageComplete,
        progressToDate: subcontract.costToDate,
      };
    })
  ), [
    subcontracts,
    progresses,
    projectCostcodeDetailsMap,
    subContractCOs,
  ]);

  const computeActualSpend = useCallback(({ phaseId, costcodeId }) => (
    getActualSpend({
      costcodeId,
      phaseId,
      projectId,
      progresses,
    })
  ), [projectId, progresses]);

  const userMap = useMemo(() => getIdMap(users), [users]);
  const costcodeIdMap = useMemo(() => getIdMap(costcodes), [costcodes]);
  const {
    phaseMap,
    unphasedCCs,
    materialCodes,
    overheadCodes,
    equipmentCodes,
    summaryData,
  } = useMemo(() => {
    const pMap = {};
    const unphased = [];
    const material = [];
    const overhead = [];
    const equipment = [];
    const summary = {
      actualMaterialSpend: 0,
      actualOverheadSpend: 0,
      actualLaborSpend: 0,
      actualEquipmentSpend: 0,
      actualSubcontractSpend: 0,
      actualTotalSpend: 0,
      actualBurdenCost: 0,
      estimatedLaborCost: estimatedLaborCost || 0,
      estimatedMaterialCost: estimatedMaterialCost || 0,
      estimatedOverheadCost: estimatedOverheadCost || 0,
      estimatedEquipmentCost: estimatedEquipmentCost || 0,
      estimatedSubcontractCost: 0,
      estimatedTotalCost: (
        estimatedLaborCost
        + estimatedMaterialCost
        + estimatedOverheadCost
        + estimatedEquipmentCost
        + (estimatedBurdenRate * estimatedLaborCost)) || 0,
      estimatedBurdenCost: (estimatedBurdenRate * estimatedLaborCost) || 0,
    };

    const uncategorizedCosts = uncategorizedInvoices.reduce((costs, invoice) => (
      costs + (invoice.amount - invoice.amountDistributed)
    ), 0);

    const uncategorizedMaterialCosts = uncategorizedMaterialUpdates.reduce((costs, update) => (
      costs + (update.total)
    ), 0);

    const uncategorizedEquipmentCosts = uncategorizedEquipmentUpdates.reduce((costs, update) => (
      costs + (update.total)
    ), 0);

    phases.forEach((phase) => {
      const {
        id,
        projectId: phaseProjectId,
        costcodeId,
        hours,
        estimatedCost: phasedEstimatedCost,
      } = phase;
      if (phaseProjectId !== projectId) return;
      const newPhase = { ...phase };
      if (newPhase.costcodeId) delete newPhase.hours;
      if (!(id in pMap)) {
        pMap[id] = {
          ...newPhase,
          projectId,
          phaseId: id,
          key: newPhase.id,
          type: 'phase',
        };
        delete pMap[id].costcodeId;
      }
      const {
        [costcodeId]: fullCostcode = {},
      } = costcodeIdMap;
      if (fullCostcode.id) {
        if (!pMap[id].children) {
          // Only add children if there is a costcode.
          // Otherwise table will show expandable icon for phases with no costcodes
          pMap[id].children = [];
        }
        const key = `${id}.${fullCostcode.id}`;
        const ourCostUpdates = categorizedCostUpdateMap?.[key] ?? [];
        pMap[id].children.push(formatCC({
          costcode: {
            ...fullCostcode,
            projectId,
            phaseId: id,
            costcodeId: fullCostcode.id,
            hours,
            subItem: true,
            estimatedCost: phasedEstimatedCost,
            costUpdates: ourCostUpdates,
          },
          phaseId: newPhase.id,
        }));
      }
    });

    let phasedProjectCostcodes = [];

    Object.values(pMap).forEach(({ children }) => {
      if (children) phasedProjectCostcodes = phasedProjectCostcodes.concat(children);
    });

    const unphasedProjectCostcodes = costcodes
      .filter(({ projectId: costcodeProjectId, phased }) => costcodeProjectId === projectId && !phased)
      .map((costcode) => formatCC({
        costcode: {
          ...costcode,
          projectId,
          costcodeId: costcode.id,
          costUpdates: categorizedCostUpdateMap?.[`unphased.${costcode.id}`] ?? [],
        },
      }));

    unphasedProjectCostcodes.concat(phasedProjectCostcodes).forEach((costcode) => {
      const { phased } = costcode;

      switch (costcode.category) {
        case 'Material': {
          const actualSpend = computeActualSpend({
            phaseId: costcode.phaseId,
            costcodeId: costcode.id,
          });
          summary.actualMaterialSpend += actualSpend;
          summary.actualTotalSpend += actualSpend;
          if (!phased) material.push(costcode);
          break;
        }
        case 'Overhead': {
          const actualSpend = computeActualSpend({
            phaseId: costcode.phaseId,
            costcodeId: costcode.id,
          });

          const actualCost = getActualCostForCostCode({
            costcodeDetailsMap: projectCostcodeDetailsMap,
            costcodeId: costcode.id,
            phaseId: costcode.phaseId,
            userMap,
          });

          const totalCost = actualCost + actualSpend;

          summary.actualOverheadSpend += totalCost;
          summary.actualTotalSpend += totalCost;

          if (!phased) overhead.push(costcode);
          break;
        }
        case 'Equipment': {
          const actualSpend = computeActualSpend({
            phaseId: costcode.phaseId,
            costcodeId: costcode.id,
          });

          summary.actualEquipmentSpend += actualSpend;
          summary.actualTotalSpend += actualSpend;

          if (!phased) equipment.push(costcode);
          break;
        }
        case 'Labor': {
          const actualCost = getActualCostForCostCode({
            costcodeDetailsMap: projectCostcodeDetailsMap,
            costcodeId: costcode.id,
            phaseId: costcode.phaseId,
            userMap,
          });

          const actualSpend = computeActualSpend({
            phaseId: costcode.phaseId,
            costcodeId: costcode.id,
          });

          const actualBurdenCost = actualCost * estimatedBurdenRate;

          const totalLaborCost = actualCost + actualSpend;

          summary.actualLaborSpend += totalLaborCost;
          summary.actualTotalSpend += (totalLaborCost + actualBurdenCost);
          summary.actualBurdenCost += actualBurdenCost;
        }
        // eslint-disable-next-line no-fallthrough
        default:
          if (!phased) unphased.push(costcode);
      }
    });

    filteredSubContracts.forEach(({
      costToDate,
      contractTotal,
    }) => {
      summary.actualSubcontractSpend += costToDate;
      summary.estimatedSubcontractCost += contractTotal;
      summary.actualTotalSpend += costToDate;
      summary.estimatedTotalCost += contractTotal;
    });

    summary.actualMaterialSpend += uncategorizedMaterialCosts;
    summary.actualTotalSpend += uncategorizedMaterialCosts;

    summary.actualEquipmentSpend += uncategorizedEquipmentCosts;
    summary.actualTotalSpend += uncategorizedEquipmentCosts;

    summary.uncategorizedCosts = uncategorizedCosts;
    summary.actualTotalSpend += uncategorizedCosts;

    return {
      phaseMap: pMap,
      unphasedCCs: unphased,
      materialCodes: material,
      overheadCodes: overhead,
      equipmentCodes: equipment,
      summaryData: [summary],
    };
  }, [
    phases,
    costcodes,
    projectId,
    costcodeIdMap,
    projectCostcodeDetailsMap,
    userMap,
    filteredSubContracts,
    projectProgressFilters,
    computeActualSpend,
    estimatedLaborCost,
    estimatedMaterialCost,
    estimatedOverheadCost,
    estimatedEquipmentCost,
    estimatedBurdenRate,
    uncategorizedInvoices,
    uncategorizedMaterialUpdates,
    uncategorizedEquipmentUpdates,
    categorizedCostUpdateMap,
  ]);

  useEffect(() => {
    const now = DateTime.local();
    if (
      visible
      && (
        now.diff(dataTimers.progress.time, 'minutes').as('minute') > 15
        || !areFiltersEqual(projectProgressFilters?.dateRange, dataTimers.progress.filters)
      )
    ) {
      dispatch(getProgress(projectProgressFilters?.dateRange));
      dataTimers.progress.time = now;
      dataTimers.progress.filters = projectProgressFilters?.dateRange
        ? JSON.parse(JSON.stringify(projectProgressFilters?.dateRange))
        : null;
    }
  }, [visible, projectProgressFilters]);

  useEffect(() => {
    dispatch(getCompanyImageURL());
    dispatch(updateProjectProgressFilters({ dateRange: null }));
    dispatch(getInvoices());
  }, []);

  useEffect(() => {
    const now = DateTime.local();
    if (visible
        && projectId
        && (
          now.diff(dataTimers.subcontractorsAndCostcodeDetails.time, 'minutes').as('minute') > 5
          || !areFiltersEqual(
            projectProgressFilters?.dateRange,
            dataTimers.subcontractorsAndCostcodeDetails.filters,
          ) || dataTimers.projectId !== projectId
        )
    ) {
      dispatch(getAllProjectCostcodeDetails(projectId, projectProgressFilters?.dateRange));
      loadSubcontracts();
      dataTimers.subcontractorsAndCostcodeDetails.time = now;
      dataTimers.subcontractorsAndCostcodeDetails.filters = projectProgressFilters?.dateRange
        ? JSON.parse(JSON.stringify(projectProgressFilters?.dateRange))
        : null;
      dataTimers.projectId = projectId;
    }
  }, [visible, projectId, loadSubcontracts, projectProgressFilters]);

  const phasedCodes = useMemo(() => (
    Object.values(phaseMap)
  ), [phaseMap]);

  const EXPORT_TITLE = getExportTitle(t);

  /** On export clicked handler */
  const onExport = useCallback((isPDF) => {
    const preparedSummaryData = prepareExportData({
      data: summaryData,
      type: SUMMARY_EXPORT_TYPE,
    });
    const preparedLaborData = prepareExportData({
      data: unphasedCCs,
      type: LABOR_EXPORT_TYPE,
      progressMap: progresses,
      costcodeDetailsMap: projectCostcodeDetailsMap,
      userMap,
      averageWage,
    });
    const preparedMaterialData = prepareExportData({
      data: materialCodes,
      type: MATERIAL_EXPORT_TYPE,
      progressMap: progresses,
      costcodeDetailsMap: projectCostcodeDetailsMap,
    });
    const preparedOverheadData = prepareExportData({
      data: overheadCodes,
      type: OVERHEAD_EXPORT_TYPE,
      progressMap: progresses,
      costcodeDetailsMap: projectCostcodeDetailsMap,
    });
    const preparedPhasedData = prepareExportData({
      data: phasedCodes,
      type: PHASED_EXPORT_TYPE,
      progressMap: progresses,
      costcodeDetailsMap: projectCostcodeDetailsMap,
    });

    exportFile({
      isPDF,
      companyImageURL,
      summaryData: preparedSummaryData,
      laborData: preparedLaborData,
      materialData: preparedMaterialData,
      overheadData: preparedOverheadData,
      phasedData: preparedPhasedData,
      title: projectName ? `${projectName} progress` : EXPORT_TITLE,
      dateRange: projectProgressFilters?.dateRange,
    });
  }, [
    unphasedCCs,
    summaryData,
    materialCodes,
    overheadCodes,
    phasedCodes,
    progresses,
    projectCostcodeDetailsMap,
    companyImageURL,
    projectName,
    userMap,
    averageWage,
    projectProgressFilters,
  ]);

  const labourColumns = useMemo(() => getLaborColumns({
    onHistory: onHistoryClick,
    onUploadInvoice,
    projectCostcodeDetailsMap,
    userMap,
    progresses,
    averageWage,
    hasWrite,
    showBilling,
  }), [
    onHistoryClick,
    onUploadInvoice,
    projectCostcodeDetailsMap,
    userMap,
    progresses,
    averageWage,
    hasWrite,
    showBilling,
  ]);

  const overHeadColumns = useMemo(() => getOverheadColumns({
    onHistory: onHistoryClick,
    onUploadInvoice,
    projectCostcodeDetailsMap,
    progresses,
    userMap,
    hasWrite,
    showBilling,
  }), [
    onHistoryClick,
    onUploadInvoice,
    projectCostcodeDetailsMap,
    progresses,
    userMap,
    hasWrite,
    showBilling,
  ]);

  const phasedColumns = useMemo(() => getPhasedColumns({
    onHistory: onHistoryClick,
    onUploadInvoice,
    projectCostcodeDetailsMap,
    userMap,
    progresses,
    averageWage,
    hasWrite,
    showBilling,
  }), [
    onHistoryClick,
    onUploadInvoice,
    projectCostcodeDetailsMap,
    userMap,
    progresses,
    averageWage,
    hasWrite,
    showBilling,
  ]);

  const materialCols = useMemo(() => getMaterialColumns({
    onHistory: onHistoryClick,
    onUploadInvoice,
    projectCostcodeDetailsMap,
    progresses,
    hasWrite,
    showBilling,
  }), [
    onHistoryClick,
    onUploadInvoice,
    projectCostcodeDetailsMap,
    progresses,
    hasWrite,
    showBilling,
  ]);

  const equipmentColumns = useMemo(() => getEquipmentColumns({
    onHistory: onHistoryClick,
    onUploadInvoice,
    projectCostcodeDetailsMap,
    progresses,
    hasWrite,
    showBilling,
  }), [
    onHistoryClick,
    onUploadInvoice,
    projectCostcodeDetailsMap,
    progresses,
    hasWrite,
    showBilling,
  ]);

  const subcontractCols = useMemo(() => (
    getSubcontractColumns({
      onHistory: onHistoryClick,
      onInfo: onInfoClick,
    })
  ), [onHistoryClick, onInfoClick]);

  const expandedCostRowRender = useCallback((record) => {
    if (record.type === 'phase') return null;
    const costcodeCostUpdates = record.costUpdates ?? [];
    let getColumns = null;

    if (record.category === 'Material') {
      getColumns = getMaterialCostColumns;
    } else if (record.category === 'Equipment') {
      getColumns = getEquipmentCostColumns;
    } else {
      getColumns = getBasicCostColumns;
    }

    return (
      <Table
        columns={getColumns()}
        dataSource={costcodeCostUpdates}
        pagination={false}
        className="project-progress-table"
        size="small"
        style={{ marginBottom: 10, marginTop: 10 }}
      />
    );
  }, []);

  const uncategorizedInvoiceCols = useMemo(() => getUncategorizedInvoiceColumns(), []);
  const uncategorizedMaterialsCols = useMemo(() => getMaterialCostColumns(), []);
  const uncategorizedEquipmentCols = useMemo(() => getEquipmentCostColumns(), []);

  const summaryCols = useMemo(() => (visible
    ? getProgressSummaryColumns(hasUncategorizedInvoices)
    : []
  ), [visible, hasUncategorizedInvoices]);

  const clearFormSelection = useCallback(() => {
    setShowDetail(false);
    // Need to reload progress
    loadSubcontracts();
    dispatch(getProgress(projectProgressFilters?.dateRange));
  }, [projectProgressFilters, loadSubcontracts]);

  const onFormClick = useCallback(async (record) => {
    Analytics.track('Forms/ViewSubmitted');
    setLoading(true);
    if (dispatch && await dispatch(getFormById(record.id, { getRecentDraft: true }))) {
      dispatch(getFormApprovals());
      setShowDetail(true);
    }
    setLoading(false);
  }, [loading]);

  const summaryTitle = useCallback(() => (
    <Row justify="space-between">
      <Col>
        Summary
      </Col>
      <Col>
        <div className="project-progress-summary-legend">
          <div
            className="project-progress-summary-legend-item"
            style={{
              backgroundColor: 'rgb(66, 133, 244)',
            }}
          />
          Estimated
        </div>
        <div className="project-progress-summary-legend">
          <div
            className="project-progress-summary-legend-item"
            style={{
              backgroundColor: 'rgb(219, 68, 55)',
            }}
          />
          Actual
        </div>
        {hasUncategorizedInvoices && (
          <div className="project-progress-summary-legend">
            <div
              className="project-progress-summary-legend-item"
              style={{
                backgroundColor: 'rgb(0, 128, 0)',
              }}
            />
            Uncategorized
          </div>
        )}
      </Col>
    </Row>
  ), [hasUncategorizedInvoices]);

  const invoiceTitleSuffix = latestInvoiceSyncTimestamp ? ` (Last Sync: ${latestInvoiceSyncTimestamp})` : '';

  return (
    <>
      <div className="project-progress-container">
        <ProgressHeader
          onExport={onExport}
          onUploadInvoice={hasWrite ? onUploadInvoice : null}
          toggleBillingDisplay={hasWrite ? toggleBillingDisplay : null}
          showBilling={showBilling}
        />
        <Table
          title={summaryTitle}
          className="project-progress-table"
          columns={summaryCols}
          dataSource={summaryData}
          pagination={false}
          expandable={false}
        />
        {hasUncategorizedInvoices && (
          <Table
            title={() => `Uncategorized Invoice Costs${invoiceTitleSuffix}`}
            className="project-progress-table"
            columns={uncategorizedInvoiceCols}
            dataSource={uncategorizedInvoices}
            pagination={false}
            rowClassName="file-table-row"
            onRow={onUncategorizedClick}
          />
        )}
        {hasUncategorizedMaterials && (
          <Table
            title={() => 'Uncategorized Material Costs'}
            className="project-progress-table"
            pagination={false}
            columns={uncategorizedMaterialsCols}
            dataSource={uncategorizedMaterialUpdates}
          />
        )}
        {hasUncategorizedEquipment && (
          <Table
            title={() => 'Uncategorized Equipment Costs'}
            className="project-progress-table"
            pagination={false}
            columns={uncategorizedEquipmentCols}
            dataSource={uncategorizedEquipmentUpdates}
          />
        )}
        {hasPOPerms && (
          <Table
            title={() => 'Sub-Contracts'}
            className="project-progress-table"
            columns={subcontractCols}
            dataSource={filteredSubContracts}
            pagination={false}
            rowClassName="file-table-row"
            onRow={(record) => ({
              onClick: () => onFormClick(record),
            })}
          />
        )}
        <Table
          title={() => 'Material Cost Codes'}
          className="project-progress-table"
          columns={materialCols}
          dataSource={materialCodes}
          pagination={false}
          expandable={{
            defaultExpandAllRows: false,
            expandedRowRender: expandedCostRowRender,
            rowExpandable: (record) => record?.costUpdates?.length,
          }}
        />
        <Table
          title={() => 'Overhead Cost Codes'}
          className="project-progress-table"
          columns={overHeadColumns}
          dataSource={overheadCodes}
          pagination={false}
          expandable={{
            defaultExpandAllRows: false,
            expandedRowRender: expandedCostRowRender,
            rowExpandable: (record) => record?.costUpdates?.length,
          }}
        />
        <Table
          title={() => 'Labor Cost Codes'}
          className="project-progress-table"
          columns={labourColumns}
          dataSource={unphasedCCs}
          pagination={false}
          expandable={{
            defaultExpandAllRows: false,
            expandedRowRender: expandedCostRowRender,
            rowExpandable: (record) => record?.costUpdates?.length,
          }}
        />
        <Table
          title={() => 'Equipment Cost Codes'}
          className="project-progress-table"
          columns={equipmentColumns}
          dataSource={equipmentCodes}
          pagination={false}
          expandable={{
            defaultExpandAllRows: false,
            expandedRowRender: expandedCostRowRender,
            rowExpandable: (record) => record?.costUpdates?.length,
          }}
        />
        <Table
          title={() => 'Phases'}
          className="project-progress-table"
          columns={phasedColumns}
          dataSource={phasedCodes}
          pagination={false}
          expandable={{
            defaultExpandAllRows: false,
            expandedRowRender: expandedCostRowRender,
            rowExpandable: (record) => (
              record?.costUpdates?.length
              || (record.type === 'phase' && record?.children?.length)
            ),
          }}
        />
      </div>
      <ProgressHistoryDrawer
        visible={selectedRecord && selectedRecord.id}
        record={selectedRecord}
        onClose={onCloseDrawer}
        onUploadInvoice={onUploadInvoice}
        hasWrite={hasWrite}
        costcodeIdMap={costcodeIdMap}
      />
      <FormDetailView
        visible={showDetail}
        onClose={clearFormSelection}
        userMap={userMap}
        shouldRedirect={false}
      />
      <InvoiceAddDrawer
        invoice={selectedInvoice}
        visible={showInvoiceDrawer}
        mode={drawerMode}
        useRange={false}
        closeDrawer={onCloseInvoiceDrawer}
        updateMode={updateDrawerMode}
        projectId={projectId}
        costcode={selectedCostcode}
        onInvoiceSubmit={onInvoiceSubmit}
        zIndex={1001}
        hasWrite={hasWrite}
        divisionId={divisionId}
      />
      <ProgressSubContractInfoSlider
        visible={showSubContractDrawer}
        onClose={toggleSubContractDrawer}
        record={selectedSubContract}
        hasWrite={hasWrite}
        subContractCOs={subContractCOs}
        setSubContractCOs={setSubContractCOs}
        projectId={projectId}
        newItemNumbers={newItemNumbers}
        setNewItemNumbers={setNewItemNumbers}
        liveSummaryValues={liveSummaryValues}
      />
      {loading
        && (
          <Row justify="center" align="middle" className="form-loading-container">
            <Spin size="large" />
          </Row>
        )}
    </>
  );
}

ProjectProgress.propTypes = {
  visible: PropTypes.bool.isRequired,
  projectId: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired,
  averageWage: PropTypes.number,
  estimatedLaborCost: PropTypes.number,
  estimatedMaterialCost: PropTypes.number,
  estimatedOverheadCost: PropTypes.number,
  estimatedBurdenRate: PropTypes.number,
  estimatedEquipmentCost: PropTypes.number,
  enableBillingDisplay: PropTypes.bool.isRequired,
  divisionId: PropTypes.string.isRequired,
};

ProjectProgress.defaultProps = {
  averageWage: 0,
  estimatedLaborCost: 0,
  estimatedMaterialCost: 0,
  estimatedOverheadCost: 0,
  estimatedBurdenRate: 0,
  estimatedEquipmentCost: 0,
};
