import React from 'react';
import { Row, Col, Button } from 'antd';
import { FileAddOutlined } from '@ant-design/icons';

import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';
import PhotoReel from '../../../common/photos/PhotoReel';

import TitleRow from './TitleRow';

import FieldTriggerCheckbox from './FieldTriggerCheckbox';
import FieldTriggerFlag from './FieldTriggerFlag';

import colors from '../../../constants/Colors';
import ConditionalRenderingRow from './ConditionalRenderingRow';

const preview = ({
  configProps = {},
  responses,
  id,
  setResponses,
  responding = false,
  sections,
  isExternalForm,
  projectId,
  templateId,
  divisions,
  fieldTriggerMap = {},
  setFieldTriggerMap,
  isDisplay,
  name,
  showCondensedView,
  onOpenFile,
  fileMap = {},
} = {}) => {
  const {
    title = 'Title goes here',
    fieldTrigger,
    files: configFiles = [],
    onClick,
  } = configProps;

  const {
    [id]: {
      files: responseFiles,
    } = {},
  } = responses ?? {};

  if (responding && setResponses && !responseFiles) {
    setResponses({
      ...responses,
      [id]: {
        files: configFiles?.map?.((file) => file.id)
          .filter((fileId) => !!fileId),
      },
    });
  }

  const files = responding
    ? responseFiles?.map((fileId) => fileMap[fileId]).filter((file) => !!file) ?? []
    : configFiles;

  const onFileClick = (idx) => {
    if (onOpenFile) {
      onOpenFile(files[idx]);
    } else if (onClick) {
      onClick(idx);
    }
  };

  return (
    <>
      <TitleRow
        title={title}
        optional
        filter={
        fieldTrigger && !isDisplay
          ? (
            <FieldTriggerFlag
              sections={sections}
              isExternalForm={isExternalForm}
              templateId={templateId}
              projectId={projectId}
              divisions={divisions}
              configProps={configProps}
              responding={responding}
              id={id}
              fieldTriggerMap={fieldTriggerMap}
              setFieldTriggerMap={setFieldTriggerMap}
              name={name}
            />
          ) : null
      }
      />
      <div style={{ pointerEvents: 'all' }}>
        <PhotoReel
          small
          files={files}
          onClick={onFileClick}
          showCondensedView={showCondensedView}
        />
      </div>
    </>
  );
};

const configure = ({
  setConfigProps,
  configProps = {},
  setCanSubmit,
  sections = [],
  isExternalForm,
  templateId,
  divisions,
  projectId,
  name,
  isBoardCards,
  setFieldTriggerEditable,
  showCondensedView,
  onShowFileModal,
  onOpenFile,
  id,
  customers = [],
  projects = [],
  users = [],
  costcodes = [],
  phases = [],
  projectIdMap = {},
  vendors = [],
  equipment = [],
  formTemplates = [],
  labels = [],
  contactAddressBooks = {},
  buckets = [],
}) => {
  const {
    hasConditionalRendering = false,
    conditionalRenderingFormula,
  } = configProps;
  const files = configProps?.files ?? [];
  const setTitle = (e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      title: value,
    });
    setCanSubmit(value);
  };

  const setConditionalRenderingFormula = (newValue) => {
    setConfigProps({
      ...configProps,
      conditionalRenderingFormula: newValue,
    });
  };

  const setHasConditionalRendering = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      hasConditionalRendering: checked,
    });
  };

  const setFieldTrigger = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      fieldTrigger: checked,
    });
  };

  const onAttachDelete = (idx) => {
    // set responses without the file in question
    const newFiles = [...files];
    newFiles.splice(idx, 1);
    setConfigProps({
      ...configProps,
      files: newFiles,
    });
  };

  const onFileClick = (idx) => {
    onOpenFile(files[idx]);
  };

  return (
    <div>
      <Row className="form-required-field">
        Text:
      </Row>
      <Row style={{ marginTop: 5 }}>
        <OnTraccrTextInput
          textarea
          placeholder="Insert title here"
          onChange={setTitle}
          value={configProps.title}
        />
      </Row>
      <ConditionalRenderingRow
        onChange={setHasConditionalRendering}
        onFormulaChange={setConditionalRenderingFormula}
        hasConditionalRendering={hasConditionalRendering}
        conditionalRenderingFormula={conditionalRenderingFormula}
        sections={sections}
        id={id}
        costcodes={costcodes}
        customers={customers}
        projects={projects}
        users={users}
        phases={phases}
        projectIdMap={projectIdMap}
        vendors={vendors}
        equipment={equipment}
        formTemplates={formTemplates}
        labels={labels}
        contactAddressBooks={contactAddressBooks}
        buckets={buckets}
      />
      {!isBoardCards && !isExternalForm && (
        <FieldTriggerCheckbox
          onChange={setFieldTrigger}
          onEditableChange={setFieldTriggerEditable}
          sections={sections}
          projectId={projectId}
          templateId={templateId}
          divisions={divisions}
          isExternalForm={isExternalForm}
          configProps={configProps}
          setConfigProps={setConfigProps}
          name={name}
        />
      )}
      <Row style={{ marginTop: 5 }}>
        <Col style={{ marginTop: 10, width: 60 }}>
          <Row style={{ height: 80 }} align="bottom">
            <Row style={{ height: 70 }} align="top">
              <Button
                className="form-attachment-add-button"
                icon={(
                  <FileAddOutlined
                    style={{
                      color: colors.ONTRACCR_RED,
                    }}
                  />
                  )}
                onClick={onShowFileModal}
              />
            </Row>
          </Row>
        </Col>
        <Col style={{ width: 'calc(100% - 60px)' }}>
          <PhotoReel
            small
            files={files}
            onDelete={onAttachDelete}
            onClick={onFileClick}
            showCondensedView={showCondensedView}
          />
        </Col>
      </Row>
    </div>
  );
};
export default {
  configure,
  preview,
  title: 'Static Attachments',
  description: 'Add files to display to users filling out the form.',
};
