import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Tabs } from 'antd';
import { useDispatch } from 'react-redux';

import BreadcrumbContainer from '../common/breadcrumbContainer/breadcrumbContainer';

import FormsSearch from './FormsSearch';
import BoardsSearch from './BoardsSearch';
import SearchSelector from './SearchSelector';

import { getQueries, clearSelectedQuery } from './state/search.actions';

const { TabPane } = Tabs;

const DEFAULT_CRUMBS = [{ text: 'Search', icon: 'search' }];

export default function Search() {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState('forms');

  const onTabChanged = useCallback((newTab) => {
    setActiveTab(newTab);
    dispatch(clearSelectedQuery());
  }, []);

  const crumbs = useMemo(() => (
    DEFAULT_CRUMBS.concat({
      text: <SearchSelector onTabChanged={setActiveTab} />,
    })
  ), []);

  useEffect(() => {
    dispatch(getQueries('forms'));
    dispatch(getQueries('boards'));
  }, []);

  return (
    <BreadcrumbContainer crumbs={crumbs}>
      <Tabs
        onChange={onTabChanged}
        activeKey={activeTab}
        tabPosition="left"
        style={{
          position: 'absolute',
          top: 90,
          right: 30,
          bottom: 23,
          left: 20,
        }}
        tabBarStyle={{
          width: 110,
        }}
      >
        <TabPane
          tab={<span>Forms</span>}
          key="forms"
          className="form-tab-pane"
        >
          <FormsSearch />
        </TabPane>
        <TabPane
          tab={<span>Boards</span>}
          key="boards"
          className="form-tab-pane"
        >
          <BoardsSearch />
        </TabPane>
      </Tabs>
    </BreadcrumbContainer>
  );
}
