import React, { useMemo, useEffect, useState } from 'react';
import { Tree } from 'antd';

import FileDrawer from './FileDrawer';

import { constructFullPath } from './fileHelpers';
import { constructTreeData } from '../helpers/fileHelpers';

const { DirectoryTree } = Tree;

export default function FileMoveDrawer({
  visible,
  prefixPath,
  onSelectedDestination,
  onClose,
  selectedFolders = [],
  selectedDestination = [],
  onSubmit,
  copy,
  fileStructure = {},
  rootFiles = [],
  baseName = 'Files/',
  copyMoveLoading,
}) {
  const verb = copy ? 'Copy' : 'Move';
  const numFolders = selectedFolders.length;

  const [selectedPaths,setSelectedPaths] = useState(new Set());
  const [expandedKeys,setExpandedKeys] = useState([baseName]);
  const treeData = useMemo(() => 
    [constructTreeData({
      fileStructure,
      rootFiles,
      selectedPaths,
      name: baseName,
      onlyFolders: true,
      selectable: true,
    })]
  ,[fileStructure,rootFiles,selectedPaths, baseName]);

  useEffect(() => {
    const selectedSet = new Set();
    selectedFolders.forEach((folder) => {
      selectedSet.add(constructFullPath(prefixPath,folder));
    });
    setSelectedPaths(selectedSet);
  },[selectedFolders,prefixPath]);

  useEffect(() => {
    if(!visible) setExpandedKeys([baseName]);
  },[visible,baseName]);

  return (
    <FileDrawer
      title={`${verb} ${numFolders} Item${numFolders === 1 ? '' : 's'}`}
      visible={visible}
      onClose={onClose}
      canSubmit={selectedDestination.length === 1}
      onSubmit={onSubmit}
      copyMoveLoading={copyMoveLoading}
    >
      <div style={{ height: 40 }}>
        {selectedDestination.length > 0 ?
          <div style={{ overflow:'hidden', textOverflow: 'ellipsis' }}>
            {verb} to destination: <b>{selectedDestination[0]}</b> ?
          </div> :
          <div>
            Please select a destination folder
          </div>
        }
      </div>
    <DirectoryTree
      treeData={treeData}
      defaultExpandedKeys={['Files/']}
      expandAction={false}
      onSelect={onSelectedDestination}
      selectedKeys={selectedDestination}
      expandedKeys={expandedKeys}
      onExpand={(keys) => setExpandedKeys(keys)}
    />
    </FileDrawer>
  );
}
