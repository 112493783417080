import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowRightOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Table } from 'antd';

import { DateTime } from 'luxon';
import { getFieldMap } from '../formHelpers';

function DiffWrapper({
  title,
  children,
  marginBottom = 10,
}) {
  return (
    <div style={{ marginBottom }}>
      <h4>{title}</h4>
      {children}
    </div>
  );
}

const parseUpdatedDate = (date) => {
  if (!date) return null;
  return date.split(', ').map((value) => {
    const luxDate = DateTime.fromISO(value);
    if (!luxDate.isValid) return '-';
    const localTimezone = DateTime.local().zoneName;
    const format = localTimezone === luxDate.zoneName ? 'yyyy-MM-dd h:mm a' : 'yyyy-MM-dd h:mm a ZZZZ';
    return luxDate.toFormat(format);
  });
};

export default function FormUpdateStep({
  oldData,
  newData,
}) {
  const { t } = useTranslation();

  const {
    sections: oldSections = [],
  } = oldData;

  const {
    sections: newSections = [],
  } = newData;

  const oldFieldMap = getFieldMap(oldSections);

  const renderFieldDiff = useCallback((
    oldField,
    newField,
  ) => {
    if (!newField) return null;
    const {
      type,
      response,
      title,
    } = newField;
    const {
      response: previousResponse,
    } = oldField ?? {};

    switch (type) {
      case 'table': {
        const previousResponseMap = previousResponse?.values?.reduce((acc, row) => {
          acc[row.id] = row;
          return acc;
        }, {}) ?? {};

        const newResponseMap = response?.values?.reduce((acc, row) => {
          acc[row.id] = row;
          return acc;
        }, {}) ?? {};

        const rowsToShow = [];

        // render removed rows
        previousResponse?.values?.forEach((row) => {
          const relevantRow = newResponseMap[row.id];

          if (!relevantRow) {
            rowsToShow.push({
              ...row,
              isRemoved: true,
            });
          } else {
            let hasColumnDiff = false;

            response?.columns?.forEach(({ key }) => {
              if (relevantRow[key] !== row[key]) {
                hasColumnDiff = true;
              }
            });

            if (hasColumnDiff) rowsToShow.push(relevantRow);
          }
        });

        response?.values?.forEach((row) => {
          if (!previousResponseMap[row.id]) {
            rowsToShow.push({
              ...row,
              isAdded: true,
            });
          }
        });

        const columns = response?.columns?.map((column) => ({
          title: column.name,
          dataIndex: column.key,
          render: (text, record) => {
            if (!text) return null;

            const relevantResponse = previousResponseMap[record.id];

            let after = text;
            let before = relevantResponse?.[column.key];

            if (column.key === 'updatedDate') {
              const parsedDateUpdated = parseUpdatedDate(text);
              after = parsedDateUpdated?.[parsedDateUpdated.length - 1] ?? null;

              before = null;
            }

            if (record.isRemoved || record.isAdded) {
              return (
                <div
                  className={record.isRemoved ? 'form-before' : 'form-after'}
                  style={{ marginLeft: 0, marginRight: 0 }}
                >
                  {after}
                </div>
              );
            }

            if (relevantResponse && relevantResponse[column.key] !== text) {
              return (
                <div>
                  {before ? (
                    <>
                      <span className="form-before">{before}</span>
                      <ArrowRightOutlined />
                    </>
                  ) : null}
                  <span className="form-after">{after}</span>
                </div>
              );
            }

            return after;
          },
        }));

        if (rowsToShow.length) {
          return (
            <DiffWrapper
              title={title}
            >
              <Table
                style={{ width: '100%', border: '1px solid #cccccc' }}
                columns={columns}
                size="small"
                pagination={false}
                dataSource={rowsToShow}
                scroll={{ x: 'max-content' }}
              />
            </DiffWrapper>
          );
        }
        break;
      }
      case 'dropdown':
      case 'attachment': {
        const key = type === 'attachment' ? 'fileIds' : 'values';

        const diff = {
          removed: [],
          added: [],
        };

        const oldValues = previousResponse?.[key] ?? [];
        const oldIds = new Set(oldValues.map((value) => value.id ?? value));

        const newValues = response?.[key] ?? [];
        const newIds = new Set(newValues.map((value) => value.id ?? value));

        oldValues.forEach((value) => {
          if (!newIds.has(value.id ?? value)) {
            diff.removed.push(value);
          }
        });

        newValues.forEach((value) => {
          if (!oldIds.has(value.id ?? value)) {
            diff.added.push(value);
          }
        });

        if (diff.removed.length || diff.added.length) {
          if (type === 'attachment') {
            return (
              <DiffWrapper
                title={title}
                marginBottom={5}
              >
                <ul>
                  { diff.removed.length ? (
                    <li>{`${diff.removed.length} ${t('file', { count: diff.removed.length })} removed`}</li>
                  ) : null}
                  { diff.added.length ? (
                    <li>{`${diff.added.length} ${t('file', { count: diff.added.length })} added`}</li>
                  ) : null}
                </ul>
              </DiffWrapper>
            );
          }

          return (
            <DiffWrapper
              title={title}
              marginBottom={5}
            >
              { diff.removed.length ? (
                <>
                  <h3>Removed:</h3>
                  <ul>
                    {diff.removed.map((value) => (
                      <li key={value.id ?? value}>{value.name}</li>
                    ))}
                  </ul>
                </>
              ) : null }
              { diff.added.length ? (
                <>
                  <h3>Added:</h3>
                  <ul>
                    {diff.added.map((value) => (
                      <li key={value.id ?? value}>{value.name}</li>
                    ))}
                  </ul>
                </>
              ) : null }
            </DiffWrapper>
          );
        }
        break;
      }
      default: {
        if (response?.value !== previousResponse?.value) {
          return (
            <DiffWrapper
              title={title}
            >
              <span className="form-before">{previousResponse?.value}</span>
              <ArrowRightOutlined />
              <span className="form-after">{response?.value}</span>
            </DiffWrapper>
          );
        }
      }
    }

    return null;
  }, [t]);

  const {
    fieldDiff,
    hasFieldDiff,
  } = useMemo(() => {
    let diffExists = false;
    const renderedDiff = newSections.map(({ fields = [] }) => (
      fields.map((field) => {
        const diff = renderFieldDiff(oldFieldMap[field.fieldId], field);
        if (diff) diffExists = true;
        return diff;
      })
    ));

    return {
      fieldDiff: renderedDiff,
      hasFieldDiff: diffExists,
    };
  }, [newSections]);

  if (!hasFieldDiff) return null;

  return (
    <div className="form-diff-container">
      {fieldDiff}
    </div>
  );
}

DiffWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  marginBottom: PropTypes.number,
};

DiffWrapper.defaultProps = {
  marginBottom: 10,
};

FormUpdateStep.propTypes = {
  oldData: PropTypes.shape({
    sections: PropTypes.arrayOf(PropTypes.shape({
      fields: PropTypes.arrayOf(PropTypes.shape({
        fieldId: PropTypes.string.isRequired,
        response: PropTypes.shape({
          value: PropTypes.string,
          values: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
          })),
        }),
      })),
    })),
  }).isRequired,
  newData: PropTypes.shape({
    sections: PropTypes.arrayOf(PropTypes.shape({
      fields: PropTypes.arrayOf(PropTypes.shape({
        fieldId: PropTypes.string.isRequired,
        response: PropTypes.shape({
          value: PropTypes.string,
          values: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
          })),
        }),
      })),
    })),
  }).isRequired,
};
