import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AdditionalItem, AdditionalLabel } from '../../forms/FormWorkflows/AssignmentDropdownItems';

const getEmailHelp = (title, isMultipleChoice, type = 'form') => {
  const helpDescriptorText = isMultipleChoice ? 'email addresses selected' : 'comma separated email addresses defined';
  return `The ${type} will be sent to the ${helpDescriptorText} in the ${title} field`;
};
const getCustomerProjectHelp = (t, isOffice, type = 'Customer') => `The form will be sent to the ${t(type).toLowerCase()}'s ${isOffice ? 'office' : 'billing'} email address`;
const getSubjectBodyHelp = (title, isSubject) => `The email will use the contents of the ${title} field as the email ${isSubject ? 'subject' : 'body'}`;
const getHelpText = ({
  isCustomer,
  isProject,
  isOffice,
  isSubject,
  isBody,
  title,
  isMultipleChoice,
  type,
  t,
}) => {
  if (isCustomer || isProject) return getCustomerProjectHelp(t, isOffice, isCustomer ? 'Customer' : 'Project');
  if (isSubject || isBody) return getSubjectBodyHelp(title, isSubject);
  return getEmailHelp(title, isMultipleChoice, type);
};
const getOption = (props) => {
  const {
    fieldId,
    title,
    isSubject,
    isBody,
  } = props;

  const HELP_TEXT = getHelpText(props);
  return {
    id: fieldId,
    label: (
      <AdditionalLabel
        title={title}
        help={HELP_TEXT}
        isEmailSubjectBodyLabel={isSubject || isBody}
      />
    ),
    children: (
      <AdditionalItem
        title={title}
        help={HELP_TEXT}
      />
    ),
  };
};

export default function useFormEmailOptions(sections, type) {
  const { t } = useTranslation();

  const [
    emailFormFields,
    attachmentFieldOptions,
    dateTimeFields,
  ] = useMemo(() => {
    const options = [];
    const fileOptions = [];
    const dateTimeOptions = [];
    let hasCustomer = false;
    let hasProject = false;
    sections.forEach(({ fields = [], name: sectionName }) => {
      fields.forEach((field) => {
        const {
          id: fieldId,
          selectedType,
          configProps: {
            dataType,
            title,
          } = {},
        } = field;
        if (selectedType === 'dropdown') {
          switch (dataType) {
            case 'Customers': {
              if (!hasCustomer) options.push({ id: fieldId, title, isCustomer: true });
              hasCustomer = true;
              break;
            }
            case 'Projects': {
              if (!hasProject) options.push({ id: fieldId, title, isProject: true });
              hasProject = true;
              break;
            }
            case 'Custom': {
              options.push({ id: fieldId, title, isMultipleChoice: true });
              break;
            }
            default:
              // do nothing
              break;
          }
        } else if (selectedType === 'text' || selectedType === 'attribute') {
          options.push({ id: fieldId, title, key: `${sectionName} - ${title}`, name: `${sectionName} - ${title}` });
        } else if (selectedType === 'attachment') {
          fileOptions.push({ value: fieldId, label: title });
        } else if (selectedType === 'dateTime') {
          dateTimeOptions.push({ key: `${sectionName} - ${title}`, name: `${sectionName} - ${title}` });
        }
      });
    });
    return [
      options,
      fileOptions,
      dateTimeOptions,
    ];
  }, [sections]);

  const [
    additionalOptions,
    subjectOptions,
    bodyOptions,
  ] = useMemo(() => {
    const options = [];
    const optionsForBody = [];
    const optionsForSubject = [];
    emailFormFields.forEach((field) => {
      const {
        id: fieldId, title, isCustomer, isProject, isMultipleChoice,
      } = field;
      if (!isCustomer && !isProject) {
        const opt = getOption({
          fieldId,
          title,
          isMultipleChoice,
          type,
          t,
        });
        options.push(opt);

        if (!isMultipleChoice) {
          const bodyOpt = getOption({
            fieldId,
            title,
            isBody: true,
            type,
            t,
          });
          optionsForBody.push(bodyOpt);

          const subjectOpt = getOption({
            fieldId,
            title,
            isSubject: true,
            type,
            t,
          });
          optionsForSubject.push(subjectOpt);
        }
        return;
      }
      if (isProject) {
        const opt = getOption({
          fieldId: `${fieldId}-project`,
          title: `${t('Project')} - Billing Email`,
          isProject: true,
          isOffice: false,
          type,
          t,
        });
        options.push(opt);
        return;
      }
      const opt1 = getOption({
        fieldId: `${fieldId}-office`,
        title: `${t('Customer')} - Office Email`,
        isCustomer,
        isOffice: true,
        type,
        t,
      });
      const opt2 = getOption({
        fieldId: `${fieldId}-billing`,
        title: `${t('Customer')} - Billing Email`,
        isCustomer,
        type,
        t,
      });
      options.push(opt1);
      options.push(opt2);
    });
    return [options, optionsForSubject, optionsForBody];
  }, [emailFormFields, type]);

  return {
    additionalOptions,
    subjectOptions,
    bodyOptions,
    attachmentFieldOptions,
    dateTimeFields,
    emailFormFields,
  };
}
