import {
  CREATE_SEARCH_QUERY,
  DELETE_SEARCH_QUERY,
  GET_SEARCH_QUERIES,
  SEARCH_BOARDS,
  SEARCH_FORMS,
  UPDATE_SEARCH_QUERY,
  SELECT_QUERY,
} from '../../state/actionTypes';
import SearchService from './search.service';

export const getQueries = (type) => async (dispatch) => {
  const { data } = await SearchService.getQueries(type);
  if (!data) return false;
  dispatch({
    type: GET_SEARCH_QUERIES,
    payload: {
      queries: data,
      type,
    },
  });
  return true;
};

export const createQuery = (payload, type) => async (dispatch) => {
  const { data } = await SearchService.createQuery(payload);
  if (!data) return false;
  dispatch({
    type: CREATE_SEARCH_QUERY,
    payload: {
      query: data,
      type,
    },
  });
  return data.id;
};

export const updateQuery = (id, payload, type) => async (dispatch) => {
  const { data } = await SearchService.updateQuery(id, payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_SEARCH_QUERY,
    payload: {
      query: {
        id,
        ...payload,
      },
      type,
    },
  });
  return true;
};

export const deleteQuery = (id, type) => async (dispatch) => {
  const { data } = await SearchService.deleteQuery(id);
  if (!data) return false;
  dispatch({
    type: DELETE_SEARCH_QUERY,
    payload: {
      id,
      type,
    },
  });
  return true;
};

export const searchForms = ({
  query, selectedQuery,
}) => async (dispatch) => {
  const { data } = await SearchService.searchForms({ query, queryId: selectedQuery?.id });
  if (!data) return false;
  dispatch({
    type: SEARCH_FORMS,
    payload: {
      results: data,
      selectedQuery,
    },
  });
  return true;
};

export const searchBoards = ({
  query, selectedQuery,
}) => async (dispatch) => {
  const { data } = await SearchService.searchBoards({ query, queryId: selectedQuery?.id });
  if (!data) return false;
  dispatch({
    type: SEARCH_BOARDS,
    payload: {
      results: data,
      selectedQuery,
    },
  });
  return true;
};

export const selectQuery = (selectedQuery) => async (dispatch) => {
  const prom = selectedQuery.type === 'boards'
    ? SearchService.searchBoards
    : SearchService.searchForms;
  const { data } = await prom({
    query: selectedQuery.query,
    queryId: selectedQuery.id,
  });
  if (!data) return false;
  dispatch({
    type: SELECT_QUERY,
    payload: {
      selectedQuery,
      results: data,
    },
  });
  return true;
};

export const clearSelectedQuery = () => ({
  type: SELECT_QUERY,
  payload: {
    selectedQuery: null,
    results: [],
  },
});
