import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Select, TreeSelect, Row, Col,
} from 'antd';
import { DeleteOutlined, DragOutlined } from '@ant-design/icons';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import BorderlessButton from '../../common/buttons/BorderlessButton';

import {
  getTimecardFields,
  getFormFields,
  EXPORTABLE_FIELD_TYPES,
  FIELDS_WITH_FILTERS,
  FIELDS_WITH_TEXT,
  FIELDS_WITH_CONFIG,
  CONFIG_MAP,
  FIRST_COST_CODE_KEY,
} from './exports.constants';

import colors from '../../constants/Colors';

const GET_FIELD_FUNCTIONS = {
  timeEntries: getTimecardFields,
  forms: getFormFields,
};

function ExportsColumn({
  column: {
    id,
    title,
    field,
    filterValue,
  } = {},
  divisionId,
  orderIndex,
  onChange,
  onDelete,
  tableFieldId,
  hasEclipse,
  type,
  formTemplateId,
}) {
  const { t } = useTranslation();
  const customFields = useSelector((state) => state.timecards.customFields);
  const formTemplates = useSelector((state) => state.forms.templates);
  const userCustomFields = useSelector((state) => state.users.customFieldTemplate);
  const costcodes = useSelector((state) => state.costcodes.costcodes);

  const TIMECARD_FIELDS = type in GET_FIELD_FUNCTIONS
    ? GET_FIELD_FUNCTIONS[type](t)
    : [];

  const onDeleteClicked = useCallback(() => {
    onDelete(id);
  }, [onDelete, id]);

  const onColumnTitleChanged = useCallback((e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    onChange(id, { title: value });
  }, [onChange, id]);

  const onDataChange = useCallback((key) => (value) => {
    onChange(id, { [key]: value });
  }, [onChange, id]);

  const onTextChanged = useCallback((e) => {
    onDataChange('filterValue')(e?.target?.value);
  }, [onDataChange]);

  const ourFormTemplate = useMemo(() => {
    if (!formTemplateId) return null;
    const {
      [formTemplateId]: {
        jsonFormData,
      } = {},
    } = formTemplates;
    let parsed;
    try {
      parsed = JSON.parse(jsonFormData);
    } catch (err) {
      //
    }
    return parsed;
  }, [formTemplates, formTemplateId]);

  const ourCustomSections = useMemo(() => {
    if (type === 'forms') {
      return ourFormTemplate?.sections ?? [];
    }
    const {
      [divisionId]: { fields: divCustomFields = [] } = {},
    } = customFields;
    return divCustomFields;
  }, [ourFormTemplate, customFields, divisionId, type]);

  const getExportableCustomFields = useCallback((sections) => {
    const exportableCustomFields = sections.reduce((acc, section) => {
      const { fields = [], id: sectionId } = section;
      const exportableFields = fields.reduce((fieldAcc, formField) => {
        const { selectedType, configProps, id: fieldId } = formField;
        const canExport = EXPORTABLE_FIELD_TYPES.has(selectedType)
        && configProps
        && configProps.title;
        if (!canExport) return fieldAcc;
        if (selectedType !== 'table') {
          /*
            Users can already set all the data (e.g. regular hours, project, cost code)
            From other columns, they dont need to use custom timecard section
          */
          if (sectionId === 'timecard') return fieldAcc;
          fieldAcc.push({ fieldId, title: configProps.title });
          return fieldAcc;
        }

        if (tableFieldId && fieldId !== tableFieldId) {
          // Can only use columns from one table
          if (configProps?.dataType === 'TimeEntry') {
            /*
              Allow picking first cost code in time entry table
              https://projectharbour.atlassian.net/browse/HARBOUR-5421
            */
            fieldAcc.push({
              fieldId: `${fieldId}-${FIRST_COST_CODE_KEY}`,
              title: `${configProps.title} - First Cost Code`,
            });
          }
          return fieldAcc;
        }
        const {
          columns = [],
        } = configProps;
        columns.forEach((col) => {
          fieldAcc.push({
            fieldId: `${fieldId}-${col.key}`,
            title: `${configProps.title} - ${col.name}`,
          });
        });
        return fieldAcc;
      }, []);
      if (exportableFields.length === 0) return acc;
      return acc.concat([{
        ...section,
        fields: exportableFields,
      }]);
    }, []);

    return exportableCustomFields;
  }, []);

  const getCustomTreeData = useCallback((exportableCustomFields, treeDataKey, treeDataTitle) => (
    [{
      key: treeDataKey,
      title: treeDataTitle,
      selectable: false,
      children: exportableCustomFields.map((section) => {
        const { fields = [] } = section;
        return {
          key: section.id,
          title: section.name,
          selectable: false,
          children: fields.map((formField) => ({
            key: formField.fieldId,
            title: formField.title,
          })),
        };
      }),
    }]
  ), []);

  const treeData = useMemo(() => {
    const userCustomSections = userCustomFields?.sections ?? [];

    const exportableCustomFields = getExportableCustomFields(ourCustomSections);
    const userExportableCustomFields = getExportableCustomFields(userCustomSections);

    let baseFields = TIMECARD_FIELDS;
    if (hasEclipse) {
      baseFields = baseFields.concat([{
        key: 'custom',
        title: 'Eclipse Fields',
        selectable: false,
        children: [{
          key: 'payType',
          title: 'Pay Type',
        }],
      }]);
    }
    const customFieldTreeData = (!exportableCustomFields?.length)
      ? []
      : getCustomTreeData(exportableCustomFields, 'custom', 'Custom Fields');

    const userCustomFieldTreeData = (!userExportableCustomFields?.length)
      ? []
      : getCustomTreeData(userExportableCustomFields, 'userCustom', 'User Custom Fields');

    return baseFields.concat(customFieldTreeData).concat(userCustomFieldTreeData);
  }, [
    ourCustomSections,
    userCustomFields,
    hasEclipse,
    tableFieldId,
    getExportableCustomFields,
    getCustomTreeData,
  ]);

  const costcodeOptions = useMemo(() => (
    costcodes
      .filter((cc) => cc.active && !cc.projectId && cc.divisionId === divisionId)
      .map((cc) => ({
        label: `${cc.code} - ${cc.name}`,
        value: cc.code,
      }))
  ), [costcodes, divisionId]);

  return (
    <Draggable draggableId={id} type="export-column" index={orderIndex}>
      {({ draggableProps, dragHandleProps, innerRef }) => (
        <div
          className="export-column"
          {...draggableProps} // eslint-disable-line react/jsx-props-no-spreading
          ref={innerRef}
        >
          <Row justify="space-between" gutter={10}>
            <Col flex="25px">
              <DragOutlined
                {...dragHandleProps} // eslint-disable-line react/jsx-props-no-spreading
              />
            </Col>
            <Col flex="auto" style={{ maxWidth: 'calc(100% - 60px' }}>
              <OnTraccrTextInput
                placeholder="Enter Title"
                onChange={onColumnTitleChanged}
                value={title}
              />
              <TreeSelect
                style={{ width: '100%', marginTop: 5 }}
                treeData={treeData}
                placeholder="Select Field"
                dropdownMatchSelectWidth={false}
                onSelect={onDataChange('field')}
                value={field}
                treeDefaultExpandAll
                showSearch
                optionFilterProp="title"
              />
              {
                (FIELDS_WITH_FILTERS.has(field) || FIELDS_WITH_CONFIG.has(field))
                && (
                  <Select
                    style={{ width: '100%', marginTop: 5 }}
                    value={filterValue}
                    options={CONFIG_MAP[field]?.options ?? costcodeOptions}
                    onSelect={onDataChange('filterValue')}
                    showSearch
                    optionFilterProp="label"
                    defaultValue={CONFIG_MAP[field]?.default}
                  />
                )
              }
              {
                FIELDS_WITH_TEXT.has(field)
                && (
                  <OnTraccrTextInput
                    style={{ width: '100%', marginTop: 5 }}
                    value={filterValue}
                    onChange={onTextChanged}
                  />
                )
              }
            </Col>
            <Col flex="25px">
              <BorderlessButton
                iconNode={<DeleteOutlined style={{ color: colors.ONTRACCR_RED, marginLeft: 0 }} />}
                onClick={onDeleteClicked}
                style={{
                  height: 20,
                  padding: 0,
                }}
              />
            </Col>
          </Row>
        </div>
      )}
    </Draggable>
  );
}

ExportsColumn.propTypes = {
  column: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  orderIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  divisionId: PropTypes.string,
  tableFieldId: PropTypes.string,
  hasEclipse: PropTypes.bool,
  type: PropTypes.string.isRequired,
  formTemplateId: PropTypes.string,
};

ExportsColumn.defaultProps = {
  divisionId: null,
  tableFieldId: null,
  hasEclipse: false,
  formTemplateId: null,
};

export default ExportsColumn;
