import { DateTime } from 'luxon';
import { message } from 'antd';

import {
  defaultDrawOptions,
  defaultTitleDrawOptions,
  defaultLogoDrawOptions,
  defaultSectionHeader,
  defaultFieldTitle,
  defaultFieldBox,
  defaultSignatureField,
  defaultSignatureLine,
  defaultSignatureName,
  defaultTableField,
  defaultStaticText,
  COLOR_LIGHT_GRAY,
  GRID_SIZE,
  COLLECTED_INITIAL_OFFSET,
  COLLECTED_HEIGHT,
  COLLECTED_GUTTER,
  PAGE_HEIGHT,
  FIELD_HEIGHT,
  DEFAULT_FIELD_BOX_HEIGHT,
  SIGNATURE_VIEW_HEIGHT,
  DEFAULT_ATTACHMENT_FIELD_HEIGHT,
  COLOR_BLUE,
} from './PDFDesigner.constants';

import { collectedDataMap } from '../../../forms/forms.constants';

import rawLogo from './ontraccr.logo.base64';

const bytes = Buffer.from(rawLogo, 'base64');
export const ONTRACCR_LOGO = new File([bytes], 'logo.png', { type: 'image/png' });

const LOGO_ENDPOINT = 'https://ontraccr-public.s3.us-west-2.amazonaws.com/logo.png';

export default {};

const componentToHex = (c) => {
  const hex = c.toString(16).toUpperCase();
  return hex.length === 1 ? `0${hex}` : hex;
};

export const rgbToHex = ({
  r, g, b, a,
}) => (
  `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}${componentToHex(parseInt(a * 255, 10))}`
);

export const rgba = ({
  r, g, b, a,
}) => `rgba(${r},${g},${b},${a})`;

export const resizeTableColumns = ({
  data = {},
  ourItem = {},
}) => {
  if (!('width' in data) || ourItem.type !== 'table') return ourItem;
  const newItem = { ...ourItem };
  // Need to readjust table columns based off of full table size
  const {
    drawOptions: {
      width: oldWidth = 1,
    } = {},
    rows: oldRows = [],
  } = newItem;
  const newRows = [...oldRows];
  const { width: newWidth = 0 } = data;
  const ratio = newWidth / oldWidth;
  const { cols: headerCols = [] } = newRows[0];
  newRows[0].cols = headerCols.map((col) => ({
    value: col.value,
    width: parseInt(col.width * ratio, 10),
  }));
  newItem.rows = newRows;
  return newItem;
};

const updateItem = ({ items, index, data }) => {
  const newItems = [...items];
  const ourItem = newItems[index];
  newItems[index] = {
    ...resizeTableColumns({ data, ourItem }),
    drawOptions: {
      ...ourItem.drawOptions,
      ...data,
    },
  };
  return newItems;
};

const getNData = ({ realY, ourItem }) => ({
  y: realY,
  height: ourItem.drawOptions.y - realY + ourItem.drawOptions.height,
});
const getEData = ({ realX, ourItem }) => ({ width: realX - ourItem.drawOptions.x });
const getSData = ({ realY, ourItem }) => ({ height: realY - ourItem.drawOptions.y });
const getWData = ({ realX, ourItem }) => ({
  x: realX,
  width: ourItem.drawOptions.x - realX + ourItem.drawOptions.width,
});

const clamp = (x, low, high) => Math.min(Math.max(x, low), high);
const round = (x) => parseInt(Math.round(x / GRID_SIZE) * GRID_SIZE, 10);

export const pointDiff = (p1, p2) => (
  Math.sqrt(
    (p1.x - p2.x) ** 2 + (p1.y - p2.y2) ** 2,
  )
);
export const handleMouseMove = ({
  e,
  ref,
  moveIndex,
  moveOffset,
  resizeIndex,
  resizeDirection,
  items = [],
}) => {
  const isMove = moveIndex !== null;
  const isResize = resizeIndex !== null;
  if (!isMove && !isResize) return items;
  const { clientX, clientY } = e;
  const {
    x: parentX, y: parentY, width, height,
  } = ref.current.getBoundingClientRect();
  const offsetX = isMove ? moveOffset.x : 0;
  const offsetY = isMove ? moveOffset.y : 0;
  const realX = round(clamp(clientX - parentX - offsetX, 0, width));
  const realY = round(clamp(clientY - parentY - offsetY, 0, height));
  let newItems = [...items];

  if (isMove) {
    newItems = updateItem({
      items,
      index: moveIndex,
      data: { x: realX, y: realY },
    });
  } else {
    const ourItem = newItems[resizeIndex];
    switch (resizeDirection) {
      case 'nw': {
        newItems = updateItem({
          items,
          index: resizeIndex,
          data: {
            ...getNData({ realY, ourItem }),
            ...getWData({ realX, ourItem }),
          },
        });
        break;
      }
      case 'n': {
        newItems = updateItem({
          items,
          index: resizeIndex,
          data: getNData({ realY, ourItem }),
        });
        break;
      }
      case 'ne': {
        newItems = updateItem({
          items,
          index: resizeIndex,
          data: {
            ...getNData({ realY, ourItem }),
            ...getEData({ realX, ourItem }),
          },
        });
        break;
      }
      case 'e': {
        newItems = updateItem({
          items,
          index: resizeIndex,
          data: getEData({ realX, ourItem }),
        });
        break;
      }
      case 'se': {
        newItems = updateItem({
          items,
          index: resizeIndex,
          data: {
            ...getSData({ realY, ourItem }),
            ...getEData({ realX, ourItem }),
          },
        });
        break;
      }
      case 's': {
        newItems = updateItem({
          items,
          index: resizeIndex,
          data: getSData({ realY, ourItem }),
        });
        break;
      }
      case 'sw': {
        newItems = updateItem({
          items,
          index: resizeIndex,
          data: {
            ...getWData({ realX, ourItem }),
            ...getSData({ realY, ourItem }),
          },
        });
        break;
      }
      case 'w': {
        newItems = updateItem({
          items,
          index: resizeIndex,
          data: getWData({ realX, ourItem }),
        });
        break;
      }
      default:
        break;
    }
  }
  return newItems;
};

export const addNewItem = (type) => {
  switch (type) {
    case 'text':
      return {
        text: 'text',
        drawOptions: defaultDrawOptions,
        deletable: true,
        type,
      };
    case 'rectangle':
      return {
        drawOptions: {
          ...defaultDrawOptions,
          borderColor: {
            r: 0, g: 0, b: 0, a: 1,
          },
          borderWidth: 1,
          borderStyle: 'solid',
        },
        deletable: true,
        type,
      };
    case 'image':
      return {
        drawOptions: {
          ...defaultDrawOptions,
          height: 200,
          width: 200,
        },
        deletable: true,
        type,
      };
    case 'table':
      return {
        drawOptions: defaultTableField,
        deletable: true,
        rows: [{
          cols: [{ value: '', width: 100 }, { value: '', width: 100 }, { value: '', width: 100 }],
          isHeader: true,
        }, {
          cols: [{ value: '', width: 100 }, { value: '', width: 100 }, { value: '', width: 100 }],
        }],
        type,
      };
    default:
      break;
  }
  return null;
};

const getCompanyLetterHead = ({
  logo = ONTRACCR_LOGO,
}) => [
  {
    type: 'image',
    drawOptions: { ...defaultLogoDrawOptions },
    deletable: true,
    image: logo,
    link: {
      settingId: 'logo',
    },
  }];

const getCollectedRow = ({
  y, response, link,
}) => (
  {
    text: response,
    drawOptions: {
      ...defaultDrawOptions,
      color: { ...COLOR_LIGHT_GRAY },
      x: 20,
      y,
      width: 130,
      height: COLLECTED_HEIGHT,
      fontSize: 12,
      textAlign: 'left',
      borderStyle: 'none',
    },
    deletable: true,
    type: 'text',
    link,
  }
);

export const collectedToPDFItems = () => {
  let offset = COLLECTED_INITIAL_OFFSET;
  const rowDelta = COLLECTED_HEIGHT + COLLECTED_GUTTER;
  const currentTime = DateTime.local().toLocaleString(DateTime.DATE_MED);
  const items = [
    getCollectedRow({
      y: offset,
      response: currentTime,
      link: {
        collectedId: collectedDataMap.date.id,
        collectedTitle: collectedDataMap.date.title,
      },
    }),
    getCollectedRow({
      y: offset + rowDelta,
      response: 'John Doe',
      link: {
        collectedId: collectedDataMap.employeeName.id,
        collectedTitle: collectedDataMap.employeeName.title,
      },
    }),
  ];
  offset += 1 * rowDelta + 15;
  return { items, offset };
};

/**
 * Builds the 'rows' object of a PDF table 'item' based on the columns of the table
 * Removes any columns marked as hidden
 * If the table already has rows, it will use the existing rows to determine the width
 * of the columns
 *
 * @param {Array} columns - [ { key: string, name: string, isHideInPDF: bool}, ... ]
 * @returns {Array} - [ { cols: Array, isHeader: bool }, ...]
 */
export const formatTablePDFItemRows = (columns, existingRows) => {
  if (!columns) return null;

  let totalWidth = defaultTableField.width;
  const filteredColumns = columns.filter((col) => !col.isHideInPDF);

  if (existingRows?.length) {
    const header = existingRows[0].cols ?? [];
    if (header.length === filteredColumns.length) return existingRows;
    totalWidth = header.reduce((acc, col) => acc + col.width, 0);
  }

  const colCount = filteredColumns.length > 0 ? filteredColumns.length : 1;
  const columnWidth = totalWidth / colCount;
  const rows = [
    {
      cols: filteredColumns.map((col) => (
        { value: col.name, width: columnWidth }
      )),
      isHeader: true,
    },
    { cols: filteredColumns.map(() => ({ value: 'Data' })) },
  ];
  return rows;
};

const getFieldItem = ({ sectionId, field = {}, offset: initialOffset }) => {
  const {
    configProps: {
      title,
      columns = [],
      isHyperlink,
    } = {},
    selectedType,
    id,
  } = field;

  let offset = initialOffset;
  let newPage = false;

  if (selectedType === 'multiSig' || selectedType === 'payment') {
    return { items: [], offset, newPage };
  }

  if (offset + FIELD_HEIGHT >= PAGE_HEIGHT - 20) {
    newPage = true;
    offset = 20;
  }

  const isStaticText = selectedType === 'staticText';
  const staticTextOpts = isStaticText ? defaultStaticText : {};
  const items = [{
    ...defaultFieldTitle,
    drawOptions: {
      ...defaultFieldTitle.drawOptions,
      ...staticTextOpts,
      y: offset,
    },
    text: title,
    isHyperlink: isHyperlink && isStaticText,
  }];
  if (isHyperlink && isStaticText) items[0].drawOptions.color = { ...COLOR_BLUE };
  if (isStaticText) {
    offset += 25;
    return { items, offset, newPage };
  }
  let offsetDiff = FIELD_HEIGHT;
  if (selectedType === 'weather') {
    const newItem = {
      drawOptions: {
        ...defaultTableField,
        y: offset + 25,
      },
      deletable: true,
      type: 'table',
      link: { sectionId, fieldId: id },
      rows: [{
        cols: [{ value: 'Weather Report', width: 100 }],
        isHeader: true,
      }, {
        cols: [{ value: '', width: 100 }],
      }],
    };
    offsetDiff = defaultTableField.rowHeight * 2;
    items.push(newItem);
  } else if (selectedType !== 'table') {
    const height = selectedType === 'attachment'
      ? DEFAULT_ATTACHMENT_FIELD_HEIGHT
      : defaultFieldBox.drawOptions.height;
    items.push({
      ...defaultFieldBox,
      drawOptions: {
        ...defaultFieldBox.drawOptions,
        y: offset + 25,
        height,
      },
      link: { sectionId, fieldId: id },
    });
    if (selectedType === 'attachment') {
      offsetDiff += DEFAULT_ATTACHMENT_FIELD_HEIGHT - DEFAULT_FIELD_BOX_HEIGHT;
    }
  } else {
    const newItem = {
      drawOptions: {
        ...defaultTableField,
        y: offset + 25,
      },
      deletable: true,
      rows: [{
        cols: [{ value: '', width: 100 }, { value: '', width: 100 }, { value: '', width: 100 }],
        isHeader: true,
      }, {
        cols: [{ value: '', width: 100 }, { value: '', width: 100 }, { value: '', width: 100 }],
      }],
      type: selectedType,
      link: { sectionId, fieldId: id },
    };
    if (columns) {
      const rows = formatTablePDFItemRows(columns);
      newItem.rows = rows;
    }
    offsetDiff = defaultTableField.rowHeight * 2;
    items.push(newItem);
  }
  offset += offsetDiff;
  return { items, offset, newPage };
};

const getSectionItems = ({ sections = [], offset: initialOffset }) => {
  let offset = initialOffset;
  offset += 20;
  const items = [[]];
  let currentIndex = 0;
  sections.forEach((section) => {
    const {
      id: sectionId,
      name: sectionName,
      fields = [],
    } = section;
    if (PAGE_HEIGHT - offset < 100) {
      currentIndex += 1;
      items.push([]);
      offset = 20;
    }
    items[currentIndex].push({
      ...defaultSectionHeader,
      drawOptions: {
        ...defaultSectionHeader.drawOptions,
        y: offset,
      },
      text: sectionName,
    });
    offset += 25;
    fields.forEach((field) => {
      const {
        items: fieldItems,
        newPage,
        offset: newOffset,
      } = getFieldItem({ field, offset, sectionId });
      if (newPage) {
        currentIndex += 1;
        items.push([]);
      }

      items[currentIndex] = items[currentIndex].concat(fieldItems);
      offset = newOffset;
    });
    offset += 20;
  });
  return items;
};

const appendSignature = ({ items = [[]], collected: { employeeSignature = {} } = {} }) => {
  const { collect } = employeeSignature;
  if (!collect) return items;
  const numPages = items.length;
  let lastPage = items[numPages - 1];
  const lastItem = lastPage[lastPage.length - 1] || {};
  const {
    drawOptions: {
      y = 0,
      height = 0,
    } = {},
  } = lastItem;
  const offset = y + height;
  const newItems = [...items];
  if (offset + SIGNATURE_VIEW_HEIGHT >= PAGE_HEIGHT - 20) {
    newItems.push([]);
    lastPage = newItems[newItems.length - 1];
  }
  lastPage = lastPage.concat([
    defaultSignatureField,
    defaultSignatureLine,
    defaultSignatureName,
  ]);
  newItems[newItems.length - 1] = lastPage;
  return newItems;
};

export const getInitialCustomPDFItems = ({
  settings, name, collected, sections, logo,
}) => {
  let items = [[{
    text: name,
    drawOptions: { ...defaultTitleDrawOptions },
    type: 'text',
    deletable: true,
  }].concat(getCompanyLetterHead({ logo })),
  ];

  const {
    items: collectedItems,
    offset,
  } = collectedToPDFItems();
  items[0] = items[0].concat(collectedItems);
  const sectionItems = getSectionItems({ sections, offset, settings });
  items[0] = items[0].concat(sectionItems[0]);
  items = items.concat(sectionItems.slice(1));

  return appendSignature({ items, collected });
};

export const imageValidator = (file) => {
  if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
    message.error('PDF Designer only supports images in PNG or JPEG format');
    return false;
  }
  return true;
};

export const parseDrawOptions = (items = []) => {
  const files = [];
  const fileMap = {};
  let fileIdCount = 0;
  const drawOptions = [];
  items.forEach((page) => {
    drawOptions.push(
      page.map((item) => {
        const parsedItem = { ...item };
        delete parsedItem.deletable;
        const { image, type } = parsedItem;
        if (type === 'image') {
          // Forms imported from form library will have this as their image
          if (image === LOGO_ENDPOINT) {
            return parsedItem;
          }
          if (image.name in fileMap) {
            parsedItem.image = fileMap[image.name];
          } else if (image.name) {
            files.push(image);
            // Will replace later with the 36 char hex id of the file in decoratePayloadWithFileIds
            parsedItem.image = fileIdCount;
            fileMap[image.name] = fileIdCount;
            fileIdCount += 1;
          }
        }
        return parsedItem;
      }),
    );
  });
  return { files, drawOptions };
};

const getFilesFromStaticAttachments = ({
  field,
  staticFileIdMap = {},
  parsedStaticFiles = [],
}) => {
  const fieldId = field?.id;
  const { files: fieldFiles = [] } = field?.configProps ?? {};
  return fieldFiles.reduce((acc, file, fileIndex) => {
    if (file.existing || file.jsFileObject?.existing) return acc.concat([file.id]);
    const realId = `${fieldId}-${fileIndex}`;
    if (realId in staticFileIdMap) {
      const parsedIndex = staticFileIdMap[realId];
      const parsedFile = parsedStaticFiles[parsedIndex];
      if (!parsedFile?.id) return acc;
      return acc.concat([parsedFile.id]);
    }
    return acc;
  }, []);
};

export const decoratePayloadWithFileIds = ({
  payload,
  files,
  staticFileIdMap = {},
  staticFiles: parsedStaticFiles = [],
}) => {
  const fileIds = files.map(({ id }) => id);
  const newPayload = {
    ...payload,
    files: files.concat(parsedStaticFiles),
  };
  const {
    drawOptions = [],
    sections,
  } = newPayload;
  const decoratedDrawOptions = drawOptions.map((page) => (
    page.map((item) => {
      const newItem = { ...item };
      const { type, image = -1 } = newItem;
      if (type === 'image' && image >= 0 && image < fileIds.length) {
        newItem.image = fileIds[image];
      }
      return newItem;
    })
  ));
  const decoratedSections = sections?.map?.((section) => {
    const fields = section?.fields ?? [];
    return {
      ...section,
      fields: fields.map((field) => {
        if (field?.selectedType !== 'staticAttachments') return field;
        const configProps = field?.configProps ?? {};
        return {
          ...field,
          configProps: {
            ...configProps,
            files: getFilesFromStaticAttachments({
              field,
              staticFileIdMap,
              parsedStaticFiles,
            }),
          },
        };
      }),
    };
  }) ?? [];

  return {
    ...newPayload,
    drawOptions: decoratedDrawOptions,
    sections: decoratedSections,
  };
};
