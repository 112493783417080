import React, {
  useMemo, useState, useCallback, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, message, Tabs } from 'antd';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { getIdMap } from 'ontraccr-common/lib/Common';

import { rotateFile } from '../../files/state/files.actions';
import FormDetailGeneralInfo from './FormDetailGeneralInfo';
import FormTriggeredItems from './FormTriggeredItems';
import FormDetailFields from './FormDetailFields';
import FormTimeline from './FormTimeline';
import FormResponder from '../FormResponder';
import FormInvoices from '../../payables/invoices/FormInvoices';
import PDFPreview from './PDFPreview';
import FullPhoto from '../../common/photos/FullPhoto';

import { PAYABLE_FORM_TYPES } from '../../payables/payables.constants';
import Analytics from '../../helpers/Analytics';
import { downloadFile, getFileType, visuallyDownloadFile } from '../../files/fileHelpers';
import {
  constructCompletedFormPDF,
  parseCompletedForm,
  parseFormTemplate,
  formatVariableText,
} from '../formHelpers';

import {
  getFormById,
  clearFormSelection,
  getChildForms,
  getTemplateDetails,
  getAssignedFormTemplates,
  getFormSnapshots,
} from '../state/forms.actions';
import { onNextFileClick, showLeftFileCyleButton, showRightFileCycleButton } from '../../helpers/fileHelpers';

import Permissions from '../../auth/Permissions';
import SliderDownloadButton from '../../common/buttons/SliderDownloadButton';
import AttachmentTab from '../../attachment/AttachmentTab';
import { EDIT_TYPES } from '../ResponderHelpers';

// Constants:
const GENERAL_INFO = 'generalInfo';
const TRIGGERED_ITEMS = 'triggeredItems';
const FORM_DETAIL_FIELDS = 'formDetailFields';
const INVOICES = 'invoices';
const PDF_VIEW = 'pdf';
const TIMELINE = 'timeline';
const ATTACHMENTS = 'attachments';

const { TabPane } = Tabs;

export default function FormDetailView({
  visible,
  onClose,
  userMap = {},
  shouldRedirect = true,
  poCloseMode,
  setPoCloseMode,
  startOnFields,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedForm = useSelector((state) => state.forms.selectedForm);
  const formTemplates = useSelector((state) => state.forms.templates);
  const globalFileMap = useSelector((state) => state.files.fileMap);

  const {
    company: {
      settings = {},
      companyImageURL: logo,
    } = {},
  } = useSelector((state) => state.settings);
  const projects = useSelector((state) => state.projects.projects);

  const {
    id,
    templateId,
    templateName,
    userId,
    users = [],
    createdAt = 0,
    data,
    status: formState,
    employeeSignature,
    steps = [],
    approvals = [],
    templateSchema = {},
    fileMap = {},
    number,
    drawOptions = [],
    useStandardTemplate,
    isComplete,
    isClosed,
    attachments = {
      instantiatedFiles: [],
      locationMap: {},
    },
    divisionId: formDivisionId,
    projects: formProjects,
  } = selectedForm || {};
  const {
    collected = {},
  } = data || {};
  const {
    [templateId]: {
      allowEdit,
      allowResubmission,
      allowSubmitChild,
      type: formType,
      pdfName,
      divisionId,
    } = {},
  } = formTemplates;

  const {
    [userId]: {
      name: submitterName,
      employeeId,
    } = {},
  } = userMap;
  const userNames = useMemo(() => (
    users.map((assignedUserId) => {
      const {
        [assignedUserId]: {
          name: assignedUserName,
        } = {},
      } = userMap;
      return assignedUserName;
    })
  ), [users, userMap]);
  const title = useMemo(() => {
    const submitText = submitterName ? `- ${submitterName} ` : '';
    return `${templateName} ${submitText}- ${DateTime.fromMillis(createdAt).toLocaleString(DateTime.DATETIME_MED)}`;
  }, [submitterName, templateName, createdAt]);

  const pdfFileName = useMemo(() => {
    if (!pdfName) return title;
    const date = DateTime.local().setZone(settings.timezone ?? 'utc').toFormat('LLL-d-yy-h:mma');
    const createdDate = DateTime.fromMillis(createdAt).setZone(settings.timezone ?? 'utc').toFormat('LLL-d-yy-h:mma');
    let formProjectName;
    const [firstProjectId] = formProjects ?? [];
    if (firstProjectId) {
      formProjectName = projects.find((project) => project.id === firstProjectId)?.name;
    }

    const variableMap = {
      'Form Author': submitterName,
      Date: date,
      'Created Date': createdDate,
      Project: formProjectName,
      'Form Number': number,
      'Form Name': templateName,
    };
    return formatVariableText({
      text: pdfName,
      variableMap,
    });
  }, [
    title,
    pdfName,
    submitterName,
    templateName,
    number,
    projects,
    formProjects,
    createdAt,
  ]);

  const projectIdMap = useMemo(() => getIdMap(projects), [projects]);

  const [selectedFile, setSelectedFile] = useState();
  const [selectedFileDetails, setSelectedFileDetails] = useState();
  const [activeTab, setActiveTab] = useState(startOnFields ? FORM_DETAIL_FIELDS : GENERAL_INFO);
  const [showRespondDrawer, setShowRespondDrawer] = useState(false);
  const [childSubmitMode, setChildSubmitMode] = useState(false);
  const [editType, setEditType] = useState();

  const clearSelectedFile = useCallback((rotation = -1) => {
    dispatch(rotateFile(
      selectedFile.id,
      { rotation, formId: id },
      fileMap[selectedFile?.id]?.rotation,
    ));
    setSelectedFile();
    setSelectedFileDetails();
  }, [selectedFile, fileMap]);

  useEffect(() => {
    if (id) dispatch(getFormSnapshots(id));
  }, [id]);

  const onFormResponderClose = useCallback(async () => {
    // Lazy way to update the form with current data
    await dispatch(getFormById(id, { getRecentDraft: true }));
    setShowRespondDrawer(false);
    setChildSubmitMode(false);
  }, [id]);

  const onEditClicked = useCallback((type) => async () => {
    // Need to call getFormId or the form is blank for some reason
    // Doesnt make sense to me, because we call this same action to open this view...
    await dispatch(getFormById(id, { getRecentDraft: true }));
    setEditType(type);
    setShowRespondDrawer(true);
  }, [id]);

  const onSubmitChildClicked = useCallback(async (childTemplateId) => {
    const shouldShow = await dispatch(getTemplateDetails(childTemplateId));
    if (shouldShow) {
      setShowRespondDrawer(true);
    }
  }, []);

  const formData = useMemo(() => {
    if (!visible) return [];
    if (data) {
      const {
        sections = [],
      } = data;
      const templateMap = {};
      const {
        sections: templateSections = [],
      } = templateSchema;
      templateSections.forEach(({ id: sectionId, fields = [] }) => {
        if (!(sectionId in templateMap)) {
          templateMap[sectionId] = {};
        }
        const subMap = templateMap[sectionId];
        fields.forEach((field) => {
          const { id: fieldId } = field;
          subMap[fieldId] = field;
        });
      });
      return parseCompletedForm({
        sections,
        fileMap,
        setSelectedFile,
        setSelectedFileDetails,
        templateMap,
      });
    }
    return parseFormTemplate({
      sections: templateSchema.sections,
      fileMap,
      setSelectedFile,
      setSelectedFileDetails,
    });
  }, [visible, data, templateSchema, fileMap]);

  const showLeft = useMemo(() => (
    showLeftFileCyleButton({ selectedFile, selectedFileDetails })
  ), [selectedFile, selectedFileDetails]);
  const showRight = useMemo(() => (
    showRightFileCycleButton({ selectedFile, selectedFileDetails, fileMap })
  ), [selectedFile, selectedFileDetails, fileMap]);

  const onNext = useCallback((isRight) => () => onNextFileClick({
    isRight,
    selectedFileDetails,
    fileMap,
    setSelectedFile,
    setSelectedFileDetails,
  }), [selectedFileDetails, fileMap]);

  const onDownload = useCallback(async () => {
    const completedFormPDF = await constructCompletedFormPDF({
      employeeSignature,
      useStandardTemplate,
      formData,
      fileMap,
      data,
      createdAt,
      drawOptions,
      title: pdfFileName,
      submitterName,
      employeeId,
      collected,
      settings,
      projectIdMap,
      logo,
      number,
      templateSchema,
    });
    downloadFile({ fileObject: completedFormPDF });
  }, [
    employeeSignature,
    useStandardTemplate,
    formData,
    fileMap,
    data,
    createdAt,
    drawOptions,
    pdfFileName,
    submitterName,
    employeeId,
    collected,
    settings,
    logo,
    number,
    templateSchema,
  ]);

  const onTabChange = useCallback((tab) => {
    Analytics.track('Forms/ViewSubmittedTab', { tab });
    setActiveTab(tab);
  }, []);

  useEffect(() => {
    if (!visible) {
      dispatch(clearFormSelection());
      setSelectedFile();
      setSelectedFileDetails();
      setActiveTab(GENERAL_INFO);
      setShowRespondDrawer(false);
      setChildSubmitMode(false);
    } else {
      setActiveTab(startOnFields ? FORM_DETAIL_FIELDS : GENERAL_INFO);
    }
  }, [visible, startOnFields]);

  useEffect(() => {
    if (visible && templateId) {
      dispatch(getChildForms(templateId));
    }
  }, [visible, templateId]);

  useEffect(() => {
    if (visible && poCloseMode) {
      setShowRespondDrawer(true);
    }
  }, [visible, poCloseMode]);

  useEffect(() => {
    if (!visible && setPoCloseMode) {
      setPoCloseMode(false);
    }
  }, [visible, setPoCloseMode]);

  useEffect(() => {
    dispatch(getAssignedFormTemplates());
  }, []);

  useEffect(() => {
    if (!selectedFile || !visible) return;
    const getFileData = async () => {
      const tempFile = { ...selectedFile };
      visuallyDownloadFile({
        dispatch,
        file: tempFile,
      });
    };

    const file = globalFileMap[selectedFile?.id];

    if (!file) {
      getFileData()
        .catch(() => {
          message.error('Could not preview file. Try again later');
        });
    }
  }, [selectedFile, visible, globalFileMap]);

  const closeButtonProps = {
    ...(childSubmitMode ? { closeIcon: false } : {}), // this prop expects a react node if not false
  };

  const preloadedIds = useMemo(() => ({
    parentFormId: id,
    parentTemplateId: templateId,
  }), [id, templateId]);

  return (
    <>
      <Drawer
        push={false}
        title={title}
        placement={childSubmitMode ? 'left' : 'right'}
        width={childSubmitMode ? '42vw' : '90vw'}
        visible={visible}
        maskClosable={false}
        onClose={childSubmitMode ? null : onClose}
        bodyStyle={{
          padding: '0px 24px 10px 24px',
          overflow: 'hidden',
        }}
        {...closeButtonProps} // eslint-disable-line react/jsx-props-no-spreading
      >
        <Tabs
          placement="top"
          onChange={childSubmitMode ? null : onTabChange}
          activeKey={activeTab}
        >
          {!childSubmitMode && (
            <>
              <TabPane tab="General Info" key={GENERAL_INFO}>
                <FormDetailGeneralInfo
                  formId={id}
                  collected={collected}
                  userName={submitterName}
                  employeeId={employeeId}
                  number={number}
                  visible={visible || activeTab === GENERAL_INFO}
                />
              </TabPane>
              <TabPane tab="Triggered Items" key={TRIGGERED_ITEMS}>
                <FormTriggeredItems
                  formId={id}
                  visible={visible || activeTab === TRIGGERED_ITEMS}
                />
              </TabPane>
            </>
          )}
          <TabPane tab="Fields" key={FORM_DETAIL_FIELDS}>
            <FormDetailFields
              formData={formData}
              formId={id}
              employeeSignature={employeeSignature}
              allowEdit={allowEdit}
              allowResubmission={allowResubmission && isComplete}
              onEditClicked={onEditClicked}
              visible={activeTab === FORM_DETAIL_FIELDS}
              allowSubmitChild={allowSubmitChild}
              onSubmitChildClicked={onSubmitChildClicked}
              childSubmitMode={childSubmitMode}
              setChildSubmitMode={setChildSubmitMode}
              sliderVisible={visible}
              setSelectedFile={setSelectedFile}
              setSelectedFileDetails={setSelectedFileDetails}
              isClosed={isClosed}
              divisionId={divisionId}
              approvals={approvals}
              formState={formState}
            />
          </TabPane>
          {!childSubmitMode && (
            <>
              <TabPane tab="PDF" key={PDF_VIEW}>
                <PDFPreview
                  sliderVisible={visible}
                  formData={formData}
                  selectedForm={selectedForm}
                />
              </TabPane>
              {Permissions.has('INVOICES_READ') && PAYABLE_FORM_TYPES.has(formType) && (
                <TabPane tab="Invoices" key={INVOICES}>
                  <FormInvoices
                    formId={id}
                    visible={activeTab === INVOICES}
                    useSummary
                    formType={formType}
                    isClosed={isClosed}
                    divisionId={formDivisionId}
                  />
                </TabPane>
              )}
              <TabPane tab="Timeline" key={TIMELINE}>
                <FormTimeline
                  steps={steps}
                  createdAt={createdAt}
                  userName={submitterName}
                  assignedUserNames={userNames}
                  state={formState}
                  approvals={approvals}
                  templateSchema={templateSchema}
                  userMap={userMap}
                  sections={formData}
                  formId={id}
                  visible={activeTab === TIMELINE}
                  isFinished={isComplete}
                />
              </TabPane>
              <TabPane tab="Attachments" key={ATTACHMENTS}>
                <AttachmentTab
                  attachments={attachments}
                  formId={id}
                />
              </TabPane>
            </>
          )}
        </Tabs>
        {!childSubmitMode && <SliderDownloadButton onDownload={onDownload} />}
        <FormResponder
          visible={showRespondDrawer}
          onClose={onFormResponderClose}
          history={shouldRedirect ? history : null}
          isOnEditStep={false}
          assignedForm={childSubmitMode ? undefined : selectedForm}
          isResubmit={(!childSubmitMode && !poCloseMode) || editType === EDIT_TYPES.RESUBMIT}
          isEdit={editType === EDIT_TYPES.EDIT}
          childSubmitMode={childSubmitMode}
          preloadedIds={preloadedIds}
          poCloseMode={poCloseMode}
        />
      </Drawer>
      {ReactDOM.createPortal(
        <FullPhoto
          rotation={selectedFile?.rotation !== undefined
            ? selectedFile?.rotation
            : (fileMap[selectedFile?.id]?.rotation ?? 0)}
          file={globalFileMap[selectedFile?.id]}
          type={selectedFile ? getFileType(selectedFile) : null}
          onClose={clearSelectedFile}
          onDownload={() => {
            const file = globalFileMap[selectedFile.id];
            if (file) downloadFile({ fileObject: file });
          }}
          onLeft={onNext(false)}
          onRight={onNext(true)}
          showLeft={showLeft}
          showRight={showRight}
          useApryse
        />,
        document.body,
      )}
    </>
  );
}

/* eslint-disable react/forbid-prop-types */
FormDetailView.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userMap: PropTypes.object,
  shouldRedirect: PropTypes.bool,
  poCloseMode: PropTypes.bool,
  setPoCloseMode: PropTypes.func,
  startOnFields: PropTypes.bool,
};

FormDetailView.defaultProps = {
  userMap: {},
  shouldRedirect: true,
  poCloseMode: false,
  setPoCloseMode: null,
  startOnFields: false,
};
