/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Drawer,
  Tabs,
} from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const { TabPane } = Tabs;

export default function FilterDrawer({
  title,
  visible,
  onClose,
  filterViews,
  CustomFilterTab,
  drawerProps = {},
  ...dataProps
}) {
  const activeFilters = useSelector((state) => state.schedule.fieldScheduleFilters) || {};
  const [activeTab, setActiveTab] = useState('custom');

  useEffect(() => {
    setActiveTab(activeFilters.filterView || 'custom');
  }, [activeFilters]);

  const onTabChange = useCallback((key) => {
    setActiveTab(key);
  }, [setActiveTab]);

  const onDrawerClose = useCallback(() => {
    onClose();
    setActiveTab(activeFilters.filterView || 'custom');
  }, [onClose, activeFilters]);

  return (
    <Drawer
      title={title}
      visible={visible}
      onClose={onDrawerClose}
      width={900}
      destroyOnClose
      {...drawerProps}
    >
      <Tabs
        activeKey={activeTab}
        tabPosition="left"
        onChange={onTabChange}
        className="filter-drawer-tabs"
        destroyInactiveTabPane
      >
        <TabPane
          tab="Custom View"
          key="custom"
        >
          <CustomFilterTab
            onClose={onDrawerClose}
            setActiveTab={setActiveTab}
            {...dataProps}
          />
        </TabPane>
        {
          filterViews.map((view) => (
            <TabPane
              tab={view.filterViewName}
              key={view.id}
            >
              <CustomFilterTab
                onClose={onDrawerClose}
                filterView={view}
                setActiveTab={setActiveTab}
                {...dataProps}
              />
            </TabPane>
          ))
        }
      </Tabs>
    </Drawer>
  );
}

FilterDrawer.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  filterViews: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      filterViewName: PropTypes.string,
    }),
  ),
  dataProps: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
  ),
  CustomFilterTab: PropTypes.node.isRequired,
  drawerProps: PropTypes.shape({}),
};

FilterDrawer.defaultProps = {
  title: 'Filters',
  filterViews: [],
  dataProps: {},
  drawerProps: {},
};
