import React, { useCallback } from 'react';
import { Form, Tabs, Checkbox } from 'antd';
import { useSelector } from 'react-redux';

import OnTraccrTextInput from '../../inputs/OnTraccrTextInput';

import {
  AUTO_COLLECTED_ID,
} from '../../../forms/forms.constants';

import PDFDesignerLinkPicker from './PDFDesignerLinkPicker';
import PDFDesignerPositionPicker from './PDFDesignerPositionPicker';
import PDFDesignerColorPicker from './PDFDesignerColorPicker';
import PDFDesignerBorderPicker from './PDFDesignerBorderPicker';
import PDFDesignerPagePicker from './PDFDesignerPagePicker';
import PDFDesignerFontPicker from './PDFDesignerFontPicker';

import { defaultDrawOptions } from './PDFDesigner.constants';

const formStyle = { marginBottom: 5 };

export default ({
  onDrawOptionChanged,
  page,
  numberOfPages,
  onPageChanged,
  onTextChanged,
  onLinkChanged,
  onIsSectionHeaderChanged,
  field:{
    text,
    drawOptions = defaultDrawOptions,
    link = {},
    isHeader,
    isSectionHeader,
  } = {},
  sections = [],
  collected = {},
  typeId,
  useStandardTemplate,
}) => {
const {
  backgroundColor,
} = drawOptions;

const {
  [typeId]: anchorNumber,
} = useSelector((state) => {
  const {
    settings: {
      company: {
        settings: {
          formAnchors = {},
        } = {},
      } = {},
    } = {},
  } = state;
  return formAnchors;
});

const onChange = useCallback((e) => onTextChanged(e.target.value),[onTextChanged]);

const onCheckChanged = useCallback((e) => (
  onIsSectionHeaderChanged(e?.target?.checked)
), [onIsSectionHeaderChanged]);

const handleLinkChange = useCallback((newLink) => {
  const fullLink = {
    ...link,
    ...newLink,
  };
  const { sectionId, fieldId } = fullLink;
  if(sectionId === AUTO_COLLECTED_ID
    && fieldId === 'number'
    && text === 'text'
    && anchorNumber) {
    // Show preview of number
    onTextChanged(anchorNumber.toString());
  }
  onLinkChanged(newLink);
},[text, link, onLinkChanged, anchorNumber]);

return (
  <Form layout='vertical' style={{ marginTop: 10, width:'100%' }}>
    <Tabs>
      <Tabs.TabPane tab='General' key='general'>
        <Form.Item key='value' label='Value' style={formStyle}>
          <OnTraccrTextInput onChange={onChange} defaultValue={text} textarea/>
        </Form.Item>
        <Form.Item key='isSectionHeader' label='Section Header' style={formStyle}>
          <Checkbox checked={isSectionHeader} onChange={onCheckChanged}/>
        </Form.Item>
        <PDFDesignerLinkPicker
          link={link}
          sections={sections}
          collected={collected}
          onLinkChanged={handleLinkChange}
          style={formStyle}
          disabled={isHeader && useStandardTemplate}
        />
        <PDFDesignerPositionPicker
          drawOptions={drawOptions}
          onDrawOptionChanged={onDrawOptionChanged}
          style={formStyle}
        />
        <PDFDesignerPagePicker
          page={page}
          numberOfPages={numberOfPages}
          onPageChanged={onPageChanged}
          style={formStyle}
          disabled={isHeader && useStandardTemplate}
        />
        <PDFDesignerColorPicker
          style={formStyle}
          title='Background Color'
          value={backgroundColor}
          onColorChanged={(newColor) => onDrawOptionChanged({ backgroundColor: newColor })}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab='Font' key='font'>
        <PDFDesignerFontPicker
          drawOptions={drawOptions}
          onDrawOptionChanged={onDrawOptionChanged}
          showFontScaleSelector
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab='Border' key='border'>
        <PDFDesignerBorderPicker
          drawOptions={drawOptions}
          onDrawOptionChanged={onDrawOptionChanged}
          style={formStyle}
        />
      </Tabs.TabPane>
    </Tabs>
  </Form>
  );
}