/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';

import PhotoReelPreview from './PhotoReelPreview';

import { getFileType } from '../../files/fileHelpers';

export default function PhotoReel({
  shadow,
  small,
  onDelete,
  photos = [],
  files = [],
  onClick,
  showCondensedView,
  locationMap = {},
  editing = false,
  fileMap: previewFileMap = {},
  timestamps = {},
}) {
  const formFileMap = useSelector((state) => state.forms.selectedForm?.fileMap || {});
  const fileMap = editing ? previewFileMap : formFileMap;
  return (
    <Row
      style={{
        marginTop: showCondensedView ? 0 : 10,
        width: '100%',
        overflowX: 'auto',
      }}
      gutter={12}
    >
      {
      // Used in Live Feed
      photos.map((file, index) => (
        <Col key={file.url}>
          <PhotoReelPreview
            uri={file.url}
            type={getFileType(file)}
            file={file}
            key={file.url}
            shadow={shadow}
            small={small}
            onDelete={onDelete ? () => onDelete(index) : null}
            onClick={onClick ? () => onClick(index) : null}
            showCondensedView={showCondensedView}
            locationMap={locationMap}
            timestamp={timestamps?.[file?.id]}
          />
        </Col>
      ))
    }
      {
      files.map((file, index) => (
        <Col key={file.name + file.id + index}>
          <PhotoReelPreview
            rotation={file?.rotation !== undefined ? file?.rotation : fileMap[file?.id]?.rotation}
            file={fileMap[file?.id]?.jsFileObject
              ? { ...file, ...fileMap[file?.id]?.jsFileObject }
              : file}
            key={file.name}
            shadow={shadow}
            small={small}
            onDelete={onDelete ? () => onDelete(index) : null}
            onClick={onClick ? () => {
              onClick(index, file);
            } : null}
            showCondensedView={showCondensedView}
            locationMap={locationMap}
            timestamp={timestamps?.[file?.id]}
          />
        </Col>
      ))
    }
    </Row>
  );
}

PhotoReel.propTypes = {
  shadow: PropTypes.bool,
  small: PropTypes.bool,
  onDelete: PropTypes.func,
  photos: PropTypes.arrayOf(PropTypes.shape({})),
  files: PropTypes.arrayOf(PropTypes.shape({})),
  onClick: PropTypes.func,
  showCondensedView: PropTypes.bool,
  locationMap: PropTypes.shape({}),
  editing: PropTypes.bool,
  fileMap: PropTypes.shape({}),
  timestamps: PropTypes.shape({}),
};

PhotoReel.defaultProps = {
  shadow: true,
  small: false,
  onDelete: null,
  photos: [],
  files: [],
  onClick: null,
  showCondensedView: false,
  locationMap: {},
  editing: false,
  fileMap: {},
  timestamps: {},
};
