import React from 'react';
import {
  Row,
  Col,
  Button,
} from 'antd';
import { FileAddOutlined } from '@ant-design/icons';
import TitleRow from './TitleRow';

import PhotoReel from '../../../common/photos/PhotoReel';
import colors from '../../../constants/Colors';

import FieldTriggerFlag from './FieldTriggerFlag';
import FieldTriggerCheckbox from './FieldTriggerCheckbox';
import ConditionalRenderingRow from './ConditionalRenderingRow';
import OptionalRow from './OptionalRow';
import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';
import CheckboxRow from './CheckboxRow';
import { uuid } from '../../../helpers/helpers';

const configure = ({
  id,
  setConfigProps,
  configProps = {},
  setCanSubmit,
  sections = [],
  isExternalForm,
  templateId,
  divisions,
  projectId,
  name,
  isBoardCards,
  setFieldTriggerEditable,
  disableOptional,
  customers = [],
  projects = [],
  users = [],
  costcodes = [],
  phases = [],
  projectIdMap = {},
  vendors = [],
  equipment = [],
  formTemplates = [],
  contactAddressBooks = {},
  labels = [],
  buckets = [],
} = {}) => {
  const {
    optional,
    hasConditionalRendering = false,
    conditionalRenderingFormula,
    append,
  } = configProps;

  const setTitle = (e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      title: value,
    });
    setCanSubmit(value);
  };

  const setOptional = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      optional: checked,
    });
  };

  const setConditionalRenderingFormula = (newVal) => {
    setConfigProps({
      ...configProps,
      conditionalRenderingFormula: newVal,
    });
  };

  const setHasConditionalRendering = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      hasConditionalRendering: checked,
    });
  };

  const setFieldTrigger = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      fieldTrigger: checked,
    });
  };

  const onAppendChange = (e) => {
    const { target: { checked } = {} } = e;
    setConfigProps({
      ...configProps,
      append: checked,
    });
  };

  return (
    <div>
      <Row className="form-required-field">
        Title:
      </Row>
      <Row style={{ marginTop: 5 }}>
        <OnTraccrTextInput
          textarea
          placeholder="Insert title here"
          onChange={setTitle}
          value={configProps.title}
        />
      </Row>
      <OptionalRow onChange={setOptional} optional={optional} disabled={disableOptional} />
      <ConditionalRenderingRow
        id={id}
        sections={sections}
        customers={customers}
        projects={projects}
        users={users}
        costcodes={costcodes}
        phases={phases}
        projectIdMap={projectIdMap}
        vendors={vendors}
        equipment={equipment}
        formTemplates={formTemplates}
        labels={labels}
        contactAddressBooks={contactAddressBooks}
        buckets={buckets}
        onChange={setHasConditionalRendering}
        onFormulaChange={setConditionalRenderingFormula}
        hasConditionalRendering={hasConditionalRendering}
        conditionalRenderingFormula={conditionalRenderingFormula}
      />
      <CheckboxRow
        title="Append to Form PDF"
        checked={append}
        onChange={onAppendChange}
        hoverText="If checked, any PDF files will be appended to the generated form PDF"
      />
      {!isBoardCards && !isExternalForm && (
        <FieldTriggerCheckbox
          onChange={setFieldTrigger}
          onEditableChange={setFieldTriggerEditable}
          sections={sections}
          projectId={projectId}
          templateId={templateId}
          divisions={divisions}
          isExternalForm={isExternalForm}
          configProps={configProps}
          setConfigProps={setConfigProps}
          name={name}
        />
      )}
    </div>
  );
};

const preview = ({
  setPreviewProps,
  previewProps = {},
  configProps = {},
  id,
  onOpenFileModal,
  onOpenFile,
  setCurrAttachmentFieldId,
  setOpenFileIndex,
  responses = {},
  setResponses,
  responding = false,
  isDisplay,
  sections,
  isExternalForm,
  projectId,
  templateId,
  divisions,
  fieldTriggerMap = {},
  setFieldTriggerMap,
  name,
  showCondensedView,
  fileMap,
  editing = false,
  setCurrFieldId,
} = {}) => {
  const {
    optional,
    title = 'Title goes here',
    showButton = true,
    fieldTrigger,
  } = configProps;

  const {
    files = [],
    onClick,
    hideDelete,
    timestamps = {},
  } = previewProps;

  const {
    [id]: {
      files: responseFiles = [],
      timestamps: responseTimestamps = {},
    } = {},
  } = responses;

  const onAttachDelete = hideDelete ? null : (idx) => {
    if (responding) {
      // set responses without the file in question
      const newFiles = [...responseFiles];
      newFiles.splice(idx, 1);
      setResponses({
        ...responses,
        [id]: {
          ...(responses[id]),
          files: newFiles,
        },
      });
    } else {
      const newFiles = [...files];
      newFiles.splice(idx, 1);
      setPreviewProps({
        ...previewProps,
        files: newFiles,
      });
    }
  };
  const onAttachAdd = () => {
    if (responding) {
      onOpenFileModal(id);
    } else {
      const newFiles = files.concat([new File([], `file-${files.length + 1}.png`, { autoSaveId: uuid() })]);
      setPreviewProps({
        ...previewProps,
        files: newFiles,
      });
    }
  };

  const onFileClick = (index) => {
    if (responding) {
      const file = responseFiles[index];
      if (setOpenFileIndex) setOpenFileIndex(index);
      if (setCurrAttachmentFieldId) setCurrAttachmentFieldId(id);
      onOpenFile(file);
      setCurrFieldId(id);
    } else {
      onClick(index);
    }
  };

  return (
    <div>
      <TitleRow
        title={title}
        optional={optional}
        filter={
          fieldTrigger && !isDisplay
            ? (
              <FieldTriggerFlag
                sections={sections}
                isExternalForm={isExternalForm}
                templateId={templateId}
                projectId={projectId}
                divisions={divisions}
                configProps={configProps}
                responding={responding}
                id={id}
                fieldTriggerMap={fieldTriggerMap}
                setFieldTriggerMap={setFieldTriggerMap}
                name={name}
              />
            ) : null
        }
      />
      <Row style={{ marginTop: 5 }}>
        {showButton && !isDisplay && (
        <Col style={{ marginTop: 10, width: 60 }}>
          <Row style={{ height: 80 }} align="bottom">
            <Row style={{ height: 70 }} align="top">
              <Button
                className="form-attachment-add-button"
                icon={(
                  <FileAddOutlined
                    style={{
                      color: colors.ONTRACCR_RED,
                    }}
                  />
                )}
                onClick={onAttachAdd}
              />
            </Row>
          </Row>
        </Col>
        )}
        <Col style={{
          width: showButton ? 'calc(100% - 60px)' : '100%',
          ...onClick
            ? { pointerEvents: 'all' }
            : {},
        }}
        >
          <PhotoReel
            fileMap={fileMap}
            editing={editing}
            small
            files={responding ? responseFiles : files}
            timestamps={responding ? responseTimestamps : timestamps}
            onDelete={isDisplay ? null : onAttachDelete}
            onClick={onFileClick}
            showCondensedView={showCondensedView}
          />
        </Col>
      </Row>
    </div>
  );
};

export default {
  configure,
  preview,
  title: 'Attachment',
  description: 'User can add one or more files/photos',
};
