import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Select,
} from 'antd';
import { CloudSyncOutlined, SettingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import axios from 'axios';

import WorkflowHandle from './WorkflowHandle';
import WorkflowActionNode from './WorkflowActionNode';
import WorkflowQuickBooksConfigureDrawer from './WorkflowQuickBooksConfigureDrawer';
import useToggle from '../../common/hooks/useToggle';
import BorderlessButton from '../../common/buttons/BorderlessButton';
import { updateData } from './workflowHelpers';

const INCORRECT_TYPE_TEXT = 'This step only works with Invoice or PO forms';

const getDisabledText = (t) => ({
  Invoice: `This step requires at least one Text field and one ${t('Customer')} Select field`,
  PO: 'This step requires at least one Material Table field and one Vendor Select field',
});

const SUPPORTED_FORM_TYPES = new Set([
  'Invoice',
  'PO',
]);

export default ({
  setDataMap,
  setElements,
  isDisplay,
  name,
  sections = [],
  formTypeName,
  divisionId,
} = {}) => {
  const WorkflowQuickBookSync = function WorkflowQuickBookSync({
    id,
    draggable,
    data = {},
    disabled,
  }) {
    const { t } = useTranslation();

    const {
      taxCode: initialTaxCode,
    } = data;

    const {
      fieldMappings: initialFieldMappings = {},
    } = data;

    const { connectedToQuickbooks = false } = useSelector((state) => state.settings.company);

    const [fieldMappings, setFieldMappings] = useState(initialFieldMappings);
    const [selectedTaxCode, setSelectedTaxCode] = useState(initialTaxCode);
    const [taxCodesMap, setTaxCodesMap] = useState({});

    useEffect(() => {
      const getTaxCodes = async () => {
        try {
          const { data: newTaxCodes = {} } = await axios.get('/quickbooks/taxcodes');
          setTaxCodesMap(newTaxCodes);
        } catch (err) {
          // silent fail
        }
      };

      if (connectedToQuickbooks) {
        getTaxCodes();
      }
    }, [connectedToQuickbooks]);

    const onTaxChange = useCallback((newValue) => {
      if (!setDataMap || !id) return;
      setSelectedTaxCode(newValue);
      setDataMap(updateData(id, { taxCode: newValue }));
    }, [id, setDataMap]);

    const onFieldMappingsChange = useCallback((newMappings) => {
      if (!setDataMap || !id) return;
      setFieldMappings(newMappings);
      setDataMap(updateData(id, { fieldMappings: newMappings }));
    }, [setDataMap, id]);

    const DISABLED_TEXT = getDisabledText(t);
    const style = useMemo(() => {
      if (disabled) return { opacity: 0.7 };
      return draggable ? {} : { width: 220 };
    }, [disabled, draggable]);

    const hover = useMemo(() => {
      if (!SUPPORTED_FORM_TYPES.has(formTypeName)) return INCORRECT_TYPE_TEXT;
      return disabled
        ? DISABLED_TEXT[formTypeName]
        : `Use this step to sync a ${formTypeName} with QuickBooks`;
    }, [disabled, formTypeName]);

    const {
      isToggled: isMappingDrawerVisible,
      toggle: toggleMappingDrawer,
    } = useToggle();

    const isInvoice = formTypeName === 'Invoice';

    const {
      defaultTaxCode,
      taxCodeOptions,
    } = useMemo(() => {
      if (!divisionId || !connectedToQuickbooks) {
        return {
          defaultTaxCode: null,
          taxCodeOptions: [],
        };
      }

      const options = taxCodesMap?.[divisionId] || [];
      const defaultTax = options.find(({ isDefault }) => isDefault);

      return {
        defaultTaxCode: defaultTax ? { label: defaultTax.Name, value: defaultTax.Id } : null,
        taxCodeOptions: options,
      };
    }, [divisionId, connectedToQuickbooks, taxCodesMap]);

    useEffect(() => {
      if (defaultTaxCode && !selectedTaxCode) {
        setSelectedTaxCode(defaultTaxCode);
      }
    }, [defaultTaxCode, selectedTaxCode]);

    return (
      <WorkflowActionNode
        id={id}
        title="Sync with Quickbooks"
        Icon={CloudSyncOutlined}
        type={isInvoice ? 'qbInvoice' : 'qbPO'}
        draggable={draggable}
        onNodeUpdate={setElements}
        isDisplay={isDisplay || disabled}
        hover={hover}
        style={style}
      >
        {!draggable && (
          <div>
            {isInvoice && (
              <>
                <Row style={{ marginTop: 10 }}>
                  Select Tax Code
                </Row>
                <Row style={{ margin: '10px 0px' }}>
                  <Select
                    onChange={onTaxChange}
                    value={selectedTaxCode}
                    showSearch
                    optionFilterProp="label"
                    options={taxCodeOptions.map(({ Id, Name }) => ({ value: Id, label: Name }))}
                    labelInValue
                  />
                </Row>
              </>
            )}
            <Row style={{ margin: '20px 0px' }}>
              <BorderlessButton
                title="Configure"
                style={{ margin: 5 }}
                iconNode={<SettingOutlined />}
                onClick={toggleMappingDrawer}
              />
            </Row>
            <WorkflowQuickBooksConfigureDrawer
              visible={isMappingDrawerVisible}
              onClose={toggleMappingDrawer}
              onSubmit={toggleMappingDrawer}
              sourceName={name}
              sourceSections={sections}
              fieldMappings={fieldMappings}
              onFieldMappingsChange={onFieldMappingsChange}
              formTypeName={formTypeName}
            />
            <WorkflowHandle type="target" position="top" disabled={isDisplay} />
            {isInvoice && (
              <>
                <Row justify="center" style={{ marginBottom: 5 }}>
                  <Col>
                    On Payment
                  </Col>
                </Row>
                <WorkflowHandle type="source" position="bottom" disabled={isDisplay} />
              </>
            )}
          </div>
        )}
      </WorkflowActionNode>
    );
  };
  WorkflowQuickBookSync.propTypes = {
    id: PropTypes.string.isRequired,
    draggable: PropTypes.bool,
    disabled: PropTypes.bool,
    data: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  };

  WorkflowQuickBookSync.defaultProps = {
    draggable: false,
    disabled: false,
    data: {},
  };
  return WorkflowQuickBookSync;
};
