import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

function ProgressWheel({ percentage = 0 }) {
  const [size, setSize] = useState(0); // The dynamic size of the component
  const strokeWidth = 6; // Thickness of the progress bar
  const radius = (size - strokeWidth * 2) / 2; // Radius of the circle
  const circumference = 2 * Math.PI * radius; // Circumference of the circle
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  // Handle dynamic sizing
  const handleResize = useCallback((event) => {
    const width = event.target.offsetWidth;
    setSize(width);
  }, []);

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        backgroundColor: '#ffffff',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5,
      }}
      ref={(el) => el && handleResize({ target: el })} // Dynamically get the width
    >
      {/* SVG Container */}
      <svg
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        {/* Background Circle */}
        <circle
          cx="50%"
          cy="50%"
          r={radius}
          stroke="#f2f2f2"
          strokeWidth={strokeWidth}
          fill="none"
        />
        {/* Progress Circle */}
        <circle
          cx="50%"
          cy="50%"
          r={radius}
          stroke="#007BFF" // Replace with your color
          strokeWidth={strokeWidth}
          fill="none"
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap="round"
        />
      </svg>
      {/* Percentage Text */}
      <span
        style={{
          fontSize: '8px',
          fontWeight: '300',
          color: '#000',
          paddingLeft: 2,
        }}
      >
        {`${Math.round(percentage)}%`}
      </span>
    </div>
  );
}

ProgressWheel.propTypes = {
  percentage: PropTypes.number,
};

ProgressWheel.defaultProps = {
  percentage: 0,
};

export default ProgressWheel;
