import React, { useCallback, useMemo } from 'react';
import {
  Dropdown,
  Menu,
} from 'antd';
import {
  GroupOutlined,
  FormOutlined,
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { selectQuery } from './state/search.actions';

function SearchSelector({
  onTabChanged,
}) {
  const dispatch = useDispatch();
  const {
    boardQueries,
    formQueries,
    selectedQuery,
  } = useSelector((state) => state.search);

  const onSelectQuery = useCallback(({ key: queryId }) => {
    // Should be unique as both form and board queries
    // Are stored in the same queries DB table
    const ourQuery = formQueries?.[queryId] ?? boardQueries?.[queryId];
    if (ourQuery) {
      dispatch(selectQuery(ourQuery));
      onTabChanged(ourQuery.type);
    }
  }, [formQueries, boardQueries]);

  const allQueries = useMemo(() => (
    Object.values(formQueries).concat(Object.values(boardQueries))
  ), [formQueries, boardQueries]);

  const menu = useMemo(() => (
    <Menu onClick={onSelectQuery}>
      {allQueries.map((query) => (
        <Menu.Item
          key={query.id}
        >
          {
            query.type === 'forms'
              ? <FormOutlined />
              : <GroupOutlined />
          }
          {query.name}
        </Menu.Item>
      ))}
    </Menu>
  ), [allQueries, onSelectQuery]);

  return (
    <Dropdown
      trigger={['click']}
      overlay={menu}
    >
      <span id="board-crumb-button">
        {selectedQuery?.name ?? 'Select a Query'}
      </span>
    </Dropdown>
  );
}

SearchSelector.propTypes = {
  onTabChanged: PropTypes.func.isRequired,
};

export default SearchSelector;
