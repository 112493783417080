import React, { useCallback, useMemo } from 'react';
import { Row, Col, Input } from 'antd';
import { FilterFilled, FilterOutlined, PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

import ScheduleDatePicker from './ScheduleDatePicker';
import ScheduleZoomButtons from './ScheduleZoomButtons';
import WhoseScheduledDrawer from './WhoseScheduledDrawer';
import OnTraccrButton from '../common/buttons/OnTraccrButton';

import Permissions from '../auth/Permissions';
import { getBiWeeklyEnd, getWeekStart } from './scheduleHelpers';
import { isFilterActive } from './GanttSchedule/ganttScheduleHelpers';

const GUTTER = 12;
function ScheduleHeader({
  viewType,
  onViewTypeChange,
  onDateChange,
  date,
  onSearch,
  onAdd,
  enableHotkeys,
  disableHotkeys,
  openFilterDrawer,
  filters,
}) {
  const isActive = useMemo(() => isFilterActive(filters), [filters]);
  const onSearchChange = useCallback((e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    onSearch(value);
  }, [onSearch]);

  const onInputVisibleChanged = useCallback((visible) => {
    if (visible) {
      disableHotkeys();
    } else {
      enableHotkeys();
    }
  }, [enableHotkeys, disableHotkeys]);

  const lowerViewType = viewType.toLowerCase();
  const isBiWeekly = viewType === 'UserBiWeekly';
  let dateMod = lowerViewType.replace('user', '');
  if (lowerViewType === 'user') {
    // Prior to 3.21.0 user day view was just called User view.
    dateMod = 'day';
  }

  const endDate = isBiWeekly ? getBiWeeklyEnd(date) : date.endOf(dateMod);
  const startDate = isBiWeekly ? getWeekStart(date) : date.startOf(dateMod);

  const typeMap = {
    Day: 'date',
    Week: 'week',
    Month: 'month',
    UserDay: 'date',
    UserBiWeekly: 'week',
  };

  return (
    <Row
      id="schedule-header-container"
      justify="space-between"
      align="middle"
      gutter={20}
      style={{
        display: 'flex',
      }}
    >
      <Col style={{
        flexGrow: 1,
      }}
      >
        <Row justify="start" gutter={GUTTER}>
          <Col>
            <Input.Search
              className="searchbar"
              placeholder="Search"
              onChange={onSearchChange}
              allowClear
              onFocus={disableHotkeys}
              onBlur={enableHotkeys}
              width={50}
              style={{
                width: 250,
              }}
            />
          </Col>
          <Col>
            {Permissions.has('SCHEDULE_WRITE') && (
              <OnTraccrButton
                title="Add"
                icon={<PlusOutlined />}
                onClick={onAdd}
              />
            )}
          </Col>
          <Col>
            <WhoseScheduledDrawer startDate={startDate} endDate={endDate} />
          </Col>
        </Row>
      </Col>
      <Col style={{
        flexGrow: 1,
      }}
      >
        <Row justify="end" gutter={GUTTER}>
          <Col>
            <OnTraccrButton
              title=""
              icon={
                  isActive
                    ? <FilterFilled style={{ marginLeft: 0 }} />
                    : <FilterOutlined style={{ marginLeft: 0 }} />
                }
              onClick={openFilterDrawer}
            />
          </Col>
          <Col>
            <ScheduleDatePicker
              viewType={viewType}
              onViewTypeChange={onViewTypeChange}
              onDateChange={onDateChange}
              date={date}
              onVisibleChanged={onInputVisibleChanged}
              typeMap={typeMap}
            />
          </Col>
          <Col>
            {viewType !== 'Month' && viewType !== 'UserBiWeekly' && <ScheduleZoomButtons />}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

/* eslint-disable react/forbid-prop-types */
ScheduleHeader.propTypes = {
  viewType: PropTypes.string.isRequired,
  onViewTypeChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(DateTime).isRequired,
  onSearch: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  enableHotkeys: PropTypes.func.isRequired,
  disableHotkeys: PropTypes.func.isRequired,
  openFilterDrawer: PropTypes.func.isRequired,
  filters: PropTypes.shape({}),
};

ScheduleHeader.defaultProps = {
  filters: {},
};

export default ScheduleHeader;
