import React, { useCallback, useMemo } from 'react';
import {
  Row, Col, Checkbox, Popover,
  Divider,
} from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import BorderlessButton from '../../common/buttons/BorderlessButton';

import { DAYS } from './formTrigger.constants';

function WorkflowTriggerCheckbox({
  onCheckChange,
  children,
  checked,
  disabled,
  showTimeOptions,
  triggerDays = [],
  onTriggerDaysChanged,
  triggerRequiredSubmit,
  onRequiredSubmitChanged,
}) {
  const triggerDaySet = useMemo(() => new Set(triggerDays), [triggerDays]);
  const onChange = useCallback((key) => (e) => {
    const {
      target: {
        checked: newChecked,
      } = {},
    } = e;
    const changeFn = key === 'requiredSubmit' ? onRequiredSubmitChanged : onCheckChange;
    if (!changeFn) return;
    changeFn(newChecked);
  }, [onCheckChange, onRequiredSubmitChanged]);

  const onDayChange = useCallback((day) => (e) => {
    if (!onTriggerDaysChanged) return;
    const {
      target: {
        checked: newChecked,
      } = {},
    } = e;
    const newDays = newChecked
      ? triggerDays.concat([day])
      : triggerDays.filter((existingDay) => existingDay !== day);
    onTriggerDaysChanged(newDays);
  }, [onTriggerDaysChanged, triggerDays]);

  return (
    <Row style={{ margin: '0px 5px', width: '100%' }}>
      <Col>
        <Checkbox
          checked={checked}
          onChange={onChange('enable')}
          style={{ color: 'white' }}
          disabled={disabled}
        >
          {children}
        </Checkbox>
      </Col>
      {
        showTimeOptions
        && (
        <Col>
          <Popover
            placement="right"
            trigger={['click']}
            content={(
              <div style={{ maxWidth: 300 }}>
                <Row>
                  <Checkbox
                    style={{ fontWeight: 'bold' }}
                    checked={triggerRequiredSubmit}
                    onChange={onChange('requiredSubmit')}
                  >
                    Enable Mandatory Form Submission
                  </Checkbox>
                </Row>
                <Divider style={{ marginBottom: 10, marginTop: 10 }} />
                <Row style={{ fontWeight: 'bold', marginBottom: 10 }}>
                  Days to Trigger
                </Row>
                <Row>
                  {DAYS.map((day) => (
                    <Checkbox
                      checked={triggerDaySet.has(day)}
                      onChange={onDayChange(day)}
                      style={{ marginLeft: 8 }}
                    >
                      {day}
                    </Checkbox>
                  ))}
                </Row>
              </div>
            )}
          >
            <BorderlessButton
              style={{
                height: 20, padding: 0, background: 'transparent', color: 'white',
              }}
              iconNode={<SettingOutlined />}
            />
          </Popover>
        </Col>
        )
      }
    </Row>
  );
}

WorkflowTriggerCheckbox.propTypes = {
  onCheckChange: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  showTimeOptions: PropTypes.bool.isRequired,
  triggerDays: PropTypes.arrayOf(PropTypes.string),
  onTriggerDaysChanged: PropTypes.func,
  triggerRequiredSubmit: PropTypes.bool,
  onRequiredSubmitChanged: PropTypes.func,
};

WorkflowTriggerCheckbox.defaultProps = {
  onCheckChange: null,
  disabled: false,
  triggerDays: [],
  onTriggerDaysChanged: null,
  triggerRequiredSubmit: false,
  onRequiredSubmitChanged: null,
};

export default WorkflowTriggerCheckbox;
