import React, {
  useMemo,
} from 'react';
import { Collapse, Row, Spin } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import BoardResultsList from './BoardResultsList';

import BoardCardDrawer from '../boards/BoardCardDrawer';
import useBoardPermissions from '../common/hooks/useBoardPermissions';

export default function BoardsResults({ loading }) {
  const boards = useSelector((state) => state.boards.boards);
  const results = useSelector((state) => state.search.boardResults);

  const {
    selectedCardId,
  } = useSelector((state) => state.boards.drawerConfig);
  const reduxCard = useSelector((state) => state.boards.selectedCard);
  const boardDetailsMap = useSelector((state) => state.boards.boardDetailsMap);

  const cardTypes = useSelector((state) => state.boards.cardTemplates);

  const selectedCard = reduxCard?.id === selectedCardId ? reduxCard : null;
  const {
    boardId: selectedBoardId,
  } = selectedCard ?? {};

  const boardDetails = boardDetailsMap[selectedBoardId] || {};
  const board = boards[selectedBoardId] || {};

  const {
    showCardNumber,
    cardTypeId,
    divisions,
    creatorId,
  } = board;

  const {
    users = [],
  } = boardDetails;
  const cardType = cardTypes?.[cardTypeId] ?? {};

  const cardsByBoardAndStatus = useMemo(() => (
    results.reduce((acc, card) => {
      if (!acc[card.boardId]) acc[card.boardId] = { resultsCount: 0 };
      if (!acc[card.boardId][card.statusId]) acc[card.boardId][card.statusId] = [];
      acc[card.boardId][card.statusId].push(card);
      acc[card.boardId].resultsCount += 1;
      return acc;
    }, {})
  ), [results]);

  const userBoardPermissions = useBoardPermissions({ users, creatorId });

  const boardResults = useMemo(() => (
    Object.entries(cardsByBoardAndStatus).map(([boardId, boardStatuses = {}]) => (
      <Collapse.Panel
        header={boards[boardId]?.title}
        key={boardId}
        extra={`${cardsByBoardAndStatus[boardId].resultsCount} Results`}
        collapsible="header"
        style={{ width: '100%' }}
      >
        <BoardResultsList
          boardId={boardId}
          cardsByStatus={boardStatuses}
        />
      </Collapse.Panel>
    ))
  ), [cardsByBoardAndStatus]);

  return (
    <>
      {
        loading
          ? (
            <div style={{ width: '100%' }}>
              <Row style={{ flex: 1 }} justify="center" align="middle">
                <Spin />
              </Row>
            </div>
          )
          : (
            <Collapse
              size="large"
              style={{ width: '100%' }}
            >
              {boardResults}
            </Collapse>
          )
      }
      <BoardCardDrawer
        boardId={selectedBoardId}
        cardTypeId={cardTypeId}
        divisions={divisions}
        showCardNumber={showCardNumber}
        useCardNumberAsCardTitle={cardType?.useCardNumberAsCardTitle}
        userBoardPermissions={userBoardPermissions}
      />
    </>
  );
}

BoardsResults.propTypes = {
  loading: PropTypes.bool,
};

BoardsResults.defaultProps = {
  loading: false,
};
