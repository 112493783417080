import PropTypes from 'prop-types';
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Spin } from 'antd';

import { getTemplateDetails } from '../forms/state/forms.actions';
import CustomFieldsSearchSection from './CustomFieldsSearchSection';
import { fieldToSearch } from './searchHelpers';

export default function FormFieldsSearch({
  query,
  onQueriesChange,
}) {
  const { templateId } = query ?? {};
  const dispatch = useDispatch();

  const templates = useSelector((state) => state.forms.templates);
  const [loading, setLoading] = useState();

  useEffect(() => {
    const getTemplate = async () => {
      setLoading(true);
      await dispatch(getTemplateDetails(templateId));
      setLoading(false);
    };
    if (templateId) {
      getTemplate();
    }
  }, [templateId]);

  const ourTemplate = useMemo(() => {
    const {
      [templateId]: targetForm = {},
    } = templates;
    return targetForm;
  }, [templateId, templates]);

  const ourSearchFields = useMemo(() => {
    const {
      formData: {
        sections: targetSections = [],
      } = {},
    } = ourTemplate;
    return targetSections.map((section) => (
      <CustomFieldsSearchSection
        key={section.id}
        section={section}
        fieldToSearch={fieldToSearch(query?.custom, onQueriesChange)}
      />
    ));
  }, [ourTemplate, query, onQueriesChange]);

  return (
    <Col>
      {loading ? (
        <Spin />
      ) : (
        ourSearchFields
      )}
    </Col>
  );
}

FormFieldsSearch.propTypes = {
  query: PropTypes.shape({ custom: {} }),
  onQueriesChange: PropTypes.func.isRequired,
};

FormFieldsSearch.defaultProps = {
  query: {},
};
