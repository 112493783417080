import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Checkbox,
} from 'antd';
import { MoreOutlined, ShareAltOutlined, GlobalOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';
import path from 'path';
import PropTypes from 'prop-types';

import FileListHeader from './FileListHeader';
import StorageFooter from './StorageFooter';

import PaginatedTable from '../common/containers/PaginatedTable';
import BorderlessButton from '../common/buttons/BorderlessButton';
import NuxFocusBackground from '../nux/NuxFocusBackground';

import {
  FILES_PAGE_NUX_STEP_2,
  FILES_PAGE_NUX_STEP_3,
  FILES_PAGE_NUX_STEP_2_FILTER_TEXT,
  FILES_PAGE_NUX_STEP_2_SELECT_TEXT,
  FILES_PAGE_NUX_STEP_2_MORE_TEXT,
  FILES_NUX_DUMMY_DATA,
} from '../nux/nux.constants';

import { fileIconMap, isDefaultPublic, isLinkedFolder } from './fileHelpers';
import sortByString, { search, toTitleCase, getIdMap } from '../helpers/helpers';
import { sortFiles } from '../helpers/fileHelpers';
import Debouncer from '../helpers/Debouncer';
import NuxPopover from '../nux/NuxPopover';

const FOOTER_HEIGHT = 40;

const debouncer = new Debouncer();

const filters = Object.keys(fileIconMap).map((fileType) => ({
  text: fileType === 'pdf' ? 'PDF' : toTitleCase(fileType),
  value: fileType,
}));

const B = 1;
const KB = 1000;
const MB = 1000 * KB;
const GB = 1000 * MB;
const magnitudes = [GB, MB, KB, B];
const suffixes = ['GB', 'MB', 'KB', 'B'];
const parseSize = (size) => {
  if (!size) return '--';
  let i = 0;
  while (i < magnitudes.length) {
    const val = size / magnitudes[i];
    if (val > 1) return `${val.toFixed(2)} ${suffixes[i]}`;
    i += 1;
  }
  return size;
};

const parseUpdated = (lastUpdated) => (
  lastUpdated
    // eslint-disable-next-line new-cap
    ? new DateTime.fromMillis(lastUpdated).toLocaleString(DateTime.DATETIME_MED)
    : lastUpdated
);

function ListButton({ onClick, record, icon }) {
  return (
    <BorderlessButton
      style={{ padding: 0, margin: '0px 10px', width: 39 }} // Cell has 8px padding + 10px margin on both sides
      iconNode={icon}
      onClick={(e) => {
        e.stopPropagation();
        onClick(record);
      }}
    />
  );
}

ListButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  record: PropTypes.shape({}).isRequired,
  icon: PropTypes.node,
};

ListButton.defaultProps = {
  icon: null,
};

function MoreButton({ onMore, record }) {
  return (
    <ListButton
      record={record}
      onClick={onMore}
      icon={<MoreOutlined style={{ margin: 0 }} />}
    />
  );
}

MoreButton.propTypes = {
  onMore: PropTypes.func.isRequired,
  record: PropTypes.shape({}).isRequired,
};

function ShareButton({ onShare, record }) {
  const defaultPublic = isDefaultPublic(record);
  return (
    <ListButton
      record={record}
      onClick={onShare}
      icon={record.isPublic || defaultPublic
        ? <GlobalOutlined style={{ margin: 0 }} />
        : <ShareAltOutlined style={{ margin: 0 }} />}
    />
  );
}

ShareButton.propTypes = {
  onShare: PropTypes.func.isRequired,
  record: PropTypes.shape({
    isPublic: PropTypes.bool.isRequired,
  }).isRequired,
};

const getColumns = ({
  onMore,
  onShare,
  hasWritePerms,
  simple,
  activeNuxAction,
}) => {
  let cols = [
    {
      title: <div style={!hasWritePerms ? { paddingLeft: 20 } : {}}>Name</div>,
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      sorter: sortByString('name'),
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
      render: (_, record) => {
        const Icon = fileIconMap[record.type];
        return (
          <Row gutter={10} justify="start" style={!hasWritePerms ? { paddingLeft: 20 } : {}}>
            <Col>
              <Icon />
            </Col>
            <Col>
              {record.name}
            </Col>
          </Row>
        );
      },
    },
    {
      title: (
        <NuxPopover
          visible={activeNuxAction === FILES_PAGE_NUX_STEP_2}
          text={FILES_PAGE_NUX_STEP_2_FILTER_TEXT}
          placement="top"
          nextAction={FILES_PAGE_NUX_STEP_3}
        >
          Type
        </NuxPopover>
      ),
      dataIndex: 'type',
      key: 'icon',
      align: simple ? 'left' : 'center',
      width: 120,
      render: (_, record) => (record.type === 'pdf' ? record.type.toUpperCase() : toTitleCase(record.type)),
      filters,
      onFilter: (value, record) => record.type === value,
      sorter: sortByString('type'),
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    },
  ];
  if (simple) return cols;
  cols = cols.concat(
    [{
      title: 'Last Updated',
      dataIndex: 'lastUpdated',
      key: 'lastUpdated',
      align: 'center',
      width: 200,
      render: parseUpdated,
      sorter: (a, b) => a.lastUpdated - b.lastUpdated,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      align: 'center',
      width: 100,
      render: parseSize,
      sorter: (a, b) => a.size - b.size,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    }],
  );
  if (hasWritePerms) {
    cols.push(
      {
        title: 'Permissions',
        key: 'permissions',
        width: 150,
        align: 'center',
        render: (_, record, index) => (index === 0 && activeNuxAction === 'tbd'
          ? (
            <NuxPopover
              visible={activeNuxAction === 'tbd'}
              text="tbd"
              placement="bottomLeft"
            >
              <ShareButton onShare={onShare} record={record} />
            </NuxPopover>
          )
          : <ShareButton onShare={onShare} record={record} />),
      },
    );
  }
  cols.push({
    title: 'More',
    key: 'more',
    width: 75,
    align: 'center',
    render: (_, record, index) => (index === 0 && activeNuxAction === FILES_PAGE_NUX_STEP_2
      ? (
        <NuxPopover
          visible={activeNuxAction === FILES_PAGE_NUX_STEP_2}
          nextAction={FILES_PAGE_NUX_STEP_3}
          text={FILES_PAGE_NUX_STEP_2_MORE_TEXT}
          placement="bottomLeft"
        >
          <MoreButton onMore={onMore} record={record} />
        </NuxPopover>
      )
      : <MoreButton onMore={onMore} record={record} />),
  });
  return cols;
};

export default function FilesList({
  onClick,
  onMore,
  onShare,
  onAddFolder,
  onAddFile,
  onDelete,
  onMove,
  onCopy,
  data = [],
  selectedFolders = [],
  onSelect,
  pathname,
  onBack,
  hasWritePerms,
  simple,
  history,
  promptForPasswordOnDelete = true,
  showAddFileButton = true,
  headerOffset = 0,
}) {
  const divisions = useSelector((state) => state.settings.divisions);
  const projects = useSelector((state) => state.projects.projects);
  const activeNuxAction = useSelector((state) => state.nux.activeNuxAction);
  const showNuxStep2 = activeNuxAction === FILES_PAGE_NUX_STEP_2;
  const showNuxStep3 = activeNuxAction === FILES_PAGE_NUX_STEP_3;
  const [searchStr, setSearchStr] = useState();
  const [relevantData, setRelevantData] = useState([]);
  const [projectMap, setProjectMap] = useState({});

  const [divisionNames, setDivisionNames] = useState(new Set());
  const [divisionFolders, setDivisionFolders] = useState(new Set());
  const [divisionProjectsFolders, setDivisionProjectsFolders] = useState(new Set());

  const sortedData = useMemo(() => sortFiles(data), [data]);

  useEffect(() => {
    if (activeNuxAction) return setRelevantData(FILES_NUX_DUMMY_DATA);
    if (!searchStr) return setRelevantData(sortedData);
    const debouncedSearch = async () => {
      const newData = await debouncer.debounce(() => (
        search({
          data: sortedData,
          value: searchStr,
          getField: (file) => file.name,
        })
      ), 250);
      setRelevantData(newData);
    };
    debouncedSearch();

    return debouncer.clear.bind(debouncer);
  }, [searchStr, sortedData, activeNuxAction]);

  useEffect(() => {
    setSearchStr();
  }, [data]);

  useEffect(() => {
    if (projects && projects.length) setProjectMap(getIdMap(projects, 'name'));
  }, [projects]);

  useEffect(() => {
    if (divisions) {
      const mappedDivisionNames = Object.values(divisions).map(({ name = '' }) => name);
      const mappedDivisionFolders = mappedDivisionNames.map((divisionName) => path.join('/files', divisionName));
      const mappedDivisionProjectFolders = mappedDivisionFolders.map((divisionFolder) => path.join(divisionFolder, 'Projects'));

      setDivisionNames(new Set(mappedDivisionNames));
      setDivisionFolders(new Set(mappedDivisionFolders));
      setDivisionProjectsFolders(new Set(mappedDivisionProjectFolders));
    }
  }, [divisions]);

  const formattedData = useMemo(() => (
    relevantData.map((record) => ({
      ...record,
      isLinkedFolder: isLinkedFolder({
        record,
        pathname,
        projectMap,
        divisionNames,
        divisionFolders,
        divisionProjectsFolders,
      }),
    }))
  ), [relevantData, pathname, projectMap, divisionNames, divisionFolders, divisionProjectsFolders]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <FileListHeader
        onSearch={setSearchStr}
        searchVal={searchStr}
        onAddFolder={onAddFolder}
        onAddFile={onAddFile}
        onDelete={onDelete}
        onMove={onMove}
        onCopy={onCopy}
        selected={selectedFolders[1]}
        onBack={onBack}
        hasWritePerms={hasWritePerms}
        promptForPasswordOnDelete={promptForPasswordOnDelete}
        history={history}
        showAddFileButton={showAddFileButton}
      />
      <PaginatedTable
        style={{
          height: '100%',
          zIndex: showNuxStep2 || showNuxStep3 ? 100 : 1,
          position: 'relative',
          pointerEvents: showNuxStep2 || showNuxStep3 ? 'none' : 'auto',
        }}
        dataSource={formattedData}
        columns={getColumns({
          onMore,
          onShare,
          hasWritePerms,
          simple,
          activeNuxAction,
        })}
        size="small"
        rowSelection={hasWritePerms ? {
          columnTitle: (
            activeNuxAction ? (
              <NuxPopover
                text={FILES_PAGE_NUX_STEP_2_SELECT_TEXT}
                visible={showNuxStep2}
                placement="top"
                nextAction={FILES_PAGE_NUX_STEP_3}
              >
                <Checkbox />
              </NuxPopover>
            ) : null
          ),
          fixed: true,
          onChange: onSelect,
          selectedRowKeys: showNuxStep3 ? [FILES_NUX_DUMMY_DATA[0].name] : selectedFolders[0],
          getCheckboxProps: (record) => ({
            disabled: record.isLinkedFolder,
          }),
        } : null}
        rowClassName="file-table-row"
        onRow={(record) => ({
          onClick: () => onClick(record),
        })}
        rowKey="name"
        scrollOffset={200 + FOOTER_HEIGHT + headerOffset}
      />
      <StorageFooter height={FOOTER_HEIGHT} history={history} />
      <NuxFocusBackground show={activeNuxAction} />
    </div>
  );
}

FilesList.propTypes = {
  onClick: PropTypes.func,
  onMore: PropTypes.func,
  onShare: PropTypes.func,
  onAddFolder: PropTypes.func,
  onAddFile: PropTypes.func,
  onDelete: PropTypes.func,
  onMove: PropTypes.func,
  onCopy: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  selectedFolders: PropTypes.arrayOf(PropTypes.array), // eslint-disable-line
  onSelect: PropTypes.func,
  pathname: PropTypes.string,
  onBack: PropTypes.func,
  hasWritePerms: PropTypes.bool,
  simple: PropTypes.bool,
  history: PropTypes.shape({}),
  promptForPasswordOnDelete: PropTypes.bool,
  showAddFileButton: PropTypes.bool,
  headerOffset: PropTypes.number,
};

FilesList.defaultProps = {
  onClick: null,
  onMore: null,
  onShare: null,
  onAddFolder: null,
  onAddFile: null,
  onDelete: null,
  onMove: null,
  onCopy: null,
  data: [],
  selectedFolders: [],
  onSelect: null,
  pathname: '',
  onBack: null,
  hasWritePerms: false,
  simple: false,
  history: {},
  promptForPasswordOnDelete: true,
  showAddFileButton: true,
  headerOffset: 0,
};
