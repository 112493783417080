import React, { useMemo, useState } from 'react';
import { Form, Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Buckets } from 'ontraccr-common';

import OnTraccrTextInput from '../common/inputs/OnTraccrTextInput';
import DisplayText from '../common/text/DisplayText';
import FormTextInput from '../common/inputs/FormTextInput';

import ContactAddress from './ContactAddress';
import ContactEmail from './ContactEmail';
import ContactPhoneNumber from './ContactPhoneNumber';
import LabelSelector from '../labels/LabelSelector';
import { getBucketSelect } from '../buckets/bucketHelpers';
import IntegrationTag from '../common/IntegrationTag';
import DivisionSelector from '../common/inputs/DivisionSelector';
import { getIdMap } from '../helpers/helpers';

const formStyle = { marginBottom: 0, paddingBottom: -8 };
const formLabelStyle = {
  style: {
    paddingBottom: 5,
    marginTop: 10,
  },
};

export default function ContactGeneralInfo({
  form: {
    isAdd,
  } = {},
  id,
  editing,
  name,
  officeNumber,
  mobileNumber,
  faxNumber,
  officeEmail,
  billingEmail,
  website,
  info,
  officeAddress,
  billingAddress,
  isVendor,
  divisionIds = [],
  overrideDivisionIds = null,
  intuitId,
}) {
  const isNotDisplay = (isAdd || editing);
  const buckets = useSelector((state) => state.buckets.buckets);
  const customers = useSelector((state) => state.customers.customers);

  const [selectedDivisions, setSelectedDivisions] = useState(divisionIds);

  const bucketMap = useMemo(() => getIdMap(buckets), [buckets]);

  const customerNameMap = useMemo(() => {
    const cMap = {};
    Object.values(customers)?.forEach((customer) => {
      const lowerName = customer.name?.toLowerCase();
      if (lowerName) cMap[lowerName] = customer.id;
    });
    return cMap;
  }, [customers]);

  const {
    downstreamBucketIds,
  } = useMemo(() => (
    Buckets.getLinkedBuckets(buckets, [id], ['customers'])
  ), [id, buckets]);

  const BucketSelect = useMemo(() => (
    getBucketSelect({
      selectedBuckets: downstreamBucketIds,
      buckets,
      customers,
      bucketMap,
      type: 'customers',
      getDownstream: false,
      isDisplay: !isNotDisplay,
    })
  ), [
    downstreamBucketIds,
    buckets,
    customers,
    isNotDisplay,
  ]);

  return (
    <>
      <Row justify="space-between" align="middle" style={{ width: '100%' }}>
        <Col span={12} style={{ paddingRight: 12 }}>
          <Form.Item
            name="name"
            label="Name"
            style={formStyle}
            labelCol={formLabelStyle}
            rules={[
              {
                required: isNotDisplay,
                message: 'Please enter a name',
              },
              {
                validator: (_, value) => {
                  if (!value) return Promise.resolve();
                  const lowerValue = value.toLowerCase();
                  const nameExists = lowerValue in customerNameMap;
                  if (editing && nameExists && customerNameMap[lowerValue] !== id) {
                    return Promise.reject(new Error('Name already exists'));
                  }
                  if (isAdd && nameExists) {
                    return Promise.reject(new Error('Name already exists'));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            {isNotDisplay ? <OnTraccrTextInput /> : <DisplayText title={name} />}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="divisionIds"
            label="Divisions"
            valuePropName="divisionIds"
            style={formStyle}
            labelCol={formLabelStyle}
            rules={[{ required: isNotDisplay, message: 'Please Select a Division ' }]}
          >
            <DivisionSelector
              mode="multiple"
              displayMode={!isNotDisplay}
              divisions={selectedDivisions}
              onChange={setSelectedDivisions}
              filter={overrideDivisionIds?.length ? new Set(overrideDivisionIds) : undefined}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between" align="middle" style={{ width: '100%' }}>
        <Col span={isNotDisplay ? 24 : 12} style={{ paddingRight: 12 }}>
          <Form.Item
            name="labels"
            key="labels"
            label="Labels"
            labelCol={formLabelStyle}
            style={{
              width: '100%',
              marginBottom: 0,
            }}
          >
            <LabelSelector
              isDisplay={!isNotDisplay}
              type={isVendor ? 'vendors' : 'customers'}
            />
          </Form.Item>
        </Col>
        {!isNotDisplay && (
          <Col span={12}>
            <Form.Item
              label="Integrations"
              style={{ marginBottom: 0 }}
            >
              <IntegrationTag id={intuitId} title="QuickBooks" />
            </Form.Item>
          </Col>
        )}
      </Row>
      {!isVendor && (
        <Form.Item
          name="downstreamBucketIds"
          key="downstreamBucketIds"
          label="Buckets"
          labelCol={formLabelStyle}
          style={{
            width: '100%',
            marginBottom: 0,
          }}
        >
          {BucketSelect}
        </Form.Item>
      )}
      <Row justify="space-between" align="middle" style={{ width: '100%' }}>
        <Col span={8} style={{ paddingRight: 12 }}>
          <ContactPhoneNumber
            type="Office"
            isNotDisplay={isNotDisplay}
            name="officeNumber"
            required={isNotDisplay}
            value={officeNumber}
          />
        </Col>
        <Col span={8} style={{ paddingRight: 6, paddingLeft: 6 }}>
          <ContactPhoneNumber
            type="Mobile"
            isNotDisplay={isNotDisplay}
            name="mobileNumber"
            value={mobileNumber}
          />
        </Col>
        <Col span={8} style={{ paddingLeft: 12 }}>
          <ContactPhoneNumber
            type="Fax"
            isNotDisplay={isNotDisplay}
            name="faxNumber"
            value={faxNumber}
          />
        </Col>
      </Row>
      <Row justify="space-between" align="middle" style={{ width: '100%' }}>
        <Col span={8} style={{ paddingRight: 12 }}>
          <ContactEmail
            type="Office"
            isNotDisplay={isNotDisplay}
            name="officeEmail"
            required={isNotDisplay}
            value={officeEmail}
          />
        </Col>
        <Col span={8} style={{ paddingRight: 6, paddingLeft: 6 }}>
          <ContactEmail
            type="Billing"
            isNotDisplay={isNotDisplay}
            name="billingEmail"
            value={billingEmail}
          />
        </Col>
        <Col span={8} style={{ paddingLeft: 12 }}>
          <FormTextInput
            isNotDisplay={isNotDisplay}
            label="Website"
            name="website"
            value={website}
          />
        </Col>
      </Row>
      <FormTextInput
        isNotDisplay={isNotDisplay}
        label="Info"
        name="info"
        textarea
        autoSize
        value={info}
      />
      <ContactAddress
        isNotDisplay={isNotDisplay}
        officeAddress={officeAddress}
        billingAddress={billingAddress}
      />
    </>
  );
}

/* eslint-disable react/forbid-prop-types */
ContactGeneralInfo.propTypes = {
  intuitId: PropTypes.string,
  divisionIds: PropTypes.array,
  overrideDivisionIds: PropTypes.arrayOf(PropTypes.string),
};

ContactGeneralInfo.defaultProps = {
  intuitId: null,
  divisionIds: [],
  overrideDivisionIds: null,
};
