export const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const DEFAULT_TRIGGER_DAYS = {
  clockIn: [...DAYS],
  clockOut: [...DAYS],
};
export const DEFAULT_REQUIRED_SUBMIT = {
  clockIn: false,
  clockOut: false,
};

export default {};
