import React, {
  useCallback, useEffect, useState, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Divider,
  Drawer,
  Row,
  Col,
  Checkbox,
} from 'antd';
import axios from 'axios';

import DrawerSubmitFooter from '../../common/containers/DrawerSubmitFooter';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import DivisionSelector from '../../common/inputs/DivisionSelector';
import HoverHelp from '../../common/HoverHelp';

import CustomExportTable from '../../reports/Exports/CustomExportsTable';

import ExportsColumnHeader from './ExportsColumnHeader';
import ExportFormsConfig from './ExportFormsConfig';

import {
  CUSTOM_EXPORT_PROP_TYPE,
} from './exports.constants';

import {
  createCustomExport,
  updateCustomExport,
} from '../../reports/state/reports.actions';
import { getTemplates } from '../../forms/state/forms.actions';

const summarizeEmployeesHelp = 'Select this checkbox if you want multiple entries for a user to be combined into one line. Overrides the Summarize Days checkbox';
const summarizeDaysHelp = 'Select this checkbox if you want multiple entries for a user in the same day to be combined into one line. Requires a Date column';
const summarizeProjectHelp = 'Select this checkbox if you want the summarized data to be broken down by project.';

function Exports({
  selectedExport,
  visible,
  onClose,
  type,
}) {
  const isTimeTracking = type === 'timeEntries';
  const isForms = type === 'forms';

  const dispatch = useDispatch();

  const formTemplates = useSelector((state) => state.forms.templates);

  const [columns, setColumns] = useState(selectedExport?.columns ?? []);
  const [title, setTitle] = useState(selectedExport?.title ?? null);
  const [approvedOnly, setApprovedOnly] = useState(!!selectedExport?.approvedOnly);
  const [
    summarizeEmployees,
    setSummarizeEmployees,
  ] = useState(!!selectedExport?.summarizeEmployees);
  const [summarizeDays, setSummarizeDays] = useState(!!selectedExport?.summarizeDays);
  const [
    generateBatchNumber,
    setGenerateBatchNumber,
  ] = useState(!!selectedExport?.generateBatchNumber);
  const [
    addLineNumbers,
    setAddLineNumbers,
  ] = useState(!!selectedExport?.addLineNumbers);
  const [
    generateFormNumber,
    setGenerateFormNumber,
  ] = useState(!!selectedExport?.generateFormNumber);
  const [
    summarizeProject,
    setSummarizeProject,
  ] = useState(!!selectedExport?.summarizeProject);
  const [
    formTemplateId,
    setFormTemplateId,
  ] = useState(selectedExport?.formTemplateId);
  const [divisionId, setDivisionId] = useState(selectedExport?.divisionId ?? null);
  const [hasEclipse, setHasEclipse] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(async () => {
    if (!columns || columns.length === 0) {
      onClose();
      return;
    }
    setLoading(true);
    const payload = {
      title,
      columns,
      approvedOnly,
      summarizeEmployees,
      summarizeDays,
      summarizeProject,
      generateBatchNumber,
      addLineNumbers,
      generateFormNumber,
      divisionId,
    };
    if (!selectedExport) {
      payload.type = type;
      payload.formTemplateId = formTemplateId;
    }
    const action = selectedExport
      ? updateCustomExport(selectedExport.id, payload)
      : createCustomExport(payload);
    if (await dispatch(action)) {
      onClose();
    }
    setLoading(false);
  }, [
    dispatch,
    selectedExport,
    columns,
    title,
    approvedOnly,
    summarizeEmployees,
    summarizeDays,
    summarizeProject,
    generateBatchNumber,
    addLineNumbers,
    generateFormNumber,
    formTemplateId,
    divisionId,
    onClose,
    type,
  ]);

  const onTitleChanged = useCallback((e) => {
    const { target: { value } = {} } = e;
    setTitle(value);
  }, []);

  const onApprovedChanged = useCallback((e) => {
    const { target: { checked } = {} } = e;
    setApprovedOnly(checked);
  }, []);

  const onSummarizeProjectChanged = useCallback((e) => {
    const { target: { checked } = {} } = e;
    setSummarizeProject(checked);
  }, []);

  const onGenerateBatchNumberChanged = useCallback((e) => {
    const { target: { checked } = {} } = e;
    if (!checked) setGenerateFormNumber(false);
    setGenerateBatchNumber(checked);
  }, []);

  const onAddLineNumbersChanged = useCallback((e) => {
    const { target: { checked } = {} } = e;
    if (!checked) setGenerateFormNumber(false);
    setAddLineNumbers(checked);
  }, []);

  const onGenerateFormNumberChanged = useCallback((e) => {
    const { target: { checked } = {} } = e;
    setGenerateFormNumber(checked);
  }, []);

  const onSummarizeEmployeesChanged = useCallback((e) => {
    const { target: { checked } = {} } = e;
    setSummarizeEmployees(checked);
    if (checked) {
      setSummarizeDays(false);
    }
  }, []);

  const onSummarizeDaysChanged = useCallback((e) => {
    const { target: { checked } = {} } = e;
    setSummarizeDays(checked);
    if (checked) {
      setSummarizeEmployees(false);
      setColumns(
        columns.filter((col) => !(
          col.field === 'type'
          || col.field === 'state'
          || (col?.field?.startsWith?.('field-'))
        )),
      );
    } else {
      setSummarizeProject(false);
    }
  }, [columns]);

  const onDivisionChanged = useCallback((newDivisionId) => {
    if (newDivisionId === divisionId) return;
    setDivisionId(newDivisionId);
    setColumns(columns.filter((col) => !col?.field?.startsWith?.('field')));
    setFormTemplateId();
  }, [divisionId, columns]);

  const previewConfig = useMemo(() => {
    const validCols = columns.filter((col) => col.title && col.field);
    if (validCols.length === 0) return null;
    // Dont add batch number so we dont consume a number if they export here
    return {
      id: 'preview',
      title: title && title.length > 0 ? title : 'Export Preview',
      columns: validCols,
      approvedOnly,
      summarizeEmployees,
      summarizeDays,
      summarizeProject,
      divisionId,
      addLineNumbers,
      type,
      formTemplateId,
    };
  }, [
    type,
    title,
    columns,
    divisionId,
    approvedOnly,
    summarizeEmployees,
    summarizeDays,
    summarizeProject,
    addLineNumbers,
    formTemplateId,
  ]);

  const shouldAddNumber = useMemo(() => {
    if (!isForms) return false;

    const {
      [formTemplateId]: {
        jsonFormData = '',
      } = {},
    } = formTemplates;

    let formData = {};

    try {
      formData = JSON.parse(jsonFormData);
    } catch (err) {
      formData = {};
    }

    const {
      collected: {
        number: {
          collect = false,
        } = {},
      } = {},
    } = formData;

    return collect;
  }, [formTemplateId, formTemplates, isForms]);

  useEffect(() => {
    if (selectedExport) {
      setTitle(selectedExport.title);
      setColumns(selectedExport.columns ?? []);
      setApprovedOnly(!!selectedExport.approvedOnly);
      setSummarizeEmployees(!!selectedExport.summarizeEmployees);
      setSummarizeDays(!!selectedExport.summarizeDays);
      setSummarizeProject(!!selectedExport.summarizeProject);
      setGenerateBatchNumber(!!selectedExport.generateBatchNumber);
      setAddLineNumbers(!!selectedExport.addLineNumbers);
      setGenerateFormNumber(!!selectedExport.generateFormNumber);
      setDivisionId(selectedExport.divisionId);
      setFormTemplateId(selectedExport.formTemplateId);
    }
  }, [selectedExport]);

  useEffect(() => {
    if (!visible) {
      setColumns([]);
      setTitle();
      setApprovedOnly(false);
      setSummarizeEmployees(false);
      setSummarizeDays(false);
      setSummarizeProject(false);
      setFormTemplateId();
      setGenerateBatchNumber(false);
      setAddLineNumbers(false);
      setGenerateFormNumber(false);
      setDivisionId();
      setHasEclipse(false);
    }
  }, [visible]);

  useEffect(() => {
    const loadHasEclipse = async () => {
      try {
        const { data: syncTime } = await axios.get('/eclipse/sync/time');
        setHasEclipse(!!syncTime);
      } catch (_err) {
        setHasEclipse(false);
      }
    };
    if (visible && isForms) {
      if (isForms) {
        dispatch(getTemplates());
      } else {
        loadHasEclipse();
      }
    }
  }, [visible, isForms]);

  const summarizeEnabled = useMemo(() => (
    columns.some((col) => col.field === 'date')
  ), [columns]);

  return (
    <Drawer
      title="Add Export"
      onClose={onClose}
      visible={visible}
      width="90%"
    >
      <Row align="middle" gutter={10}>
        <Col span={12}>
          <OnTraccrTextInput
            placeholder="Enter Export Title"
            onChange={onTitleChanged}
            value={title}
          />
        </Col>
        <Col>
          <DivisionSelector
            divisionId={divisionId}
            onChange={onDivisionChanged}
            allowClear={false}
            style={{ width: 250 }}
            disabled={isForms && selectedExport?.id}
          />
        </Col>
      </Row>
      {
        isTimeTracking
        && (
          <Row align="middle" gutter={10} style={{ marginTop: 10, marginBottom: 10 }}>
            <Col>
              <Checkbox
                checked={approvedOnly}
                onChange={onApprovedChanged}
              >
                Approved Hours Only?
              </Checkbox>
            </Col>
            <Col style={{ paddingLeft: 30 }}>
              <Checkbox
                checked={summarizeEmployees}
                onChange={onSummarizeEmployeesChanged}
              >
                Summarize by Employee?
              </Checkbox>
            </Col>
            <Col>
              <HoverHelp content={summarizeEmployeesHelp} placement="bottomRight" />
            </Col>
            <Col style={{ paddingLeft: 30 }}>
              <Checkbox
                checked={summarizeDays && summarizeEnabled && !summarizeEmployees}
                onChange={onSummarizeDaysChanged}
                disabled={!summarizeEnabled || summarizeEmployees}
              >
                Summarize Days?
              </Checkbox>
            </Col>
            <Col>
              <HoverHelp content={summarizeDaysHelp} placement="bottomRight" />
            </Col>
            {summarizeDays && (
              <>
                <Col style={{ paddingLeft: 30 }}>
                  <Checkbox
                    checked={summarizeProject && summarizeDays && summarizeEnabled}
                    onChange={onSummarizeProjectChanged}
                    disabled={!summarizeEnabled}
                  >
                    Summarize by Project?
                  </Checkbox>
                </Col>
                <Col>
                  <HoverHelp content={summarizeProjectHelp} placement="bottomRight" />
                </Col>
              </>
            )}
          </Row>
        )
      }
      {
        isForms
        && (
          <ExportFormsConfig
            canChangeTemplate={!selectedExport?.id}
            divisionId={divisionId}
            formTemplateId={formTemplateId}
            onFormTemplateSelected={setFormTemplateId}
          />
        )
      }
      <Row align="middle" gutter={10} style={{ marginTop: 10, marginBottom: 20 }}>
        <Col>
          <Checkbox
            checked={generateBatchNumber}
            onChange={onGenerateBatchNumberChanged}
          >
            Generate Batch Number
          </Checkbox>
        </Col>
        <Col style={{ paddingLeft: 30 }}>
          <Checkbox
            checked={addLineNumbers}
            onChange={onAddLineNumbersChanged}
          >
            Add Line Numbers
          </Checkbox>
        </Col>
        {
          generateBatchNumber && addLineNumbers && isForms && shouldAddNumber && (
            <Checkbox
              checked={generateFormNumber}
              onChange={onGenerateFormNumberChanged}
            >
              Auto-Generate Form Numbers
              <HoverHelp
                content={(
                  <div style={{ width: 300 }}>
                    Enable this to set all exported form&apos;s numbers
                    to <code>[Batch Number]-[Line Number]</code>.
                  </div>
                )}
                iconProps={{
                  style: {
                    paddingLeft: '0.5em',
                  },
                }}
              />
            </Checkbox>
          )
        }
      </Row>
      <ExportsColumnHeader
        columns={columns}
        onColumnsChanged={setColumns}
        divisionId={divisionId}
        hasEclipse={hasEclipse}
        type={type}
        formTemplateId={formTemplateId}
      />
      <Divider />
      {previewConfig
        && (
          <CustomExportTable
            selectedExport={previewConfig}
            scroll="calc(100vh - 550px)"
          />
        )}
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmit}
        canSubmit={!!title && !!divisionId && columns.length > 0}
        loading={loading}
      />
    </Drawer>
  );
}

Exports.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedExport: CUSTOM_EXPORT_PROP_TYPE,
  type: PropTypes.string.isRequired,
};

Exports.defaultProps = {
  selectedExport: null,
};

export default Exports;
