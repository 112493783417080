import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

import {
  DatePicker, Input, InputNumber, Select, Table,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import moment from 'moment';
import { useSelector } from 'react-redux';
import BorderlessButton from '../../common/buttons/BorderlessButton';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import FormColorPicker from '../../common/inputs/FormColorPicker';

import { stripText } from '../../common/excel/excelHelpers';
import {
  parseColor, parseDate, parseCustomData, parseNumber,
  getDataTreeMap,
  getTeamTreeMap,
} from './ShiftUpload.helpers';
import { getIdMap } from '../../helpers/helpers';
import { formatProjectLabelFromCompanySettings } from '../../projects/projectHelpers';
import config from '../../config';

const DEFAULT_COLUMN_WIDTH = 250;

function UploadPreview({
  selectedSheet,
  headerMapping,
  onMassUploadDataChanged,
  defaultHeaders,
  defaultDivisionId,
}) {
  const divisions = useSelector((state) => state.settings.divisions);
  const { settings = {} } = useSelector((state) => state.settings.company);
  const projects = useSelector((state) => state.projects.projects);
  const phases = useSelector((state) => state.costcodes.phases);
  const costcodes = useSelector((state) => state.costcodes.costcodes);
  const teams = useSelector((state) => state.teams.teams);
  const users = useSelector((state) => state.users.users);
  const userDivisions = useSelector((state) => state.users.userDivisions);

  const [data, setData] = useState([]);

  const activeCostcodes = useMemo(() => (
    costcodes.filter((costcode) => costcode.active)
  ), [costcodes]);

  const activeTeams = useMemo(() => (
    teams.filter((team) => team.active && team.members?.length)
  ), [teams]);

  const activeUsers = useMemo(() => (
    users.filter((user) => user.active)
  ), [users]);

  const activeProjects = useMemo(() => (
    projects.filter((project) => project.active)
  ), [projects]);

  const projectIdMap = useMemo(() => getIdMap(activeProjects), [activeProjects]);
  const phaseIdMap = useMemo(() => getIdMap(phases), [phases]);
  const costcodeIdMap = useMemo(() => getIdMap(activeCostcodes), [activeCostcodes]);
  const teamIdMap = useMemo(() => getIdMap(activeTeams), [activeTeams]);
  const userIdMap = useMemo(() => getIdMap(activeUsers), [activeUsers]);

  const dataTree = useMemo(() => (
    getDataTreeMap({
      projectIdMap,
      divisions: Object.values(divisions),
      projects: activeProjects,
      phases,
      costcodes: activeCostcodes,
    })
  ), [projectIdMap, phases, activeCostcodes]);

  const teamTree = useMemo(() => (
    getTeamTreeMap({
      divisions: Object.keys(divisions),
      teams: activeTeams,
    })
  ), [activeTeams, divisions]);

  const userOptionTree = useMemo(() => activeUsers.reduce((acc, user) => {
    const divisionIds = userDivisions[user.id] ?? [];
    divisionIds.forEach((divisionId) => {
      if (!acc[divisionId]) {
        acc[divisionId] = [];
      }
      acc[divisionId].push({
        value: user.id,
        label: user.name,
      });
    });
    return acc;
  }, {}), [activeUsers]);

  const getRelevantUserIds = useCallback(({ divisionId, teamIds }) => {
    if (!teamIds || !teamIds.length) {
      return userOptionTree[divisionId]?.map((user) => user.value) ?? [];
    }
    const membersSet = teamIds.reduce((acc, teamId) => {
      const members = teamTree?.[divisionId]?.[teamId] ?? [];
      members.forEach(({ id: userId }) => {
        const user = userIdMap[userId];
        if (!user) return;
        if (!user.active) return;
        if (config.showDivision && user.divisionsIds?.every((div) => div !== divisionId)) return;
        acc.add(userId);
      });
      return acc;
    }, new Set());
    return Array.from(membersSet);
  }, [teamTree, userOptionTree]);

  const divisionOptions = useMemo(() => {
    const options = [];
    Object.values(divisions).forEach((division) => {
      options.push({
        value: division.id,
        label: division.name,
      });
    });
    return options;
  }, [divisions]);

  const getProjectOptions = useCallback(({ divisionId }) => {
    const relevantProjects = Object.keys(dataTree?.[divisionId] ?? {});
    const options = [];
    relevantProjects.forEach((projectId) => {
      if (!projectIdMap[projectId]) return;
      options.push({
        value: projectId,
        label: formatProjectLabelFromCompanySettings({
          name: projectIdMap?.[projectId]?.name,
          number: projectIdMap?.[projectId]?.number,
          settings,
        }),
      });
    });
    return options;
  }, [dataTree, settings, projectIdMap]);

  const getPhaseOptions = useCallback(({ divisionId, projectId }) => {
    const relevantPhases = Object.keys(dataTree?.[divisionId]?.[projectId] ?? {});

    const options = [];

    relevantPhases.forEach((phaseId) => {
      if (!phaseIdMap[phaseId]) return;
      options.push({
        value: phaseId,
        label: phaseIdMap?.[phaseId]?.name || 'Unphased',
      });
    });

    return options;
  }, [dataTree, phaseIdMap]);

  const getCostcodeOptions = useCallback(({ divisionId, projectId, phaseId }) => {
    const relevantCostcodes = dataTree?.[divisionId]?.[projectId]?.[phaseId] ?? [];

    const options = [];

    relevantCostcodes.forEach((costcodeId) => {
      if (!costcodeIdMap[costcodeId]) return;

      options.push({
        value: costcodeId,
        label: costcodeIdMap[costcodeId].name,
      });
    });

    return options;
  }, [dataTree, costcodeIdMap]);

  const getTeamOptions = useCallback(({ divisionId }) => {
    const relevantTeams = Object.keys(teamTree[divisionId] ?? {});

    const options = [];

    relevantTeams.forEach((teamId) => {
      if (!teamIdMap[teamId]) return;

      options.push({
        value: teamId,
        label: teamIdMap[teamId].name,
      });
    });

    return options;
  }, [teamTree, teamIdMap]);

  const getUserOptions = useCallback(({ divisionId, teamIds }) => {
    if (!teamIds?.length) {
      return userOptionTree[divisionId] ?? [];
    }
    const options = [];
    const userIds = getRelevantUserIds({ divisionId, teamIds });
    userIds.forEach((userId) => {
      if (!userIdMap[userId]) return;

      options.push({
        value: userId,
        label: userIdMap[userId].name,
      });
    });

    return options;
  }, [userOptionTree, userIdMap, getRelevantUserIds]);

  const dataMaps = {
    divisions,
    projects: projectIdMap,
    phases: phaseIdMap,
    costcodes: costcodeIdMap,
    teams: teamIdMap,
    users: userIdMap,
  };

  const defaultValues = {
    divisionId: defaultDivisionId,
  };

  const getRelevantIds = useCallback(({
    dataType, divisionId, projectId, phaseId, teamIds,
  }) => {
    switch (dataType) {
      case 'divisions': return Object.keys(dataTree ?? {});
      case 'projects': return Object.keys(dataTree[divisionId] ?? {});
      case 'phases': return Object.keys(dataTree[divisionId]?.[projectId] ?? {});
      case 'costcodes': return dataTree[divisionId]?.[projectId]?.[phaseId] ?? [];
      case 'teams': return Object.keys(teamTree[divisionId] ?? {});
      case 'users': return getRelevantUserIds({ divisionId, teamIds });
      default: return [];
    }
  }, [dataTree, teamTree, userOptionTree, getRelevantUserIds]);

  const getDataNameMap = useCallback(({
    dataType, relevantIds,
  }) => {
    const nameMap = {};
    const dataMap = dataMaps[dataType];
    relevantIds.forEach((id) => {
      const name = stripText(dataMap[id]?.name ?? '');
      if (!name || name === '') return;
      nameMap[name] = id;
    });

    return nameMap;
  }, [dataMaps]);

  const onValueChanged = useCallback((index, newData) => {
    setData(
      data.map((datum, idx) => {
        if (idx !== index) return datum;
        return {
          ...datum,
          ...newData,
        };
      }),
    );
  }, [data]);

  const onDelete = useCallback((id) => {
    setData(data.filter((datum) => datum.id !== id));
  }, [data]);

  const getErrorStyle = (value) => {
    if (value === '') {
      return { color: 'red', borderColor: 'red' };
    }
    return {};
  };

  useEffect(() => {
    const newData = selectedSheet.map((row, idx) => {
      const parsedRow = {};
      defaultHeaders.forEach(({
        key, type, dataType, multiple,
      }) => {
        const header = headerMapping[key];
        const value = row[header];
        const strippedValue = value && typeof (value) === 'string' ? stripText(value) : value;

        const divisionId = parsedRow.divisionId ?? defaultDivisionId;
        const projectId = parsedRow.projectId ?? null;
        const phaseId = parsedRow.phaseId ?? null;
        const teamIds = parsedRow.teams ?? [];

        switch (type) {
          case 'date': {
            parsedRow[key] = parseDate(strippedValue);
            break;
          }
          case 'color': {
            parsedRow[key] = parseColor(strippedValue);
            break;
          }
          case 'select': {
            const relevantIds = getRelevantIds({
              dataType, divisionId, projectId, phaseId, teamIds,
            });
            const nameMap = getDataNameMap({ dataType, relevantIds });
            parsedRow[key] = null;
            const validOptions = new Set(relevantIds);

            if (multiple) {
              parsedRow[key] = strippedValue?.split(',').map((strippedVal) => parseCustomData({
                value: strippedVal,
                nameMap,
                validOptions,
              }))
                .filter((val) => val !== null);
            } else {
              parsedRow[key] = parseCustomData({
                value: strippedValue,
                nameMap,
                defaultValue: defaultValues[key],
                validOptions,
              });
            }
            break;
          }
          case 'text': {
            parsedRow[key] = value ?? '';
            break;
          }
          case 'number': {
            parsedRow[key] = parseNumber(strippedValue);
            break;
          }
          default: {
            parsedRow[key] = strippedValue;
            break;
          }
        }
      });

      parsedRow.id = idx;
      return parsedRow;
    });
    setData(newData);
  }, [
    selectedSheet,
    headerMapping,
    defaultHeaders,
    defaultDivisionId,
    dataTree,
    teamTree,
    userOptionTree,
  ]);

  useEffect(() => {
    onMassUploadDataChanged((prevState) => ({
      ...prevState,
      data,
    }));
  }, [data]);

  const columns = useMemo(() => {
    const relevantCols = [
      {
        title: '',
        dataIndex: '',
        width: 30,
        render: (_, record) => (
          <BorderlessButton
            iconNode={<DeleteOutlined style={{ color: 'red', marginLeft: 0 }} />}
            onClick={() => onDelete(record.id)}
          />
        ),
      }, {
        title: 'Title',
        dataIndex: 'title',
        width: 150,
        render: (value, _, index) => (
          <OnTraccrTextInput
            allowClear={false}
            value={value}
            onChange={(e) => {
              const {
                target: {
                  value: newValue,
                } = {},
              } = e;
              onValueChanged(index, { title: newValue });
            }}
            style={getErrorStyle(value)}
          />
        ),
      }, {
        title: 'Division',
        dataIndex: 'divisionId',
        width: DEFAULT_COLUMN_WIDTH,
        render: (value, _, index) => (
          <Select
            showSearch
            optionFilterProp="label"
            allowClear={false}
            value={value}
            defaultValue={defaultDivisionId}
            onChange={(newValue) => {
              onValueChanged(index, {
                divisionId: newValue,
                projectId: null,
                phaseId: null,
                costcodeId: null,
                teams: [],
                users: [],
              });
            }}
            options={divisionOptions}
            style={{ ...getErrorStyle(value), width: '100%' }}
          />
        ),
      }, {
        title: 'Dates',
        dataIndex: 'dates',
        width: 400,
        render: (_, record, index) => {
          const { startTime, endTime } = record;

          const momentStart = moment(startTime);
          const momentEnd = moment(endTime);
          const value = [momentStart, momentEnd];

          const onChange = ([start, end]) => {
            onValueChanged(index, {
              startTime: start.valueOf(),
              endTime: end.valueOf(),
            });
          };

          return (
            <DatePicker.RangePicker
              value={value}
              onChange={onChange}
              style={{ width: '100%' }}
              showTime
              showSecond={false}
              format="YYYY-MM-DD H:mm a"
              allowClear={false}
            />
          );
        },
      }, {
        title: 'Project',
        dataIndex: 'projectId',
        width: DEFAULT_COLUMN_WIDTH,
        render: (value, record, index) => {
          const options = getProjectOptions({
            divisionId: record.divisionId,
          });
          return (
            <Select
              allowClear
              showSearch
              optionFilterProp="label"
              value={value}
              onChange={(newValue) => {
                onValueChanged(index, {
                  projectId: newValue,
                  phaseId: null,
                  costcodeId: null,
                });
              }}
              options={options}
              style={{ width: '100%' }}
            />
          );
        },
      }, {
        title: 'Phase',
        dataIndex: 'phaseId',
        width: DEFAULT_COLUMN_WIDTH,
        render: (value, record, index) => {
          const options = getPhaseOptions({
            divisionId: record.divisionId,
            projectId: record.projectId,
          });
          return (
            <Select
              allowClear
              showSearch
              optionFilterProp="label"
              value={value}
              onChange={(newValue) => {
                onValueChanged(index, {
                  phaseId: newValue,
                  costcodeId: null,
                });
              }}
              options={options}
              style={{ width: '100%' }}
            />
          );
        },
      }, {
        title: 'Costcode',
        dataIndex: 'costcodeId',
        width: DEFAULT_COLUMN_WIDTH,
        render: (value, record, index) => {
          const options = getCostcodeOptions({
            divisionId: record.divisionId,
            projectId: record.projectId,
            phaseId: record.phaseId,
          });
          return (
            <Select
              allowClear
              showSearch
              optionFilterProp="label"
              value={value}
              onChange={(newValue) => {
                onValueChanged(index, { costcodeId: newValue });
              }}
              options={options}
              style={{ width: '100%' }}
            />
          );
        },
      }, {
        title: 'Teams',
        dataIndex: 'teams',
        width: DEFAULT_COLUMN_WIDTH,
        render: (value, record, index) => {
          const options = getTeamOptions({
            divisionId: record.divisionId,
          });
          return (
            <Select
              allowClear
              showSearch
              optionFilterProp="label"
              value={value}
              onChange={(newValue) => {
                onValueChanged(index, {
                  teams: newValue,
                  users: [],
                });
              }}
              mode="multiple"
              options={options}
              style={{ width: '100%' }}
            />
          );
        },
      }, {
        title: 'Color',
        dataIndex: 'color',
        width: 150,
        render: (value, _, index) => (
          <FormColorPicker
            style={{ ...getErrorStyle(value), width: '100%' }}
            isNotDisplay
            value={value}
            onChange={(newValue) => onValueChanged(index, { color: newValue })}
          />
        ),
      }, {
        title: 'Users',
        dataIndex: 'users',
        width: DEFAULT_COLUMN_WIDTH,
        render: (value, record, index) => {
          const options = getUserOptions({
            divisionId: record.divisionId,
            teamIds: record.teams,
          });
          return (
            <Select
              allowClear
              showSearch
              optionFilterProp="label"
              mode="multiple"
              value={value}
              onChange={(newValue) => {
                onValueChanged(index, { users: newValue });
              }}
              options={options}
              style={{ width: '100%' }}
            />
          );
        },
      }, {
        title: 'Description',
        dataIndex: 'description',
        width: 200,
        render: (value, _, index) => (
          <Input.TextArea
            value={value}
            onChange={(e) => {
              const {
                target: {
                  value: newValue,
                } = {},
              } = e;
              onValueChanged(index, { description: newValue });
            }}
          />
        ),
      },
      {
        title: 'Expected Users',
        dataIndex: 'expectedNumberOfUsers',
        width: 200,
        render: (value, _, index) => {
          const onChange = (val) => {
            const newValue = Math.floor(val);
            onValueChanged(index, { expectedNumberOfUsers: newValue });
          };
          return (
            <InputNumber
              value={value}
              onChange={onChange}
              min={0}
              step={1}
            />
          );
        },
      },
    ];

    return relevantCols;
  }, [
    onDelete,
    onValueChanged,
    divisionOptions,
    getProjectOptions,
    getPhaseOptions,
    getCostcodeOptions,
    getTeamOptions,
    getUserOptions,
    defaultDivisionId,
  ]);

  return (
    <Table
      size="small"
      columns={columns}
      dataSource={data}
      scroll={{ x: 'max-content' }}
    />
  );
}

export default UploadPreview;

/* eslint-disable react/forbid-prop-types */
UploadPreview.propTypes = {
  selectedSheet: PropTypes.array,
  headerMapping: PropTypes.object,
  onMassUploadDataChanged: PropTypes.func.isRequired,
  defaultHeaders: PropTypes.arrayOf(PropTypes.shape({})),
  defaultDivisionId: PropTypes.string.isRequired,
};

UploadPreview.defaultProps = {
  selectedSheet: [],
  headerMapping: {},
  defaultHeaders: [],
};
