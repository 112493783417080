// eslint-disable-next-line import/prefer-default-export
export const decorateEquipment = (equipment = {}) => {
  const newEquipment = { ...equipment };

  const hours = newEquipment.hours ?? 0;
  const hourlyCost = newEquipment.hourlyCost ?? 0;
  const hourlyBillingRate = newEquipment.hourlyBillingRate ?? 0;

  newEquipment.totalHourlyCost = hourlyCost * hours;
  newEquipment.totalHourlyBilling = hourlyBillingRate * hours;
  return newEquipment;
};