/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Droppable } from 'react-beautiful-dnd';

import { PropTypes } from 'prop-types';

import BorderlessButton from '../../common/buttons/BorderlessButton';

import BoardListCard from './BoardListCard';

import {
  getTemplateMap,
} from '../boards.helpers';

import { openCardDrawer, openFormMapDrawer } from '../state/boards.actions';

const getKey = (statusId) => `board-status-open-state-${statusId}`;
// Local Storage saves as string
const getLocalStorageOpen = (statusId) => window.localStorage.getItem(getKey(statusId)) === 'true';
export default function BoardListSection({
  cardTemplate,
  status: {
    id: statusId,
    title,
    displayFields = [],
  } = {},
  cards = [],
  style = {},
  isLocked,
  showCardNumber,
  showListIndex,
  userBoardPermissions,
  projectIdMap,
  onBoardSelected,
}) {
  const droppableId = statusId ? statusId.toString() : 'null';
  const {
    canCreate,
  } = userBoardPermissions;

  const dispatch = useDispatch();

  const {
    statusId: targetStatusId,
  } = useSelector((state) => state.boards.targetCard);

  const [open, setOpen] = useState(getLocalStorageOpen(statusId));
  const [showColBorder, setShowColBorder] = useState(false);

  const fullTitle = `${title} ${cards.length}`;

  const saveOpenState = useCallback((openState) => {
    window.localStorage.setItem(getKey(statusId), openState);
    setOpen(openState);
  }, [statusId]);

  useEffect(() => {
    if (targetStatusId && statusId === targetStatusId) saveOpenState(true);
  }, [targetStatusId, statusId, saveOpenState]);

  useEffect(() => {
    setOpen(getLocalStorageOpen(statusId));
  }, [statusId]);

  const onForm = useCallback((cardId) => {
    const newSelectedCard = cards.find((card) => card.id === cardId);
    if (!newSelectedCard) return;
    dispatch(openFormMapDrawer({
      selectedCardId: cardId,
    }));
  }, [dispatch, cards]);

  const onCardClick = useCallback((card) => () => {
    dispatch(openCardDrawer({
      selectedCardId: card.id,
      onForm,
    }));
  }, [dispatch, onForm]);

  const onFormClicked = useCallback((card) => (e) => {
    e.stopPropagation();
    dispatch(openFormMapDrawer({
      selectedCardId: card.id,
      onForm,
    }));
  }, [dispatch, onForm]);

  const onAddClicked = useCallback(() => {
    dispatch(openCardDrawer({
      statusId,
    }));
  }, [dispatch, statusId]);

  const onEditClicked = useCallback((card) => (e) => {
    e.stopPropagation();
    dispatch(openCardDrawer({
      selectedCardId: card.id,
      editMode: true,
      isLocked,
    }));
  }, [dispatch, isLocked]);

  const onOpenToggle = useCallback(() => {
    saveOpenState(!open);
  }, [open, saveOpenState]);

  const onScroll = useCallback((e) => {
    const {
      target: {
        scrollLeft = 0,
      } = {},
    } = e;
    setShowColBorder(scrollLeft > 10);
  }, []);

  const templateMap = useMemo(() => getTemplateMap(cardTemplate), [cardTemplate]);
  const collapsedMap = useMemo(() => {
    const cMap = {};
    Object.values(templateMap).forEach((sectionMap) => {
      Object.keys(sectionMap).forEach((fieldId) => {
        cMap[fieldId] = sectionMap[fieldId];
      });
    });
    return cMap;
  }, [templateMap]);
  const headers = useMemo(() => {
    const titles = [];
    if (!displayFields) return titles;
    displayFields.forEach((fieldId) => {
      const {
        [fieldId]: {
          configProps: { title: fieldTitle } = {},
        } = {},
      } = collapsedMap;
      if (fieldTitle) {
        titles.push(fieldTitle);
      }
    });
    return titles;
  }, [fullTitle, displayFields, collapsedMap]);

  const filteredDisplayFields = useMemo(() => {
    // Filter out display fields that no longer exist in card template
    if (!displayFields || !cardTemplate) return [];
    const { fields: sections = [] } = cardTemplate;
    const allFieldIds = new Set();
    sections.forEach((section) => {
      const { fields = [] } = section;
      fields.forEach((field) => {
        allFieldIds.add(field.id);
      });
    });
    return displayFields.filter((fieldId) => allFieldIds.has(fieldId));
  }, [displayFields, cardTemplate]);

  const displayMap = useMemo(() => {
    const dMap = {};
    if (filteredDisplayFields.length === 0) return dMap;
    filteredDisplayFields.forEach((fieldId, displayIdx) => {
      dMap[fieldId] = displayIdx;
    });
    return dMap;
  }, [filteredDisplayFields]);

  const {
    cards: fullCards,
    shadows,
  } = useMemo(() => (
    cards.reduce((acc, card, idx) => {
      const comp = (
        <BoardListCard
          key={card.id}
          idx={idx}
          rowProps={idx < cards.length - 1 ? { className: 'board-list-row' } : {}}
          card={card}
          templateMap={templateMap}
          displayFields={displayFields}
          displayMap={displayMap}
          onCardClick={onCardClick}
          onEditClicked={onEditClicked}
          onFormClicked={onFormClicked}
          isLocked={isLocked}
          showCardNumber={showCardNumber}
          showListIndex={showListIndex}
          userBoardPermissions={userBoardPermissions}
          projectIdMap={projectIdMap}
          onBoardSelected={onBoardSelected}
        />
      );
      if (card.isShadow) {
        acc.shadows.push(comp);
      } else {
        acc.cards.push(comp);
      }
      return acc;
    }, { cards: [], shadows: [] })
  ), [
    cards,
    templateMap,
    displayFields,
    displayMap,
    isLocked,
    onCardClick,
    onEditClicked,
    userBoardPermissions,
    projectIdMap,
    onBoardSelected,
  ]);

  const cardCount = open ? cards.length : 0;

  const addButton = (
    <BorderlessButton
      iconNode={<PlusOutlined />}
      onClick={onAddClicked}
      style={{
        width: 26, backgroundColor: 'transparent', height: 26, fontSize: 18, padding: 0,
      }}
    />
  );

  return (
    <div style={{ position: 'relative', ...style }}>
      <div className="board-list-view-status-section" onScroll={onScroll}>
        <table className="board-list-table">
          <thead>
            <tr
              className="boards-column-header"
              style={cardCount === 0 ? { borderBottom: 'none' } : {}}
            >
              <th
                className="board-list-title-column-header"
                style={{ width: 300, paddingLeft: 5 }}
              >
                <Row align="middle" gutter={5}>
                  <Col>
                    <button
                      style={{
                        background: 'none',
                        color: 'inherit',
                        border: 'none',
                        padding: 0,
                        font: 'inherit',
                        outline: 'inherit',
                        cursor: 'pointer',
                        transform: open ? 'rotate(0deg)' : 'rotate(-90deg)',
                      }}
                      onClick={onOpenToggle}
                      aria-label="expand"
                      type="button"
                    >
                      <DownOutlined />
                    </button>
                  </Col>
                  <Col>
                    {fullTitle}
                  </Col>
                  {
                    canCreate
                      && (
                      <Col>
                        {addButton}
                      </Col>
                      )
                  }
                </Row>
              </th>
              {headers.map((h, i) => <th key={i} className="board-list-header">{h}</th>)}
            </tr>
          </thead>
          <Droppable droppableId={droppableId} type="CARDS" direction="vertical">
            {({ droppableProps, innerRef, placeholder }) => (
              <tbody
                {...droppableProps}
                ref={innerRef}
                style={
                  fullCards.length === 0 || !open
                    ? { height: 1 }
                    : {}
                }
              >
                {open ? fullCards : []}
                {placeholder}
              </tbody>
            )}
          </Droppable>
          <tfoot>
            {open ? shadows : []}
          </tfoot>
        </table>
      </div>
      {
        showColBorder
        && <div className="board-list-title-column-border" />
      }
    </div>
  );
}

BoardListSection.propTypes = {
  isLocked: PropTypes.number,
  style: PropTypes.shape({
    width: PropTypes.string,
  }),
  cards: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    orderIndex: PropTypes.number,
    onClick: PropTypes.func,
    onEdit: PropTypes.func,
    onForm: PropTypes.func,
    isLocked: PropTypes.number,
    color: PropTypes.string,
    users: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      avatar: PropTypes.string,
    })),
    draggable: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      type: PropTypes.string,
      value: PropTypes.string,
    })),
  })),
  displayFields: PropTypes.arrayOf(PropTypes.string),
  cardTemplate: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    sections: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      fields: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        type: PropTypes.string,
        value: PropTypes.string,
      })),
    })),
    fields: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  status: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    displayFields: PropTypes.arrayOf(PropTypes.string),
  }),
  userBoardPermissions: PropTypes.shape({
    canCreate: PropTypes.bool,
  }),
  showCardNumber: PropTypes.bool,
  showListIndex: PropTypes.bool,
  projectIdMap: PropTypes.shape({}),
  onBoardSelected: PropTypes.func,
};

BoardListSection.defaultProps = {
  isLocked: 0,
  style: {},
  cards: [],
  displayFields: [],
  cardTemplate: {},
  userBoardPermissions: {
    canCreate: false,
  },
  showCardNumber: false,
  showListIndex: false,
  status: {},
  projectIdMap: {},
  onBoardSelected: undefined,
};
