import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List, Row, Col, Spin } from 'antd';

import FullPhoto from '../../common/photos/FullPhoto';

import BoardCardEmailThreadDrawer from './BoardCardEmailThreadDrawer';
import BoardCardEmailThread from './BoardCardEmailThread';

import { getCardEmailFile, getCardEmails } from '../state/boards.actions';

import { downloadFile, getFileType } from '../../files/fileHelpers';

export default ({
  visible,
  id
}) => {
  const dispatch = useDispatch();

  const cardEmailFiles = useSelector(state => state.boards.cardEmailFiles);
  const cardEmailMap = useSelector((state) => state.boards.cardEmailMap);

  const [selectedFileId, setSelectedFileId] = useState();
  const [selectedThread, setSelectedThread] = useState();
  const [loading, setLoading] = useState(false);

  const cardEmails = useMemo(() => {
    const { [id]: emails = [] } = cardEmailMap;
    return emails;
  }, [cardEmailMap, id]);

  const selectedFile = useMemo(() => {
    const {
      [selectedFileId]: { jsFileObject } = {},
    } = cardEmailFiles;
    return jsFileObject;
  },[cardEmailFiles, selectedFileId]);

  const sortedEmails = useMemo(() => {
    const sorted = [...cardEmails];
    sorted.sort((a,b) => b.timestamp - a.timestamp);
    return sorted;
  },[cardEmails]);
  

  const onFileClick = useCallback((thread) => async (file) => {
    const { attachmentId } = file;
    const { id: threadId } = thread;
    if (!(attachmentId in cardEmailFiles)) {
      await dispatch(getCardEmailFile({ file, threadId }))
    }
    setSelectedFileId(attachmentId);
  },[dispatch, cardEmailFiles, selectedThread]);

  const onThreadSelect = useCallback((thread) => () => {
    setSelectedThread(thread);
  },[]);

  const closeSelectedThread = useCallback(() => {
    setSelectedThread(false);
  },[]);

  const onDownload = useCallback(() => {
    downloadFile({ fileObject: selectedFile });
  },[selectedFile]);

  const onClose = useCallback(() => setSelectedFileId(),[]);

  useEffect(() => {
    const loadEmails = async () => {
      setLoading(true);
      await dispatch(getCardEmails(id));
      setLoading(false);
    }
    if (!visible) {
      setSelectedFileId();
    } else if (!(id in cardEmailMap)) {
      loadEmails();
    }
  },[visible, id, cardEmailMap]);

  const type = useMemo(() => selectedFile ? getFileType(selectedFile) : null,[selectedFile]);

  return (
    <>
      {
        loading
         ? (
          <Row style={{ height: '100%', width: '100%' }} justify="center" align="middle">
            <Col>
              <Spin />
            </Col>
          </Row>
         )
         : (
          <List
            className='board-card-emails'
            dataSource={sortedEmails}
            renderItem={(item) => (
              <BoardCardEmailThread
                {...item}
                onFileClick={onFileClick(item)}
                onClick={onThreadSelect(item)}
              />
            )}
          />
         )
      }
      <FullPhoto
        onDownload={onDownload}
        file={selectedFile}
        onClose={onClose}
        type={type}
      />
      <BoardCardEmailThreadDrawer
        {...selectedThread}
        visible={!!selectedThread}
        onClose={closeSelectedThread}
      />
    </>
  )
}