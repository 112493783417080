import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Select } from 'antd';

import { formatProjectLabelFromCompanySettings } from '../projects/projectHelpers';

const getOption = ({ id, name }) => ({ value: id, label: name });

export default function BoardDropdownFieldSelector({
  dataType,
  divisionIds,
  users,
  value = [],
  onChange,
}) {
  const projects = useSelector((state) => state.projects.projects);
  const customers = useSelector((state) => state.customers.customers);
  const equipment = useSelector((state) => state.equipment.equipment);
  const vendors = useSelector((state) => state.vendors.vendors);
  const divisions = useSelector((state) => state.settings.divisions);
  const { settings = {} } = useSelector((state) => state.settings.company);

  const data = useMemo(() => {
    const divisionSet = new Set(divisionIds);
    const filterByDivisionIds = ({ divisionIds: divIds, active = true }) => (
      divIds?.some((divisionId) => divisionSet.has(divisionId) && active)
    );

    switch (dataType) {
      case 'Customers':
        return Object.values(customers)
          .filter(filterByDivisionIds)
          .map(getOption);
      case 'Projects':
        return projects
          .filter(({ divisionId, active }) => divisionSet.has(divisionId) && active)
          .map((project) => ({
            value: project.id,
            label: formatProjectLabelFromCompanySettings({
              name: project.name,
              number: project.number,
              settings,
            }),
          }));
      case 'Users': {
        return users;
      }
      case 'Equipment':
        return equipment
          .filter(filterByDivisionIds).map(getOption);
      case 'Vendors':
        return Object.values(vendors)
          .filter(filterByDivisionIds)
          .map(getOption);
      default:
        return [];
    }
  }, [
    dataType,
    divisionIds,
    projects,
    customers,
    users,
    equipment,
    vendors,
    divisions,
    settings,
  ]);

  return (
    <Select
      allowClear
      options={data}
      placeholder={`Select ${dataType}`}
      optionFilterProp="label"
      showSearch
      onChange={onChange}
      value={value}
      mode="multiple"
    />
  );
}

BoardDropdownFieldSelector.propTypes = {
  dataType: PropTypes.string,
  divisionIds: PropTypes.arrayOf(PropTypes.string),
  users: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

BoardDropdownFieldSelector.defaultProps = {
  dataType: '',
  divisionIds: [],
  users: [],
  value: [],
};
