import { DateTime } from 'luxon';

import {
  GET_COMPANY,
  GET_COMPANY_IMAGE,
  SAVE_COMPANY_IMAGE,
  GET_COMPANY_SETTINGS,
  SAVE_COMPANY_SETTINGS,
  UPDATE_COMPANY_OWNER,
  GET_COMPANY_POSITIONS,
  GET_COMPANY_AC_POSITIONS,
  RESET_PERMISSIONS,
  TOGGLE_PERMISSIONS,
  TOGGLE_AC_PERMISSIONS,
  CREATE_POSITION,
  RENAME_POSITION,
  DELETE_POSITION,
  VIEW_BILLING,
  UPDATE_USER_LIMIT,
  CONNECT_QUICKBOOKS,
  DISCONNECT_QUICKBOOKS,
  IMPORT_QUICKBOOKS_DATA,
  CONNECT_PROCORE,
  DISCONNECT_PROCORE,
  GET_PROCORE_COMPANIES,
  GET_PROCORE_GLOBAL_CODES_LIST,
  PROCORE_IMPORT,
  IMPORT_INTEGRATION_DATA,
  CHECK_INTEGRATION_CONNECTION_STATUS,
  DECREASE_COMPANY_STORAGE_USAGE,
  INCREASE_COMPANY_STORAGE_USAGE,
  SET_COMPANY_STORAGE_USAGE,
  SET_SELECTED_DIVISIONS,
  GET_DIVISIONS,
  CREATE_DIVISIONS,
  DELETE_DIVISIONS,
  CREATE_USER,
  UPDATE_USER_BY_ID,
  TRANSFER_DIVISIONS,
  RENAME_DIVISION,
  USER_LOGOUT,
  GET_SAGE_DATA,
  CONNECT_MICROSOFT365,
  DEACTIVATE_INTEGRATION,
  GET_INTEGRATED_EMAILS,
  GET_COMPANY_CUSTOMIZATION_TABS,
  UPDATE_COMPANY_CUSTOMIZATION_TABS,
  GET_QUICKBOOKS_INTEGRATIONS,
  UPDATE_QUICKBOOKS_INTEGRATION,
  GET_COMPANY_TRANSLATIONS,
  UPDATE_COMPANY_TRANSLATIONS,
  REORDER_AC_POSITIONS,
  GET_WORKING_HOURS,
  UPDATE_POSITION_WORKING_HOURS,
  GET_SAGE_TIME_SYNC,
  WRITE_SAGE_TIME,
  RESOLVE_SAGE_TIME,
  GET_COMPANY_ROUNDING_POLICIES,
  CREATE_COMPANY_ROUNDING_POLICY,
  UPDATE_COMPANY_ROUNDING_POLICY,
  DELETE_COMPANY_ROUNDING_POLICY,
  UPDATE_DIVISION_CODE,
  CONNECT_DOCUSIGN,
  DISCONNECT_DOCUSIGN,
  GET_SAGE_JOB_MAPPINGS,
  UPDATE_SAGE_JOB_MAPPINGS,
  UPDATE_SCHEDULE_RESTRICTION,
  GET_SCHEDULE_RESTRICTION,
} from '../../state/actionTypes';

import Permissions from '../../auth/Permissions';

let initialDivisions;
try {
  const selectedDivisions = window.localStorage.getItem('selectedDivisions');
  initialDivisions = new Set(JSON.parse(selectedDivisions));
} catch (err) {
  //
}

const initialState = {
  divisions: {},
  selectedDivisions: initialDivisions,
  billingRates: {},
  integratedEmails: [],
  tabs: [],
  company: {},
  quickbookIntegrations: [],
  translations: [],
  workingHours: {},
  sageTime: {},
  sageJobTypeMappings: {},
  roundingPolicies: [],
  roundingPolicyPositionMap: {},
  scheduleRestriction: {},
  positionNames: [],
};

const parseDate = (date, defaultDate = DateTime.local().minus({ days: 1 })) => (
  date
    ? DateTime.fromISO(date)
    : defaultDate
);

const modifyDivs = ({
  userId,
  divMap = {},
  divisions = [],
  isDelete,
}) => {
  const newDivs = { ...divMap };
  divisions.forEach((divId) => {
    if (!(divId in newDivs)) return;
    const ourDiv = { ...newDivs[divId] };
    const {
      users = new Set(),
    } = ourDiv;
    const newUsers = new Set(users);
    if (isDelete) {
      newUsers.delete(userId);
    } else {
      newUsers.add(userId);
    }
    ourDiv.users = newUsers;
    newDivs[divId] = ourDiv;
  });
  return newDivs;
};

const updateRoundingPolicyPositionMap = ({
  positionMap,
  roundingPolicy,
}) => {
  const { positions = [] } = roundingPolicy;
  const newPositionMap = { ...positionMap };
  positions.forEach((position) => {
    if (!newPositionMap[position]) {
      newPositionMap[position] = [];
    }
    newPositionMap[position].push(roundingPolicy);
  });
  return newPositionMap;
};

export default function settingsActions(state = initialState, action) {
  switch (action.type) {
    case GET_COMPANY:
    case UPDATE_COMPANY_OWNER: {
      const {
        company: {
          userId,
          subscriptionEnd,
          paidTier,
          userLimit,
          freeUserLimit,
          downgradeDate,
          connectedToStripe,
          connectedToQuickbooks,
          connectedToProcore,
          connectedToDocusign,
          connectedToSage,
          connectedToSage100,
          documentManagementEnabled,
          storageUsed,
          storageLimit,
          isExternalFormEnabled,
          paidPDF,
          paidFlags,
        } = {},
      } = action.payload;
      return {
        ...state,
        company: {
          ...state.company,
          userId,
          subscriptionEnd: parseDate(subscriptionEnd),
          downgradeDate: parseDate(downgradeDate, DateTime.local()),
          userLimit,
          paidTier,
          freeUserLimit,
          connectedToStripe,
          connectedToQuickbooks,
          connectedToProcore,
          connectedToDocusign,
          connectedToSage,
          connectedToSage100,
          documentManagementEnabled,
          storageUsed,
          storageLimit,
          isExternalFormEnabled,
          paidPDF,
          paidFlags,
        },
      };
    }
    case GET_COMPANY_IMAGE:
      return {
        ...state,
        company: {
          ...state.company,
          companyImageURL: action.payload.companyImageURL,
        },
      };
    case SAVE_COMPANY_IMAGE:
      return {
        ...state,
        company: {
          ...state.company,
          companyImage: action.payload.companyImage,
        },
      };
    case REORDER_AC_POSITIONS:
    case GET_COMPANY_AC_POSITIONS:
    case TOGGLE_AC_PERMISSIONS:
      return {
        ...state,
        acPositions: action.payload.acPositions,
      };
    case CREATE_POSITION:
    case RESET_PERMISSIONS:
    case TOGGLE_PERMISSIONS:
    case GET_COMPANY_POSITIONS:
    case RENAME_POSITION:
    case DELETE_POSITION: {
      const {
        positions = {},
      } = action.payload;

      const approverPositions = Object.keys(positions)
        .filter((positionName) => (
          positions[positionName].some(
            (permission) => permission === 'TEAMS_WRITE',
          )
        ));

      return {
        ...state,
        positions: action.payload.positions,
        positionNames: action.payload.positionNames,
        approverPositions: new Set(approverPositions),
      };
    }
    case GET_COMPANY_SETTINGS:
    case IMPORT_QUICKBOOKS_DATA:
    case IMPORT_INTEGRATION_DATA:
    case SAVE_COMPANY_SETTINGS: {
      const {
        company: {
          settings = {},
        } = {},
      } = state;
      return {
        ...state,
        company: {
          ...state.company,
          settings: { ...settings, ...action.payload.settings },
        },
      };
    }
    case VIEW_BILLING: {
      const {
        data: {
          billingURL,
        } = {},
      } = action.payload;
      return {
        ...state,
        company: {
          ...state.company,
          billingURL,
        },
      };
    }
    case UPDATE_USER_LIMIT: {
      const {
        userLimit,
      } = action.payload;
      return {
        ...state,
        company: {
          ...state.company,
          userLimit,
        },
      };
    }
    case CHECK_INTEGRATION_CONNECTION_STATUS:
    case DEACTIVATE_INTEGRATION: {
      const {
        data,
      } = action.payload;
      return {
        ...state,
        company: {
          ...state.company,
          ...data,
        },
      };
    }

    case CONNECT_QUICKBOOKS: {
      const {
        connectedToQuickbooks,
      } = action.payload;
      return {
        ...state,
        company: {
          ...state.company,
          connectedToQuickbooks,
        },
      };
    }
    case DISCONNECT_QUICKBOOKS: {
      const {
        payload: { id } = {},
      } = action;
      const {
        quickbookIntegrations: oldIntegrations = [],
      } = state;
      const newIntegrations = oldIntegrations.filter((integration) => integration.id !== id);
      return {
        ...state,
        company: {
          ...state.company,
          connectedToQuickbooks: newIntegrations.length > 0,
        },
        quickbookIntegrations: newIntegrations,
      };
    }

    case UPDATE_QUICKBOOKS_INTEGRATION: {
      const {
        payload: { id, newData = {} } = {},
      } = action;
      const {
        quickbookIntegrations: oldIntegrations = [],
      } = state;
      const newIntegrations = oldIntegrations.map((integration) => {
        if (integration.id !== id) return integration;
        return {
          ...integration,
          ...newData,
        };
      });
      return {
        ...state,
        quickbookIntegrations: newIntegrations,
      };
    }

    case GET_QUICKBOOKS_INTEGRATIONS: {
      const {
        payload: {
          data = [],
        } = {},
      } = action;

      return {
        ...state,
        quickbookIntegrations: data,
      };
    }

    case CONNECT_PROCORE: {
      return state;
    }
    case DISCONNECT_PROCORE: {
      return {
        ...state,
        company: {
          ...state.company,
          connectedToProcore: false,
        },
      };
    }
    case GET_PROCORE_COMPANIES: {
      const {
        data: procoreCompanies,
      } = action.payload;
      return {
        ...state,
        procoreCompanies,
      };
    }
    case GET_PROCORE_GLOBAL_CODES_LIST: {
      const {
        data: procoreGlobalCodeLists,
      } = action.payload;
      return {
        ...state,
        procoreGlobalCodeLists,
      };
    }
    case PROCORE_IMPORT: {
      const {
        data: procoreData,
      } = action.payload;
      return {
        ...state,
        procoreData,
      };
    }
    case CONNECT_DOCUSIGN: {
      return {
        ...state,
        company: {
          ...state.company,
          connectedToDocusign: true,
        },
      };
    }
    case DISCONNECT_DOCUSIGN: {
      return {
        ...state,
        company: {
          ...state.company,
          connectedToDocusign: false,
        },
      };
    }
    case DECREASE_COMPANY_STORAGE_USAGE: {
      const {
        payload: {
          deleteSize = 0,
        } = {},
      } = action;
      const {
        company = {},
      } = state;
      const { storageUsed = 0 } = company;
      return {
        ...state,
        company: {
          ...company,
          storageUsed: storageUsed - deleteSize,
        },
      };
    }
    case INCREASE_COMPANY_STORAGE_USAGE: {
      const {
        payload: {
          createSize = 0,
        } = {},
      } = action;
      const {
        company = {},
      } = state;
      const { storageUsed = 0 } = company;

      return {
        ...state,
        company: {
          ...company,
          storageUsed: storageUsed + createSize,
        },
      };
    }
    case SET_COMPANY_STORAGE_USAGE: {
      const {
        payload: {
          totalSize = 0,
        } = {},
      } = action;
      return {
        ...state,
        company: {
          ...state.company,
          storageUsed: totalSize,
        },
      };
    }
    case GET_DIVISIONS: {
      const {
        payload: {
          divisions = {},
        } = {},
      } = action;

      const newDivMap = {};
      Object.values(divisions).forEach((division) => {
        const {
          id, name, code, users = [],
        } = division;
        newDivMap[id] = {
          id,
          name,
          code,
          users: new Set(users),
        };
      });

      return {
        ...state,
        divisions: newDivMap,
      };
    }
    case CREATE_DIVISIONS: {
      const {
        payload: {
          name,
          id,
        } = {},
      } = action;
      const {
        company: {
          userId: ownerId,
        } = {},
      } = state;

      return {
        ...state,
        divisions: {
          ...state.divisions,
          [id]: {
            id,
            name,
            users: new Set([ownerId, Permissions.id]),
          },
        },
      };
    }
    case DELETE_DIVISIONS: {
      const {
        payload: {
          divisionId,
        },
      } = action;
      const {
        divisions = {},
        selectedDivisions = new Set(),
      } = state;

      const newDivisions = {
        ...divisions,
      };
      delete newDivisions[divisionId];

      const newSelected = new Set(selectedDivisions);
      newSelected.delete(divisionId);
      if (newSelected.size === 0) {
        const divIds = Object.keys(newDivisions);
        if (divIds.length > 0) newSelected.add(divIds[0]);
      }
      return {
        ...state,
        divisions: newDivisions,
        selectedDivisions: newSelected,
      };
    }
    case SET_SELECTED_DIVISIONS: {
      const {
        payload: {
          selected = new Set(),
        } = {},
      } = action;
      return {
        ...state,
        selectedDivisions: selected,
      };
    }
    case CREATE_USER: {
      const {
        payload: {
          user: {
            id,
            divisions = [],
          } = {},
        } = {},
      } = action;
      const {
        divisions: stateDivs = {},
      } = state;
      return {
        ...state,
        divisions: modifyDivs({
          userId: id,
          divMap: stateDivs,
          divisions,
        }),
      };
    }
    case UPDATE_USER_BY_ID: {
      const {
        payload: {
          user: {
            id,
            addedDivisions = [],
            removedDivisions = [],
          },
        },
      } = action;
      const {
        divisions: stateDivisions = {},
      } = state;
      let newDivs = { ...stateDivisions };
      newDivs = modifyDivs({
        userId: id,
        divMap: newDivs,
        divisions: addedDivisions,
      });
      newDivs = modifyDivs({
        userId: id,
        divMap: newDivs,
        divisions: removedDivisions,
        isDelete: true,
      });
      return {
        ...state,
        divisions: newDivs,
      };
    }
    case TRANSFER_DIVISIONS: {
      const {
        payload: {
          type,
          divisionId,
          oldDivisionId,
          keepOldDivision,
          ids = [],
        },
      } = action;
      const {
        divisions: stateDivisions = {},
      } = state;
      const newDivisions = { ...stateDivisions };
      if (type !== 'users') return state;

      ids.forEach((userId) => {
        const {
          [divisionId]: {
            users: targetUsers = new Set(),
          } = {},
        } = newDivisions;
        targetUsers.add(userId);
        newDivisions[divisionId] = {
          ...newDivisions[divisionId],
          users: new Set(targetUsers),
        };

        if (!keepOldDivision) {
          const {
            [oldDivisionId]: {
              users: oldUsers = new Set(),
            } = {},
          } = newDivisions;
          oldUsers.delete(userId);
          newDivisions[oldDivisionId] = {
            ...newDivisions[oldDivisionId],
            users: new Set(oldUsers),
          };
        }
      });

      return {
        ...state,
        divisions: { ...newDivisions },
      };
    }
    case RENAME_DIVISION: {
      const {
        payload: {
          id, name,
        } = {},
      } = action;
      const {
        divisions: stateDivisions = {},
      } = state;
      const newDivisions = { ...stateDivisions };
      if (id in newDivisions) {
        newDivisions[id] = {
          ...newDivisions[id],
          name,
        };
      }
      return {
        ...state,
        divisions: newDivisions,
      };
    }
    case UPDATE_DIVISION_CODE: {
      const {
        payload: {
          id, code,
        } = {},
      } = action;
      const {
        divisions: stateDivisions = {},
      } = state;
      const newDivisions = { ...stateDivisions };
      if (id in newDivisions) {
        newDivisions[id] = {
          ...newDivisions[id],
          code,
        };
      }
      return {
        ...state,
        divisions: newDivisions,
      };
    }
    case USER_LOGOUT: {
      // Have to clear out selected divisions on logout
      return {
        ...state,
        selectedDivisions: new Set(),
      };
    }
    case GET_SAGE_DATA: {
      const {
        payload: {
          data: sageData = {},
        } = {},
      } = action;
      return {
        ...state,
        sageData,
      };
    }
    case CONNECT_MICROSOFT365: {
      return {
        ...state,
        company: {
          ...state.company,
          connectedToMicrosoft365: true,
        },
      };
    }

    case GET_INTEGRATED_EMAILS: {
      const {
        payload: {
          emails = [],
        } = {},
      } = action;
      return {
        ...state,
        integratedEmails: emails,
      };
    }

    case GET_COMPANY_CUSTOMIZATION_TABS:
    case UPDATE_COMPANY_CUSTOMIZATION_TABS: {
      const {
        payload: {
          customizationTabs = [],
        } = {},
      } = action;
      return {
        ...state,
        tabs: customizationTabs,
      };
    }
    case GET_COMPANY_TRANSLATIONS:
    case UPDATE_COMPANY_TRANSLATIONS: {
      const {
        payload: {
          translations = [],
        } = {},
      } = action;
      return {
        ...state,
        translations,
      };
    }
    case GET_WORKING_HOURS: {
      const {
        payload: {
          workingHours = {},
        } = {},
      } = action;
      return {
        ...state,
        workingHours,
      };
    }
    case UPDATE_POSITION_WORKING_HOURS: {
      const {
        payload: {
          positionName,
          data = {},
        } = {},
      } = action;
      return {
        ...state,
        workingHours: {
          ...(state.workingHours ?? {}),
          [positionName]: data,
        },
      };
    }
    case WRITE_SAGE_TIME:
    case RESOLVE_SAGE_TIME:
    case GET_SAGE_TIME_SYNC: {
      const {
        payload: {
          data = {},
          integrationId,
        } = {},
      } = action;
      const { sageTime: stateTime = {} } = state;
      const newTime = {
        ...stateTime,
      };
      if (!newTime[integrationId]) {
        newTime[integrationId] = {};
      }
      newTime[integrationId] = {
        ...newTime[integrationId],
        ...data,
      };
      return {
        ...state,
        sageTime: newTime,
      };
    }
    case GET_SAGE_JOB_MAPPINGS:
    case UPDATE_SAGE_JOB_MAPPINGS: {
      const {
        payload: {
          data = [],
          integrationId,
        } = {},
      } = action;
      const { sageJobTypeMappings: stateMappings = {} } = state;
      const newMappings = {
        ...stateMappings,
      };
      newMappings[integrationId] = data;
      return {
        ...state,
        sageJobTypeMappings: newMappings,
      };
    }
    case GET_COMPANY_ROUNDING_POLICIES: {
      const {
        payload: {
          data = [],
        } = {},
      } = action;
      const roundingPolicyPositionMap = data.reduce((acc, policy) => (
        updateRoundingPolicyPositionMap({
          positionMap: acc,
          roundingPolicy: policy,
        })
      ), {});

      return {
        ...state,
        roundingPolicies: data,
        roundingPolicyPositionMap,
      };
    }
    case CREATE_COMPANY_ROUNDING_POLICY: {
      const {
        payload: {
          data = {},
        } = {},
      } = action;
      const { roundingPolicies = [], roundingPolicyPositionMap = {} } = state;

      return {
        ...state,
        roundingPolicies: [...roundingPolicies, data],
        roundingPolicyPositionMap: updateRoundingPolicyPositionMap({
          positionMap: roundingPolicyPositionMap,
          roundingPolicy: data,
        }),
      };
    }
    case UPDATE_COMPANY_ROUNDING_POLICY: {
      const {
        payload: {
          id,
          data = {},
        } = {},
      } = action;
      const { roundingPolicies = [], roundingPolicyPositionMap = {} } = state;
      const newRoundingPolicies = roundingPolicies.map((policy) => {
        if (policy.id !== id) return policy;
        return data;
      });

      return {
        ...state,
        roundingPolicies: newRoundingPolicies,
        roundingPolicyPositionMap: updateRoundingPolicyPositionMap({
          positionMap: roundingPolicyPositionMap,
          roundingPolicy: data,
        }),
      };
    }
    case DELETE_COMPANY_ROUNDING_POLICY: {
      const {
        payload: {
          id,
        } = {},
      } = action;
      const { roundingPolicies = [], roundingPolicyPositionMap = {} } = state;
      const newRoundingPolicies = roundingPolicies.filter((policy) => policy.id !== id);
      const newRoundingPolicyPositionMap = { ...roundingPolicyPositionMap };
      Object.keys(newRoundingPolicyPositionMap).forEach((position) => {
        newRoundingPolicyPositionMap[position] = newRoundingPolicyPositionMap[position]
          .filter((policy) => policy.id !== id);
      });

      return {
        ...state,
        roundingPolicies: newRoundingPolicies,
        roundingPolicyPositionMap: newRoundingPolicyPositionMap,
      };
    }
    case UPDATE_SCHEDULE_RESTRICTION: {
      const {
        payload: {
          positionName,
          data = {},
        } = {},
      } = action;
      return {
        ...state,
        scheduleRestriction: {
          ...(state.scheduleRestriction ?? {}),
          [positionName]: data,
        },
      };
    }
    case GET_SCHEDULE_RESTRICTION: {
      const {
        payload: {
          scheduleRestriction = {},
        } = {},
      } = action;
      return {
        ...state,
        scheduleRestriction,
      };
    }
    default:
      return state;
  }
}
