import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { Space } from 'antd';
import LuxonDatePicker from '../inputs/LuxonDatePicker';

export default function StackedDateSelector({ value = [], onChange, isDisplay }) {
  if (isDisplay) {
    return (
      <div>
        {value
          .map((dateISO) => {
            const date = DateTime.fromISO(dateISO);
            const localTimezone = DateTime.local().zoneName;
            if (date.zoneName === localTimezone) {
              return date.toFormat('yyyy-MM-dd h:mm a');
            }
            return date.isValid ? date.toFormat('yyyy-MM-dd h:mm a ZZZZ') : null;
          })
          .filter((date) => !!date)
          .map((date, idx) => (
            <div key={idx}>{date}</div>
          ))}
      </div>
    );
  }

  return (
    <Space direction="vertical">

      {value.map((date, idx) => (
        <LuxonDatePicker
          key={idx}
          value={DateTime.fromISO(date)}
          onChange={(newDate) => {
            const newDatesArray = [...value];
            newDatesArray[idx] = newDate.toISO();
            onChange(newDatesArray);
          }}
          showTime={{ format: 'hh:mm a' }}
          allowClear={false}
        />
      ))}
    </Space>
  );
}

StackedDateSelector.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string), // Array of ISO strings
  onChange: PropTypes.func,
  isDisplay: PropTypes.bool,
};

StackedDateSelector.defaultProps = {
  value: [],
  onChange: () => {},
  isDisplay: false,
};
