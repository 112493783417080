import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from 'antd';

import ProjectAddNewEquipmentDrawer from './ProjectAddNewEquipmentDrawer';
import ProjectAddExistingEquipmentDrawer from './ProjectAddExistingEquipmentDrawer';
import ProjectEquipmentList from './ProjectEquipmentList';
import {
  attachCustomDataToEquipment,
  attachQRCodeToEquipment,
} from '../../equipment/Equipment';
import { compareArrays } from '../../helpers/helpers';

import { updateEquipment } from '../../equipment/state/equipment.actions';

export default ({
  isNotDisplay,
  divisionId,
  equipment: chosen,
  onCheckChanged,
  showEquipment,
  onAdd,
  onAddExisting: propAddExisting,
  onRemove,
  projectId,
  equipmentSchedule,
  onEquipmentDateChanged,
  onEquipmentDateRemoved,
  onEditEquipment,
}) =>  {
  const dispatch = useDispatch();

  const {
    equipment = [],
    equipmentFiles = {},
  } = useSelector((state) => state.equipment);

  const [visible, setVisible] = useState(false);
  const [showGlobal,setShowGlobal] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState();
  const [errors, setErrors] = useState({});
  const [customFields, setCustomFields] = useState([]);

  const onAddNew = useCallback(() => setVisible(true),[]);
  const onAddExisting = useCallback(() => setShowGlobal(true),[]);
  const onHideExisting = useCallback(() => setShowGlobal(false),[]);

  const closeDrawer = useCallback(() => {
    setErrors({});
    setCustomFields([]);
    setVisible(false);
    setSelectedEquipment();
  }, []);

  const onCloseNew = useCallback((equipment) => {
    if(equipment) {
      const formattedEquipment = attachCustomDataToEquipment({
        equipment,
        customFields,
      });

      if (formattedEquipment.error) {
        setErrors(formattedEquipment.errorMap);
        return;
      }

      onAdd(formattedEquipment);
    }

    closeDrawer();
  },[onAdd, customFields, closeDrawer]);

  const onEditExisting = useCallback(async (data) => {
    if (!data || !selectedEquipment) {
      closeDrawer();
      return true;
    }
    const ourEquipment = equipment.find((eq) => eq.id === selectedEquipment.id);
    if (!ourEquipment) return true;
    const {
      [selectedEquipment.id]: oldFiles = [],
    } = equipmentFiles;

    
    const formattedData = attachCustomDataToEquipment({
      equipment: data,
      customFields,
    });

    if (formattedData.error) {
      setErrors(formattedData.errorMap);
      return false;
    }

    const payload = { ...formattedData };
    const {
      files: newFiles = [],
    } = payload;


    const {
      removed: removedFiles,
      added: addedFiles,
    } = compareArrays(oldFiles, newFiles);

    const oldLabels = ourEquipment.labels ?? [];
    let newLabels = payload.labels;
    if (!newLabels) newLabels = oldLabels;

    const {
      removed: removedLabels,
      added: addedLabels,
    } = compareArrays(
      oldLabels,
      newLabels,
    );

    delete payload.labels;
    setErrors({});

    const equipmentWithQR = attachQRCodeToEquipment(payload);

    const passed = await dispatch(updateEquipment(selectedEquipment.id, {
      ...equipmentWithQR,
      addedProjects: [],
      deletedProjects: [],
      addedFiles,
      removedFiles: removedFiles.map((file) => file.id),
      addedLabels: addedLabels.map((label) => ({ labelId: label.id, title: label.title })),
      removedLabels: removedLabels.map((label) => label.id),
    }));

    if (passed) {
      onEditEquipment(selectedEquipment.id, { ...equipmentWithQR, labels: newLabels })
      closeDrawer();
    }
  }, [onEditEquipment, equipment, equipmentFiles, selectedEquipment, closeDrawer]);

  const onSubmitExisting = useCallback((equipment) => {
    propAddExisting(equipment);
    setShowGlobal(false);
  },[propAddExisting]);

  const onRowClick = useCallback((record) => {
    if (isNotDisplay) return;
    setSelectedEquipment(record);
    setVisible(true);
  },[]);

  return (
  <div className='project-add-costcode-container'>
    {isNotDisplay && <Checkbox
      onChange={onCheckChanged}
      style={{ marginBottom:10 }}
      checked={showEquipment}
    >
      Add Equipment?
    </Checkbox>}
    {(showEquipment || !isNotDisplay) &&
      <div>
        <ProjectEquipmentList
          title='Equipment'
          isNotDisplay={isNotDisplay}
          onAddNew={onAddNew}
          onAddExisting={onAddExisting}
          onRemove={onRemove}
          collapsible={false}
          chosen={chosen}
          projectId={projectId}
          equipmentSchedule={equipmentSchedule}
          onEquipmentDateChanged={onEquipmentDateChanged}
          onEquipmentDateRemoved={onEquipmentDateRemoved}
          onRowClick={onRowClick}
        />
      </div>
    }
    <ProjectAddNewEquipmentDrawer
      visible={visible}
      onClose={onCloseNew}
      onEditExisting={onEditExisting}
      divisionId={divisionId}
      chosen={chosen}
      selectedEquipment={selectedEquipment}
      errors={errors}
      customFields={customFields}
      setCustomFields={setCustomFields}
      isNotDisplay={isNotDisplay}
    />
    <ProjectAddExistingEquipmentDrawer
      visible={showGlobal}
      onClose={onHideExisting}
      onSubmit={onSubmitExisting}
      divisionId={divisionId}
      chosen={chosen}
    />
  </div>);
};
