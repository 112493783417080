import React, { useCallback, useMemo, useState } from 'react';

import Subtasks from './Subtasks';
import Permissions from '../auth/Permissions';
import BreadcrumbContainer from '../common/breadcrumbContainer/breadcrumbContainer';
import FilterDropdown from '../common/FilterDropdown';
import { SUBTASK_CATEGORIES } from '../helpers/subtasks.constants';

export default function SubtasksPage() {
  const [activeFilters, setActiveFilters] = useState({
    category: new Set([
      SUBTASK_CATEGORIES.GENERAL,
      SUBTASK_CATEGORIES.EQUIPMENT,
      SUBTASK_CATEGORIES.CARD,
    ]),
  });

  const filters = [[
    [
      {
        title: 'Category',
        key: 'category',
        children: [
          {
            title: SUBTASK_CATEGORIES.GENERAL,
            key: SUBTASK_CATEGORIES.GENERAL,
          },
          {
            title: SUBTASK_CATEGORIES.EQUIPMENT,
            key: SUBTASK_CATEGORIES.EQUIPMENT,
          },
          {
            title: SUBTASK_CATEGORIES.CARD,
            key: SUBTASK_CATEGORIES.CARD,
          },
        ],
      },
    ],
  ]];

  const filterIsAtDefault = useMemo(() => {
    const {
      category = new Set(),
    } = activeFilters;
    return category.has(SUBTASK_CATEGORIES.GENERAL)
      && category.has(SUBTASK_CATEGORIES.EQUIPMENT)
      && category.has(SUBTASK_CATEGORIES.CARD);
  }, [activeFilters]);

  const query = useMemo(() => ({
    shouldJoinLinks: true,
    assigneeId: Permissions.id,
  }), [Permissions.id]);

  const onFilter = useCallback((checkedFilters, filterCategory) => {
    // Need to filter out header values
    setActiveFilters({
      ...activeFilters,
      [filterCategory]: new Set(checkedFilters),
    });
  }, [activeFilters]);

  return (
    <BreadcrumbContainer
      crumbs={[{ text: 'My Tasks', icon: 'profile' }]}
      bodyStyle={{ height: '90vh', overflowY: 'auto', overflowX: 'hidden' }}
    >
      { !!query.assigneeId?.length && (
        <Subtasks
          query={query}
          showCategory
          filter={(
            <FilterDropdown
              filters={filters}
              activeFilters={activeFilters}
              onFilter={onFilter}
            />
          )}
          filterActive={filterIsAtDefault}
          activeFilters={activeFilters}
        />
      )}
    </BreadcrumbContainer>
  );
}
