import React from 'react';
import { DatePicker } from 'antd';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import moment from 'moment';

// Convert Luxon format string to Moment format
const convertFormat = (luxonFormat) => {
  // Basic format conversion map
  const formatMap = {
    // Date
    yyyy: 'YYYY',
    yy: 'YY',
    MM: 'MM',
    M: 'M',
    dd: 'DD',
    d: 'D',
    kk: 'HH',
    k: 'H',
    hh: 'hh',
    h: 'h',
    mm: 'mm',
    m: 'm',
    ss: 'ss',
    s: 's',
    SSS: 'SSS',
    a: 'a',

    // Timezone
    Z: 'ZZ', // +/-HH:mm
    ZZZZ: 'Z', // +/-HHmm
    z: 'z', // Timezone abbreviation
  };

  let momentFormat = luxonFormat;
  Object.entries(formatMap).forEach(([luxon, momentDate]) => {
    momentFormat = momentFormat.replace(luxon, momentDate);
  });

  return momentFormat;
};

function LuxonDatePicker({
  value,
  onChange,
  format = 'yyyy-MM-dd',
  ...props
}) {
  // Convert Luxon DateTime to Moment for internal use
  const momentValue = value
    ? moment(value.toJSDate())
    : null;

  // Convert Moment back to Luxon for onChange
  const handleChange = (date) => {
    if (onChange) {
      onChange(date ? DateTime.fromJSDate(date.toDate()) : null);
    }
  };

  return (
    <DatePicker
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      value={momentValue}
      onChange={handleChange}
      format={convertFormat(format)}
    />
  );
}

LuxonDatePicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  format: PropTypes.string,
};

LuxonDatePicker.defaultProps = {
  value: undefined,
  format: 'yyyy-MM-dd hh:mm a',
};

export default LuxonDatePicker;
