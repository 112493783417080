import React from 'react';
import { DateTime } from 'luxon';
import {
  DatePicker, Input, InputNumber, Select,
} from 'antd';
import moment from 'moment';
import { COLOR_OPTIONS_MAP, parseHexString } from '../../boards/BoardCardUpload/BoardCardUploadHelpers';
import FormColorPicker from '../../common/inputs/FormColorPicker';

export default {};

const parsers = [
  (value) => DateTime.fromJSDate(value),
  (value) => DateTime.fromISO(value),
  (value) => DateTime.fromFormat(value, 'M/d/yyyy h:mm a'),
  (value) => DateTime.fromFormat(value, 'M/d/yyyy H:mm'),
  (value) => DateTime.fromFormat(value, 'yyyy-MM-dd HH:mm'),
  (value) => DateTime.fromFormat(value, 'M/d/yyyy'),
  (value) => DateTime.fromFormat(value, 'yyyy-MM-dd'),
];

export const parseColor = (color, defaultColor = '#ffffffff') => {
  if (!color) return defaultColor;
  if (color in COLOR_OPTIONS_MAP) return COLOR_OPTIONS_MAP[color];
  const parsedHex = parseHexString(color);
  return parsedHex ?? defaultColor;
};

export const parseDate = (date) => {
  const now = DateTime.local();
  if (!date) return now.toMillis();

  let parsed;

  parsers.some((parser) => {
    parsed = parser(date);
    if (parsed.isValid) return true;
    return false;
  });

  return parsed?.isValid ? parsed.toMillis() : now.toMillis();
};

export const parseCustomData = ({
  value, nameMap, defaultValue = null, validOptions = {},
}) => {
  if (!value) {
    return defaultValue;
  }
  const found = nameMap[value];
  if (!found) {
    return defaultValue;
  }
  if (!validOptions.has(found)) {
    return defaultValue;
  }
  return found;
};

export const parseNumber = (value) => {
  if (!value) return 0;
  let number = parseInt(value, 10);
  if (Number.isNaN(number)) number = 0;
  else if (!Number.isInteger(number)) number = Math.floor(number);

  if (number < 0) number = 0;

  return number;
};

export const buildMassUploadData = (rawData = {}) => {
  const { data } = rawData;
  const payload = data?.map((row) => {
    const newRow = { ...row };
    const {
      startTime,
      endTime,
    } = row;

    if (startTime > endTime) {
      newRow.endTime = startTime;
      newRow.startTime = endTime;
    }

    delete newRow.id;
    newRow.isEvent = false;
    return newRow;
  });
  return payload;
};

const getErrorStyle = (value, required) => {
  if (!required) return {};
  if (value === '') {
    return { color: 'red', borderColor: 'red' };
  }
  return {};
};

const renderColor = ({ value, onChange, required }) => (
  <FormColorPicker
    isNotDisplay
    value={value}
    onChange={(newValue) => onChange(newValue)}
    style={{ ...getErrorStyle(value, required), width: '100%' }}
  />
);

const renderSelect = ({
  value, onChange, required, options, defaultValue, multiple,
}) => (
  <Select
    allowClear={false}
    value={value}
    defaultValue={defaultValue}
    onChange={onChange}
    options={options}
    mode={multiple ? 'multiple' : undefined}
    style={{ ...getErrorStyle(value, required), width: '100%' }}
  />
);

const renderText = ({ value, onChange, required }) => (
  <Input.TextArea
    value={value}
    onChange={(e) => {
      const {
        target: {
          value: newValue,
        } = {},
      } = e;
      onChange(newValue);
    }}
    style={getErrorStyle(value, required)}
  />
);

const renderNumber = ({ value, onChange, required }) => {
  const handleChange = (val) => {
    const newValue = Math.floor(val);
    onChange(newValue);
  };
  return (
    <InputNumber
      value={value}
      onChange={handleChange}
      min={0}
      step={1}
      style={getErrorStyle(value, required)}
    />
  );
};

const renderDate = ({ value, onChange, required }) => {
  const handleChange = (newValue) => {
    const millisValue = newValue.valueOf();
    onChange(millisValue);
  };

  const momentValue = moment(value);
  return (
    <DatePicker
      value={momentValue}
      onChange={handleChange}
      showTime
      showSecond={false}
      format="YYYY-MM-DD H:mm a"
      allowClear={false}
      style={{ ...getErrorStyle(value, required), width: '100%' }}
    />
  );
};

export const renderMap = {
  color: renderColor,
  select: renderSelect,
  date: renderDate,
  text: renderText,
  number: renderNumber,
};

/**
 * Grabs a Tree Object from phaseIds
 * @param {*} phases
 * @returns {Object} projectToPhaseMap
 * {
 *  [divisionId]:
 *    [projectId]: {
 *      [phaseId]: [costcodeId, ...],
 *       ...
 *     }
 *     ...
 *   }
 * }
 */
export const getDataTreeMap = ({
  projectIdMap = {},
  divisions,
  projects,
  phases,
  costcodes,
}) => {
  const map = {};

  divisions.forEach((division) => {
    const { id } = division;
    // Add divisionId to the map if it doesn't exist
    if (!map[id]) {
      map[id] = {};
    }
  });

  projects.forEach((project) => {
    const { divisionId, id } = project;
    // Skip if divisionId doesn't exist
    if (!map[divisionId]) return;

    // Add projectId to the map if it doesn't exist
    if (!map[divisionId][id]) {
      map[divisionId][id] = {};
    }
  });

  phases.forEach((phase) => {
    const { projectId, costcodeId } = phase;
    const { divisionId } = projectIdMap[projectId] ?? {};
    // Skip if divisionId doesn't exist
    if (!map[divisionId]) return;

    // Skip if projectId doesn't exist
    if (!map[divisionId][projectId]) return;

    // Add phaseId to the map if it doesn't exist
    if (!map[divisionId][projectId][phase.id]) {
      map[divisionId][projectId][phase.id] = [];
    }
    map[divisionId][projectId][phase.id].push(costcodeId);
  });

  costcodes.forEach((costcode) => {
    const { phased, projectId } = costcode;
    if (phased) return;
    const { divisionId } = projectIdMap[projectId] ?? {};

    // Skip if divisionId doesn't exist
    if (!map[divisionId]) return;

    // Skip if projectId doesn't exist
    if (!map[divisionId][projectId]) return;

    // Add phaseId to the map if it doesn't exist
    if (!map[divisionId][projectId].Unphased) {
      map[divisionId][projectId].Unphased = [];
    }
    map[divisionId][projectId].Unphased.push(costcode.id);
  });

  return map;
};

/**
 * Grabs a Tree Object from teamIds
 * @param {*} divisions
 * @param {*} teams
 * @returns {Object} teamTreeMap
 * {
 *   [divisionId]: {
 *     [teamId]: [userId, ...],
 *     ...
 *   }
 *   ...
 * }
 */
export const getTeamTreeMap = ({
  divisions = [],
  teams = [],
}) => {
  const map = {};

  divisions.forEach((divisionId) => {
    // Add divisionId to the map if it doesn't exist
    if (!map[divisionId]) {
      map[divisionId] = {};
    }
  });

  teams.forEach((team) => {
    const { divisionIds = [], id, members = [] } = team;
    divisionIds?.forEach((divisionId) => {
    // Skip if divisionId doesn't exist
      if (!map[divisionId]) return;

      // Add teamId to the map if it doesn't exist
      if (!map[divisionId][id]) {
        map[divisionId][id] = [];
      }

      map[divisionId][id].push(...members);
    });
  });

  return map;
};
