import React, {
  useMemo,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Select, Collapse } from 'antd';
import { DateTime } from 'luxon';
import { EditOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import {
  STATUS_FILTERS,
  DEFAULT_STATUS_FILTERS,
  PRIORITY_FILTERS,
  STATUS_MAP,
  PRIORITY_MAP,
} from './subtask.constants';

import BorderlessButton from '../common/buttons/BorderlessButton';

import SearchFormFilterHeader from '../forms/CompletedForms/SearchFormFilterHeader';

import sortByString, { getIdMap } from '../helpers/helpers';

import { changeSubtaskStatus } from './state/subtasks.actions';
import { SUBTASK_DUE_DATE_CATEGORIES } from '../helpers/subtasks.constants';

const { Panel } = Collapse;

const getCols = (onStatusChange, defaultHideDone, showCategory) => {
  const cols = [{
    title: 'Status',
    dataIndex: 'status',
    sorter: (a, b) => STATUS_MAP[a.status] - STATUS_MAP[b.status],
    showSorterTooltip: false,
    sortDirections: ['descend', 'ascend'],
    filters: STATUS_FILTERS,
    defaultFilteredValue: defaultHideDone ? DEFAULT_STATUS_FILTERS : [],
    onFilter: (value, record) => record.status === value,
    render: (status, record) => (
      <Select
        options={
          STATUS_FILTERS.map((opt) => ({ label: opt.text, value : opt.value }))
        }
        value={status}
        onChange={(newStatus) => onStatusChange(record.id, {
          status: newStatus,
          title: record.title, // For some reason title is required on backend
        })}
        onClick={(e) => e.stopPropagation()}
        dropdownMatchSelectWidth={false}
      />
    ),
  }, {
    title: 'Priority',
    dataIndex: 'priority',
    sorter: (a, b) => PRIORITY_MAP[a.priority] - PRIORITY_MAP[b.priority],
    showSorterTooltip: false,
    sortDirections: ['descend', 'ascend'],
    filters: PRIORITY_FILTERS,
    onFilter: (value, record) => record.priority === value,
  }, {
    title: 'Due Date',
    dataIndex: 'dueDate',
    render: (value, record) => {
      if (!value) return '';
      const now = DateTime.local();
      const dueDateDT = DateTime.fromMillis(value);
      const isOverdue = now >= dueDateDT;
      return (
        <span style={isOverdue && record.status !== 'Done' ? { color: 'red' } : {}}>
          {dueDateDT.toLocaleString(DateTime.DATE_MED)}
        </span>
      );
    },
    sorter: (a, b) => a.dueDate - b.dueDate,
    showSorterTooltip: false,
    sortDirections: ['descend', 'ascend'],
  }];

  if (showCategory) {
    cols.push({
      title: 'Category',
      dataIndex: 'category',
      sorter: sortByString('category'),
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    });
  }

  return cols;
};

export default function SubtaskTable({
  subtasks = [],
  onTaskClick,
  onTaskEdit,
  scroll = { y: 'calc(100vh - 300px)' },
  visibleColumns = [],
  defaultHideDone,
  showCategory = false,
}) {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.users);

  const [activeKeys, setActiveKeys] = useState([]);

  const onStatusChange = useCallback((id, payload) => {
    dispatch(changeSubtaskStatus(id, payload.status));
  }, []);

  const userIdMap = useMemo(() => getIdMap(users), [users]);
  const userFilters = useMemo(() => {
    const userIdSet = new Set();
    const uFilters = [];
    subtasks.forEach(({ assigneeId }) => {
      if (assigneeId && !userIdSet.has(assigneeId)) {
        userIdSet.add(assigneeId);
        const {
          [assigneeId]: { name } = {},
        } = userIdMap;
        if (!name) return;
        uFilters.push({ text: name, value: assigneeId });
      }
    });
    return uFilters;
  });
  const fullCols = useMemo(() => (
    [{
      title: 'Title',
      dataIndex: 'title',
      sorter: sortByString('title'),
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
      render: (title, record) => (
        <span>
          {title}
          <BorderlessButton
            style={{ width: 30, background: 'transparent' }}
            iconNode={<EditOutlined style={{ marginLeft: 0 }} />}
            onClick={(e) => {
              e.stopPropagation();
              onTaskEdit(record.id);
            }}
          />
        </span>
      ),
    }, {
      title: 'Assigned User',
      dataIndex: 'assigneeId',
      render: (assigneeId) => {
        if (!assigneeId) return null;
        const {
          [assigneeId]: {
            name = '',
          } = {},
        } = userIdMap;
        return name;
      },
      filters: userFilters,
      filterSearch: true,
      onFilter: (value, record) => record.assigneeId === value,
      filterDropdown: SearchFormFilterHeader,
    }].concat(getCols(onStatusChange, defaultHideDone, showCategory))
  ), [onTaskEdit, userFilters, userIdMap, onStatusChange, defaultHideDone, showCategory]);

  const filteredCols = useMemo(() => {
    if (!visibleColumns.length) return fullCols;
    return fullCols.filter(({ title }) => visibleColumns.includes(title));
  }, [visibleColumns, fullCols]);

  const subtaskDueDateCategoryMap = useMemo(() => {
    if (!showCategory) return {};
    return subtasks.reduce((acc, subtask) => {
      const { dueDateCategory } = subtask;
      if (!acc[dueDateCategory]) acc[dueDateCategory] = [];
      acc[dueDateCategory].push(subtask);
      return acc;
    }, {});
  }, [subtasks, showCategory]);

  const TaskTable = useCallback(({ subtaskList }) => (
    <Table
      size="small"
      pagination={false}
      dataSource={subtaskList}
      columns={filteredCols}
      className={`file-list-container ${showCategory ? 'subtask-page-table' : ''}`}
      rowClassName="file-table-row"
      onRow={(record) => ({
        onClick: () => onTaskClick(record),
      })}
      scroll={scroll}
      rowKey="id"
      style={{ height: '100%' }}
    />
  ), [filteredCols, onTaskClick, scroll, showCategory]);

  const defaultActiveKeys = useMemo(() => (
    Object.values(SUBTASK_DUE_DATE_CATEGORIES).filter((category) => (
      subtaskDueDateCategoryMap[category]?.length
    ))
  ), [subtaskDueDateCategoryMap]);

  useEffect(() => {
    setActiveKeys(defaultActiveKeys);
  }, [defaultActiveKeys]);

  if (!showCategory) {
    return <TaskTable subtaskList={subtasks} />;
  }

  return (
    <Collapse
      activeKey={activeKeys}
      className="subtask-collapse"
      ghost
      onChange={setActiveKeys}
    >
      {Object.values(SUBTASK_DUE_DATE_CATEGORIES).map((category) => (
        <Panel
          header={category}
          key={category}
        >
          <TaskTable subtaskList={subtaskDueDateCategoryMap[category]} />
        </Panel>
      ))}
    </Collapse>
  );
}

/* eslint-disable react/forbid-prop-types */
SubtaskTable.propTypes = {
  subtasks: PropTypes.array,
  onTaskClick: PropTypes.func.isRequired,
  onTaskEdit: PropTypes.func.isRequired,
  scroll: PropTypes.object,
  visibleColumns: PropTypes.array,
  defaultHideDone: PropTypes.bool,
  showCategory: PropTypes.bool,
};

SubtaskTable.defaultProps = {
  subtasks: [],
  scroll: { y: 'calc(100vh - 300px)' },
  visibleColumns: [],
  defaultHideDone: false,
  showCategory: false,
};
