import axios from 'axios';
import qs from 'query-string';
import formToPDF from 'ontraccr-form-to-pdf';
import * as Sentry from '@sentry/react';

import { request, archive, uploadFiles } from '../../helpers/requests';
import { convertPDFtoJPEG } from '../../common/pdf/pdfHelpers';
import { decoratePayloadWithFileIds } from '../../common/pdf/PDFDesigner/PDFDesignerHelpers';
import { constructFormPayloadForAPI } from '../ResponderHelpers';
import {
  decorateFormWithShallowFiles,
  getStaticAttachmentFiles,
} from '../formHelpers';

// Need to create a new axios instance,
// because our default attaches a Bearer Token to all requests
const axiosFileInstance = axios.create();

const uploadPreview = async ({
  payload, data, files = [], parsedFiles = [],
}) => {
  let preview;
  if (payload && data && data.id) {
    const { data: url } = await axios.post('/forms/templates/preview', { id: data.id });
    if (url) {
      const { drawOptions } = payload;

      const pdfFiles = await Promise.all(
        Object.values(parsedFiles).map(async ({ id, name }, index) => {
          const bytes = await files[index].arrayBuffer();
          return {
            id,
            name,
            data: bytes,
          };
        }),
      );

      const pdfData = await formToPDF(payload, { drawOptions, files: pdfFiles });
      const pdfFile = new File([pdfData], `${payload.name}.pdf`, { type: 'application/pdf' });
      preview = await convertPDFtoJPEG(data.id, pdfFile);
      await axiosFileInstance.put(url, preview, {
        headers: {
          'Content-Type': 'image/jpeg',
        },
      });
    }
  }
  return preview;
};

const errorParser = (verb) => (err) => {
  const {
    message: errorMessage = 'Bad Request',
  } = err;
  return errorMessage !== 'Bad Request' ? errorMessage : `Could not ${verb} form template`;
};

const FormsService = {
  createTemplate: (payload) => request({
    call: async () => {
      const {
        idMap: staticFileIdMap = {},
        files: staticFiles,
      } = getStaticAttachmentFiles(payload);
      const parsedStaticFiles = await uploadFiles({ files: staticFiles });
      const parsedFiles = await uploadFiles({ files: payload.files });
      const parsedPayload = decoratePayloadWithFileIds({
        payload,
        files: parsedFiles,
        staticFileIdMap,
        staticFiles: parsedStaticFiles,
      });
      const { data, err } = await axios.post('/forms/templates', parsedPayload);
      const preview = await uploadPreview({
        payload: parsedPayload,
        data,
        parsedFiles,
        files: payload.files,
      });
      return { data: { formTemplate: data, preview }, err };
    },
    errMsg: errorParser('create'),
  }),
  editTemplate: (id, payload) => request({
    call: async () => {
      const {
        idMap: staticFileIdMap = {},
        files: staticFiles,
      } = getStaticAttachmentFiles(payload);
      const parsedStaticFiles = await uploadFiles({ files: staticFiles });
      const parsedFiles = await uploadFiles({ files: payload.files });
      const parsedPayload = decoratePayloadWithFileIds({
        payload,
        files: parsedFiles,
        staticFileIdMap,
        staticFiles: parsedStaticFiles,
      });
      const { data, err } = await axios.put(`/forms/templates/${id}`, parsedPayload);
      const preview = await uploadPreview({
        payload: parsedPayload,
        data,
        parsedFiles,
        files: payload.files,
      });
      return { data: { formTemplate: data, preview }, err };
    },
    errMsg: errorParser('update'),
  }),
  copyFormTemplate: (id) => request({
    call: async () => {
      const { data, err } = await axios.post(`/forms/templates/${id}/copy`);
      if (err) return { err };
      const { preview, name } = data;
      let previewFile;
      try {
        const { data: previewData } = await axiosFileInstance.get(preview, {
          responseType: 'arraybuffer',
        });
        previewFile = new File([previewData], `${name}.jpeg`, { type: 'image/jpeg' });
      } catch (e) {
        // noop
      }
      return { data: { formTemplate: data, preview: previewFile } };
    },
    errMsg: errorParser('create'),
  }),
  favoriteFormTemplate: (id, payload) => request({
    call: axios.put(`/forms/templates/${id}/favorite`, payload),
    errMsg: errorParser('create'),
  }),
  getTemplates: ({ getPreview = false } = {}) => request({
    call: async () => {
      const {
        data: {
          forms: templates = [],
          types = [],
        } = {}, err,
      } = await axios.get('/forms/templates', { params: { getFormData: true, getPreview } });
      if (!templates) return { err };
      if (!getPreview) {
        return {
          data: { templates, types },
        };
      }
      const previews = (await Promise.all(
        templates.map(async ({ preview, name, id }) => {
          try {
            const { data } = await axiosFileInstance.get(preview, {
              responseType: 'arraybuffer',
            });
            return { id, file: new File([data], `${name}.jpeg`, { type: 'image/jpeg' }) };
          } catch (e) {
            // noop
            return null;
          }
        }),
      )).filter((preview) => !!preview);
      return {
        data: {
          templates: templates.map((template) => {
            const {
              preview,
              ...rest
            } = template;
            return rest;
          }),
          types,
          previews,
        },
      };
    },
    errMsg: 'Failed to get form templates',
    hideSuccessToast: true,
  }),
  archive: (id, active) => archive({
    id, active, type: 'forms/templates',
  }),
  deleteTemplate: (id) => request({
    call: axios.delete(`/forms/templates/${id}`),
    errMsg: 'Could not delete form template',
  }),
  getTemplateDetails: (id, draftId) => request({
    call: async () => {
      const query = { getFiles: true };
      if (draftId) query.draftId = draftId;
      const { data: form, err } = await axios.get(`/forms/templates/${id}`, { params: query });
      if (!form) return { err };
      await decorateFormWithShallowFiles(form, { onlyImages: true });
      return { data: form };
    },
    errMsg: 'Could not get form template',
    hideSuccessToast: true,
  }),
  getForms: (query = {}) => request({
    call: axios.get('/forms', {
      params: query,
      paramsSerializer: qs.stringify,
    }),
    errMsg: 'Could not get forms',
    hideSuccessToast: true,
  }),
  getLightWeightForms: (query = {}) => request({
    call: axios.get('/forms/lightweight', {
      params: query,
    }),
    errMsg: 'Could not get forms',
    hideSuccessToast: true,
  }),
  getFormById: (id, query = {}) => request({
    call: async () => {
      const { data: form, err } = await axios.get(`/forms/${id}`, { params: { getStaticFiles: true, ...query } });
      if (!form) return { err };
      const { employeeSignatureURL } = form;
      if (employeeSignatureURL) {
        try {
          const { data } = await axiosFileInstance.get(employeeSignatureURL, {
            responseType: 'arraybuffer',
          });
          form.employeeSignature = new File([data], 'signature.png', { type: 'image/png' });
        } catch (sigErr) {
          Sentry.withScope(() => {
            Sentry.captureException(sigErr, { formId: id });
          });
        }
      }
      await decorateFormWithShallowFiles(
        form,
        { templateSections: form?.templateSchema?.sections },
      );
      return { data: form };
    },
    errMsg: 'Could not get form',
    hideSuccessToast: true,
  }),
  getFormStatuses: () => request({
    call: axios.get('/forms/statuses'),
    errMsg: 'Could not get forms statuses',
    hideSuccessToast: true,
  }),
  getFormApprovals: () => request({
    call: axios.get('/forms/approvals', { params: { getFormData: true } }),
    errMsg: 'Could not get form approvals',
    hideSuccessToast: true,
  }),
  approveRejectForm: (formId, payload) => request({
    call: axios.post(`/forms/${formId}/approve`, payload),
    errMsg: 'Could not approve or reject form',
    hideSuccessToast: true,
  }),
  getAssigned: (userId) => request({
    call: axios.get('/forms/assigned', { params: { userId } }),
    errMsg: 'Could not get assigned forms',
    hideSuccessToast: true,
  }),
  getAssignedTemplates: () => request({
    call: axios.get('/forms/templates/assigned'),
    errMsg: 'Could not get form templates',
    hideSuccessToast: true,
  }),
  getFormTypes: () => request({
    call: axios.get('/forms/types'),
    errMsg: 'Could not get form types',
    hideSuccessToast: true,
  }),
  createCustomTable: (payload) => request({
    call: axios.post('/forms/tables', payload),
    errMsg: 'Could not create custom form table',
  }),
  getCustomTables: () => request({
    call: axios.get('/forms/tables'),
    errMsg: 'Could not get custom form tables',
    hideSuccessToast: true,
  }),
  updateCustomTable: (id, payload) => request({
    call: axios.put(`/forms/tables/${id}`, payload),
    errMsg: 'Could not update custom form table',
  }),
  deleteCustomTable: (id) => request({
    call: axios.delete(`/forms/tables/${id}`),
    errMsg: 'Could not delete custom form table',
  }),
  getDrafts: (query = {}) => request({
    call: axios.get('/forms/drafts', { params: query }),
    errMsg: 'Could not get form drafts',
    hideSuccessToast: true,
  }),
  deleteDraft: (id) => request({
    call: axios.delete(`/forms/drafts/${id}`),
    errMsg: 'Could not delete form draft',
  }),
  markInvoiceAsPaid: (id) => request({
    call: axios.put(`/forms/${id}/paid`),
    errMsg: 'Could not mark invoice as paid',
  }),
  cancelWorkflow: (formId) => request({
    call: axios.put(`/forms/${formId}/cancel`),
    errMsg: 'Could not cancel workflow',
  }),
  resubmitEmail: (formId, payload) => request({
    call: axios.put(`/forms/${formId}/emailResubmission`, payload),
    errMsg: 'Could not resubmit email',
  }),
  submitForm: (
    {
      id,
      collected,
      responses,
      name,
      templateId,
      isOnEditStep,
      signatureFile,
      isDraft,
      draftId,
      isResubmit,
      isEdit,
      cardId,
      valueFieldId,
      dataOnly,
      clearData,
      fieldTriggerMap,
      isAutosave,
      sessionDraftId,
      poCloseMode,
      companyId,
    },
    isExternalForm,
    suppressError = false,
    config = {},
  ) => request({
    call: async () => {
      const {
        circularFieldIds = [],
      } = config;
      let draftIdToUse = draftId;
      if (isDraft && !draftIdToUse) {
        await axios.post('/forms/drafts/generateId', {
          templateId,
          formId: id,
          draftId: sessionDraftId,
        });
        draftIdToUse = sessionDraftId;
      }
      const payload = await constructFormPayloadForAPI({
        form: {
          id,
          collected,
          responses,
          name,
          templateId,
          isOnEditStep,
          isResubmit,
          isEdit,
          cardId,
          valueFieldId,
          fieldTriggerMap,
          isDraft,
          draftId: draftIdToUse,
          companyId,
        },
        addSectionId: true,
        isExternalForm,
      });
      if (!isDraft) {
        payload.dataOnly = dataOnly;
        payload.clearData = clearData;
        payload.poCloseMode = poCloseMode;
        // prevent workflow re-triggering from PO close
        if (poCloseMode) payload.dataOnly = true;
      }
      payload.data.circularFieldIds = circularFieldIds;
      let ourId = id;
      try {
        if (signatureFile) {
          const {
            projectId,
            projectIds,
          } = collected;
          const {
            data: {
              url: signedURL,
              id: returnedId,
            } = {},
          } = await axios.post('/forms/signature', { id: ourId, templateId, projectId: projectIds?.[0] || projectId });
          ourId = returnedId;
          if (signedURL) {
            await axiosFileInstance.put(signedURL, signatureFile, {
              headers: {
                'Content-Type': 'image/png',
              },
            });
          }
          const {
            data: {
              collected: existingCollected = {},
            } = {},
          } = payload;
          payload.data.collected = {
            ...existingCollected,
            employeeSignature: true,
          };
        }

        if (ourId) payload.formId = ourId;
        payload.draftId = draftIdToUse;
        let endpoint = '/forms/submit';

        if (isDraft) {
          endpoint = '/forms/drafts';
        } else if (isExternalForm) {
          endpoint = '/forms/external/submit';
        }
        const { data } = await axios.post(endpoint, payload);
        return { data };
      } catch (error) {
        throw new Error('Failed to upload signature');
      }
    },
    suppressError,
    successMsg: isAutosave ? 'Autosaved' : undefined,
  }),
  getSharedFormDraftChildren: (formId) => request({
    call: axios.get(`/forms/${formId}/children/sharedFormDrafts`),
    errMsg: 'Could not get form draft children',
    hideSuccessToast: true,
  }),
  reassignForm: (formId, payload) => request({
    call: axios.put(`/forms/${formId}/reassign`, payload),
    errMsg: 'Could not reassign form',
  }),
  reassignDraft: (draftId, payload) => request({
    call: axios.put(`/forms/drafts/${draftId}/reassign`, payload),
    errMsg: 'Could not reassign draft',
  }),
  createCustomType: (payload) => request({
    call: axios.post('/forms/types', payload),
    errMsg: 'Could not create Custom Form Type',
  }),
  deleteCustomType: (id) => request({
    call: axios.delete(`/forms/types/${id}`),
    errMsg: 'Could not delete Custom Form Type',
  }),
  updateCustomType: (id, payload) => request({
    call: axios.put(`/forms/types/${id}`, payload),
    errMsg: 'Could not change name of Custom Type',
  }),
  getExternalFormTemplates: () => request({
    call: axios.get('/forms/external'),
    errMsg: 'Could not get external form templates',
    hideSuccessToast: true,
  }),
  getExternalFormTemplateById: (id) => request({
    call: axios.get(`/forms/external/templates/${id}`),
    errMsg: 'Could not get external form template',
    hideSuccessToast: true,
  }),
  importForm: (payload) => request({
    call: axios.post('/forms/import', payload),
    errMsg: 'Could not import form',
  }),
  massApproveRejectForms: (payload) => request({
    call: axios.post('/forms/approve', payload),
    errMsg: 'Could not approve or reject forms',
  }),
  getUserFormFilterViews: () => request({
    call: axios.get('/forms/filters'),
    errMsg: 'Could not get form filter views',
    hideSuccessToast: true,
  }),
  createUserFormFilterView: (payload) => request({
    call: axios.post('/forms/filters', payload),
    errMsg: 'Could not create form filter view',
  }),
  updateUserFormFilterView: (filterViewId, payload) => request({
    call: axios.put(`/forms/filters/${filterViewId}`, payload),
    errMsg: 'Could not update form filter view',
  }),
  deleteUserFormFilterView: (filterViewId) => request({
    call: axios.delete(`/forms/filters/${filterViewId}`),
    errMsg: 'Could not delete form filter view',
  }),
  getChildForms: (templateId) => request({
    call: axios.get(`/forms/templates/${templateId}/childForms`),
    errMsg: 'Could not get child forms',
    hideSuccessToast: true,
  }),
  getFormSnapshots: (formId) => request({
    call: async () => {
      const { data: snapshots } = await axios.get(`/forms/${formId}/snapshots`);
      const parsedSnapshots = await Promise.all(snapshots.map(async (snapshot) => {
        await decorateFormWithShallowFiles(snapshot);
        return snapshot;
      }));
      return { data: parsedSnapshots };
    },
    errMsg: 'Could not get form snapshots',
    hideSuccessToast: true,
  }),
  getSubContractDetails: (subContractIds) => request({
    call: axios.get('/subcontracts/details', { params: { ids: subContractIds } }),
    errMsg: 'Could not get sub contract details',
    hideSuccessToast: true,
  }),
  cancelForms: (payload) => request({
    call: axios.post('/forms/cancel', payload),
    errMsg: 'Could not cancel forms',
  }),
};

export default FormsService;
