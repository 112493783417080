import React, { useMemo, useState } from 'react';
import * as XLSX from 'xlsx';
import PropTypes from 'prop-types';
import { Common } from 'ontraccr-common';
import SheetUploadPrompt from '../../common/excel/SheetUploadPrompt';
import StepsContainer from '../../common/containers/StepsContainer';
import UploadSelectSheet from './UploadSelectSheet';
import UploadSelectHeader from './UploadSelectHeader';
import { defaultHeaders } from './ShiftUpload.constants';
import UploadPreview from './UploadPreview';

export default function ShiftUpload({
  uploadFile,
  setUploadFile,
  currentStep,
  setMassUploadData,
}) {
  const [selectedSheetName, setSelectedSheetName] = useState();
  const [headerMapping, setHeaderMapping] = useState({});
  const [defaultDivision, setDefaultDivision] = useState([]);

  const sheets = useMemo(() => {
    if (!uploadFile) return {};
    const {
      Sheets = {},
    } = uploadFile;
    return Sheets;
  }, [uploadFile]);

  const selectedSheet = useMemo(() => {
    if (!sheets || !selectedSheetName) return [];
    const {
      [selectedSheetName]: fullSheet = {},
    } = sheets;

    const data = XLSX.utils.sheet_to_json(fullSheet, { defval: '' });
    return data;
  }, [sheets, selectedSheetName]);

  return !uploadFile || Common.isObjectEmpty(uploadFile) ? (
    <SheetUploadPrompt
      onFileChange={setUploadFile}
      templateLink="https://ontraccr-public.s3.us-west-2.amazonaws.com/Ontraccr+Scheduling+Import+Template.xlsx"
    />
  ) : (
    <StepsContainer
      currentStep={currentStep}
      steps={[
        {
          title: 'Select Sheet',
          content: (
            <UploadSelectSheet
              workbook={uploadFile}
              onSheetChanged={setSelectedSheetName}
              onDefaultDivisionChanged={setDefaultDivision}
            />
          ),
        }, {
          title: 'Select Headers',
          content: (
            <UploadSelectHeader
              defaultHeaders={defaultHeaders}
              selectedSheet={selectedSheet}
              headerMapping={headerMapping}
              setHeaderMapping={setHeaderMapping}
            />
          ),
        }, {
          title: 'Preview',
          content: (
            <UploadPreview
              selectedSheet={selectedSheet}
              headerMapping={headerMapping}
              defaultDivision={defaultDivision}
              onMassUploadDataChanged={setMassUploadData}
              defaultHeaders={defaultHeaders}
              defaultDivisionId={defaultDivision}
            />
          ),
        },
      ]}
    />
  );
}

ShiftUpload.propTypes = {
  currentStep: PropTypes.number,
  uploadFile: PropTypes.shape({
    Sheets: PropTypes.shape({}),
  }),
  setUploadFile: PropTypes.func,
  setMassUploadData: PropTypes.func.isRequired,
};

ShiftUpload.defaultProps = {
  currentStep: 0,
  uploadFile: {},
  setUploadFile: () => {},
};
