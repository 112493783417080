import PropTypes from 'prop-types';
import React, {
  useState, useCallback, useMemo, useEffect,
  useRef,
} from 'react';
import { Col, Dropdown, Menu } from 'antd';
import { Trie } from 'ontraccr-common';
import { getIdMap } from 'ontraccr-common/lib/Common';

import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';
import Colors from '../../../constants/Colors';
import OnTraccrButton from '../../../common/buttons/OnTraccrButton';

const regex = /(\[(?:\w|\W|\/|-|:| )+?\])/g;
const preRegex = /\[\w*$/g;

const isVariable = (text, variables) => {
  const sub = text.substring(1, text.length - 1);
  return variables[sub];
};
const syntaxHighlight = (text, variables) => (
  text
    .split(regex)
    .map((word, i) => {
      if (word.match(regex) !== null && isVariable(word, variables)) {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <span key={i} style={{ color: Colors.ONTRACCR_RED }}>
            {word}
          </span>
        );
      }
      // eslint-disable-next-line react/no-array-index-key
      return <span key={i}>{word}</span>;
    })
);
export default function VariableTextInput({
  textAreaProps,
  onTextChange,
  text: initialText,
  variables,
  parser,
  formatter,
  overlay,
  showAllOptions,
  maxOptions,
  visible,
}) {
  const rendererRef = useRef();
  const [parsedText, setParsedText] = useState();
  const [textRef, setTextRef] = useState();
  const [options, setOptions] = useState([]);

  const [text, setText] = useState(initialText);

  const variablesMap = useMemo(() => getIdMap(variables, 'key'), [variables]);

  const variableTrie = useMemo(() => Trie.constructTrie(variables, 'name'), [variables]);

  const onChange = useCallback((e) => {
    const {
      target: {
        value: newValue,
      } = {},
    } = e;
    setText(newValue);
    onTextChange(newValue);
  }, [
    textRef,
    text,
    onTextChange,
  ]);

  const onSelect = useCallback(({ key }) => {
    const position = textRef?.resizableTextArea.textArea.selectionEnd;
    const variable = variablesMap[key];
    if (!variable) return;
    const formattedVariable = `[${formatter ? formatter(variable) : variable.name}]`;

    // Search for tags preceeding our caret position
    const searchArea = text.slice(0, position);
    const match = preRegex.exec(searchArea);
    let index = position;
    let endIndex = index;
    if (match) {
      const { index: matchIndex } = match;
      index = matchIndex;
      endIndex = index + match[0].length;
      // If there's already an end bracket, remove it
      if (text[index + match[0].length] === ']') {
        endIndex += 1;
      }
    }
    // Slot in the full variable text
    const prefix = text.slice(0, index);
    const suffix = text.slice(endIndex, text.length);
    const newText = `${prefix}${formattedVariable}${suffix}`;

    const newPosition = prefix.length + formattedVariable.length;
    setTimeout(() => {
      textRef.focus();
      // Need this line for the cursor to move to the correct location on Safari.
      textRef
        .resizableTextArea
        .textArea
        // eslint-disable-next-line no-multi-assign
        .selectionStart = textRef
          .resizableTextArea
          .textArea
          .selectionEnd = newPosition;
    }, 50);

    setText(newText);
    onTextChange(newText);
    setOptions([]);
  }, [
    textRef,
    text,
    textRef,
    onTextChange,
    variablesMap,
  ]);

  useEffect(() => {
    setParsedText(syntaxHighlight(text, variablesMap));
    const position = textRef?.resizableTextArea?.textArea.selectionEnd;
    if (!position) return;

    // Search for tags preceeding our caret position
    const searchArea = text.slice(0, position);
    const match = searchArea.match(preRegex);

    if (!match) {
      setOptions([]);
      return;
    }

    const last = match.pop();
    // If only the prefix has been typed, show all/max options
    if (last === '[') {
      const results = showAllOptions
        ? variables
        : variables.slice(0, Math.min(variables.length, maxOptions));
      setOptions(results);
      return;
    }
    // Otherwise, search
    const searchResults = Trie.searchTrie(variableTrie, last.slice(1));
    setOptions(searchResults);
  }, [
    text,
    parser,
    variableTrie,
    variablesMap,
    textRef,
    showAllOptions,
    maxOptions,
  ]);

  useEffect(() => {
    setText(initialText);
  }, [initialText]);

  useEffect(() => {
    if (!visible) setOptions([]);
  }, [visible]);

  const syncScroll = useCallback((e) => {
    rendererRef.current.scrollTop = e.target.scrollTop;
    rendererRef.current.scrollLeft = e.target.scrollLeft;
  }, [rendererRef]);

  const variablesOverlay = useMemo(() => (
    overlay ? overlay(options, onSelect) : (
      <Menu style={{ maxHeight: 500, overflow: 'scroll', paddingTop: 0 }}>
        {options.map((option) => (
          <Menu.Item
            key={option.key}
            onClick={onSelect}
          >
            {option.name}
          </Menu.Item>
        ))}
      </Menu>
    )
  ), [onSelect, overlay, options]);

  const showVariables = useCallback(() => {
    const results = showAllOptions
      ? variables
      : variables.slice(0, Math.min(variables.length, maxOptions));
    setOptions(results);
  }, [showAllOptions, maxOptions, variables]);

  return (
    <Col style={{ width: '100%' }}>
      <OnTraccrButton title="Add Variable" onClick={showVariables} style={{ marginBottom: 10 }} />
      <div className="variable-input-container" style={{ width: '100%' }}>
        <div ref={rendererRef} className="variable-input-renderer">
          {parsedText}
        </div>
        <Dropdown
          placement="topCenter"
          visible={options.length > 0}
          overlay={variablesOverlay}
          overlayClassName="form-calculation-field-picker"
        >
          <OnTraccrTextInput
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...textAreaProps}
            textarea
            autoSize
            onRef={setTextRef}
            onChange={onChange}
            className="variable-input-input"
            value={text}
            onScroll={syncScroll}
          />
        </Dropdown>
      </div>
    </Col>
  );
}

VariableTextInput.propTypes = {
  onTagsChange: PropTypes.func.isRequired,
  onTextChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tags: PropTypes.array,
  text: PropTypes.string,
  textAreaProps: PropTypes.shape({}),
  // eslint-disable-next-line react/forbid-prop-types
  variables: PropTypes.array,
  parser: PropTypes.func.isRequired,
  formatter: PropTypes.func,
  overlay: PropTypes.func,
  showAllOptions: PropTypes.bool,
  maxOptions: PropTypes.number,
  visible: PropTypes.bool,
};

VariableTextInput.defaultProps = {
  formatter: undefined,
  overlay: undefined,
  tags: [],
  text: '',
  textAreaProps: {},
  variables: [],
  showAllOptions: undefined,
  maxOptions: 1,
  visible: undefined,
};
