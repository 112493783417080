import React, { useState, useCallback, useEffect }  from 'react';
import { Popover, PageHeader, Checkbox, Row, Col } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import OnTraccrButton from '../common/buttons/OnTraccrButton';

const options = [
  { label: 'ID', value: 'code' },
  { label: 'Description', value: 'description' },
  { label: 'Part Number', value: 'partNumber' },
  { label: 'Supplier', value: 'supplier' },
  { label: 'Material Cost', value: 'cost' },
  { label: 'Material Markup', value: 'markup' },
  { label: 'Material Price', value: 'price' },
  { label: 'Labour Cost', value: 'labourCost' },
  { label: 'Labour Markup', value: 'labourMarkup' },
  { label: 'Labour Price', value: 'labourPrice' },
  { label: 'Quantity', value: 'quantity' },
  { label: 'Quantity Allocated', value: 'quantityAllocated' },
  { label: 'Quantity Available', value: 'quantityAvailable' },
  { label: 'Threshold', value: 'quantityThreshold' },
  { label: 'Unit Type', value: 'units' },
  { label: 'Cost Code', value: 'costcode' },
  { label: 'Locations', value: 'locations' },
];
const defaultHidden = new Set(['quantityAllocated', 'quantityAvailable']);
const defaults = options.filter(({ value }) => !defaultHidden.has(value)).map((opt) => opt.value);

export default function MaterialsColumnChanger({
  onChange: propChangeCallback,
}) {
  const [selected, setSelected] = useState();

  const onChange = useCallback((newChecked) => {
    setSelected(newChecked);
    // Save to local storage
    window.localStorage.setItem('materialColumns', JSON.stringify(newChecked));
  },[]);

  useEffect(() => {
    try {
      const rawChecked = window.localStorage.getItem('materialColumns');
      const storedChecked = rawChecked ? JSON.parse(rawChecked) : defaults;
      setSelected(storedChecked);
    } catch (err) {
      //
    }
  },[]);

  useEffect(() => {
    propChangeCallback(selected);
  },[selected]);
  return (
    <Popover trigger='click' placement='bottomRight' content={
      <div style={{ width: 175 }}>
        <Row>
          <PageHeader
            title='Columns'
            style={{ padding:0,marginBottom:5,fontFamily:'raleway-medium' }}
          />
        </Row>
        <Col>
          <Checkbox.Group
            onChange={onChange}
            value={selected}
          >
            {options.map(({ label, value }) => (
              <Row>
                <Checkbox value={value}>{label}</Checkbox>
              </Row>
            ))}
          </Checkbox.Group>
        </Col>
      </div>
    }>
      <OnTraccrButton
        title='Columns'
        icon={<SettingOutlined />}
        type='cancel'
      />
    </Popover>
  );
}
