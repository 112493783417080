import React, { useEffect, useMemo } from 'react';
import { Descriptions, Select } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

export default function UploadSelectSheet({
  workbook: {
    SheetNames = [],
  } = {},
  onSheetChanged,
  onDefaultDivisionChanged,
}) {
  const divisions = useSelector((state) => state.settings.divisions);

  const sheetOptions = useMemo(() => (
    SheetNames.map((sheet) => ({ value: sheet, label: sheet }))
  ), [SheetNames]);

  const divisionOptions = useMemo(() => (
    Object.values(divisions).map((division) => ({ value: division.id, label: division.name }))
  ), [divisions]);

  useEffect(() => {
    if (divisionOptions.length > 0) {
      onDefaultDivisionChanged(divisionOptions[0].value);
    }
    if (SheetNames.length > 0) {
      onSheetChanged(SheetNames[0]);
    }
  }, [divisionOptions, SheetNames, onDefaultDivisionChanged, onSheetChanged]);

  return (
    <Descriptions title="Select Sheet">
      <Descriptions.Item label="Sheet">
        <Select
          style={{ width: 200 }}
          defaultValue={SheetNames[0]}
          onSelect={onSheetChanged}
          options={sheetOptions}
        />
      </Descriptions.Item>
      <Descriptions.Item label="Default Division">
        <Select
          style={{ width: 200 }}
          defaultValue={divisionOptions[0].value}
          onSelect={onDefaultDivisionChanged}
          options={divisionOptions}
        />
      </Descriptions.Item>
    </Descriptions>
  );
}

UploadSelectSheet.propTypes = {
  workbook: PropTypes.shape({
    SheetNames: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onSheetChanged: PropTypes.func.isRequired,
  onDefaultDivisionChanged: PropTypes.func.isRequired,
};
