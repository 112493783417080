import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import AddDrawer from '../../common/addDrawer';
import EquipmentAddView from '../../equipment/EquipmentAddView';

import Permissions from '../../auth/Permissions';

const SMALL_WIDTH = 800;
const LARGE_WIDTH = 1000;

export default ({
  visible,
  onClose,
  divisionId,
  chosen = [],
  selectedEquipment,
  errors,
  customFields,
  setCustomFields,
  isNotDisplay,
  onEditExisting,
}) => {
  const { t } = useTranslation();
  const equipment = useSelector(state => state.equipment.equipment);

  const hasWritePerms = Permissions.has('EQUIPMENT_WRITE');

  const [formData, setFormData] = useState({
    editing: !selectedEquipment,
    divisionIds: [divisionId],
    ...selectedEquipment
  });

  useEffect(() => {
    setFormData({
      editing: !selectedEquipment,
      divisionIds: [divisionId],
      ...selectedEquipment
    });
  }, [selectedEquipment, divisionId]);


  return (
    <AddDrawer
      onClose={isNotDisplay ? onClose : onEditExisting}
      visible={visible}
      title={selectedEquipment ? `${selectedEquipment.name}` : `Add New Equipment to ${t('Project')}`}
      isAdd={!selectedEquipment}
      viewOnly={selectedEquipment}
      formView={(form,formProps) => (
        <EquipmentAddView
          {...form}
          formProps={
            isNotDisplay
            ? formProps
            : { editing: false, ...formProps }
          }
          equipment={equipment}
          isProjectSpecific
          key={chosen.length}
          errors={errors}
          customFields={customFields}
          setCustomFields={setCustomFields}
        />
      )}
      onEditStarted={hasWritePerms ? () => {
        setFormData({
          ...formData,
          editing: true,
        })
      } : null}
      onEditCanceled={() => {
        setFormData({
          ...formData,
          editing: false,
        })
      }}
      formProps={formData}
      width={selectedEquipment ? LARGE_WIDTH : SMALL_WIDTH}
    />
  );
}