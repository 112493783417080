import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import {
  Drawer,
  Form,
  DatePicker,
  Select,
  TreeSelect,
  Row,
  Col,
} from 'antd';

import moment from 'moment';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CloseOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import Permissions from '../../auth/Permissions';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import DisplayText from '../../common/text/DisplayText';
import OnTraccrNumberInput from '../../common/inputs/OnTraccrNumberInput';
import {
  calculateNewEndDate,
  calculateOverlap,
  constructCostCodeTreeData,
  getFilteredCostCodes,
  getFilteredEquipment,
  getFilteredMaterials,
  getFilteredPhaseMap,
  getFilteredUsers,
  getPhaseOptions,
} from './ganttScheduleHelpers';
import FormColorPicker from '../../common/inputs/FormColorPicker';
import DrawerSubmitFooter from '../../common/containers/DrawerSubmitFooter';
import CustomConfirmModal from '../../common/modals/CustomConfirmModal';
import {
  getIdMap,
  validateFieldsOnFormUpdate,
} from '../../helpers/helpers';
import MaterialTableAddDrawer from '../../forms/FormBuilder/FormFields/MaterialTableAddDrawer';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import GanttScheduleAddEditWarning from './GanttScheduleAddEditWarning';
import ScheduleExpectedUsersInput from '../ScheduleExpectedUsersInput';
import HoverHelp from '../../common/HoverHelp';
import ScheduleUserSelector from '../ScheduleUserSelector';
import { validateExpectedUsers } from '../scheduleHelpers';

export default function GanttScheduleAddDrawer({
  onClose,
  visible,
  handleSubmit,
  row = {},
  readOnly,
  onDelete,
  userLabelMap = {},
  positionMap = {},
  showDispatchDrawer,
  showShiftSelectorModal,
  isShiftConnections,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [endDate, setEndDate] = useState();
  const [labelColor, setLabelColor] = useState('#000');
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [overlappingEquipment, setOverlappingEquipment] = useState([]);
  const [overlappingUsers, setOverlappingUsers] = useState([]);
  const [selectedPhase, setSelectedPhase] = useState(null);
  const [showMaterialDrawer, setShowMaterialDrawer] = useState(false);
  const [invalidDependencies, setInvalidDependencies] = useState([]);

  const {
    selectedDivisions,
    divisions,
  } = useSelector((state) => state.settings);

  const userToLabel = useSelector((state) => state.users.userToLabel);
  const users = useSelector((state) => state.users.users);
  const materials = useSelector((state) => state.materials.materials);
  const equipment = useSelector((state) => state.equipment.equipment);
  const {
    costcodes: costCodes,
    phases,
  } = useSelector((state) => state.costcodes);

  const {
    ganttScheduleRows: tasks,
    ganttScheduleLabels: labels,
    ganttScheduleSettings: settings,
    ganttScheduleHolidays: holidays,
  } = useSelector((state) => state.schedule);

  const labelMap = useMemo(() => getIdMap(labels), [labels]);

  useEffect(() => {
    if (row.id && visible) {
      if (form) {
        form.setFieldsValue({
          name: row.name,
          start: moment.utc(row.startDate * 1000),
          end: row.endDate,
          duration: row.duration,
          color: row.color,
          progress: row.progress,
          label: row.labelId,
          users: row.users,
          equipment: row.equipment,
          costCodes: row.costCodes,
          expectedUsers: row.expectedUsers,
          phaseId: row.phaseId,
          actualStartDate: row.actualStartDate
            ? moment.utc(row.actualStartDate * 1000)
            : null,
          actualEndDate: row.actualEndDate
            ? moment.utc(row.actualEndDate * 1000)
            : null,
          notes: row.notes,
        });

        setSelectedMaterials(row.materials);
        setSelectedPhase(row.phaseId);

        if (row.labelId) {
          setLabelColor(labelMap[row.labelId].color);
        }
      }

      setEndDate(DateTime.fromSeconds(row.endDate).toUTC().toFormat('DDD'));
    }
    return () => {
      form.resetFields();
      form.setFieldsValue({
        start: null,
      });
      setEndDate();
      setSelectedMaterials([]);
      setOverlappingEquipment([]);
      setOverlappingUsers([]);
      setSelectedPhase(null);
      setInvalidDependencies([]);
    };
  }, [form, row, visible]);

  const checkForOverlap = useCallback(() => {
    const formValues = form.getFieldsValue();

    if (!formValues.start || !formValues.end) {
      setOverlappingEquipment([]);
      setOverlappingUsers([]);
      return;
    }

    const dateRange = {
      start: formValues.start.valueOf() / 1000,
      end: formValues.end,
    };

    const {
      overlappingEquipment: overlapEquipment,
      overlappingUsers: overlapUsers,
    } = calculateOverlap({
      dateRange,
      taskId: row.id,
      tasks,
      users: formValues.users,
      equipment: formValues.equipment,
    });

    setOverlappingEquipment(overlapEquipment);
    setOverlappingUsers(overlapUsers);
  }, [form, row, tasks]);

  const {
    dependencyOptions,
    taskMap,
  } = useMemo(() => {
    const options = [];
    const map = {};
    tasks.forEach((task) => {
      if (task.id !== row.id && task.ganttScheduleId === row.ganttScheduleId) {
        options.push({
          label: task.name,
          value: task.id,
        });
      }

      map[task.id] = task;
    });

    return {
      dependencyOptions: options,
      taskMap: map,
    };
  }, [tasks, row]);

  // Parent dependencies end date that is greater than task's start date are considered invalid
  const validateDependencies = useCallback(() => {
    const {
      parentDependencies,
      childDependencies,
      start,
      end: taskEndDate,
    } = form.getFieldsValue();

    if (
      (
        (!parentDependencies || !parentDependencies.length)
        && (!childDependencies || !childDependencies.length)
      ) || !start
    ) {
      setInvalidDependencies([]);
      return;
    }

    const taskStartDate = start.valueOf() / 1000;
    const invalidDeps = [];
    const parentDependencyMap = {};

    parentDependencies.forEach((dependency) => {
      const dependencyTask = taskMap[dependency];
      parentDependencyMap[dependency] = dependencyTask;

      if (dependencyTask.endDate > taskStartDate) {
        invalidDeps.push({
          ...dependencyTask,
          warningType: 'parentDependencyDate',
        });
      }
    });

    childDependencies.forEach((dependency) => {
      const dependencyTask = taskMap[dependency];

      if (dependencyTask.startDate < taskEndDate) {
        invalidDeps.push({
          ...dependencyTask,
          warningType: 'childDependencyDate',
        });
      }

      if (parentDependencyMap[dependency]) {
        invalidDeps.push({
          ...dependencyTask,
          warningType: 'circularDependency',
        });
      }
    });

    setInvalidDependencies(invalidDeps);
  }, [form, taskMap]);

  // End date is based off duration and start date
  const getNewEndDate = useCallback((startDate, duration) => {
    const start = startDate.startOf('day');
    return calculateNewEndDate(
      start.valueOf() / 1000,
      duration,
      settings.workingDays,
      holidays,
    );
  }, [settings, holidays]);

  const adjustFieldsToUTC = useCallback((changedValues) => {
    const fieldsToAdjustForUTC = [
      'start',
      'actualStartDate',
      'actualEndDate',
    ];

    fieldsToAdjustForUTC.forEach((field) => {
      if (changedValues[field]) {
        form.setFieldsValue({
          [field]: changedValues[field].startOf('day').utcOffset(0),
        });
      }
    });
  }, [form]);

  const onValuesChange = useCallback((changedValues, allValues) => {
    adjustFieldsToUTC(changedValues);

    const {
      start,
      duration,
    } = allValues;

    if (start && duration) {
      const newEndDate = getNewEndDate(start, duration);
      setEndDate(DateTime.fromSeconds(newEndDate.toSeconds()).toUTC().toFormat('DDD'));

      if (form) {
        form.setFieldsValue({
          end: newEndDate.toSeconds(),
        });
      }
    }

    validateFieldsOnFormUpdate(
      changedValues,
      ['start', 'users', 'equipment', 'duration'],
      checkForOverlap,
    );

    validateFieldsOnFormUpdate(
      changedValues,
      ['start', 'duration', 'parentDependencies', 'childDependencies'],
      validateDependencies,
    );
  }, [
    form,
    settings,
    holidays,
    checkForOverlap,
    getNewEndDate,
    validateFieldsOnFormUpdate,
    validateDependencies,
    adjustFieldsToUTC,
  ]);

  const userMap = useMemo(() => getIdMap(users), [users]);
  const equipmentMap = useMemo(() => getIdMap(equipment), [equipment]);
  const costCodeMap = useMemo(() => getIdMap(costCodes), [costCodes]);

  const onLabelChange = useCallback((val) => {
    form.setFieldsValue({
      label: val,
    });

    setLabelColor(labelMap[val].color);
  }, [labelMap]);

  const onSubmit = async () => {
    const values = form.getFieldsValue();
    values.materials = selectedMaterials;

    try {
      await form.validateFields();
    } catch (e) {
      const relevantErrors = [];
      const fieldsToIgnore = [
        'expectedUsers',
        'users',
      ];

      e.errorFields.forEach((field) => {
        if (field.name.length !== 1 || !fieldsToIgnore.includes(field.name[0])) {
          relevantErrors.push(field);
        }
      });

      if (relevantErrors.length) {
        return;
      }
    }

    handleSubmit(values);
  };

  const onDeleteClick = () => new Promise((resolve) => {
    CustomConfirmModal({
      title: 'Delete Task',
      content: (
        <p>
          Are you sure you wish to delete this task?
        </p>
      ),
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk() {
        resolve(onDelete(row.ganttScheduleId, row.id));
      },
      onCancel() {
        resolve();
      },
    });
  });

  const phaseMap = useMemo(() => getFilteredPhaseMap({
    phases,
    selectedProjects: new Set([row.projectId]),
    selectedPhases: new Set([row.phaseId]),
  }), [
    row,
    phases,
  ]);

  const onSelectChange = useCallback((val, option) => {
    if (option === 'phaseId') {
      setSelectedPhase(val);

      const phaseCostCodes = phaseMap[val] || [];
      const costCodesMap = getIdMap(phaseCostCodes, 'costcodeId');

      const relevantCostCodeSet = new Set();
      form.getFieldValue('costCodes').forEach((costCode) => {
        const [, costCodeId] = costCode.split('.');
        if (costCodesMap[costCodeId]) {
          relevantCostCodeSet.add(`${val}.${costCodeId}`);
        }
      });

      // Only leave cost codes associated with the phase
      form.setFieldsValue({
        costCodes: Array.from(relevantCostCodeSet.keys()),
        [option]: val,
      });

      return;
    }

    form.setFieldsValue({
      [option]: val,
    });
  }, [form, phaseMap]);

  const userOptions = useMemo(() => getFilteredUsers({
    users,
    divisions,
    selectedDivisions,
    selectedUsers: new Set(row.users),
  }), [
    row,
    users,
    divisions,
    selectedDivisions,
  ]);

  const equipmentOptions = useMemo(() => getFilteredEquipment({
    equipment,
    selectedDivisions,
    selectedEquipment: new Set(row.equipment),
  }), [
    row,
    equipment,
    selectedDivisions,
  ]);

  const materialOptions = useMemo(() => getFilteredMaterials({
    materials,
    selectedDivisions,
    selectedMaterials: new Set((row.materials || []).map((material) => material.id)),
  }), [
    row,
    materials,
    selectedDivisions,
  ]);

  const costCodeOptions = useMemo(() => getFilteredCostCodes({
    costCodes,
    selectedDivisions,
    selectedCostCodes: new Set(row.costCodes),
    selectedProjects: new Set([row.projectId]),
  }), [
    row,
    costCodes,
    selectedDivisions,
  ]);

  const phaseOptions = useMemo(() => getPhaseOptions(phaseMap), [phaseMap]);
  const costCodesTreeData = useMemo(() => constructCostCodeTreeData({
    costCodes: costCodeOptions,
    costCodeMap,
    phaseMap,
    phaseIds: selectedPhase ? [selectedPhase] : [],
    t,
  }), [costCodeOptions, selectedPhase, phaseMap, costCodeMap]);

  const onMaterialSubmit = useCallback((newMaterials) => {
    setSelectedMaterials(newMaterials);
    setShowMaterialDrawer(false);
  }, []);

  const drawerTitle = useMemo(() => {
    let prefix = 'Add';

    if (row.id) {
      prefix = 'Edit';
    }

    if (readOnly) {
      prefix = 'View';
    }

    return `${prefix} Task`;
  }, [readOnly, row]);

  const header = useMemo(() => {
    if (!Permissions.has('SCHEDULE_READ') || isShiftConnections) return drawerTitle;
    return (
      <Row style={{
        display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
      }}
      >
        <Col style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
          {row?.shiftConnections?.length ? (
            <button
              type="button"
              style={{ border: 'none', background: 'none', padding: 0 }}
              aria-label="action"
              onClick={() => showShiftSelectorModal({ row })}
            >
              <HoverHelp
                content={`This task has been dispatched to ${row?.shiftConnections?.length} shift${row?.shiftConnections?.length === 1 ? '' : 's'}.`}
                Icon={InfoCircleTwoTone}
                iconProps={{ twoToneColor: '#fdb81b', style: { marginTop: 2, fontSize: '18px' } }}
              />
            </button>
          ) : null}
          {drawerTitle}
        </Col>

        {showDispatchDrawer && Permissions.has('SCHEDULE_WRITE') && (
          <Col style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
            <OnTraccrButton onClick={showDispatchDrawer} title="Dispatch" style={{ marginTop: -5 }} />
          </Col>
        )}

      </Row>
    );
  }, [drawerTitle, readOnly, row]);

  return (
    <Drawer
      title={header}
      visible={visible}
      onClose={onClose}
      width={750}
      maskClosable={readOnly}
      destroyOnClose
      closeIcon={readOnly ? <CloseOutlined /> : false}
      getContainer={false}
    >

      <Form
        form={form}
        layout="vertical"
        className="schedule-of-values-form"
        style={{
          maxWidth: '95%',
          paddingBottom: 20,
        }}
        onValuesChange={onValuesChange}
        initialValues={{
          expectedUsers: [{ type: 'default', count: row.expectedNumberOfUsers || 0, id: null }],
        }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            { required: true, message: 'Name is required' },
          ]}
        >
          { !readOnly
            ? (
              <OnTraccrTextInput
                style={{ width: '100%' }}
                placeholder="Name"
              />
            )
            : <DisplayText title={row.name} />}
        </Form.Item>
        <Form.Item
          name="notes"
          label="Notes"
        >
          {!readOnly ? (
            <OnTraccrTextInput
              textarea
              placeholder="Notes"
            />
          )
            : <DisplayText title={row.notes} />}

        </Form.Item>
        <Form.Item
          name="duration"
          label="Duration (days)"
          rules={[
            { required: true, message: 'Duration is required' },
          ]}
        >
          { !readOnly
            ? (
              <OnTraccrNumberInput
                style={{ width: '100%' }}
                placeholder="Duration"
                min={1}
              />
            )
            : <DisplayText title={`${row.duration} days`} />}
        </Form.Item>
        <Form.Item
          label="Color"
          name="color"
          style={{ marginBottom: readOnly ? 0 : 10, maxWidth: 150 }}
          labelCol={{
            style: {
              paddingBottom: 0,
              marginTop: 5,
            },
          }}
          rules={[
            { required: true, message: 'Color is required' },
          ]}
        >
          <FormColorPicker isNotDisplay={!readOnly} value="#9B9B9BFF" />
        </Form.Item>
        <Form.Item
          name="progress"
          label="Progress"
        >
          { !readOnly
            ? (
              <OnTraccrNumberInput
                style={{ width: '100%' }}
                placeholder="Progress"
                formatter={(value) => `${value}%`}
                min={0}
                max={100}
              />
            )
            : <DisplayText title={`${row.progress}%`} />}
        </Form.Item>
        <Form.Item
          name="start"
          label="Start Date"
          rules={[
            { required: true, message: 'Start date is required' },
          ]}
        >
          { !readOnly
            ? <DatePicker format="MMM Do YY" allowClear={false} />
            : (
              <DisplayText
                title={
                  row.start
                  && DateTime.fromSeconds(row.startDate).toLocaleString(DateTime.DATE_FULL)
                }
              />
            )}
        </Form.Item>
        <Form.Item
          name="end"
          label="End Date"
        >
          <DisplayText title={endDate} />
        </Form.Item>
        {
          labels.length > 0
            && (
              <Form.Item
                name="label"
                label="Label"
              >
                <Select
                  onChange={onLabelChange}
                  style={{ color: labelColor }}
                  disabled={readOnly}
                  allowClear
                >
                  {labels.map(({ id, title, color }) => (
                    <Select.Option key={id} value={id} style={{ color }}>
                      {title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )
        }
        <Form.Item
          name="actualStartDate"
          label="Actual Start Date"
        >
          { !readOnly
            ? <DatePicker format="MMM Do YY" allowClear={false} />
            : row.actualStartDate && (
              <DisplayText
                title={DateTime.fromSeconds(row.actualStartDate).toLocaleString(DateTime.DATE_FULL)}
              />
            )}
        </Form.Item>
        <Form.Item
          name="actualEndDate"
          label="Actual End Date"
        >
          { !readOnly
            ? <DatePicker format="MMM Do YY" allowClear={false} />
            : row.actualEndDate && (
              <DisplayText
                title={DateTime.fromSeconds(row.actualEndDate).toLocaleString(DateTime.DATE_FULL)}
              />
            )}
        </Form.Item>
        <Form.Item
          name="expectedUsers"
          label="Expected User Groups"
          className="error-warning-form-item"
          initialValue={row.expectedUsers || []}
          rules={[
            {
              validator: () => validateExpectedUsers({
                form,
                userToLabel,
                userLabelMap,
                userMap,
                positionMap,
              }),
            },
          ]}
        >
          <ScheduleExpectedUsersInput readOnly={readOnly} />
        </Form.Item>
        <Form.Item
          name="users"
          label="Users"
          className="error-warning-form-item"
          rules={[
            {
              validator: () => validateExpectedUsers({
                form,
                userToLabel,
                userLabelMap,
                userMap,
                positionMap,
              }),
            },
          ]}
          initialValue={row.users || []}
        >
          <ScheduleUserSelector
            disabled={readOnly}
            onChange={(val) => onSelectChange(val, 'users')}
            users={userOptions}
            showLabels
          />
        </Form.Item>
        <GanttScheduleAddEditWarning
          tasks={overlappingUsers}
          warningTitle="Overlapping Users"
          property="overlapUsers"
          itemMap={userMap}
        />
        {
          materialOptions.length > 0
            && (
              <>
                <p className="form-label">Materials</p>
                <Select
                  mode="multiple"
                  disabled
                  value={selectedMaterials.map((item) => item.id)}
                  options={materialOptions.map((item) => ({ value: item.id, label: item.name }))}
                  placeholder="No Materials"
                  style={{ paddingBottom: 10 }}
                />
                <OnTraccrButton
                  type="primary"
                  title="Manage Materials"
                  onClick={() => setShowMaterialDrawer(true)}
                  readOnly={readOnly}
                />
                <br />
                <br />
              </>
            )
        }
        <Form.Item
          name="parentDependencies"
          label="Parent Dependencies"
          initialValue={row.parentDependencies || []}
        >
          <Select
            disabled={readOnly}
            onChange={(val) => onSelectChange(val, 'parentDependencies')}
            allowClear
            options={dependencyOptions}
            placeholder="Parent Dependencies"
            mode="multiple"
            optionFilterProp="label"
          />
        </Form.Item>
        <GanttScheduleAddEditWarning
          tasks={invalidDependencies.filter((task) => task.warningType === 'parentDependencyDate')}
          warningTitle="Parent dependencies should not end after the task starts"
        />
        <GanttScheduleAddEditWarning
          tasks={invalidDependencies.filter((task) => task.warningType === 'circularDependency')}
          warningTitle="Parent dependency should not also be a child dependency"
        />
        <Form.Item
          name="childDependencies"
          label="Child Dependencies"
          initialValue={row.childDependencies || []}
        >
          <Select
            disabled={readOnly}
            onChange={(val) => onSelectChange(val, 'childDependencies')}
            allowClear
            options={dependencyOptions}
            placeholder="Child Dependencies"
            mode="multiple"
            optionFilterProp="label"
          />
        </Form.Item>
        <GanttScheduleAddEditWarning
          tasks={invalidDependencies.filter((task) => task.warningType === 'childDependencyDate')}
          warningTitle="Child dependencies should not start before the task ends"
        />
        <GanttScheduleAddEditWarning
          tasks={invalidDependencies.filter((task) => task.warningType === 'circularDependency')}
          warningTitle="Child dependency should not also be a parent dependency"
        />
        {
          equipment.length > 0
            && (
              <>
                <Form.Item
                  name="equipment"
                  label="Equipment"
                  className="error-warning-form-item"
                  initialValue={row.equipment || []}
                >
                  <Select
                    mode="multiple"
                    disabled={readOnly}
                    onChange={(val) => onSelectChange(val, 'equipment')}
                    allowClear
                    options={equipmentOptions.map((item) => ({ value: item.id, label: item.name }))}
                    placeholder="Equipment"
                    optionFilterProp="label"
                  />
                </Form.Item>
                <GanttScheduleAddEditWarning
                  tasks={overlappingEquipment}
                  warningTitle="Overlapping Equipment"
                  property="overlapEquipment"
                  itemMap={equipmentMap}
                />
              </>
            )
        }
        {
          Object.keys(phaseMap).length > 0
            && (
              <Form.Item
                name="phaseId"
                label="Phase"
              >
                <Select
                  disabled={readOnly}
                  onChange={(val) => onSelectChange(val, 'phaseId')}
                  allowClear
                  options={phaseOptions}
                  placeholder="Phase"
                />
              </Form.Item>
            )
        }
        <Form.Item
          name="costCodes"
          label="Cost Codes"
          initialValue={row.costCodes || []}
        >
          <TreeSelect
            disabled={readOnly}
            onChange={(val) => onSelectChange(val, 'costCodes')}
            allowClear
            treeData={costCodesTreeData}
            multiple
            treeNodeFilterProp="title"
            placeholder="Cost Codes"
            values={row.costCodes || []}
            treeDefaultExpandAll={selectedPhase}
          />
        </Form.Item>
      </Form>
      {!isShiftConnections && (
      <DrawerSubmitFooter
        onClose={onClose}
        onDelete={row.id ? onDeleteClick : null}
        onSubmit={onSubmit}
        canSubmit={!readOnly}
      />
      )}
      <MaterialTableAddDrawer
        visible={showMaterialDrawer}
        onClose={() => setShowMaterialDrawer(false)}
        onSubmit={onMaterialSubmit}
        selected={selectedMaterials}
        showInput
        materials={getIdMap(materialOptions)}
      />
    </Drawer>
  );
}

GanttScheduleAddDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  row: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date),
    duration: PropTypes.number,
    color: PropTypes.string,
    progress: PropTypes.number,
    expectedNumberOfUsers: PropTypes.number,
  }),
  readOnly: PropTypes.bool,
  onDelete: PropTypes.func,
  showDispatchDrawer: PropTypes.func,
  showShiftSelectorModal: PropTypes.func,
  isShiftConnections: PropTypes.bool,
  userLabelMap: PropTypes.objectOf(PropTypes.shape({})),
  positionMap: PropTypes.objectOf(PropTypes.shape({})),
};

GanttScheduleAddDrawer.defaultProps = {
  row: {},
  readOnly: false,
  onDelete: () => {},
  showDispatchDrawer: null,
  showShiftSelectorModal: null,
  isShiftConnections: false,
  userLabelMap: {},
  positionMap: {},
};
