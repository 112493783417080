import {
  Drawer, message, Select,
} from 'antd';
import PropTypes from 'prop-types';
import React, {
  useCallback, useMemo, useRef, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { filterSelectDropdown } from '../../helpers/helpers';
import ContactAddView from '../../contacts/ContactAddView';
import DrawerSubmitFooter from '../containers/DrawerSubmitFooter';
import Permissions from '../../auth/Permissions';
import { createCustomer } from '../../contacts/customers/state/customers.actions';
import OnTraccrButton from '../buttons/OnTraccrButton';

/**
 * NOTE: A component with similar functionality exists in `ContactSelector.jsx`
 *
 * Key Differences:
 * - `ContactSelector` stores values in a form while `CustomerSelector` allows setting an `onChange`
 *  handler
 * - `ContactSelector` only allows a single value for the contact while `CustomerSelector` allows
 *   multiple
 * - `ContactSelector` does not handle the actual dispatching of the contact creation while
 *   `CustomerSelector` will automatically dispatch the creation of the contact
 *
 * Overall, `ContactSelector` is better tailored as a form input while `CustomerSelector` is closer
 * to a stand-alone input component
 */
export default function CustomerSelector({
  options,
  value,
  onChange,
  disabled,
  numAnswers,
  openLimit,
  style,
  isDisplay,
  overrideDivisionIds = null,
}) {
  const hasWritePerms = Permissions.has('CUSTOMERS_WRITE');

  const dispatch = useDispatch();

  const formRef = useRef();

  const [showContactAddDrawer, setShowContactAddDrawer] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const selectMode = useMemo(() => (numAnswers > 1 || openLimit ? 'multiple' : null), [numAnswers, openLimit]);

  const selectOptions = useMemo(() => (
    options.map(({
      id: optionId,
      name: optionName,
      label: optionLabel,
    }) => {
      const label = optionLabel ?? optionName;

      return (
        <Select.Option
          value={optionId}
          key={optionId}
          label={label}
          name={optionName}
          disabled={
            !openLimit
            && numAnswers > 1
            && value.length >= numAnswers
            && !value.includes(optionId)
          }
        >
          {label}
        </Select.Option>
      );
    })), [options, value]);

  const onAdd = useCallback(() => {
    if (!hasWritePerms) return;
    setShowContactAddDrawer(true);
  }, [hasWritePerms]);

  const onClose = useCallback(async () => {
    setShowContactAddDrawer(false);
    try {
      await formRef?.current?.resetFields();
    } catch (error) {
      message.error('Something went wrong. Please try again.');
    }

    setCurrentStep(0);
  }, []);

  const handleFormDataValidation = useCallback(async () => {
    const form = formRef.current;
    return form.validateFields();
  }, [formRef.current]);

  const handleOnSubmit = useCallback(async () => {
    const form = formRef.current;
    try {
      const isValidated = await handleFormDataValidation();
      if (!isValidated) return;
      const values = await form.getFieldsValue();
      const data = await dispatch(createCustomer(values));

      const {
        customer: {
          id: customerId,
          name: customerName,
        } = {},
      } = data;

      if (customerId) {
        message.success('Customer created successfully.');

        // onSelectChange is not able to recognize the new customer option in the state so we need
        // to manually pass it along to the onChange function
        // NOTE: AntD's Select already passes a second argument along with every onChange for the
        // selected value(s), so we can just pass the new customer option as the second argument
        const newCustomerOption = { key: customerId, label: customerName, value: customerId };
        if (selectMode !== 'multiple') onChange(customerId, newCustomerOption);

        onClose();
      } else {
        message.warning('Customer could not be created. Please check your responses and try again.');
      }
    } catch (error) {
      message.error('Something went wrong. Please check your responses and try again.');
    } finally {
      setLoading(false);
    }
  }, [formRef.current, selectMode, onChange]);

  const handleOnNext = useCallback(async () => {
    setLoading(true);

    // Handle General Info Validation
    let isValidated = false;
    try {
      isValidated = await handleFormDataValidation();
    } catch (error) {
      setLoading(false);
    }
    if (!isValidated) return;

    if (currentStep === 0) {
      setCurrentStep(currentStep + 1);
      setLoading(false);
    } else if (currentStep === 1) {
      await handleOnSubmit();
    }
  }, [currentStep]);

  const handleOnBack = useCallback(() => {
    if (currentStep === 0) return;
    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  const dropdownRender = useCallback((menu) => (
    <>
      {menu}
      <div
        style={{
          padding: '8px 8px 4px 8px',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'end',
          borderTop: '1px solid #f0f0f0',
        }}
      >

        <OnTraccrButton
          onClick={onAdd}
          title="Add Customer"
        />
      </div>

    </>
  ), [onAdd]);

  return (
    <>
      <Select
        style={{ ...style, flexGrow: 1 }}
        mode={selectMode}
        onChange={onChange}
        value={value}
        showSearch={!isDisplay}
        allowClear
        disabled={!isDisplay && disabled}
        filterOption={filterSelectDropdown}
        placeholder="Select a customer"
        dropdownRender={(!isDisplay && hasWritePerms) ? dropdownRender : undefined}
        open={isDisplay ? false : undefined}
      >
        {selectOptions}
      </Select>
      <Drawer
        title="Add Customer"
        width={900}
        visible={showContactAddDrawer}
        onClose={onClose}
        destroyOnClose
      >
        <ContactAddView
          isVendor={false}
          form={{ formRef, currentStep, isAdd: true }}
          overrideDivisionIds={overrideDivisionIds}
        />
        <DrawerSubmitFooter
          onAction={onClose}
          onClose={currentStep !== 0 && handleOnBack}
          onSubmit={handleOnNext}
          actionTitle="Cancel"
          closeTitle="Back"
          submitTitle={currentStep < 1 ? 'Next' : 'Submit'}
          loading={loading}
        />
      </Drawer>
    </>
  );
}

CustomerSelector.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    number: PropTypes.string,
    label: PropTypes.string,
    subNames: PropTypes.arrayOf(PropTypes.string),
  })).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  numAnswers: PropTypes.number,
  openLimit: PropTypes.bool,
  style: PropTypes.shape({}),
  isDisplay: PropTypes.bool,
  overrideDivisionIds: PropTypes.arrayOf(PropTypes.string),
};

CustomerSelector.defaultProps = {
  value: null,
  disabled: false,
  numAnswers: 1,
  openLimit: false,
  style: {},
  isDisplay: false,
  overrideDivisionIds: null,
};
