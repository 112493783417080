/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

export default function InvisibleButton(props) {
  const {
    onClick, style, children, ...rest
  } = props;
  return (
    <button
      type="button"
      style={{
        border: 'none',
        backgroundColor: 'transparent',
        padding: 0,
        margin: 0,
        shadow: 0,
        outline: 'none',
        cursor: 'pointer',
        ...style,
      }}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
}

InvisibleButton.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.shape({}),
  children: PropTypes.node,
};

InvisibleButton.defaultProps = {
  onClick: () => {},
  style: {},
  children: null,
};
