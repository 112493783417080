import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Row, Select } from 'antd';

import { sortByLocalCompare } from '../../helpers/helpers';

export default ({
  selected,
  onChange,
  isSettings,
}) => {
  const formMappings = useSelector(state => state.boards.formMappings);
  const assignedFormTemplates = useSelector((state) => state.forms.assignedFormTemplates);

  const templateSet = useMemo(() => (
    new Set(assignedFormTemplates.map((template) => template.id))
  ), [assignedFormTemplates]);

  const relevantMappings = useMemo(() => {
    if (isSettings) return formMappings;
    return formMappings.filter((template) => {
      if (!template.useFormPermissions) return true;
      return templateSet.has(template.formTemplateId);
    });
  }, [formMappings, templateSet, isSettings]);

  const selectOptions = useMemo(() => {
    const sortedMappings = [...relevantMappings];
    sortedMappings.sort(sortByLocalCompare());
    return sortedMappings.map((mapping) => {
      const { id, name } = mapping;
      return (
      <Select.Option
        value={id}
        key={id}
        label={name}
      >
        {name}
      </Select.Option>
    )});
  },[relevantMappings]);

  return (
    <>
      <Row style={{ marginTop: 5 }}>
        Select Form Mapping
      </Row>
      <Row style={{ margin: '10px 0px' }}>
        <Select
          style={{width:'100%'}}
          allowClear
          value={selected}
          dropdownMatchSelectWidth={false}
          onChange={onChange}
        >
          {selectOptions}
        </Select>
      </Row>
    </>
  );
}