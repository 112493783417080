import React, { useMemo } from 'react';
import { Select, Space } from 'antd';
import PropTypes from 'prop-types';
import HoverHelp from '../../../../common/HoverHelp';

function TimeEntryTableDatePreload({
  configProps,
  updateConfig,
  sections = [],
}) {
  const dateTimeOptions = useMemo(() => (
    sections.reduce((acc, section) => {
      const { fields = [], name: sectionName } = section;
      return acc.concat(
        fields.reduce((fieldAcc, field) => {
          const {
            id: fieldId,
            selectedType,
            configProps: {
              title: fieldTitle,
              hideDate,
            } = {},
          } = field;
          if (selectedType !== 'dateTime' || hideDate) return fieldAcc;
          return fieldAcc.concat([{
            label: `${sectionName} - ${fieldTitle}`,
            value: fieldId,
          }]);
        }, []),
      );
    }, [])
  ), [sections]);

  const preloadDateOptions = useMemo(() => [
    { value: 'none', label: 'None' },
    { value: 'currentDate', label: 'Current Date' },
    { value: 'dateTime', label: 'Date Time Field', disabled: !dateTimeOptions.length },
  ], [dateTimeOptions]);

  const updateValue = (key) => (value) => {
    if (key === 'preloadEntryDateType' && value !== 'dateTime') {
      updateConfig({
        [key]: value,
        preloadEntryDateField: undefined,
      });
    } else {
      updateConfig({ [key]: value });
    }
  };

  return (
    <Space direction="vertical">
      <Space>
        Preload Date Field
        <HoverHelp
          style={{ marginTop: 3 }}
          content={(
            <div style={{ maxWidth: 300 }}>
              Choose how you want to prefill the date field for each time entry row. <br />
              The default is the current date. <br />
              If you choose &apos;Date Time Field&apos;, you will need to select a date time field
              from the form.
            </div>
          )}
        />
      </Space>
      <Space>
        <Select
          options={preloadDateOptions}
          style={{ width: 300 }}
          defaultValue="currentDate"
          placeholder="Select a Preload Option"
          value={configProps.preloadEntryDateType}
          onChange={updateValue('preloadEntryDateType')}
        />
        {configProps.preloadEntryDateType === 'dateTime' && (
        <Select
          options={dateTimeOptions}
          style={{ width: 300 }}
          allowClear
          placeholder="Select a Date Time Field"
          value={configProps.preloadEntryDateField}
          onChange={updateValue('preloadEntryDateField')}
        />
        )}
      </Space>
    </Space>
  );
}

/* eslint-disable react/forbid-prop-types */
TimeEntryTableDatePreload.propTypes = {
  configProps: PropTypes.object.isRequired,
  updateConfig: PropTypes.func.isRequired,
  sections: PropTypes.array,
};

TimeEntryTableDatePreload.defaultProps = {
  sections: [],
};

export default TimeEntryTableDatePreload;
