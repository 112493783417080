import React, {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Tabs } from 'antd';

import DivisionsCard from './DivisionsCard';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import CustomConfirmModal from '../../common/modals/CustomConfirmModal';

import DivisionAddModal from './DivisionAddModal';

import { getDivisions, createDivision, deleteDivision } from '../state/settings.actions';

import { getIdMap } from '../../helpers/helpers';

const { TabPane } = Tabs;

const createDivisionIdMap = (arr = []) => {
  const divMap = {};
  arr.forEach((item) => {
    if (!(item.divisionId in divMap)) divMap[item.divisionId] = [];
    divMap[item.divisionId].push(item);
  });
  return divMap;
};

const createMultiDivisionIdMap = (arr = []) => {
  const divMap = {};
  arr.forEach((item) => {
    item?.divisionIds?.forEach((divId) => {
      if (!(divId in divMap)) divMap[divId] = [];
      divMap[divId].push(item);
    });
  });
  return divMap;
};

const getFromMap = (idMap, id) => {
  const {
    [id]: res = [],
  } = idMap;
  return res;
};

export default function Divisions() {
  const dispatch = useDispatch();
  // effect to get all divisions
  useEffect(() => {
    if (dispatch) dispatch(getDivisions());
  }, [dispatch]);

  const divisions = useSelector((state) => state.settings.divisions);

  const teams = useSelector((state) => state.teams.teams);
  const users = useSelector((state) => state.users.users);
  const projects = useSelector((state) => state.projects.projects);

  const userMap = useMemo(() => getIdMap(users), [users]);
  const teamDivMap = useMemo(() => createMultiDivisionIdMap(teams), [teams]);
  const projectDivMap = useMemo(() => createDivisionIdMap(projects), [projects]);

  const divisionList = useMemo(() => {
    const decoratedDivisions = [];
    Object.values(divisions).forEach((division) => {
      const { id, users } = division;
      decoratedDivisions.push({
        ...division,
        users: Array.from(users).map((userId) => userMap[userId]),
        teams: getFromMap(teamDivMap, id),
        projects: getFromMap(projectDivMap, id),
      });
    });
    return decoratedDivisions;
  }, [divisions, userMap, teamDivMap, projectDivMap]);

  const [modalOpen, setModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState();

  const openModal = useCallback(() => setModalOpen(true), []);

  const create = useCallback(async (divisionName) => {
    if (divisionName === '' || divisionName === undefined) {
      setModalOpen(false);
      return true;
    }
    const res = await dispatch(createDivision(divisionName));
    if (res) setModalOpen(false);
    return res;
  }, [dispatch]);

  const deleteDiv = useCallback(({ id, name }) => () => (
    CustomConfirmModal({
      title: 'Delete $ division?',
      onOk: async () => {
        if (await dispatch(deleteDivision(id)) && activeTab === id) {
          const newActive = divisionList.find((division) => division.id !== id);
          if (newActive && newActive.id) setActiveTab(newActive.id);
        }
      },
    })
  ), [dispatch, activeTab, divisionList]);

  const onTabChange = useCallback((tab) => {
    if (tab !== 'add') setActiveTab(tab);
  }, []);

  useEffect(() => {
    const [{ id } = {}] = divisionList;
    if (!activeTab && id) setActiveTab(id);
  }, [divisionList, activeTab]);

  return (
    <Row id="divisions-container">
      <Tabs
        style={{
          width: '100%',
          height: '100%',
        }}
        defaultActiveKey="0"
        tabPosition="left"
        tabBarStyle={{
          marginTop: 15,
          width: 150,
        }}
        activeKey={activeTab}
        onChange={onTabChange}
      >
        {divisionList.map((division) => {
          const { id, name } = division;
          return (
            <TabPane tab={name} key={id}>
              <DivisionsCard
                {...division}
                onDelete={divisionList.length > 1 ? deleteDiv({ id, name }) : null}
              />
            </TabPane>
          );
        })}
        <TabPane
          key="add"
          tab={(
            <OnTraccrButton
              title="Add Division"
              type="primary"
              style={{
                marginTop: 0,
              }}
              onClick={openModal}
            />
)}
        />
      </Tabs>
      <DivisionAddModal
        visible={modalOpen}
        onClose={create}
      />
    </Row>
  );
}
