import React from 'react';
import StackedDateSelector from '../../../common/datepicker/StackedDateSelector';
import { currencyParser } from '../../../helpers/inputParsers';
import { currencyFormatter } from '../../../helpers/helpers';
import OnTraccrNumberInput from '../../../common/inputs/OnTraccrNumberInput';
import { getPriceFloat, hasMarkup } from '../../../materials/materialsHelpers';
import { priceToCostMap, priceToMarkupMap } from '../../forms.constants';

export default {};

export const getColumnTitle = ({
  requiredColumns,
  title,
}) => <div className={requiredColumns && 'form-required-field'}>{title}</div>;

export const getUpdatedDateColumn = ({
  title = 'Date Updated',
  dataIndex = 'updatedDate',
  width = 250,
  requiredColumns = false,
  onChange,
  isDisplay = false,
}) => ({
  title: <div className={requiredColumns && 'form-required-field'}>{title}</div>,
  dataIndex,
  width,
  render: (updatedDates, record) => {
    const safeDatesArray = typeof updatedDates === 'string' ? updatedDates.split(', ') : [];

    return (
      <StackedDateSelector
        value={safeDatesArray}
        onChange={(val) => onChange(val, record)}
        isDisplay={isDisplay}
      />
    );
  },
});

export const getCostColumn = ({
  title,
  dataIndex,
  width = 150,
  requiredColumns = false,
  onChange,
  isDisplay = false,
}) => ({
  title: getColumnTitle({ requiredColumns, title }),
  dataIndex,
  width,
  render: (cost, record) => {
    if (isDisplay) {
      if (!cost) return '$0';
      return `$${parseFloat(cost).toFixed(2)}`;
    }
    return (
      <OnTraccrNumberInput
        key={record.id}
        formatter={currencyFormatter}
        parser={currencyParser}
        step={0.01}
        precision={2}
        onChange={(val) => onChange(val, record)}
        defaultValue={cost}
      />
    );
  },
});

export const getPriceColumn = ({
  title,
  dataIndex,
  width = 100,
  requiredColumns = false,
  onChange,
  isDisplay = false,
  discount,
}) => ({
  title: getColumnTitle({ requiredColumns, title }),
  dataIndex,
  width,
  render: (price, record) => {
    if (isDisplay) {
      if (!price) return '$0';
      return `$${parseFloat(price).toFixed(2)}`;
    }
    const costKey = priceToCostMap[dataIndex];
    const markupKey = priceToMarkupMap[dataIndex];

    const initialPrice = record[costKey] && hasMarkup(record[markupKey])
      ? getPriceFloat(record[costKey], record[markupKey], discount)
      : null;
    return (
      <OnTraccrNumberInput
        key={record.id}
        formatter={currencyFormatter}
        parser={currencyParser}
        step={0.01}
        precision={2}
        onChange={(val) => onChange(val, record)}
        defaultValue={initialPrice}
            // eslint-disable-next-line react/jsx-props-no-spreading
        {...price ? { value: price } : {}}
      />
    );
  },
});
