import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Spin } from 'antd';
import {
  getCustomTables,
  getStatuses,
  getTemplates,
} from '../forms/state/forms.actions';
import { getBillingRates } from '../billingRates/state/billingRates.actions';
import { getCustomers } from '../contacts/customers/state/customers.actions';
import { getUserTeams } from '../users/state/users.actions';
import { getProjectEquipment } from '../projects/state/projects.actions';
import { getEquipment } from '../equipment/state/equipment.actions';
import { getCustomFields } from '../timecards/state/timecards.actions';
import { getLabels } from '../labels/state/labels.actions';
import { getBoardCardTemplates, getBoards } from '../boards/state/boards.actions';
import { getQueries } from './state/search.actions';
import useToggle from '../common/hooks/useToggle';
import FormsSearchDrawer from './FormsSearchDrawer';
import FormsList from '../forms/CompletedForms/FormsList';
import OnTraccrButton from '../common/buttons/OnTraccrButton';

export default function FormsSearch() {
  const dispatch = useDispatch();
  const results = useSelector((state) => state.search.formResults);
  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const { isToggled: drawerVisible, toggle: toggleVisible } = useToggle();

  const onClose = useCallback(async (promise) => {
    toggleVisible();
    if (promise) {
      setSearching(true);
      await promise;
      setSearching(false);
    }
  }, [toggleVisible]);

  useEffect(() => {
    const get = async () => {
      setLoading(true);
      await Promise.all([
        dispatch(getTemplates({ getPreview: true })),
        dispatch(getBillingRates()),
        dispatch(getStatuses()),
        dispatch(getCustomers()),
        dispatch(getCustomTables()),
        dispatch(getUserTeams()),
        dispatch(getEquipment()),
        dispatch(getProjectEquipment()),
        dispatch(getCustomFields()),
        dispatch(getBoards()),
        dispatch(getBoardCardTemplates()),
        dispatch(getLabels()),
      ]);
      setLoading(false);
    };
    get();
  }, []);

  const SearchButton = useMemo(() => (
    <>
      <OnTraccrButton
        title="Open Search"
        icon={<SearchOutlined />}
        style={{ marginLeft: 10 }}
        onClick={toggleVisible}
        disabled={loading || searching}
      />
      {(loading || searching) ?? <Spin /> }
    </>
  ), [toggleVisible, loading, searching]);

  return (
    <Col>
      <FormsList
        forms={results}
        hideDate
        customSearch={SearchButton}
      />
      <FormsSearchDrawer
        visible={drawerVisible}
        onClose={onClose}
      />
    </Col>
  );
}
