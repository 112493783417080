import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Descriptions,
  Select,
  Row,
  Col,
} from 'antd';

import HoverHelp from '../../common/HoverHelp';

import { stripText } from '../../common/excel/excelHelpers';

export default function UploadSelectHeader({
  title,
  helpText,
  selectedSheet = [],
  defaultHeaders,
  headerMapping,
  setHeaderMapping,
}) {
  const sheetHeaders = useMemo(() => {
    if (!selectedSheet || selectedSheet.length === 0) return [];
    const firstRow = selectedSheet.find((row = {}) => Object.keys(row).length > 0);
    return Object.keys(firstRow);
  }, [selectedSheet]);

  const matchHeader = useCallback((key) => {
    let match;
    defaultHeaders.forEach((h) => {
      const rk = stripText(key);
      const strippedTitle = stripText(h.title);
      if (rk === strippedTitle) match = h.key;
    });
    return match;
  }, [defaultHeaders]);

  const onSelect = useCallback((key) => (newValue) => {
    setHeaderMapping({
      ...headerMapping,
      [key]: newValue,
    });
  }, [headerMapping]);

  useEffect(() => {
    const initialMapping = {};
    sheetHeaders.forEach((header) => {
      const foundMatch = matchHeader(header);
      if (foundMatch) initialMapping[foundMatch] = header;
    });
    setHeaderMapping(initialMapping);
  }, [sheetHeaders, matchHeader]);

  const options = useMemo(() => sheetHeaders.map((header) => (
    { value: header, label: header }
  )), [sheetHeaders]);

  return (
    <>
      <Row>
        <Col style={{
          fontSize: '16px',
          fontWeight: 'bold',
          marginRight: '5px',
          marginBottom: 25,
        }}
        >
          {title}
        </Col>
        {helpText && (
          <Col>
            <HoverHelp
              placement="topRight"
              content={helpText}
            />
          </Col>
        )}
      </Row>
      <Descriptions title="Select Column Headers" bordered>
        {defaultHeaders.map((header) => (
          <Descriptions.Item label={header.title} key={header.key} style={{ width: '100%' }}>
            <Select
              value={headerMapping[header.key]}
              style={{ width: '10em' }}
              onSelect={onSelect(header.key)}
              options={options}
            />
          </Descriptions.Item>
        ))}
      </Descriptions>
    </>
  );
}
UploadSelectHeader.propTypes = {
  selectedSheet: PropTypes.arrayOf(PropTypes.shape({})),
  headerMapping: PropTypes.shape({}),
  setHeaderMapping: PropTypes.func.isRequired,
  defaultHeaders: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string,
  helpText: PropTypes.element,
};

UploadSelectHeader.defaultProps = {
  selectedSheet: [],
  headerMapping: {},
  defaultHeaders: [],
  title: 'Column Headers',
  helpText: null,
};
