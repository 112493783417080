import { React, useCallback, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { Switch, Typography } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

import {
  autoBreakDurationOptions,
  autoBreakMinTimeOptions,
  autoBreakModeOptions,
  autoBreakPlacementOptions,
  autoBreakRows,
} from './timeTrackingConstants';

import CompanySettingsCard from '../CompanySettingsCard';
import CheckRows from '../CheckRows';
import TimeTrackingSelect from './TimeTrackingSelect';

const formatMinTime = (option) => `${option} ${option > 1 ? 'hours' : 'hour'}`;
const formatBreakDuration = (option) => `${option} minutes`;
function AutomaticBreaks({
  settings,
  settings: {
    autoBreakEnabled,
    autoBreakMode = autoBreakModeOptions[0],
    autoBreakPlacement = autoBreakPlacementOptions[0],
    autoBreakMinTime = autoBreakMinTimeOptions[0],
    autoBreakDuration = autoBreakDurationOptions[0],
  } = {},
  saveCompanySettings,
}) {
  const onSettingChange = useCallback((setting) => (value) => saveCompanySettings({
    [setting]: value,
  }), [saveCompanySettings]);

  const onToggle = useCallback((checked) => saveCompanySettings({
    autoBreakEnabled: checked,
    autoBreakMode,
    autoBreakPlacement,
    autoBreakMinTime,
    autoBreakDuration,
  }), [saveCompanySettings, autoBreakMode, autoBreakMinTime, autoBreakDuration]);

  const displayRows = useMemo(() => {
    if (!autoBreakMode || autoBreakMode === 'Subtract') return autoBreakRows;
    return autoBreakRows.filter(({ key }) => key !== 'autoBreakPlacement');
  }, [autoBreakMode]);

  const getRowView = useCallback((index) => {
    const row = displayRows[index];
    switch (row.key) {
      case 'autoBreakMode':
        return (
          <TimeTrackingSelect
            data={autoBreakModeOptions}
            defaultValue={autoBreakMode}
            onChange={onSettingChange('autoBreakMode')}
          />
        );
      case 'autoBreakPlacement':
        return (
          <TimeTrackingSelect
            data={autoBreakPlacementOptions}
            defaultValue={autoBreakPlacement}
            onChange={onSettingChange('autoBreakPlacement')}
          />
        );
      case 'autoBreakMinTime':
        return (
          <TimeTrackingSelect
            data={autoBreakMinTimeOptions}
            format={formatMinTime}
            defaultValue={autoBreakMinTime}
            onChange={onSettingChange('autoBreakMinTime')}
          />
        );
      case 'autoBreakDuration':
        return (
          <TimeTrackingSelect
            data={autoBreakDurationOptions}
            format={formatBreakDuration}
            defaultValue={autoBreakDuration}
            onChange={onSettingChange('autoBreakDuration')}
          />
        );
      default:
        return null;
    }
  }, [settings, autoBreakEnabled, saveCompanySettings, displayRows]);

  return (
    <CompanySettingsCard
      title="Automatic Breaks"
      leftHeaderView={(
        <Switch
          style={{
            opacity: 1,
          }}
          checked={autoBreakEnabled}
          defaultChecked={false}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={onToggle}
        />
      )}
    >
      {autoBreakEnabled && (
        <>
          <div
            style={{ marginTop: 10 }}
          >
            <Typography.Text>
              Make sure to configure auto break permissions under Roles & Permissions!
            </Typography.Text>
          </div>
          <CheckRows
            data={displayRows}
            getView={getRowView}
            onChange={async (item) => {
              await saveCompanySettings({ [item.key]: !settings[item.key] });
            }}
            isChecked={(item) => settings[item.key]}
          />
        </>
      )}
    </CompanySettingsCard>
  );
}

AutomaticBreaks.propTypes = {
  settings: PropTypes.shape({
    autoBreakEnabled: PropTypes.bool,
    autoBreakMinTime: PropTypes.number,
    autoBreakDuration: PropTypes.number,
  }),
  saveCompanySettings: PropTypes.func.isRequired,
};
AutomaticBreaks.defaultProps = {
  settings: {},
};

export default AutomaticBreaks;
