import React from 'react';
import { Drawer, Row } from 'antd';

import OnTraccrButton from '../common/buttons/OnTraccrButton';

export default ({
  title,
  visible,
  onClose,
  onSubmit,
  width = 400,
  maskClosable = false,
  children,
  canSubmit = false,
  copyMoveLoading,
}) => (
  <Drawer
    title={title}
    width={width}
    visible={visible}
    maskClosable={maskClosable}
    onClose={onClose}
  >
    {children}
    <div className='drawer-footer'>
      <Row justify='end' gutter={10}>
        <OnTraccrButton
          title='Cancel'
          type='cancel'
          style={{ marginRight: 8 }}
          onClick={onClose}
          loading={copyMoveLoading}
        />
        <OnTraccrButton
          title='Submit'
          disabled={!canSubmit}
          onClick={onSubmit}
          loading={copyMoveLoading}
        />
      </Row>
    </div>
  </Drawer>
);