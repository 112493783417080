import sortByString, { sortByLocalCompare } from '../helpers/helpers';

export default {};

export const getDisplayColumns = ({
  divisions,
  statuses,
  equipmentTypeMap,
}) => {
  const cols = [
    {
      title: 'ID',
      dataIndex: 'code',
      width: 50,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
      sorter: sortByString('code'),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: 200,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
      sorter: sortByLocalCompare('name'),
    }, {
      title: 'Type',
      dataIndex: 'equipmentTypeId',
      width: 200,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
      render: (equipmentTypeId) => (
        equipmentTypeMap[equipmentTypeId]?.name ?? 'None'
      ),
      sorter: (a, b) => {
        const aVal = (equipmentTypeMap[a.equipmentTypeId]?.name ?? 'None')?.toLowerCase()?.trim();
        const bVal = (equipmentTypeMap[b.equipmentTypeId]?.name ?? 'None')?.toLowerCase()?.trim();
        if (aVal < bVal) return -1;
        if (aVal > bVal) return 1;
        return 0;
      },
    }, {
      title: 'Status',
      dataIndex: 'statusId',
      width: 200,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
      render: (statusId) => (
        statuses[statusId]?.status
      ),
      sorter: (a, b) => {
        const aVal = (statuses[a.statusId]?.status || '~')?.toLowerCase()?.trim();
        const bVal = (statuses[b.statusId]?.status || '~')?.toLowerCase()?.trim();
        if (aVal < bVal) return -1;
        if (aVal > bVal) return 1;
        return 0;
      },
    }];
  if (Object.keys(divisions).length === 1) return cols;
  return cols.concat([{
    title: 'Divisions',
    dataIndex: 'divisionIds',
    width: 200,
    showSorterTooltip: false,
    sortDirections: ['descend', 'ascend'],
    render: (divisionIds) => (
      divisionIds.map((divId) => divisions?.[divId]?.name)
        .filter((divName) => !!divName)
        .join(', ')
    ),
  }]);
};
